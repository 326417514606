import { DatePipe, NgIf, NgClass } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { dateFormat } from 'src/app/shared/date-format';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-financial-movement',
    templateUrl: './financial-movement.component.html',
    styleUrls: ['./financial-movement.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, NgIf, MatCard, MatCardContent, MatFormField, MatLabel, MatDatepickerInput, FormsModule, MatInput, NgxMaskDirective, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, NgClass, MatRadioGroup, MatRadioButton, ButtonComponent, LoadingSpinnerComponent, DatePipe]
})
export class FinancialMovementComponent {
  public currentDate = new Date();
  public isLoading = false;
  public timeoutError = false;
  public success: boolean = false;
  public generationError = false;
  public dataLabelInicio: Date | undefined;
  public dataLabelFinal: Date | undefined;
  public dateError = false;
  public file: any;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public dataInicioRelatorio = new FormControl('', [Validators.required, dateFormat.financialMovementValidate]);
  public dataFimRelatorio = new FormControl('', [Validators.required, dateFormat.financialMovementValidate]);
  public reportType = 'Analitico'
  public reportFormat = 'xlsx'
  public emptyDocument = false;

  constructor(private apiService: ApiHttpService, private userService: UserService, public dialog: MatDialog, private datePipe: DatePipe, private router: Router) {

    const firstDayOfCurrentMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    this.dataInicioRelatorio.setValue(this.datePipe.transform(firstDayOfCurrentMonth, 'dd/MM/yyyy'));
    this.dataLabelInicio = firstDayOfCurrentMonth;

    const today = new Date();
    this.dataLabelFinal = today;
    this.dataFimRelatorio.setValue(this.formatDateDefault(this.dataLabelFinal as any))

  }

  ngOnInit() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    this.dataInicioRelatorio.setValue(this.formatDate(firstDayOfMonth));
  }

  back() {
    this.success = false
  }

  getFormattedLabel(): string {
    if (this.reportFormat === 'xlsx') {
      return 'BAIXAR RELATÓRIO (.XLS)';
    }
    return `BAIXAR RELATÓRIO (.${this.reportFormat.toUpperCase()})`;
  }

  onInputChangeInicial(isCalendar: boolean) {
    this.generationError = false;
    if (this.dataInicioRelatorio.value?.length === 0) {
      this.dataLabelInicio = undefined;
    }
    if (this.dataInicioRelatorio && this.dataInicioRelatorio.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataInicial2 = this.dataInicioRelatorio.value ?
        this.getDateByString(this.dataInicioRelatorio.value) : null;
      if (!isCalendar) {
        this.dataLabelInicio = dataInicial2 || undefined
        this.validateDataFinal();
      }
    }
  }

  onInputChangeFinal(isCalendar: boolean) {
    this.generationError = false;
    if (isCalendar) {
      this.dataFimRelatorio.setValue(this.formatDateDefault(this.dataLabelFinal as any))
    }

    if (this.dataFimRelatorio.value?.length === 0) {
      this.dataLabelFinal = undefined;
    }
    if (this.dataFimRelatorio && this.dataFimRelatorio.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataFinal2 = this.dataFimRelatorio.value ?
        this.getDateByString(this.dataFimRelatorio.value) : null;
      if (!isCalendar) {
        this.dataLabelFinal = dataFinal2 || undefined
      }
    }
    this.validateDataFinal()
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  validateDataFinal() {
    const isDataInicialValid = this.dataInicioRelatorio.value && this.dataInicioRelatorio.value.replace(/[/_]/g, "").length == 8;
    const isDataFinalValid = this.dataFimRelatorio.value && this.dataFimRelatorio.value.replace(/[/_]/g, "").length == 8;
    const dataInicio = isDataInicialValid ? new Date(this.getDateByString(this.dataInicioRelatorio.value || '')) : null;
    const dataFim = isDataFinalValid ? new Date(this.getDateByString(this.dataFimRelatorio.value || '')) : null;
    if (dataInicio && dataFim && dataInicio > dataFim) {
      this.dateError = true;
      this.dataFimRelatorio.setErrors({ "incorrect": true })
    } else {
      console.log('entrou')
      this.dateError = false;
      const errors = { ...this.dataFimRelatorio.errors };
      delete errors['incorrect'];
      this.dataFimRelatorio.setErrors(Object.keys(errors).length !== 0 ? errors : null);
    }
    this.dataFimRelatorio.markAllAsTouched()
  }

  initialFormatDate(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  sendRequest = (): void => {
    this.isLoading = true;

    const dataInicio = this.dataInicioRelatorio.value;
    const dataFinal = this.dataFimRelatorio.value;
    const tipoRelatorio = this.reportType;
    const formatoArquivo = this.reportFormat;

    if (dataInicio && dataFinal && dataInicio.trim() !== '' && dataFinal.trim() !== '') {
      const [dia, mes, ano] = dataInicio.split('/');
      const [diaFinal, mesFinal, anoFinal] = dataFinal.split('/');
      const dataInicioObj = new Date(Number(ano), Number(mes) - 1, Number(dia));
      const dataFinalObj = new Date(Number(anoFinal), Number(mesFinal) - 1, Number(diaFinal));

      const dataInicioFormatada = this.formatDate(dataInicioObj);
      const dataFinalFormatada = this.formatDate(dataFinalObj);

      this.apiService.getFinancialMovement(dataInicioFormatada, dataFinalFormatada, tipoRelatorio, formatoArquivo)
        .pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              this.timeoutError = true;
              return throwError('A requisição demorou muito tempo e foi cancelada.');
            }
            return throwError(error);
          })
        )
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            
            this.isLoading = false;
            console.log(response)
            if (response.status == 204) {
              this.emptyDocument = true;
            }
            else {
              if (response.body) {
                this.emptyDocument = false;
                const isPDF = this.reportFormat === 'pdf';
                const monthName = this.getMonthName(dataFinalObj);
                const fileExtension = isPDF ? 'pdf' : 'xlsx';
                const contentType = isPDF ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    
                const fileType = this.reportType === 'Analitico' ? 'Analítico' : 'Sintético';
                const fileName = `Conciliação Movimentação Financeira ${fileType} - ${monthName}.${fileExtension}`;
    
                const file = new Blob([response.body], { type: contentType });
                const fileURL = URL.createObjectURL(file);
                this.file = { name: fileName, url: fileURL };
                this.success = true;
              } else {
                this.success = false;
                this.generationError = true;
                this.emptyDocument = false;
              }
              
            }
        },
          error: (error) => {
            this.isLoading = false;

            if (!this.timeoutError) {
              this.success = false;
            }

            console.log(error);
            this.generationError = true;
            this.emptyDocument = false;
          },
        });
    } else {
      this.isLoading = false;
      this.generationError = true;
    }
  }

  onReportTypeChange(): void {
    if (this.reportType === 'Analitico' && this.reportFormat === 'pdf') {
      this.reportFormat = 'xlsx';
    }
  }

  getBack() {
    this.router.navigate(['area']);
  }

  formatDateDefault(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  private getMonthName(date: Date): string {
    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];
    const monthIndex = date.getMonth();
    return monthNames[monthIndex];
  }

      downloadFile = () : void => {
        const fileName = this.file.name;
        const fileURL = this.file.url;
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = fileName;
        downloadLink.click();
      }

}

import { DatePipe, formatDate, NgIf } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/format-datepicker/format-datepicker';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatButton } from '@angular/material/button';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'segregation-report',
    templateUrl: './segregation-report.component.html',
    styleUrls: ['./segregation-report.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, NgIf, MatCard, MatCardContent, MatFormField, MatLabel, 
        MatDatepickerInput, FormsModule, MatInput, NgxMaskDirective, ReactiveFormsModule, MatDatepickerToggle, 
        MatSuffix, MatDatepicker, MatError, MatRadioGroup, MatRadioButton, MatButton, ButtonComponent, DatePipe,
        LoadingSpinnerComponent]
})
export class SegregationReportComponent {
    pipe = new DatePipe('en-US');
    public dataLabelBase: Date;
    public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    public currentDate = new Date();
    public success: boolean = false;
    public dateError = false;
    public emptyDocument = false;
    public generationError = false;
    public maxDate!: Date;
    public newCurrentDate = new Date();
    public timeoutError = false;
    public file: any;

    public dataBase = new FormControl('', [
        Validators.required,
        dateFormat.positionValidator,
    ]);
    public periodAnual = new FormControl(false, []);
    public reportTypeAnalitico = new FormControl(true, []);
    public isLoading: boolean = false;

    constructor(
        private apiService: ApiHttpService,
        public dialog: MatDialog,
        private router: Router
    ) {
        this.currentDate.setDate(this.currentDate.getDate());
        this.dataLabelBase = this.currentDate;
        this.maxDate = this.newCurrentDate;
    }

    getBack() {
        this.router.navigate(['area']);
    }

    onInputChange() {
        this.generationError = false;
        const dataInicio = this.dataBase.value
            ? new Date(this.initialFormatDate(this.dataBase.value))
            : null;
        if (dataInicio) {
            this.dateError = true;
        } else {
            this.dateError = false;
        }
    }

    initialFormatDate(dateString: string) {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    }

    sendRequest = (): void => {
        this.isLoading = true;
        let formattedDate = '';
        if (this.dataBase.value) {
            const [dia, mes, ano] = this.dataBase?.value?.split('/');
            formattedDate = `${ano}-${mes}-${dia}`;
        }
        this.apiService.getSegregationReport(formattedDate,
            this.periodAnual.value,
            this.reportTypeAnalitico.value)
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    if (response.status === 204) {
                        this.isLoading = false;
                        this.emptyDocument = true;
                    } else {
                        this.isLoading = false;
                        if (response.body) {
                            const fileName = `Relatório de segregação curto e longo prazo.pdf`;
                            const file = new Blob([response.body], { type: 'application/pdf' });
                            const fileURL = URL.createObjectURL(file);
                            this.file = { name: fileName, url: fileURL };
                            this.success = true;
                        } else {
                            this.success = false;
                            this.isLoading = false;
                            this.generationError = true;
                        }
                    }
                },
                error: (error) => {
                    this.isLoading = false;
                    if (!this.timeoutError) {
                        this.success = false;
                    }
                    this.generationError = true;
                    this.emptyDocument = false;
                },
            });

    }

    openDialog() {
        this.dialog.open(ModalGenericComponent, {
            width: '393px',
            data: {
                text: 'Ao gerar um novo arquivo, o atual será apagado. Tem certeza que deseja continuar?',
                primaryButtonAction: 'close',
                primaryButtonText: 'FECHAR',
                primaryButtonVariant: 'secondary',
                secundaryButtonAction: () => {
                    this.success = false;
                    this.generationError = false;
                    this.emptyDocument = false;
                },
                secundaryButtonText: 'SIM, GERAR NOVO ARQUIVO',
                secundaryButtonVariant: 'primary',
            },
        });
    }

    downloadFile() {
        const fileName = this.file.name;
        const fileURL = this.file.url;
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}


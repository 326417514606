import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { EStatusRequisicao } from 'src/app/shared/enums/status-requisicao.enum';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatTabGroup, MatTab, MatTabLabel } from '@angular/material/tabs';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-release-values-report',
    templateUrl: './release-values-report.component.html',
    styleUrls: ['./release-values-report.component.scss'],
    standalone: true,
    imports: [
        HeaderComponent,
        LinkBackComponent,
        NgIf,
        MatCard,
        MatTabGroup,
        MatTab,
        MatTabLabel,
        MatCardContent,
        MatCheckbox,
        FormsModule,
        MatFormField,
        MatLabel,
        MatDatepickerInput,
        MatInput,
        NgxMaskDirective,
        ReactiveFormsModule,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        MatError,
        MatSelect,
        NgFor,
        MatOption,
        MatRadioGroup,
        MatRadioButton,
        ButtonComponent,
        LoadingSpinnerComponent,
        DatePipe,
    ],
})
export class ReleaseValuesReportComponent {
  @ViewChild('mySel') select: MatSelect | undefined;
  public currentDate = new Date();
  public isLoading = false;
  public timeoutError = false;
  public success: boolean = false;
  public reportFormat: string = 'xlsx';
  public generationError = false;
  public situacaoList = [
    { value: EStatusRequisicao.A_RECEBER, description: 'A - A Receber' },
    { value: EStatusRequisicao.PENDENTE_2A_LIBERACAO, description: 'B - Pendente de 2a. Liberação' },
    { value: EStatusRequisicao.CANCELADO, description: 'C - Cancelado' },
    { value: EStatusRequisicao.AUTORIZAR_DIRETORIA, description: 'D - A autorizar pela diretoria' },
    { value: EStatusRequisicao.ENCERRADA_LIQUIDADA, description: 'E - Encerrada/Liquidada' },
    { value: EStatusRequisicao.ENVIADA_EBANK_COM_CC, description: 'F - Enviada para o eBank em lote - com cc' },
    { value: EStatusRequisicao.DEBITAR_ONLINE_CONJUNTO, description: 'J - A debitar on line em conjunto' },
    { value: EStatusRequisicao.ERRO_ENVIO_SPB_DEBITADA_ONLINE, description: 'K - Erro no envio ao SPB - conta debitada online' },
    { value: EStatusRequisicao.LIBERADA, description: 'L - Liberada' },
    { value: EStatusRequisicao.ENVIADA_EBANK_SEM_CC, description: 'N - Enviada para o eBank em lote - sem cc' },
    { value: EStatusRequisicao.PENDENTE, description: 'P - Pendente' },
    { value: EStatusRequisicao.RECEBIDA_SPB, description: 'R - Recebida pelo SPB' },
    { value: EStatusRequisicao.ENVIADA_SPB, description: 'S - Enviada ao SPB' },
    { value: EStatusRequisicao.DEVOLVIDA, description: 'V - Devolvida' },
  ];
  public dataInicialLabel: Date | undefined;
  public dataFinalLabel: Date | undefined;
  public maxDate: Date | undefined;
  public situacao: FormControl = new FormControl();
  public dateError = false;
  public emptyDocument = false;
  public file: any;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public dataInicial = new FormControl('', [
    Validators.required,
    dateFormat.positionValidator,
  ]);
  public dataFinal = new FormControl('', [
    Validators.required,
    dateFormat.positionValidator,
  ]);
  public newCurrentDate = new Date();
  public contrato = new FormControl('');
  public checkedAll = false;
  public interteminate = false;
  public checkedAllPeriod = false;

  constructor(
    private apiService: ApiHttpService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.currentDate.setDate(this.currentDate.getDate());
    this.dataInicialLabel = this.currentDate;
    this.dataFinalLabel = this.currentDate;
    this.maxDate = this.newCurrentDate;
  }

  onInputChange(isCalendar: boolean) {
    this.generationError = false;
    if (this.dataInicial.value?.length === 0) {
      this.dataInicialLabel = undefined;
    }
    if (this.dataFinal.value?.length === 0) {
      this.dataFinalLabel = undefined;
    }
    if (
      this.dataInicial &&
      this.dataInicial.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8 &&
      this.dataFinal &&
      this.dataFinal.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8
    ) {
      const dataInicio = this.dataInicial.value
        ? this.initialFormatDate(this.dataInicial.value)
        : null;

      const dataFim = this.dataFinal.value
        ? this.initialFormatDate(this.dataFinal.value)
        : null;

      if (!isCalendar) {
        this.dataInicialLabel = dataInicio || undefined;
        this.dataFinalLabel = dataFim || undefined;
      }

      if (dataInicio && dataFim && dataInicio > dataFim) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
    }
  }

  initialFormatDate(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  click(typeClick: string) {
    if (this.isDisabled() === 'enabled') {
      this.sendRequest(typeClick)
    }
  }

  getBack() {
    this.router.navigate(['area']);
  }

  selectAll() {
    if (!this.checkedAll) {
      this.select?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminate = false;
    this.checkedAll = !this.checkedAll;
  }

  checkHandle() {
    const optionFound = this.select?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAll = false;
    }

    if (optionFound?.length === 3) {
      this.checkedAll = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < 3) {
      this.interteminate = true;
    } else {
      this.interteminate = false;
    }
  }

  downloadFile() {
    const fileName = this.file.name;
    const fileURL = this.file.url;
    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  sendRequest(typeClick: string) {
    let objSend = {}
    const dataInicial = this.dataInicial.value
    const dataFinal = this.dataFinal.value
    let dataInicialFormatada = ''
    let dataFinalFormatada = ''

    if (dataInicial) {
      const [dia, mes, ano] = dataInicial.split('/')
      dataInicialFormatada = `${ano}-${mes}-${dia}`;
    }

    if (dataFinal) {
      const [dia, mes, ano] = dataFinal.split('/')
      dataFinalFormatada = `${ano}-${mes}-${dia}`;
    }

    if (typeClick === 'dates') {
      objSend = {
        chkTodoPeriodo: this.checkedAllPeriod,
        dataInicial: !this.checkedAllPeriod ? dataInicialFormatada : undefined,
        dataFinal: !this.checkedAllPeriod ? dataFinalFormatada : undefined,
        situacao: this.situacao.value && this.situacao.value.length > 0 ? this.situacao.value : undefined
      }
    } else {
      objSend = {
        chkTodoPeriodo: true,
        contrato: this.contrato.value ? this.contrato.value : undefined
      }
    }
    objSend = { ...objSend, formatoArquivo: this.reportFormat };

    this.isLoading = true;
    this.apiService
      .getReleaseValuesReport(objSend)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            this.timeoutError = true;
            this.generationError = true;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          if (response.status === 204) {
            this.isLoading = false;
            this.emptyDocument = true;
          } else {
            this.isLoading = false;
            if (response.body) {
              const fileName = `Relatório de Liberação de Valores.${this.reportFormat}`;
              const file = new Blob([response.body], {
                type: this.reportFormat === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
              });
              const fileURL = URL.createObjectURL(file);
              this.file = { name: fileName, url: fileURL };
              this.success = true;
            } else {
              this.success = false;
              this.isLoading = false;
              this.generationError = true;
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
          if (!this.timeoutError) {
            this.success = false;
          }
          this.generationError = true;
          this.emptyDocument = false;
        },
      });
  }

  isDisabled() {
    if (
      !this.checkedAllPeriod &&
      this.dateError ||
      this.dataInicial.hasError('invalidDate') ||
      this.dataInicial.hasError('required') ||
      this.dataInicial.hasError('dataValida') ||
      this.dataFinal.hasError('invalidDate') ||
      this.dataFinal.hasError('required') ||
      this.dataFinal.hasError('dataValida')
    )
      return 'disabled';

    return 'enabled';
  }

  changeTab(tabNumber: any) {
    this.currentDate = tabNumber;
  }

  allPeriodChange() {
    if (this.checkedAllPeriod) {
      this.dataInicial.disable()
      this.dataFinal.disable()
    } else {
      this.dataInicial.enable()
      this.dataFinal.enable()
    }
  }

  openDialog() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Ao gerar um novo arquivo, o atual será apagado. Tem certeza que deseja continuar?',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => {
          this.success = false;
          this.generationError = false;
          this.emptyDocument = false;
        },
        secundaryButtonText: 'SIM, GERAR NOVO ARQUIVO',
        secundaryButtonVariant: 'primary',
      },
    });
  }
}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { IDataModalSummary } from 'src/app/models/summary-data.interface';
import { ModalSummaryComponent } from '../modal-summary/modal-summary.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'yfs-modal-information',
    templateUrl: './modal-information.component.html',
    styleUrls: ['./modal-information.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, CurrencyPipe, DatePipe]
})
export class ModalInformationComponent {
  public dataSource: any;
  public status = '';
  public pontualidade = '';
  public quitacao = '';
  public restricao = '';
  public pontuais = 0;
  public totalPontuais = 0;
  public naoPontuais = 0;

  public displayedColumnsResume: string[] = [
    'nomeTarifa',
    'dataCadastro',
    'valor',
  ];

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private cdref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @ViewChild('secondTableSort')
  secondTableSort!: MatSort;

  ngOnInit() {
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    this.dataSource = this.data;
    this.dataSource = new MatTableDataSource(this.data.values);
    this.dataSource.sort = this.secondTableSort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => {
      if (sortHeaderId === 'status') {
        return data.statusParcelaDescricao;
      }
      return data[sortHeaderId];
    };
    this.cdref.detectChanges();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  handlePrimaryButton() {
    if (this.data.primaryButtonAction === 'close')
      this.closeModal()
    else {
      this.data.primaryButtonAction()
    }
  }

  announceSortChange2(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}

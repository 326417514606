<mat-card>
  <mat-card-content>
<span class="title-document">Selecione o canal de relacionamento do contrato</span>
<div class="container-content">
    <div class="content">
      <div class="input">
        <mat-form-field class="input" appearance="fill">
          <mat-label>Concessionária</mat-label>
          <input [matAutocomplete]="autoRepresentantes" matInput [formControl]="concessionariaControl">
          <mat-autocomplete #autoRepresentantes="matAutocomplete" (optionSelected)="onSelection($event)">
            <ng-container *ngIf="!isMaster">
            <mat-label class="select-title" *ngIf="listRepresentantes.length > 0">Representantes</mat-label>
            <mat-option *ngFor="let representante of listRepresentantes" [value]="representante.razaoSocial">
              <div class="custom-radio">
                <span class="radio-circle" [class.selected-circle]="selectedId === representante.idConcessionaria"></span>
                <span>{{ representante.razaoSocial }}</span>
              </div>
            </mat-option>
          </ng-container>
            <mat-label class="select-title" *ngIf="listConcessionarias.length > 0" >Concessionárias</mat-label>
            <mat-option *ngFor="let concessionaria of listConcessionarias" [value]="concessionaria.razaoSocial">
              <div class="custom-radio">
                <span class="radio-circle" [class.selected-circle]="selectedId === concessionaria.idConcessionaria"></span>
                <span>{{ concessionaria.razaoSocial }}</span>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!timeoutError && !genericError" class="container-buttons">
      <div class="divisor-content">
          <yfs-button state="enabled" label="CANCELAR" type="tertiary" (click)="openDialog()"></yfs-button>
          <span class="remaining-number">1 de 5</span>
      </div>
      <div class="divisor-content-next">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="goBack()"></yfs-button>
        <yfs-button [state]="concessionarias.hasError('required') ? 'disabled' : 'enabled'" label="PROSSEGUIR" type="primary" [press]="nextStep"></yfs-button>
      </div>
  </div>
  <div *ngIf="!timeoutError && !genericError" class="mobile-buttons">
    <span class="remaining-number">1 de 5</span>
    <yfs-button [state]="concessionarias.hasError('required') ? 'disabled' : 'enabled'" label="PROSSEGUIR" type="primary" class="button" [fullWidth]="isFullWidth" [press]="nextStep"></yfs-button>
    <yfs-button state="enabled" label="VOLTAR" type="secondary" [fullWidth]="isFullWidth" class="button" (click)="goBack()"></yfs-button>
    <yfs-button state="enabled" label="CANCELAR" type="tertiary" [fullWidth]="isFullWidth" class="button" (click)="openDialog()"></yfs-button>
</div>
</div>
</mat-card-content>
</mat-card>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<div class="dialog-content" mat-dialog-content>
  <mat-icon aria-hidden="false" aria-label="Ícone de expandir para direita" class="material-symbols-outlined signature-class" fontIcon="signature"></mat-icon>

  <div  class="dialog-text-first">
      <span>Documento pendente de assinatura do termo de cancelamento</span>
  </div>

  <div  class="dialog-text-second">
    <span>O termo de cancelamento foi enviado para o e-mail, sms ou whatsapp cadastrado.</span>
  </div>

  <div class="message-document">
    <mat-icon aria-hidden="false" aria-label="Ícone de informação"
       class="material-symbols"
       fontIcon="info"> </mat-icon><span><b>Assinar o termo até {{date | date : 'dd/MM/yyyy HH:mm'}}</b> ou o contrato retornará para o status anterior.</span>
  </div>

  <div class="dialog-buttons">
    <yfs-button state="enabled" label="FECHAR" type="primary" (click)="close()"></yfs-button>
  </div>
</div>

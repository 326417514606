import { ValidatorFn, AbstractControl } from "@angular/forms";

export class CustomValidators {
  static validPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = /^[\d\s()-]+$/.test(control.value);
      return isValid ? null : { invalidPhoneNumber: { value: control.value } };
    };
  }
}

<div class="dialog-content" mat-dialog-content>
    <mat-icon aria-hidden="false" aria-label="Ícone de selecionado" class="material-symbols-outlined check-circle"
        fontIcon="check_circle"></mat-icon>

    <span class="title">Valide seu celular</span>
    <span class="description">Nós enviamos um código de validação por SMS para seu celular. Preencha o campo abaixo e
        confirme.</span>

    <div>
      <form [formGroup]="codeForm">
        <div class="code-inputs">
            <input #codeInput type="text" inputmode="numeric" formControlName="code1" mask="0" maxlength="1"
                (keyup)="onKeyUp($event, 1)" [class.filled]="hasValue('code1')" [class.success]="correctCode"
                [class.error]="incorrectCode">
            <input #codeInput type="text" inputmode="numeric" formControlName="code2" mask="0" maxlength="1"
                (keyup)="onKeyUp($event, 2)" [class.filled]="hasValue('code2')" [class.success]="correctCode"
                [class.error]="incorrectCode">
            <input #codeInput type="text" inputmode="numeric" formControlName="code3" mask="0" maxlength="1"
                (keyup)="onKeyUp($event, 3)" [class.filled]="hasValue('code3')" [class.success]="correctCode"
                [class.error]="incorrectCode">
            <input #codeInput type="text" inputmode="numeric" formControlName="code4" mask="0" maxlength="1"
                (keyup)="onKeyUp($event, 4)" [class.filled]="hasValue('code4')" [class.success]="correctCode"
                [class.error]="incorrectCode">
            <input #codeInput type="text" inputmode="numeric" formControlName="code5" mask="0" maxlength="1"
                (keyup)="onKeyUp($event, 5)" [class.filled]="hasValue('code5')" [class.success]="correctCode"
                [class.error]="incorrectCode">
            <input #codeInput type="text" inputmode="numeric" formControlName="code6" mask="0" maxlength="1"
                (keyup)="onKeyUp($event, 6)" [class.filled]="hasValue('code6')" [class.success]="correctCode"
                [class.error]="incorrectCode">
        </div>
    </form>
        <div class="message" [style.color]="getMessage().color">
            <mat-icon aria-hidden="false" aria-label="Ícone de selecionado" [class]="currentFlow == 'error' || currentFlow == 'wait' ? 'material-symbols-outlined' : '' "
                [fontIcon]="getMessage().icon"></mat-icon>
            <span>{{getMessage().message}}</span>
        </div>

        <div *ngIf="incorrectCode" class="error-message">
            <mat-icon aria-hidden="false" aria-label="Ícone de selecionado" fontIcon="info"></mat-icon>
            <span>O código digitado está incorreto. Tente outra vez.</span>
        </div>

        <div class="button-container">
            <div *ngIf="currentFlow !== 'success'">
                <yfs-button class="btn-send" [state]="codeForm.valid && timeLeft > 0 ?  'enabled' : 'disabled'" label="VALIDAR" type="primary" [press]="sendCode" [fullWidth]="isFullWidth"></yfs-button>
                <yfs-button  [state]="timeLeft <= 0 ?  'enabled' : 'disabled'" label="Não recebeu o SMS? Reenviar código." type="tertiary" [press]="resendCode" [fullWidth]="isFullWidth"></yfs-button>
            </div>
            <yfs-button *ngIf="currentFlow == 'success'" state="enabled" label="CONTINUAR" type="primary" (click)="continueHandleClick()"></yfs-button>
        </div>
    </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { HeaderComponent } from '../../components/header/header.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatIcon } from '@angular/material/icon';
import { OldTablesTariffComponent } from './old-tables-tariff/old-tables-tariff.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-parameter-registration-tariff',
    templateUrl: './parameter-registration-tariff.component.html',
    styleUrls: ['./parameter-registration-tariff.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NgIf, LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatCheckbox, NgFor, MatOption, MatError, MatDatepickerInput, MatInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, ButtonComponent, CurrencyMaskModule, MatIcon, OldTablesTariffComponent, LoadingSpinnerComponent, DatePipe]
})
export class ParameterRegistrationTariffComponent {
  public previousScreen: string = '';
  public tarifaAtivacaoPeriodicidade = '';
  public tarifaAtivacaoTipo = 1;
  public tarifaAtivacao = new FormControl(null, [Validators.required]);
  public tarifaRecargaPeriodicidade = '';
  public tarifaRecargaTipo = 1;
  public tarifaRecarga = new FormControl('', [Validators.required]);
  public tarifaMensalidadePeriodicidade = '';
  public tarifaMensalidadeTipo = 1;
  public tarifaMensalidade = new FormControl(null, [Validators.required]);
  public tarifaUsoPeriodicidade = '';
  public tarifaUsoTipo = 1;
  public tarifaUso = new FormControl(null, [Validators.required]);
  public tarifaInatividadePeriodicidade = '';
  public tarifaInatividadeTipo = 1;
  public tarifaInatividade = new FormControl(null, [Validators.required]);
  public tarifaTransferenciaPeriodicidade = '';
  public tarifaTransferenciaTipo = 1;
  public tarifaTransferencia = new FormControl(null, [Validators.required]);
  public tarifaCancelamentoPeriodicidade = '';
  public tarifaCancelamentoTipo = 1;
  public tarifaCancelamento = new FormControl(null, [Validators.required]);
  public tarifaAdesaoPeriodicidade = '';
  public tarifaAdesaoTipo = 1;
  public tarifaAdesao = new FormControl(null, [Validators.required]);
  public valorMaximo = new FormControl(null, [Validators.required]);
  public percentualMinimo = new FormControl('', [Validators.required]);
  public percentualMaximo = new FormControl('', [Validators.required]);
  public majoracao = new FormControl('', [Validators.required]);
  public categoria = new FormControl('', [Validators.required]);
  public currentFlow = 'parameter';
  public percentageValues = ["0%", "5%", "10%", "15%", "20%", "25%", "30%"];
  public selectedCategoria: number[] = [];
  public idsCategoria: number[] = [];
  public checkedAllCategoria = false;
  public listaCategoria: any;
  public isLoading = false;
  public minDate: Date | undefined;
  public dataVigenciaLabel: Date | undefined;
  public dataVigencia = new FormControl('', [Validators.required, dateFormat.parameterTariffValidator]);
  public updateCurrentContracts = false;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public tipoList = [
    {
      tipoValue: 1,
      tipoDescription: 'Valor'
    },
    {
      tipoValue: 2,
      tipoDescription: '% do saldo reserva'
    },
    {
      tipoValue: 3,
      tipoDescription: '% do valor da parcela'
    },
    {
      tipoValue: 4,
      tipoDescription: '% do valor do plano'
    }
  ]

  constructor(
    private router: Router,
    private apiService: ApiHttpService,
    public dialog: MatDialog
  ) {
    this.tarifaRecarga.setValue(null);
    this.getCategories();
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);
    this.minDate = tomorrowDate;
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  onInputChange(isCalendar: boolean) {
    if (this.dataVigencia.value?.length === 0) {
      this.dataVigenciaLabel = undefined;
    }

    if (this.dataVigencia && this.dataVigencia.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataVigencia2 = this.dataVigencia.value ?
        this.getDateByString(this.dataVigencia.value) : null;

      if (!isCalendar) {
        this.dataVigenciaLabel = dataVigencia2 || undefined
      }
    }
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  checkFields() {
    if (this.dataVigencia.value) {
      this.dataVigencia.markAllAsTouched();
    }
    if (
      this.categoria.valid &&
      this.dataVigencia.valid &&
      this.percentualMaximo.valid &&
      this.percentualMinimo.valid &&
      this.majoracao.valid &&
      this.tarifaAtivacao.valid &&
      this.tarifaAdesao.valid &&
      this.tarifaRecarga.valid &&
      this.tarifaMensalidade.valid &&
      this.tarifaUso.valid &&
      this.tarifaInatividade.valid &&
      this.tarifaTransferencia.valid &&
      this.tarifaCancelamento.valid &&
      this.valorMaximo.valid
    ) {
      return 'enabled'
    }
    return 'disabled'
  }

  openOldTables() {
    this.currentFlow = 'oldTables';
  }

  changeCurrentFlow(flow: string) {
    this.currentFlow = flow;
  }

  cleanField(control: any) {
    control.setValue(null);
  }

  formatValor(control: any) {
    let currentValue = control.value?.replace(/[^\d,]/g, '');
    currentValue = currentValue += '%';
    if (currentValue == '%') control.setValue('')
    else
      control.setValue(currentValue);
  }

  selectAllCategoria() {
    if (this.selectedCategoria.length === this.listaCategoria.length) {
      this.selectedCategoria = [];
      this.idsCategoria = [];
      this.checkedAllCategoria = false;
    } else {
      this.selectedCategoria = this.listaCategoria.map((categoria: any) => categoria.idCategoriaExibicao);
      this.idsCategoria = this.selectedCategoria.slice();
      this.checkedAllCategoria = true;
    }
    this.categoria.setValue(this.selectedCategoria as any);
  }

  checkHandleCategoria(idCategoria: number) {
    if (this.selectedCategoria.includes(idCategoria)) {
      this.selectedCategoria = this.selectedCategoria.filter(item => item !== idCategoria);
    } else {
      this.selectedCategoria.push(idCategoria);
    }

    this.idsCategoria = this.selectedCategoria.slice();

    if (this.selectedCategoria.length === this.listaCategoria.length) {
      this.checkedAllCategoria = true;
    } else {
      this.checkedAllCategoria = false;
    }
    this.categoria.setValue(this.selectedCategoria as any);
  }

  formatPercentualMinimo() {
    let currentValue = this.percentualMinimo.value?.replace(/[^0-9]/g, '');
    const defaultCurretValue = currentValue;
    currentValue = currentValue += '%';
    if (currentValue == '%') this.percentualMinimo.setValue('')
    else
      this.percentualMinimo.setValue(currentValue);
    this.validPercentualMinimo(defaultCurretValue);
  }

  validPercentualMinimo(minimoValue?: string) {
    if (!minimoValue) return;
    const minimoInt = parseInt(minimoValue);
    if (minimoInt < 20 || minimoInt > 100) {
      this.percentualMinimo.setErrors({ rangeError: true });
    }
    this.formatPercentualMaximo()
  }

  formatPercentualMaximo() {
    let currentValue = this.percentualMaximo.value?.replace(/[^0-9]/g, '');
    const defaultCurretValue = currentValue;
    currentValue = currentValue += '%';
    if (currentValue == '%') this.percentualMaximo.setValue('')
    else
      this.percentualMaximo.setValue(currentValue);
    this.validPercentualMaximo(defaultCurretValue);
  }

  validPercentualMaximo(maximoValue?: string) {
    if (!maximoValue) return;
    const maximoInt = parseInt(maximoValue);
    if (maximoInt > 80) {
      this.percentualMaximo.setErrors({ rangeError: true });
    }
    this.validRangeMaxAndMinValues()
  }

  validRangeMaxAndMinValues() {
    if (this.percentualMinimo.value && this.percentualMaximo.value) {
      const valueMin = this.percentualMinimo.value?.replace('%', '');
      const valueMinInt = parseInt(valueMin || '');
      const valueMax = this.percentualMaximo.value?.replace('%', '');
      const valueMaxInt = parseInt(valueMax || '');
      if (valueMinInt >= valueMaxInt) {
        this.percentualMaximo.setErrors({ minValueError: true });
      }
      this.percentualMaximo.markAllAsTouched();
    }
  }

  formatPercentualMajoracao() {
    let currentValue = this.majoracao.value?.replace(/[^0-9]/g, '');
    const defaultCurretValue = currentValue;
    currentValue = currentValue += '%';
    if (currentValue == '%') this.majoracao.setValue('')
    else
      this.majoracao.setValue(currentValue);
    this.validPercentualMajoracao(defaultCurretValue);
  }

  validPercentualMajoracao(majoracaoValue?: string) {
    if (!majoracaoValue) return;
    const majoracaoInt = parseInt(majoracaoValue);
    if (majoracaoInt > 100) {
      this.majoracao.setErrors({ rangeError: true });
    }
  }

  getParameters() {
    this.isLoading = true;
    this.apiService.getParameterTariff().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          if (result) {
            this.bidingParametersValues(result)
          }
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  bidingParametersValues(parameters: any) {
    if (parameters.dataVigencia) {
      const dataVigenciaFormated = parameters.dataVigencia ? this.convertDateToString(parameters.dataVigencia) : ''
      this.dataVigencia.setValue(dataVigenciaFormated);
    }
    this.percentualMinimo.setValue(parameters.percentualCapitalizacaoMinimo ? parameters.percentualCapitalizacaoMinimo + "%" : '');
    this.percentualMaximo.setValue(parameters.percentualCapitalizacaoMaximo ? parameters.percentualCapitalizacaoMaximo + "%" : '');
    this.majoracao.setValue(parameters.percentualMajoracao ? parameters.percentualMajoracao + "%" : '');
    this.tarifaAtivacaoPeriodicidade = parameters.ativacao.periodicidade;
    this.tarifaAtivacaoTipo = parameters.ativacao.valueType;
    this.bidingControls(this.tarifaAtivacao, this.tarifaAtivacaoTipo, parameters.ativacao.value)
    this.tarifaRecargaPeriodicidade = parameters.cargaRecarga.periodicidade;
    this.tarifaRecargaTipo = parameters.cargaRecarga.valueType;
    this.bidingControls(this.tarifaRecarga, this.tarifaRecargaTipo, parameters.cargaRecarga.value)
    this.tarifaMensalidadePeriodicidade = parameters.mensalidade.periodicidade;
    this.tarifaMensalidadeTipo = parameters.mensalidade.valueType;
    this.bidingControls(this.tarifaMensalidade, this.tarifaMensalidadeTipo, parameters.mensalidade.value)
    this.tarifaUsoPeriodicidade = parameters.usoRedeCredenciada.periodicidade;
    this.tarifaUsoTipo = parameters.usoRedeCredenciada.valueType;
    this.bidingControls(this.tarifaUso, this.tarifaUsoTipo, parameters.usoRedeCredenciada.value)
    this.tarifaInatividadePeriodicidade = parameters.inatividade.periodicidade;
    this.tarifaInatividadeTipo = parameters.inatividade.valueType;
    this.bidingControls(this.tarifaInatividade, this.tarifaInatividadeTipo, parameters.inatividade.value)
    this.tarifaCancelamentoPeriodicidade = parameters.cancelamento.periodicidade;
    this.tarifaCancelamentoTipo = parameters.cancelamento.valueType;
    this.bidingControls(this.tarifaCancelamento, this.tarifaCancelamentoTipo, parameters.cancelamento.value)
    this.tarifaAdesaoPeriodicidade = parameters.adesao.periodicidade;
    this.tarifaAdesaoTipo = parameters.adesao.valueType;
    this.bidingControls(this.tarifaAdesao, this.tarifaAdesaoTipo, parameters.adesao.value)
    this.valorMaximo.setValue(parameters.valorMaximoCartaoVirtual.value ?? null);
    this.tarifaTransferenciaPeriodicidade = parameters.transferenciaContaCorrente.periodicidade;
    this.tarifaTransferenciaTipo = parameters.transferenciaContaCorrente.valueType;
    this.bidingControls(this.tarifaTransferencia, this.tarifaTransferenciaTipo, parameters.transferenciaContaCorrente.value)
    if (parameters.categorias && parameters.categorias.length > 0)
      this.pickCategoria(parameters.categorias);
  }

  bidingControls(control: any, tipo: number, parameterValue: any) {
    if (tipo === 1) {
      control.setValue(parameterValue ?? null);
    } else {
      const value = parameterValue ? parameterValue.toString().replace('.', ',') + '%' : null
      control.setValue(value);
    }
  }

  pickCategoria(categorias: any) {
    const categoriasSelecionadas: any[] | null = [];
    categorias.forEach((categoriaRetorno: any) => {
      const categoriaSelecionada = this.listaCategoria.find((categoria: any) => categoria.idCategoriaExibicao === categoriaRetorno)
      if (categoriaSelecionada) {
        categoriasSelecionadas.push(categoriaSelecionada)
      }
    })

    this.categoria.setValue(categoriasSelecionadas as any)
    if (categoriasSelecionadas.length > 0) {
      categoriasSelecionadas.forEach((categoria) => {
        this.checkHandleCategoria(categoria.idCategoriaExibicao)
      })
    }
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj.toString()
  }

  convertDateToString(dataNascimento: any) {
    const dataOriginal = new Date(dataNascimento);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  getCategories() {
    this.isLoading = true;
    this.apiService.getCategory().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.listaCategoria = result;
          this.getParameters();
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  parseValueToNumber(value: any) {
    if (!value || value == undefined || typeof value !== 'string') return value;
    return parseFloat(value.replace('%', '').replace(',', '.'));
  }

  updateParameters() {
    this.isLoading = true;
    const dataVigenciaValue = this.dataVigencia.value;
    const dataVigenciaFormated = dataVigenciaValue ? this.convertStringToDate(dataVigenciaValue) : undefined;
    const parameterTariffPayload = {
      categorias: this.idsCategoria,
      dataVigencia: dataVigenciaFormated,
      percentualMajoracao: this.parseValueToNumber(this.majoracao.value),
      percentualCapitalizacaoMinimo: this.parseValueToNumber(this.percentualMinimo.value),
      percentualCapitalizacaoMaximo: this.parseValueToNumber(this.percentualMaximo.value),
      atualizarContratos: this.updateCurrentContracts,
      ativacao: {
        value: this.parseValueToNumber(this.tarifaAtivacao.value),
        valueType: this.tarifaAtivacaoTipo
      },
      cargaRecarga: {
        value: this.parseValueToNumber(this.tarifaRecarga.value),
        valueType: this.tarifaRecargaTipo,
      },
      mensalidade: {
        value: this.parseValueToNumber(this.tarifaMensalidade.value),
        valueType: this.tarifaMensalidadeTipo
      },
      usoRedeCredenciada: {
        value: this.parseValueToNumber(this.tarifaUso.value),
        valueType: this.tarifaUsoTipo,
      },
      inatividade: {
        value: this.parseValueToNumber(this.tarifaInatividade.value),
        valueType: this.tarifaInatividadeTipo
      },
      cancelamento: {
        value: this.parseValueToNumber(this.tarifaCancelamento.value),
        valueType: this.tarifaCancelamentoTipo
      },
      adesao: {
        value: this.parseValueToNumber(this.tarifaAdesao.value),
        valueType: this.tarifaAdesaoTipo
      },
      valorMaximoCartaoVirtual: {
        value: this.valorMaximo.value,
        valueType: 1
      },
      transferenciaContaCorrente: {
        value: this.parseValueToNumber(this.tarifaTransferencia.value),
        valueType: this.tarifaTransferenciaTipo,
      }
    }
    this.apiService.updateParameterTariff(parameterTariffPayload).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.openDialogSaveProductSuccess();
        },
        error: error => {
          this.isLoading = false;
          this.openDialogSaveChangesError();
          console.log(error)
        }
      })
  }

  openDialogConfirmSave = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '392px',
      data: {
        text: this.updateCurrentContracts ?
        'Todos os contratos vigentes terão suas tarifas alteradas para esta nova tabela, deseja continuar?' :
        'As novas tarifas valerão somente para contratos iniciados a partir da data de vigência da tabela, deseja continuar?',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.updateParameters() },
        secundaryButtonText: 'SIM, ATUALIZAR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openDialogExist() {
    this.dialog.open(ModalGenericComponent, {
      width: '425px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogSaveProductSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Os dados foram alterados com sucesso.',
        icon: 'success',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        primaryButtonAction: 'close'
      },
    });
  }

  openDialogSaveChangesError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível alterar os dados nesse momento. Tente novamente mais tarde?',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => {  },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }
}

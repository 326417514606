import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { IPersonalData } from 'src/app/models/register.interface';
import { UserService } from 'src/app/services/user.service';
import { dateFormat } from 'src/app/shared/date-format';
import { EEstadoCivil } from 'src/app/shared/enums/estado-civil-enum';

import { ESexo } from 'src/app/shared/enums/sexo-enum';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgFor, DatePipe, KeyValuePipe } from '@angular/common';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { NgxMaskDirective } from 'ngx-mask';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { AlertBoxComponent } from '../../../components/alert-box/alert-box.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';



@Component({
    selector: 'yfs-register-personal-data',
    templateUrl: './register-personal-data.component.html',
    styleUrls: ['./register-personal-data.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, MatDatepickerInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, MatSelect, NgFor, MatOption, ButtonComponent, AlertBoxComponent, LoadingSpinnerComponent, DatePipe, KeyValuePipe]
})
export class RegisterPersonalDataComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public nomeCompleto = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(35)]);
  public dataNascimento = new FormControl('', [Validators.required, dateFormat.yearsValidator]);
  public estadoCivil = new FormControl('', [Validators.required]);
  public sexo = new FormControl('', [Validators.required]);
  public nomeMae = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(120)]);
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public listSexo = ESexo;
  public listEstadoCivil = Object.values(EEstadoCivil);
  public dataLabel = "";
  public maxDate = '';
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public dataValida = true;
  public shiftPressed = false;
  public isFullWidth = window.innerWidth < 820;

  constructor(public dialog: MatDialog, private userService: UserService) {
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth() + 8, currentDate.getDate());
    this.maxDate = maxDate.toISOString().substring(0, 10);
  }

  ngOnInit() {
    this.isLogged()
    if (localStorage.getItem("dadosPessoais")) {
      let dadosPessoais: IPersonalData;
      dadosPessoais = JSON.parse(atob(localStorage.getItem("dadosPessoais") || '{}'))
      this.bidingValuesPersonal(dadosPessoais)
    }
    if (localStorage.getItem("contatoSimulacao")) {
      let contatoSimulacao = JSON.parse(atob(localStorage.getItem('contatoSimulacao') || '{}'))
      this.nomeCompleto.setValue(contatoSimulacao.nomeCompleto);
      const dataNascimentoFormated = contatoSimulacao.dataNascimento ? this.convertDateToString(contatoSimulacao.dataNascimento) : ''
      this.dataLabel = contatoSimulacao.dataNascimento ? this.convertDataLabel(dataNascimentoFormated) : '';
      this.dataNascimento.setValue(dataNascimentoFormated);
    }
  }

  isLogged(): boolean {
    return this.userService.userIsLogged();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 820;
  }

  getRemainingNumber(): string {
    return this.isLogged() ? '3 de 5' : '2 de 5';
  }

  goBack() {
    this.currentFlowChanged.emit("document")
  }

  bidingValuesPersonal(personalData: IPersonalData) {
    this.nomeCompleto.setValue(personalData.nomeCompleto ?? '')
    this.dataNascimento.setValue(personalData.dataNascimento ?? '');
    this.estadoCivil.setValue(personalData.estadoCivil ?? '');
    this.sexo.setValue(personalData.sexo ?? '');
    this.nomeMae.setValue(personalData.nomeMae ?? '');
    const dataNascimentoFormated = personalData.dataNascimento ? this.convertDateToString(personalData.dataNascimento) : ''
    this.dataLabel = personalData.dataNascimento ? this.convertDataLabel(dataNascimentoFormated) : '';
    this.dataNascimento.setValue(dataNascimentoFormated);
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj.toString()
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  convertDateToString(dataNascimento: any) {
    const dataOriginal = new Date(dataNascimento);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  nextStep() {
    this.nomeCompleto.markAllAsTouched()
    this.dataNascimento.markAllAsTouched()
    this.estadoCivil.markAllAsTouched()
    this.sexo.markAllAsTouched()
    this.nomeMae.markAllAsTouched()

    if (this.nomeCompleto.valid && this.estadoCivil.valid && this.dataNascimento.valid &&
      this.sexo.valid && this.nomeMae.valid) {

      const dataNascimentoValue = this.dataNascimento.value;
      const dataNascimentoFormated = dataNascimentoValue ? this.convertStringToDate(dataNascimentoValue) : undefined

      let dadosPessoais: IPersonalData = {
        nomeCompleto: this.nomeCompleto.value ?? undefined,
        dataNascimento: dataNascimentoFormated,
        estadoCivil: this.estadoCivil.value ?? undefined,
        sexo: this.sexo.value ?? undefined,
        nomeMae: this.nomeMae.value ?? undefined
      };

      localStorage.setItem("dadosPessoais", btoa(JSON.stringify(dadosPessoais)))
      this.currentFlowChanged.emit("address")
    }
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }

  sanitizeInput(event: Event, field: string): void {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '');

    if (field === 'nomeCompleto') {
      this.nomeCompleto.setValue(sanitizedValue);
    } else if (field === 'nomeMae') {
      this.nomeMae.setValue(sanitizedValue);
    }
  }

  handlePaste(event: ClipboardEvent, field: string): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const sanitizedText = pastedText.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '');

    if (field === 'nomeCompleto') {
      this.nomeCompleto.setValue(sanitizedText);
    } else if (field === 'nomeMae') {
      this.nomeMae.setValue(sanitizedText);
    }
  }
}



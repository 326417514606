import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../buttons/button/button.component';

interface IDataModalGeneric {
  clientName: string;
  contractNumber: string;
  handleCancelPlan: any;
}


@Component({
    selector: 'yfs-modal-cancel-plan',
    templateUrl: './modal-cancel-plan.component.html',
    styleUrls: ['./modal-cancel-plan.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, MatFormField, MatInput, FormsModule, ButtonComponent]
})
export class ModalCancelPlanComponent {

  public numberStep = 1;
  public cancelarString = ""
  public buttonCancelType = 'enabled';
  public isFullWidth = window.innerWidth < 1279;

  constructor(
    public dialogRef: MatDialogRef<ModalCancelPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataModalGeneric
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  checkInput() {
    if (this.cancelarString === 'CANCELAR') {
      this.buttonCancelType = 'enabled';
    }
    else {
      this.buttonCancelType = 'disabled';
    }
  }

  cancelPlanClick() {
    if (this.numberStep == 1) {
      this.buttonCancelType = 'disabled';
      this.numberStep = 2
    }
    if (this.buttonCancelType === 'enabled') {
      this.closeModal()
      this.data.handleCancelPlan()
    }
  }

}

import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'yfs-link-back',
    templateUrl: './link-back.component.html',
    styleUrls: ['./link-back.component.scss'],
    standalone: true,
    imports: [MatIcon]
})
export class LinkBackComponent {
  @Input() text: string = ''
}

<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
    <div class="button-back">
        <yfs-link-back [text]="currentFlow == 'results' ? 'Voltar para Gerar relatório' : 'Voltar para Minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>GERAR RELATÓRIO DE INELEGIBILIDADE</h1>
    <h2 class="subtitle">
        Selecione a data da geração do arquivo. Por padrão a data preenchida é a data atual.
    </h2>

    <mat-card class="card" *ngIf="currentFlow == 'parameters' && !nofiles">
        <mat-card-content class="card-content">
            <div class="inputs-date">
                <mat-form-field appearance="fill">
                    <mat-label>Início do relatório</mat-label>
                    <input type="hidden" [matDatepicker]="pickerInicial" [(ngModel)]="dataInicialLabel"
                        [max]="maxDateInicial" (ngModelChange)="onInputChangeInicial(true)" />
                    <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataInicial" [max]="maxDateInicial"
                        [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'"
                        (ngModelChange)="onInputChangeInicial(false)" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerInicial"></mat-datepicker-toggle>
                    <mat-datepicker #pickerInicial></mat-datepicker>
                    <mat-error class="date-error" *ngIf="dataInicial.hasError('required')"> O campo é
                        obrigatório</mat-error>
                    <mat-error class="date-error" *ngIf="dataInicial.hasError('invalidDate')">Data inválida.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Final do relatório</mat-label>
                    <input type="hidden" [matDatepicker]="pickerFinal" [(ngModel)]="dataFinalLabel" [max]="maxDateFinal"
                        (ngModelChange)="onInputChangeFinal(true)" />
                    <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataFinal" [max]="maxDateFinal"
                        (ngModelChange)="onInputChangeFinal(false)" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFinal></mat-datepicker>
                    <mat-error class="date-error" *ngIf="dataFinal.hasError('required')"> O campo é
                        obrigatório</mat-error>
                    <mat-error class="date-error" *ngIf="dataFinal.hasError('invalidDate')">Data inválida.</mat-error>
                    <mat-error class="date-error" *ngIf="dataFinal.hasError('incorrect')"></mat-error>
                </mat-form-field>
            </div>
            <span class="date-error" *ngIf="dataFinalError || dataInicialError">
                <img src="assets/svg/atention.svg" alt="icone de atenção" />
                {{dataInicialError ? dataInicialError : dataFinalError}}
            </span>
            <mat-form-field appearance="fill">
                <mat-label>Canal de originação</mat-label>
                <mat-select #mySel [formControl]="canalOriginacao" multiple>
                    <div class="select-all" (click)="selectAllOriginacao()">
                        <mat-checkbox [(ngModel)]="checkedAllOriginacao" [indeterminate]="interteminateOriginacao"
                            color="primary" (change)="selectAllOriginacao()"><span>Todos</span></mat-checkbox>
                    </div>
                    <mat-option *ngFor="let originacao of originacaoList" [value]="originacao.value"
                        (click)="checkHandleOriginacao()">{{ originacao.description }}</mat-option>
                </mat-select>
                <mat-error *ngIf="canalOriginacao.hasError('required')">
                    O campo é obrigatório
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Canal de relacionamento</mat-label>
                <mat-select #mySel2 [formControl]="canalRelacionamento" multiple>
                    <div class="select-all" (click)="selectAllRelacionamento()">
                        <mat-checkbox [(ngModel)]="checkedAllRelacionamento"
                            [indeterminate]="interteminateRelacionamento" color="primary"
                            (change)="selectAllRelacionamento()"><span>Todos</span></mat-checkbox>
                    </div>
                    <mat-option *ngFor="let relacionamento of relacionamentoList" [value]="relacionamento.idConcessionaria"
                        (click)="checkHandleRelacionamento()">{{ relacionamento.razaoSocial }}</mat-option>
                </mat-select>
                <mat-error *ngIf="canalRelacionamento.hasError('required')">
                    O campo é obrigatório
                </mat-error>
            </mat-form-field>

            <span class="date-error-request" *ngIf="generationError">
                <img src="assets/svg/atention.svg" alt="icone de atenção" />
                Não foi possível gerar o relatório.
            </span>

            <yfs-button [state]="isEnabladButton()" label="GERAR RELATÓRIO" class="btn-generate" type="primary"
                [press]="search"></yfs-button>
        </mat-card-content>
    </mat-card>

    <mat-card class="no-files-container" *ngIf="nofiles">
        <mat-card-content>
            <div class="no-files">
                <img src="assets/svg/empty.svg" alt="documento vazio" height="64" width="64">
                <div class="text-no-files">
                    <span>Nenhum usuário encontrado no período selecionado,</span>
                    <span>tente escolher outras datas e gere um novo relatório.</span>
                </div>
                <yfs-button state="enabled" label="GERAR NOVO RELATÓRIO" type="primary"
                    (click)="nofiles = false"></yfs-button>
            </div>
        </mat-card-content>
    </mat-card>

    <div style="width: 80%;" *ngIf="currentFlow == 'results'">
        <yfs-list-ineligibility-report [listItens]="listItens" [dataInicio]="dataInicial.value" [dataFim]="dataFinal.value" [allSelected]="checkedAllRelacionamento"></yfs-list-ineligibility-report>
    </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
<section>
  <header>
    <div class="mat-toolbar mat-toolbar-multiple-rows">
      <mat-toolbar-row class="mat-toolbar-row .mat-toolbar-single-row first-line">
        <div class="container-content">
          <div class="container-brand">
            <a id="logoLibera" (click)="goToHome()"
              ><img
                src="assets/img/liberacred2x.png"
                class="logo-libera"
                alt="logo liberacred"
            /></a>
          </div>
          <button mat-icon-button class="mobile-menu-icon" (click)="toggleMenu()">
            <mat-icon>{{ menuOpen ? 'close' : 'menu' }}</mat-icon>
          </button>
          <yfs-login class="showLogin" *ngIf="!userName" (nomeAtualizado)="atualizarNome($event)" [flowSeller]="flowSeller"></yfs-login>
          <div class="flex-content" *ngIf="userName">
            <label class="user-label">{{ userName }} |</label>
            <button class="menu-label" mat-button [disableRipple]="true" [matMenuTriggerFor]="menu">
              {{role}}
              <mat-icon aria-hidden="false"  aria-label="Ícone de expandir" class="material-symbols-outlined expand-icon" fontIcon="expand_more"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="goToArea()"><span class="mdc-list-item">MINHA ÁREA</span></button>
              <button mat-menu-item (click)="logout()">
                <mat-icon aria-hidden="false" aria-label="Sair" class="material-symbols-outlined mdc-list-item-icon"
                fontIcon="logout"></mat-icon>
                <span class="mdc-list-item">SAIR</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row class="second-line" [ngClass]="{'flow': !isHomeRoute }">
          <div class="container-sub-menu">
          <ul class="links" [ngClass]="{'plan': !isHomeRoute }">
            <li class="plan" *ngIf="!showPlan || !showComunication">
              <a data-test="button-whats-liberacred" id="linkLiberacred" href="#Saiba"> O QUE É O LIBERACRED</a>
              <a data-test="button-questions" id="linkPerguntas" href="#Requisitos"> REQUISITOS</a>
            </li>
            <li class="plan-client" *ngIf="showPlan && !isClienteUser() || showComunication">
              <div *appHasAuthorization="registerAllowedPermissions"><a *ngIf="!isClienteUser()" id="linkContratarPlano" class="link-container" (click)="goToSimulation()">CONTRATAR PLANO</a></div>
              <div *appHasAuthorization="consultAllowedPermissions"><a id="linkPlanos" class="link-container" (click)="goToPlans()">PLANOS CONTRATADOS</a></div>
              <div *appHasAuthorization="comunicationAllowedPermissions"><a id="linkContratarPlano" class="link-container" (click)="goToParameters()">PARÂMETROS TÉCNICOS</a></div>
            </li>
          </ul>
        </div>
      </mat-toolbar-row>
      <div class="mobile-itens" *ngIf="menuOpen">
        <div class="background-user">
        <yfs-login *ngIf="!userName" (nomeAtualizado)="atualizarNome($event)" [flowSeller]="flowSeller"></yfs-login>
        <div class="flex-content-mobile" *ngIf="userName">
          <mat-icon aria-hidden="false" aria-label="Ícone de usuário" class="person-icon" fontIcon="person"></mat-icon>
          <label class="user-label">{{ userName }} |</label>
          <label class="user-label">{{ role }}</label>
        </div>
        </div>
        <div class="itens">
        <a *ngIf="!showMenu" data-test="button-whats-liberacred" id="linkLiberacred" href="#Saiba" (click)="toggleMenu()">O QUE É O LIBERACRED</a>
        <a *ngIf="!showMenu" data-test="button-questions" id="linkPerguntas" href="#Requisitos" (click)="toggleMenu()">REQUISITOS</a>
        <a *appHasAuthorization="comunicationAllowedPermissions" (click)="goToParameters()">PARÂMETROS TÉCNICOS</a>
        <a mat-menu-item *ngIf="userName" (click)="goToArea()">
          <span class="mdc-list-item">MINHA ÁREA</span>
        </a>

          <div class="user" *appHasAuthorization="plansAlowedPermissions" (click)="togglePlans(!showPlans)">
            <span>
              <img alt="icone dos planos" src="assets/svg/icon-plano.svg" />
            </span>
            <h1>Planos</h1>
            <mat-icon *ngIf="!showPlans" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
            <mat-icon *ngIf="showPlans" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
          </div>
          <div class="plans" *ngIf="showPlans && !verifyClienteUser()">
            <span (click)="goToSimulation()" *appHasAuthorization="registerAllowedPermissions">Contratar Plano</span>
            <span (click)="goToContractsPlans()" *appHasAuthorization="consultAllowedPermissions">Planos Contratados</span>
            <span (click)="goToReleaseValues()" *appHasAuthorization="releaseValuesReportAlowedPermissions">Liberação de valores</span>
          </div>
          <div class="plans" *ngIf="showPlans && verifyClienteUser()">
            <span (click)="goToMyPlan()" *appHasAuthorization="registerAllowedPermissions">Plano contratado</span>
          </div>


        <div class="user" *appHasAuthorization="generateReportAlowedPermissions" (click)="toggleReports(!showReport)">
          <span>
            <img alt="icone dos relatórios" src="assets/svg/relatorio.svg" />
          </span>
          <h1>Relatórios</h1>
          <mat-icon *ngIf="!showReport" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
          <mat-icon *ngIf="showReport" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
        </div>
        <div class="plans" *ngIf="showReport">
          <span *appHasAuthorization="generatePositionReportAlowedPermissions" (click)="goToPositionReport()">Posição Contábil</span>
          <span *appHasAuthorization="generateComissionsReportAlowedPermissions" (click)="goToCommissions()">Comissões</span>
          <span *appHasAuthorization="generateReleaseValuesReportAlowedPermissions" (click)="goToReleaseValuesReport()">Liberação de valores</span>
          <span *appHasAuthorization="generateContractExtractReportAlowedPermissions" (click)="goToContractExtractReport()">Extrato do Contrato</span>
          <span *appHasAuthorization="ineligibilityReportAllowedPermissions" (click)="goToIneligibilityReport()">Inelegibilidade</span>
          <span *appHasAuthorization="generateFinancialMovementAlowedPermissions" (click)="goTofinancialMovement()">Conciliação de Movimentação Financeira</span>
          <span *appHasAuthorization="generateSegregationReportAlowedPermissions" (click)="goToSegregationReport()">Segregação Curto e Longo Prazo</span>
          <span *appHasAuthorization="generateChangeReportAlowedPermissions" (click)="goToChangeReport()">Mudança de Status</span>
        </div>

        <div class="user" *appHasAuthorization="accountingAlowedPermissions" (click)="toggleAccounting(!showAccounting)">
          <span>
            <img alt="icone contábil" src="assets/svg/contabil.svg" />
          </span>
          <h1>Contábil</h1>
          <mat-icon *ngIf="!showAccounting" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
          <mat-icon *ngIf="showAccounting" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
        </div>
        <div class="plans" *ngIf="showAccounting">
          <span (click)="goToAccounting()">Gerar Arquivo Contábil</span>
        </div>

        <div class="user" *appHasAuthorization="userAllowedPermissions" (click)="toggleUser(!showUser)">
          <span>
            <mat-icon class="groups-icon">groups</mat-icon>
          </span>
          <h1>Usuários</h1>
          <mat-icon *ngIf="!showUser" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
          <mat-icon *ngIf="showUser" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
        </div>
        <div class="plans" *ngIf="showUser">
          <span *appHasAuthorization="userAllowedPermissions" (click)="goToUserList()">Tabela de usuários</span>
          <span *appHasAuthorization="addInternalUserPermission" (click)="goToRegisterInternalUser()">Cadastrar usuário Interno</span>
          <span *appHasAuthorization="addExternalUserPermission" (click)="goToRegisterExternalUser()">{{descriptionAddExternal()}}</span>
          <span *appHasAuthorization="createProfilePermission" (click)="goToUserProfile()">Perfis de usuário</span>
        </div>

        <div class="user" *appHasAuthorization="technicalParametersAllowedPermissions" (click)="toggleParameters(!showParameters)">
          <span>
            <mat-icon aria-hidden="false" aria-label="Ícone de parâmetros técnicos" class="groups-icon material-symbols-outlined" fontIcon="mail"></mat-icon>
          </span>
          <h1>Parâmetros técnicos</h1>
          <mat-icon *ngIf="!showParameters" aria-hidden="false" aria-label="Ícone de expandir" class="material-symbols-outlined expand" fontIcon="expand_more"></mat-icon>
          <mat-icon *ngIf="showParameters" aria-hidden="false" aria-label="Ícone de retrair expansão" class="material-symbols-outlined expand" fontIcon="expand_less"></mat-icon>
        </div>
        <div class="plans" *ngIf="showParameters">
          <span *appHasAuthorization="comunicationAllowedPermissions" (click)="goToComunication()">Comunicação</span>
          <span *appHasAuthorization="productsAllowedPermissions" (click)="goToProducts()">Produtos</span>
          <span *appHasAuthorization="parameterTariffAllowedPermissions" (click)="goToParameterCommission()">Cadastrar parâmetros de comissão</span>
          <span *appHasAuthorization="parameterTariffAllowedPermissions" (click)="goToParameterTariff()">Cadastrar Tarifas</span>
          <span *appHasAuthorization="tariffHistoricAllowedPermissions" (click)="goToOldTablesTariff()">Consultar tabela de tarifas</span>
          <span *appHasAuthorization="tariffHistoricAllowedPermissions" (click)="goToOldTablesCommissions()">Consultar tabela de comissões</span>
          <span *appHasAuthorization="manageMinutaAllowedPermissions" (click)="goToDraftList()">Minutas</span>
        </div>
        <a mat-menu-item (click)="logout()" *ngIf="userName" class="icon-text-container">
          <mat-icon aria-hidden="false" aria-label="Sair" class="material-symbols-outlined mdc-list-item-icon" fontIcon="logout"></mat-icon>
          <span class="mdc-list-item">SAIR</span>
        </a>
        </div>

      </div>
      <yfs-menu *ngIf="showMenu"></yfs-menu>
    </div>
  </header>
</section>
<div *ngIf="showOverlay" class="overlay-menu"></div>

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { ButtonComponent } from '../buttons/button/button.component';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-template-listing-minuta',
    templateUrl: './template-listing-minuta.component.html',
    styleUrls: ['./template-listing-minuta.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatIcon, MatInput, FormsModule, ReactiveFormsModule, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatRadioGroup, MatRadioButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, ButtonComponent, NgIf, LoadingSpinnerComponent]
})
export class TemplateListingMinutaComponent implements OnInit {
  public nomeTemplate = new FormControl();
  public hasValue = true;
  public length = 50;
  public pageSize = 5;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public isLoading = false;
  public dataSource: any;
  public selectedTemplate: any;
  public listaTemplates = [];
  public displayedColumns2: string[] = [
    'nomeTemplate',
  ];
  constructor(
    private customPaginator: MatPaginatorIntl,
    public dialogRef: MatDialogRef<TemplateListingMinutaComponent>,
    private apiService: ApiHttpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    customPaginator.itemsPerPageLabel = "Itens por página";
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined;

  ngOnInit() {
    this.getTemplates()
  }

  closeModal() {
    this.dialogRef.close();
  }

  setPageStart() {
    this.pageIndex = 0;
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getTemplates()
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  selectTemplate = () => {
    this.dialogRef.close();
    this.data.insertTemplateSelectedToEditor(this.selectedTemplate);
  }

  getTemplates() {
    const searchValue = this.nomeTemplate.value ?? ""

    this.isLoading = true;
    this.apiService.getTemplatesMinuta(searchValue, this.pageIndex + 1, this.pageSize).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.listaTemplates = result.items;
          this.dataSource = result.items;
          this.dataSource = new MatTableDataSource(this.listaTemplates);
          if (this.listaTemplates && this.listaTemplates.length === 0) this.hasValue = false;
          else this.hasValue = true;
          this.length = result.totalCount;
          this.customPaginator.getRangeLabel = this.getRangeLabel;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

}

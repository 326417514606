import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf, NgStyle } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';

interface IDataModalGeneric {
  text: string;
  textBold?: string;
  secondaryText?: string;
  icon: string;
  primaryButtonAction: any;
  primaryButtonText: string;
  primaryButtonVariant: string;
  primaryButtonColor?: string;
  secundaryButtonAction?: any;
  secundaryButtonText?: string;
  secundaryButtonVariant?: string;
  secundaryButtonColor?: string;
  highlightText?: string;
  highlightTextInner?: string;
  showCloseButton?: boolean,
  primaryButtonDisabled?: boolean;
  secundaryButtonDisabled?: boolean;
}

@Component({
    selector: 'yfs-modal-generic',
    templateUrl: './modal-generic.component.html',
    styleUrls: ['./modal-generic.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, MatDialogClose, NgStyle, ButtonComponent]
})
export class ModalGenericComponent {
  public isFullWidth = window.innerWidth < 768;

  constructor(
    public dialogRef: MatDialogRef<ModalGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataModalGeneric
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 768;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  handlePrimaryButton() {
    if (this.data.primaryButtonAction === 'close')
      this.closeModal()
    else {
      this.data.primaryButtonAction()
    }
  }

  handleSecundaryButton() {
    if (this.data.secundaryButtonAction === 'close')
      this.closeModal()
    else {
      this.closeModal()
      this.data.secundaryButtonAction()
    }
  }

  getIcon() {
    let urlIcon = 'assets/svg/'
    switch (this.data.icon) {
      case 'warning':
        return urlIcon + 'warning-icon.svg';
      case 'waiting':
        return urlIcon + 'waiting-icon.svg';
      case 'wait-blue':
        return urlIcon + 'wait-blue.svg';
      case 'error':
        return urlIcon + 'error-icon.svg';
      case 'info':
        return urlIcon + 'info-icon.svg';
      case 'success':
        return urlIcon + 'success.svg';
      default:
        return urlIcon + 'warning-icon.svg'
    }
  }

}

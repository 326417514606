<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
    <div class="button-back">
      <yfs-link-back [text]="previousScreen === 'parameters' ? 'Voltar para parâmetros técnicos' : 'Voltar para minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>RELATÓRIO DE MUDANÇA DE STATUS</h1>
    <h2 class="subtitle">
        Selecione a data da geração do arquivo. Por padrão a data preenchida é a data atual.
    </h2>

    <mat-card *ngIf="currentFlow == 'results'" class="card-success">
        <mat-card-content class="card-content-success">
            <img src="assets/svg/success.svg" height="48" width="48" alt="logo modal alert" />
            <span>Relatório gerado com sucesso. Você já pode fazer o download abaixo.</span>

            <yfs-button state="enabled" [label]="'BAIXAR RELATÓRIO (.' + formatoArquivo.toUpperCase() + ')'"  type="primary" (click)="handleGenerateReport()" [fullWidth]="isFullWidth"></yfs-button>
            <yfs-button state="enabled" label="VOLTAR" icon="arrow_back"  type="tertiary" (click)="currentFlow = 'parameters'"></yfs-button>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="currentFlow == 'parameters'" class="card">
        <mat-card-content class="card-content">
            <mat-form-field class="inputs" (input)="handleInputNumeroContrato()" appearance="fill">
                <mat-label>Número do Contrato</mat-label>
                <input [formControl]="contrato" matInput />
            </mat-form-field>
            <div class="inputs-date">
                <mat-form-field appearance="fill">
                    <mat-label>Data inicial do relatório</mat-label>
                    <input type="hidden" [matDatepicker]="pickerInicial" [disabled]="isDisabledMode" [(ngModel)]="dataInicialLabel"
                        [max]="maxDateInicial" (ngModelChange)="onInputChangeInicial(true)" />
                    <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataInicial" [max]="maxDateInicial"
                        [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'"
                        (ngModelChange)="onInputChangeInicial(false)" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerInicial"></mat-datepicker-toggle>
                    <mat-datepicker #pickerInicial></mat-datepicker>
                    <mat-error class="date-error" *ngIf="dataInicial.hasError('required')"> O campo é
                        obrigatório</mat-error>
                    <mat-error class="date-error" *ngIf="dataInicial.hasError('invalidDate')">Data inválida.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Data final do relatório</mat-label>
                    <input type="hidden" [matDatepicker]="pickerFinal" [disabled]="isDisabledMode" [(ngModel)]="dataFinalLabel" [max]="maxDateFinal"
                        (ngModelChange)="onInputChangeFinal(true)" />
                    <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataFinal" [max]="maxDateFinal"
                        (ngModelChange)="onInputChangeFinal(false)" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFinal></mat-datepicker>
                    <mat-error class="date-error" *ngIf="dataFinal.hasError('required')"> O campo é
                        obrigatório</mat-error>
                    <mat-error class="date-error" *ngIf="dataFinal.hasError('invalidDate')">Data inválida.</mat-error>
                    <mat-error class="date-error" *ngIf="dataFinal.hasError('incorrect')"></mat-error>
                </mat-form-field>
            </div>
            <span class="date-error" *ngIf="(dataFinalError || dataInicialError) && !contrato.value">
                <img src="assets/svg/atention.svg" alt="icone de atenção" />
                {{dataInicialError ? dataInicialError : dataFinalError}}
            </span>

            <div class="radio-content" style="margin-bottom: 8px;">
                <span class="radio-title">Canal de originação:</span>
                <mat-radio-group class="radio-group" aria-label="Select an option" [(ngModel)]="canalOriginacao" (change)="handleCanalOriginacaoChange()">
                    <mat-radio-button [disabled]="isDisabledMode" value="Concessionaria">
                        <span class="radio-title">
                            Concessionária / Representante
                        </span>
                    </mat-radio-button>
                    <mat-radio-button [disabled]="isDisabledMode" value="E-commerce">
                        <span class="radio-title">
                            E-commerce
                        </span>
                    </mat-radio-button>
                    <mat-radio-button [disabled]="isDisabledMode" value="Todos">
                        <span class="radio-title">
                            Todos
                        </span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Selecione as empresas</mat-label>
                <mat-select #mySel1 [formControl]="empresas" multiple>
                    <mat-select-trigger>
                        {{ getSelectedLabelEmpresas() }}
                    </mat-select-trigger>
                    <div class="select-all" (click)="selectAllEmpresas()">
                        <mat-checkbox [(ngModel)]="checkedAllEmpresas"
                            [indeterminate]="interteminateEmpresas" color="primary"
                            (change)="selectAllEmpresas()"><span>Todos</span></mat-checkbox>
                    </div>
                    <mat-option *ngFor="let relacionamento of empresasList"
                        [value]="relacionamento.idConcessionaria" (click)="checkHandleEmpresas()">{{
                        relacionamento.razaoSocial }}</mat-option>
                </mat-select>
                <mat-error *ngIf="empresas.hasError('required')">
                    O campo é obrigatório
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Canal de relacionamento</mat-label>
                <mat-select #mySel2 [formControl]="canalRelacionamento" multiple>
                    <mat-select-trigger>
                        {{ getSelectedLabelCanalRelacionamento() }}
                    </mat-select-trigger>
                    <div class="select-all" (click)="selectAllRelacionamento()">
                        <mat-checkbox [(ngModel)]="checkedAllRelacionamento"
                            [indeterminate]="interteminateRelacionamento" color="primary"
                            (change)="selectAllRelacionamento()"><span>Todos</span></mat-checkbox>
                    </div>
                    <mat-option *ngFor="let relacionamento of relacionamentoList"
                        [value]="relacionamento.idConcessionaria" (click)="checkHandleRelacionamento()">{{
                        relacionamento.razaoSocial }}</mat-option>
                </mat-select>
                <mat-error *ngIf="canalRelacionamento.hasError('required')">
                    O campo é obrigatório
                </mat-error>
            </mat-form-field>

            <div class="status-title">
                <span class="radio-title">Status:</span>
            </div>
            <div class="inputs-date">
                <mat-form-field appearance="fill">
                    <mat-label>Status de origem</mat-label>
                    <mat-select #mySel3 [formControl]="statusOrigem" multiple>
                        <mat-select-trigger>
                            {{ getSelectedLabelStatus(statusOrigem) }}
                        </mat-select-trigger>
                        <div class="select-all" (click)="selectAllStatusOrigem()">
                            <mat-checkbox [(ngModel)]="checkedAllStatusOrigem"
                                [indeterminate]="interteminateStatusOrigem" color="primary"
                                (change)="selectAllStatusOrigem()"><span>Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let origem of statusOptions"
                            [value]="origem.value" (click)="checkHandleStatusOrigem()">{{
                            origem.label }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="statusOrigem.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Status de destino</mat-label>
                    <mat-select #mySel4 [formControl]="statusDestino" multiple>
                        <mat-select-trigger>
                            {{ getSelectedLabelStatus(statusDestino) }}
                        </mat-select-trigger>
                        <div class="select-all" (click)="selectAllStatusDestino()">
                            <mat-checkbox [(ngModel)]="checkedAllStatusDestino"
                                [indeterminate]="interteminateStatusDestino" color="primary"
                                (change)="selectAllStatusDestino()"><span>Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let destino of statusOptions"
                            [value]="destino.value" (click)="checkHandleStatusDestino()">{{
                            destino.label }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="statusDestino.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="radio-content format-file">
                <span class="radio-title">Formato do arquivo:</span>
                <mat-radio-group class="radio-group" aria-label="Select an option" [(ngModel)]="formatoArquivo">
                    <mat-radio-button value="pdf">
                        <span class="radio-title">
                            PDF
                        </span>
                    </mat-radio-button>
                    <mat-radio-button value="xls">
                        <span class="radio-title">
                            XLS
                        </span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="button-container">
                <yfs-button [state]="isEnabledButton() ? 'enabled' : 'disabled'" label="GERAR ARQUIVO" class="btn-generate" type="primary" [press]="search" [fullWidth]="isFullWidth"></yfs-button>
            </div>

        </mat-card-content>
    </mat-card>

    <mat-card class="no-files-container" *ngIf="currentFlow == 'nofiles'">
        <mat-card-content>
            <div class="no-files">
                <img src="assets/svg/empty.svg" alt="documento vazio" height="64" width="64">
                <div class="text-no-files">
                    <span>Nenhum relatório encontrado com os parâmetros selecionado,</span>
                    <span>tente mudar os parâmetros e gere um novo relatório.</span>
                </div>
                <yfs-button state="enabled" label="GERAR NOVO RELATÓRIO" type="primary"
                    (click)="currentFlow = 'parameters'" [fullWidth]="isFullWidth"></yfs-button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

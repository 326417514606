import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { HeaderComponent } from '../../../components/header/header.component';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgFor } from '@angular/common';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCheckbox } from '@angular/material/checkbox';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-create-profile',
    templateUrl: './create-profile.component.html',
    styleUrls: ['./create-profile.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, MatRadioGroup, MatRadioButton, NgFor, MatCheckbox, ButtonComponent, LoadingSpinnerComponent],
    providers:[RouterModule]
})
export class CreateProfileComponent {
  public name = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  public isLoading = false;
  public permissions: { label: string, control: FormControl }[] = [];
  public profileType = new FormControl();

  constructor(
    private apiService: ApiHttpService,
    public dialog: MatDialog,
    private router: Router,
  ) {
  }

  ngOnInit(){
    this.getProfiles()
  }

  getBack() {
    this.router.navigate(['user-profile']);
  }

  getProfiles() {
    this.isLoading = true;
    this.apiService
      .getAccessProfile()
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if (error.status === 400 || error.status === 404 || error.status === 500) {
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.body) {
            this.permissions = result.body.map((permission: any) => ({
              idPermissaoAcesso: permission.idPermissaoAcesso,
              label: permission.descricao,
              control: new FormControl(false)
            }));
          }
          this.isLoading = false;
        },
      });
  }

  sendRequest = () => {
    this.isLoading = true;
    const usuario = {
      nome: this.name.value,
      interno: this.profileType.value,
      idsPermissaoAcesso: this.permissions
        .filter((permission: any) => permission.control.value)
        .map((permission: any) => permission.idPermissaoAcesso)
    };
    this.apiService
      .postCreateProfile(usuario)
      .pipe(
        timeout(300000),
        catchError((error) => {
          this.isLoading = false;

          if (error instanceof TimeoutError) {
            return throwError(() => 'A requisição demorou muito tempo e foi cancelada.');
          }

          if (error.status === 400 && error.error && error.error.errors) {
            this.openDialogValidationError(error.error.errors);
          } else if (error.status === 400 || error.status === 404 || error.status === 500) {
            this.openDialogCreateError();
          }

          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 201) {
            this.openDialogCreateSucess();
          } else {
            this.openDialogCreateError();
          }
          this.isLoading = false;
        },
      });
}

  openDialogCreateSucess = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: `Perfil criado com sucesso.`,
        primaryButtonText: 'IR PRA A LISTA DE PERFIS',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'primary'
      }
    }).afterClosed().subscribe(() => this.goToList())
  }

  openDialogCreateError() {
    this.dialog.open(ModalGenericComponent, {
      data: {
        icon: 'error',
        text: `Houve um problema ao tentar criar o perfil.`,
        primaryButtonText: 'VOLTAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.sendRequest()}
      }
    })
  }

  openDialogCancel() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Você tem certeza que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.`,
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.goToList()}
      }
    })
  }

  openDialogValidationError(errors: any) {
    const errorMessage = errors.Nome ? errors.Nome[0] : 'Houve um problema ao tentar criar o perfil.';

    this.dialog.open(ModalGenericComponent, {
      data: {
        icon: 'error',
        text: errorMessage,
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.sendRequest()}
      }
    });
}

  isFormValid(): boolean {
    const hasName = !this.name.hasError('required');
    const hasProfileType = this.profileType.value !== null && this.profileType.value !== undefined;
    const hasSelectedPermissions = this.permissions.some(permission => permission.control.value);

    return hasName && hasProfileType && hasSelectedPermissions;
  }

  goToList(){
    this.router.navigate(['user-profile']);
  }


}

<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
  </div>
  <h1>GERAÇÃO DE RELATÓRIO DE POSIÇÃO CONTÁBIL</h1>
  <h2 class="subtitle">
    Utilize os filtros abaixo para selecionar os critérios para a geração do relatório.
  </h2>
  <h2 class="subtitle second-subtitle">Por padrão, a data preenchida é a de ontem e somente datas até o dia atual podem
    ser selecionadas.</h2>
  <mat-card class="card" *ngIf="!success">
    <mat-card-content class="card-content">
      <div *ngIf="emptyDocument && !generationError">
        <img src="assets/svg/empty.svg" alt="documento vazio">
        <div class="message-empty-document">
          <span>Nenhum resultado encontrado para os critérios selecionados. Por favor, tente novamente com outras
            opções.</span>
        </div>
      </div>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Data base</mat-label>
        <input type="hidden" [matDatepicker]="pickerDataBase" [max]="maxDate" [(ngModel)]="dataLabelBase"
          (ngModelChange)="onInputChange()" />
        <input data-test="input-date-movimentation" matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDate"
          [formControl]="dataBase" [ngModel]="dataLabelBase | date : 'dd/MM/yyyy'"
          (ngModelChange)="onInputChange(); checkValidInputs()" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerDataBase"></mat-datepicker-toggle>
        <mat-datepicker #pickerDataBase></mat-datepicker>
        <mat-error *ngIf="dataBase.hasError('required')">
          Este campo não pode ficar vazio
        </mat-error>
        <mat-error class="date-error" *ngIf="dataBase.hasError('invalidDate')">Data inválida.</mat-error>
        <mat-error class="date-error" *ngIf="dataBase.hasError('dataValida')">Data inválida. Selecione uma data até o
          dia atual.</mat-error>
      </mat-form-field>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>N° Contrato</mat-label>
        <input [formControl]="contrato" (ngModelChange)="checkValidInputs()" matInput>
      </mat-form-field>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>CPF do cliente</mat-label>
        <input data-test="input-cpf" matInput mask="000.000.000-00"[formControl]="cpf"
          (ngModelChange)="checkValidInputs()">
        <mat-error *ngIf="cpf.hasError('invalidCpf')">CPF não existe</mat-error>
      </mat-form-field>
      <mat-form-field id="status" appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="status" (ngModelChange)="checkValidInputs()" multiple>
          <mat-select-trigger>
            {{ getStatusLabel(status.value?.[0]) || '' }}
            <span *ngIf="(status.value?.length || 0) > 1" class="example-additional-selection">
              (+{{ (status.value?.length || 0) - 1 }} {{ status.value?.length === 2 ? 'outro' : 'outros' }})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption.value">
            {{ statusOption.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="file-format">
        <div class="recipient-content">
          <span class="content-title">Formato do arquivo:</span>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="reportFormat">
              <mat-radio-button value="pdf">PDF</mat-radio-button>
              <mat-radio-button value="xlsx">XLS</mat-radio-button>
          </mat-radio-group>
      </div>
      </div>
      <yfs-button
        [state]="dataBase.hasError('dataValida') || dataBase.hasError('invalidDate') || dataBase.hasError('required') || cpf.hasError('invalidCpf') ? 'disabled' : 'enabled'"
        type="primary" data-test="button-generate" class="btn-generate" [press]="sendRequest" label="GERAR RELATÓRIO">
      </yfs-button>
      <span *ngIf="generationError" data-test="error-message-file" class="error-message"><img
          src="assets/svg/atention.svg" alt="icone de atenção" /> Não foi possível gerar o relatório nesse
        momento.</span>
      <span *ngIf="generationError" data-test="error-message-file" class="error-try-again"> Tente novamente mais
        tarde.</span>
    </mat-card-content>
  </mat-card>
  <mat-card class="card" *ngIf="success">
    <mat-card-content class="card-content">
      <img src="assets/svg/success.svg">
      <div class="card-message">
        <div class="message">
          <span>Arquivo gerado com sucesso!</span>
        </div>
        <div class="message">
          <span>Você já pode fazer o download abaixo.</span>
        </div>
      </div>
      <yfs-button state="enabled" class="btn-download" label="BAIXAR ARQUIVO" type="primary"
        (click)="downloadFile()"></yfs-button>
      <div>
        <a (click)="openDialog()">GERAR OUTRO ARQUIVO</a>
      </div>
      <div class="atention">
        <span>(O arquivo atual será apagado)</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { NgIf, NgClass, DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatTabGroup, MatTab, MatTabLabel } from '@angular/material/tabs';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { NgxMaskDirective } from 'ngx-mask';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-contract-extract-report',
    templateUrl: './contract-extract-report.component.html',
    styleUrls: ['./contract-extract-report.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, NgIf, MatCard, MatTabGroup, MatTab, MatTabLabel, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatError, MatCheckbox, MatDatepickerInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, NgClass, MatRadioGroup, MatRadioButton, ButtonComponent, LoadingSpinnerComponent, DatePipe]
})
export class ContractExtractReportComponent {

  public currentDate = new Date();
  public isLoading = false;
  public timeoutError = false;
  public success: boolean = false;
  public generationError = false;
  public dataInicialLabel: Date | undefined;
  public dataFinalLabel: Date | undefined;
  public maxDate: Date | undefined;
  public dateError = false;
  public emptyDocument = false;
  public formatoArquivo = 'pdf'
  public file: any;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public dataInicial = new FormControl('', [
    dateFormat.commissionsValidate,
  ]);
  public dataFinal = new FormControl('', [
    dateFormat.commissionsValidate,
  ]);
  public newCurrentDate = new Date();
  public contrato = new FormControl('', [Validators.required]);
  public cpf = new FormControl('', [Validators.required, CpfValidator.cpf]);
  public nome = new FormControl('', [Validators.required]);
  public checkedAll = false;
  public interteminate = false;
  public checkedShowDate = false;
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public contractFile = '';

  constructor(
    private apiService: ApiHttpService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.currentDate.setDate(this.currentDate.getDate());
    this.dataInicialLabel = this.currentDate;
    this.dataFinalLabel = this.currentDate;
    this.maxDate = this.newCurrentDate;
  }

  getBack() {
    this.router.navigate(['area']);
  }

  changeTab(tabNumber: any) {
    const currentDate = new Date();
    this.currentDate = tabNumber;
    this.checkedShowDate = false;
    this.contrato.setValue('');
    this.cpf.setValue('');
    this.nome.setValue('');
    this.formatoArquivo = 'pdf';
    this.dataInicial.setValue('');
    this.dataFinal.setValue('');
    this.dataInicialLabel = currentDate;
    this.dataFinalLabel = currentDate;
    this.generationError = false;
    this.emptyDocument = false;
    this.isDisabledContrato();
    this.isDisabledCpf();
    this.isDisabledNome();
  }

  onInputChange() {
    this.generationError = false;
    const dataInicio = this.dataInicial.value ? new Date(this.initialFormatDate(this.dataInicial.value)) : null;
    const dataFim = this.dataFinal.value ? new Date(this.initialFormatDate(this.dataFinal.value)) : null;
    if (dataInicio && dataFim && dataInicio > dataFim) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  initialFormatDate(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  clickNumero = () => {
    this.sendRequest('NumeroContrato')
  }

  clickCpf = () => {
    this.sendRequest('Cpf')
  }

  clickName = () => {
      this.sendRequest('NomeCliente')
  }

  isDisabledContrato() {
    if (this.contrato.hasError('required') )
      return 'disabled';
    return 'enabled';
  }

  isDisabledCpf() {
    if (this.cpf.hasError('required') || this.cpf.hasError('cpfInvalid'))
      return 'disabled';
    return 'enabled';
  }

  isDisabledNome() {
    if ( this.nome.hasError('required'))
      return 'disabled';
    return 'enabled';
  }

  openDialog() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Ao gerar um novo arquivo, o atual será apagado. Tem certeza que deseja continuar?',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => {
          this.success = false;
          this.generationError = false;
          this.emptyDocument = false;
        },
        secundaryButtonText: 'SIM, GERAR NOVO ARQUIVO',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  downloadFile() {
    const fileName = this.file.name;
    const fileURL = this.file.url;
    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  sendRequest(typeClick: string) {
    let objSend = {}
    const dataInicial = this.dataInicial.value
    const dataFinal = this.dataFinal.value
    let dataInicialFormatada = ''
    let dataFinalFormatada = ''

    if (dataInicial) {
      const [dia, mes, ano] = dataInicial.split('/')
      dataInicialFormatada = `${ano}-${mes}-${dia}`;
    }

    if (dataFinal) {
      const [dia, mes, ano] = dataFinal.split('/')
      dataFinalFormatada = `${ano}-${mes}-${dia}`;
    }

    if (typeClick === 'NumeroContrato') {
      objSend = {
        campo: typeClick,
        valorCampo: this.contrato.value,
        dataInicial: !this.checkedShowDate ? '' : dataInicialFormatada || '',
        dataFinal: !this.checkedShowDate ? '' : dataFinalFormatada || '',
        formatoArquivo: this.formatoArquivo
      }
    } else if (typeClick === 'Cpf') {
      objSend = {
        campo: typeClick,
        valorCampo: this.cpf.value?.replace(/[^0-9]/g, ''),
        dataInicial: !this.checkedShowDate ? '' : dataInicialFormatada || '',
        dataFinal: !this.checkedShowDate ? '' : dataFinalFormatada || '',
        formatoArquivo: this.formatoArquivo
      }
    } else if (typeClick === 'NomeCliente') {
      objSend = {
        campo: typeClick,
        valorCampo: this.nome.value,
        dataInicial: !this.checkedShowDate ? '' : dataInicialFormatada || '',
        dataFinal: !this.checkedShowDate ? '' : dataFinalFormatada || '',
        formatoArquivo: this.formatoArquivo
      }
    }
    this.isLoading = true;
    this.apiService
      .getExtractReport(objSend)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            this.timeoutError = true;
            this.generationError = true;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          if (response.status === 204) {
            this.isLoading = false;
            this.emptyDocument = true;
          } else {
            this.isLoading = false;
            if (response.body?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              const fileName = `Relatório de Extrato do Contrato.xlsx`;
              const file = new Blob([response.body], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const fileURL = URL.createObjectURL(file);
              this.file = { name: fileName, url: fileURL };
              this.success = true;
            } else if (response.body?.type === 'application/pdf') {
              const fileName = `Relatório de Extrato do Contrato.pdf`;
              const file = new Blob([response.body], {
                type: 'application/pdf',
              });
              const fileURL = URL.createObjectURL(file);
              this.file = { name: fileName, url: fileURL };
              this.success = true;
            }
              else {
              this.success = false;
              this.isLoading = false;
              this.generationError = true;
            }
          }
        },
        error: (error) => {
          this.isLoading = false;
          if (!this.timeoutError) {
            this.success = false;
          }
          this.generationError = true;
          this.emptyDocument = false;
        },
      });
  }

  formatarNome() {
    let valor = this.nome.value;
    if (valor) {
      valor = valor.replace(/[^a-zA-ZÀ-ÿ\s]/g, '');
      this.nome.setValue(valor);
    }
  }

  formatarCPF() {
    let valor = this.cpf.value;
    if (valor) {
      valor = valor.replace(/\D/g, '');
      if (valor.length > 11) {
        valor = valor.slice(0, 11);
      }
      valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    }
  }

}

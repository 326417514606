import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { UserService } from 'src/app/services/user.service';
import { dateFormat } from 'src/app/shared/date-format';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { LinkBackComponent } from '../../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf, DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { ButtonComponent } from '../../../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-proof-payment',
    templateUrl: './proof-payment.component.html',
    styleUrls: ['./proof-payment.component.scss'],
    standalone: true,
    imports: [LinkBackComponent, MatCard, MatCardContent, NgIf, MatIcon, MatFormField, MatLabel, MatDatepickerInput, FormsModule, MatInput, NgxMaskDirective, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, ButtonComponent, LoadingSpinnerComponent, DatePipe]
})
export class ProofPaymentComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();
  @Input() idContrato: number | undefined;

  public fileSizeError: boolean = false;
  public srcResult: any;
  public fileName: any;
  public fileType: any;
  public file: string | undefined;
  public dataRegularizacaoLabel: Date | undefined;
  public dataRegularizacaoDisabled: string = '';
  public dataRegularizacao = new FormControl('', [Validators.required, dateFormat.positionValidator]);
  public idComprovanteQuitacao = null;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public proofPaymentSent = false;
  public proofPaymentProcessed = false;
  public isSacUser: boolean = false;
  public showInputCalendar: boolean = false;
  public showInputDisabled: boolean = false;
  public showButtons: boolean = false;
  public isLoading: boolean = false;
  public maxDate: Date;
  public newCurrentDate = new Date();
  public invalidFormat = false;
  public isFullWidth = window.innerWidth < 800;
  public permissions: string[] = [];

  constructor(private apiService: ApiHttpService, private userService: UserService, public dialog: MatDialog) {
    this.maxDate = this.newCurrentDate;
  }

  ngOnInit() {
    const currentUser = this.userService.getCurrentUser();
    this.isSacUser = (currentUser?.PerfilAcesso === 'SAC / Ouvidoria' || this.hasPermission('SERVICO_USUARIO_OUVIDORIA')) ? true : false;
    this.getProofPayment();
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  managerInputRender() {
    if (this.proofPaymentSent) {
      if (this.proofPaymentProcessed) {
        this.showButtons = false;
        this.showInputCalendar = false;
        this.showInputDisabled = true;
      } else {
        this.showInputDisabled = !this.isSacUser;
        this.showInputCalendar = this.isSacUser;
        this.showButtons = this.isSacUser;
      }
    } else {
      this.showInputCalendar = true;
      this.showInputDisabled = false;
      this.showButtons = true;
    }
  }

  enabledButton() {
    if (!this.fileName || this.fileSizeError || !this.dataRegularizacao.valid) return 'disabled';
    return 'enabled'
  }

  getBack() {
    this.currentFlowChanged.emit('contractedPlans');
  }

  onFileSelected(event: any) {
    const inputNode = event.target;
    const selectedFile = inputNode.files[0];
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type))
      {
        this.invalidFormat = true;
        return;
      }
      this.invalidFormat = false;
      this.fileType = selectedFile.type;
      if (selectedFile.size > 5242880) {
        this.fileSizeError = true;
      } else {
        this.fileSizeError = false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        this.srcResult = reader.result;
        this.file = this.srcResult.replace(/^data:(.*?);base64,/, '');
      };

      this.fileName = selectedFile.name;
    }
  }

  onRemoveFile() {
    this.fileName = null;
    const inputNode = document.querySelector('input[type="file"]') as HTMLInputElement;
    inputNode.value = null ?? '';
    this.fileSizeError = false;
  }

  onInputChange(isCalendar: boolean) {
    if (this.dataRegularizacao.value?.length === 0) {
      this.dataRegularizacaoLabel = undefined;
    }

    if (this.dataRegularizacao && this.dataRegularizacao.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataRegularizacao2 = this.dataRegularizacao.value ?
        this.getDateByString(this.dataRegularizacao.value) : null;

      if (!isCalendar) {
        this.dataRegularizacaoLabel = dataRegularizacao2 || undefined
      }
    }
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  convertDateToString(data: any) {
    const dataOriginal = new Date(data);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  openFileNewTab() {
    const byteCharacters = atob(this.file || '');
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: this.fileType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    link.click();
  }

  handleUploadFile = () => {
    let text = 'Tem certeza que deseja enviar esse comprovante?';
    if (this.proofPaymentSent) {
      text = `<span>Tem certeza que deseja enviar solicitação de consulta na data <b>${this.dataRegularizacao.value}</b>?</span>`
    }
    this.openModalConfirmSend(text);
  }

  bindValues(proofPaymentObject: any) {
    this.idComprovanteQuitacao = proofPaymentObject?.idComprovanteQuitacao;
    this.proofPaymentSent = proofPaymentObject?.arquivo ? true : false;
    this.proofPaymentProcessed = proofPaymentObject?.concluido;
    this.fileName = proofPaymentObject?.nomeArquivo;
    this.fileType = proofPaymentObject?.extensao;
    this.file = proofPaymentObject?.arquivo;
    const dataRegularizacaoFormated = proofPaymentObject?.dataRegularizacao ? this.convertDateToString(proofPaymentObject?.dataRegularizacao) : '';
    this.dataRegularizacaoLabel = proofPaymentObject?.dataRegularizacao ? this.convertDataLabel(dataRegularizacaoFormated) : undefined;
    this.dataRegularizacao.setValue(dataRegularizacaoFormated);
    this.dataRegularizacaoDisabled = dataRegularizacaoFormated;
  }

  getProofPayment() {
    this.isLoading = true;
    this.apiService
      .getProofPayment(this.idContrato)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.bindValues(result?.comprovanteQuitacao);
          this.managerInputRender();
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  postProofPayment() {
    this.isLoading = true;
    const dataRegularizacaoFormated = this.dataRegularizacao.value ? this.convertStringToDate(this.dataRegularizacao.value) : undefined

    const apiObservable = this.proofPaymentSent
      ? this.apiService.updateProofPayment({
        idComprovanteQuitacao: this.idComprovanteQuitacao,
        dataRegularizacao: dataRegularizacaoFormated
      })
      : this.apiService.postProofPayment({
        idContrato: this.idContrato,
        dataRegularizacao: dataRegularizacaoFormated,
        arquivo: this.file,
        extensao: this.fileType,
        nomeArquivo: this.fileName
      })

    apiObservable.pipe(
      timeout(300000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (this.proofPaymentSent) {
            this.openModalSuccess('A solicitação de consulta foi enviada com sucesso.')
          } else {
            this.openModalSuccess('O comprovante foi enviado com sucesso.')
          }
        },
        error: (error) => {
          this.proofPaymentSent = true;
          this.managerInputRender()
          if (this.proofPaymentSent) {
            this.openModalError('a solicitação de cunsulta')
          } else {
            this.openModalError('o comprovante')
          }
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  openModalClose() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Você tem certeza de que deseja sair sem enviar o comprovante? Todas as modificações feitas serão perdidas.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalConfirmSend(text: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        secondaryText: text,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.postProofPayment() },
        secundaryButtonText: 'SIM, ENVIAR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalError(text: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '410px',
      data: {
        icon: 'error',
        text: `Não foi possível enviar ${text} nesse momento. Tente novamente mais tarde.`,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalSuccess(text: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        icon: 'success',
        text: text,
        primaryButtonAction: () => {this.getBack(); this.dialog.closeAll()},
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }


}

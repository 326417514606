import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Directive({
    selector: '[appHasAuthorization]',
    standalone: true,
})
export class HasAuthorizationDirective implements OnInit {
  @Input() appHasAuthorization: Array<string> | undefined;

  constructor(
    private authService: UserService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    const user = this.authService.getCurrentUser();

    if (!user || !user.PermissaoAcesso || user.PermissaoAcesso.length === 0) return;
    
    const isAllowed = this.appHasAuthorization?.some(r => user.PermissaoAcesso.includes(r))

    if (isAllowed) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
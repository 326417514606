import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { CnpjValidator } from 'src/app/shared/validators/cnpj-validator';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatSelect } from '@angular/material/select';
import { NgFor, NgIf } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-change-relationship',
    templateUrl: './change-relationship.component.html',
    styleUrls: ['./change-relationship.component.scss'],
    standalone: true,
    imports: [LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgxMaskDirective, MatSelect, NgFor, MatOption, NgIf, MatError, MatAutocompleteTrigger, MatAutocomplete, ButtonComponent, LoadingSpinnerComponent]
})
export class ChangeRelationshipComponent {
  @Input() idContrato: number | undefined;
  @Input() concessionariaValue: number | undefined;
  @Output() currentFlowChanged = new EventEmitter<string>();
  public listUfs: any[] = [];
  public listCities: any[] = [];
  public estado = new FormControl('', [Validators.required]);
  public endereco = new FormControl({ value: '', disabled: true });
  public enderecoNovo = new FormControl({ value: '', disabled: true });
  public cidade = new FormControl({ value: '', disabled: true }, [Validators.required]);
  public concessionariaAtual = new FormControl({ value: '', disabled: true });
  public concessionariaNova = new FormControl({ value: '', disabled: true }, [Validators.required]);
  public listaConcessionarias: any[] = [];
  public isLoading: boolean = false;
  public locations: any[] = [];
  public cnpj = new FormControl({ value: '', disabled: true }, [CnpjValidator.cnpj]);
  public celularComDdd = new FormControl({ value: '', disabled: true });
  public celularComDddNovo = new FormControl({ value: '', disabled: true });
  public cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public isFullWidth = window.innerWidth < 1279;

  constructor(private apiService: ApiHttpService, public dialog: MatDialog, private router: Router, public userService: UserService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
  }

  ngOnInit(): void {
    this.getConcessionariaValue();
    this.getEstadosCidades();
    this.setupValueChanges();
  }

  setupValueChanges(): void {
    this.estado.valueChanges.subscribe((estado) => {
      this.updateCidades(estado || '');
    });

    this.cidade.valueChanges.subscribe((cidade) => {
      this.concessionariaNova.setValue('');
      this.concessionariaNova.disable();
      this.listaConcessionarias = [];
      this.celularComDddNovo.setValue('');
      this.enderecoNovo.setValue('');
      if (cidade) {
        this.loadConcessionarias();
      }
    });

    this.concessionariaNova.valueChanges.subscribe((selectedConcessionaria: any) => {
      if (selectedConcessionaria && typeof selectedConcessionaria !== 'string') {
        this.enderecoNovo.setValue(selectedConcessionaria.endereco || '');
        const telefoneFormatado = selectedConcessionaria.telefone ? this.formatarTelefone(selectedConcessionaria.telefone) : '';
        this.celularComDddNovo.setValue(telefoneFormatado);
      } else {
        this.enderecoNovo.setValue('');
        this.celularComDddNovo.setValue('');
      }
    });
  }


  getEstadosCidades(): void {
    this.apiService.getEstadosCidadesEntrega().subscribe(
      (result: any) => {
        this.locations = result;
        this.listUfs = result.map((item: any) => ({ key: item.estado, value: item.estado }));
      }
    );
  }



  formatarCNPJ(cnpj: string): string {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  }

  getConcessionariaValue() {
    this.isLoading = true;
    this.apiService.getConcessionaria(this.concessionariaValue).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => 'A requisição demorou muito tempo e foi cancelada.');
        }
        return throwError(() => error);
      })
    ).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (result.length > 0) {
          this.concessionariaAtual.setValue(result[0].razaoSocial);
          this.cnpj.setValue(this.formatarCNPJ(result[0].cnpj));
          this.endereco.setValue(result[0].endereco);
          const telefoneFormatado = this.formatarTelefone(result[0].telefone);
          this.celularComDdd.setValue(telefoneFormatado);
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  formatarTelefone(telefone: string): string {
    const ddd = telefone.substring(0, 2);
    const parte1 = telefone.substring(2, 6);
    const parte2 = telefone.substring(6, 10);
    return `${ddd} ${parte1}-${parte2}`;
  }

    updateConcessionaria(){
      this.isLoading = true;
      const obj = {
        idContrato: this.idContrato,
        idConcessionaria: (this.concessionariaNova.value as any).idConcessionaria
      };
      this.apiService.putRelationshipChannel(obj).pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
        .subscribe({
          next: (result) => {
            if (result) {
              this.isLoading = false;
              this.openModalSuccess()
            }
          },
            error: (error) => {
              this.isLoading = false;
              console.log(error);
              this.openModalError()
            },
          });
      }

  updateCidades(estado: string): void {
    const selectedEstado = this.locations.find(item => item.estado === estado);
    if (selectedEstado) {
      this.listCities = selectedEstado.cidades.map((cidade: any) => ({ key: cidade, value: cidade }));
      this.cidade.enable();
    } else {
      this.listCities = [];
      this.cidade.disable();
    }
    this.cidade.setValue('');
    this.listaConcessionarias = [];
    this.enderecoNovo.setValue('')
    this.celularComDddNovo.setValue('')
    this.concessionariaNova.setValue('');
    this.concessionariaNova.disable();
  }

  loadConcessionarias(): void {
    const estado = this.estado.value || '';
    const cidade = this.cidade.value || '';
    this.apiService.getConcessionariaEntrega(estado, cidade).subscribe(
      (result: any) => {
        this.listaConcessionarias = result.body;
        this.enderecoNovo.setValue(result.body[0].endereco)
        this.celularComDddNovo.setValue(result.body[0].telefone)
        this.concessionariaNova.enable();
      },
      (error: any) => {
        this.listaConcessionarias = [];
        this.concessionariaNova.setValue('');
        this.concessionariaNova.disable();
      }
    );
  }

  displayFn(concessionaria?: any): string {
    return concessionaria ? concessionaria.razaoSocial : '';
  }

  openModalSuccess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: 'O canal de relacionamento foi alterado com sucesso.',
        primaryButtonAction: () => { this.getBack(); this.dialog.closeAll() },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: 'Não foi possível alterar o canal de relacionamento nesse momento. Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalUpdate = () => {
    const concessionaria =  (this.concessionariaNova.value as any).razaoSocial
    this.dialog.open(ModalGenericComponent, {
      width: '420px',
      data: {
        icon: 'warning',
        text: `Tem certeza que deseja alterar o canal de relacionamento para a concessionária abaixo?`,
        highlightText: concessionaria,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ALTERAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.updateConcessionaria()}
      },
    });
  }

  getBack() {
    this.currentFlowChanged.emit('contractedPlans');
  }
}

import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { NgIf, DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-accounting',
    templateUrl: './accounting.component.html',
    styleUrls: ['./accounting.component.scss'],
    standalone: true,
    imports: [
        HeaderComponent,
        LinkBackComponent,
        NgIf,
        MatCard,
        MatCardContent,
        MatFormField,
        MatLabel,
        MatDatepickerInput,
        FormsModule,
        MatInput,
        NgxMaskDirective,
        ReactiveFormsModule,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        MatError,
        ButtonComponent,
        LoadingSpinnerComponent,
        DatePipe,
    ],
})
export class AccountingComponent {
  public dataMovimentacao = new FormControl('', [Validators.required,
    dateFormat.accountingValidator,
  ]);
  public currentDate = new Date();
  public isLoading = false;
  public maxDate: Date;
  public dataLabel: Date;
  public timeoutError = false;
  public success: boolean = false;
  public generationError = false;
  public accountingFile = '';
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public emptyDocument = false;

  constructor(private apiService: ApiHttpService, public dialog: MatDialog, private router: Router) {
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.dataLabel = this.currentDate;
    this.maxDate = this.currentDate;
  }

  openDialog() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Ao gerar um novo arquivo, o atual será apagado. Tem certeza que deseja continuar?',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.success = false },
        secundaryButtonText: 'SIM, GERAR NOVO ARQUIVO',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  getBack() {
    this.router.navigate(['area']);
  }

  onInputChange() {
   this.generationError = false;
   this.emptyDocument = false;
  }

  sendRequest = () => {
    this.isLoading = true;
    const valorMovimentacao = this.dataMovimentacao.value;

    if (valorMovimentacao !== null) {
      const [dia, mes, ano] = valorMovimentacao.split('/');
      const data = new Date(Number(ano), Number(mes) - 1, Number(dia));
      const dataFormatada = `${data.getFullYear()}-${(
        '0' +
        (data.getMonth() + 1)
      ).slice(-2)}-${('0' + data.getDate()).slice(-2)}`;
      this.apiService
        .postAccounting(dataFormatada)
        .pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              this.timeoutError = true;
              return throwError(
                () => 'A requisição demorou muito tempo e foi cancelada.'
              );
            }
            return throwError(() => error);
          })
        )
        .subscribe({
          next: (result) => {
            if(result.status === 204) {
              this.isLoading = false;
              this.generationError = false;
              this.emptyDocument = true;
            }
            else if(result.status === 201) {
              this.isLoading = false;
              this.generationError = false;
              this.success = true;
              this.accountingFile = result.body.arquivoContabil;
            }
            else {
              this.isLoading = false;
              this.success = false;
              this.emptyDocument = false;
              this.generationError = true;
            }
          },
          error: (error) => {
            this.isLoading = false;
            this.emptyDocument = false;
            this.generationError = true;
            if (this.timeoutError === false) {
              this.success = false;
            }
            console.log(error);
          },
        });
    } else {
      this.emptyDocument = false;
      this.generationError = true;
    }
  }

  downloadFile() {
    const date = this.dataMovimentacao.value?.toString().replace(/\//g, '').replace(/_/g, '');
    const fileName = `liberacred_${date}`;
    const linkSource = `data:text/plain;base64,${this.accountingFile}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    }
}

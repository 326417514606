<yfs-header></yfs-header>
<section class="container-content">
  <div *ngIf="currentFlow === 'contractedPlans'">
    <div class="button-back">
      <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
      <h1>PLANOS CONTRATADOS</h1>
    </div>
    <div>
      <h2>Contratos neste mês</h2>
      <div class="card-content">
        <mat-card class="mini-card">
          <span class="h1">Iniciados</span>
          <span class="h2">{{contratosIniciados || '-'}}</span>
        </mat-card>
        <mat-card class="mini-card">
          <span class="h1">Finalizados</span>
          <span class="h2">{{contratosFinalizados || '-'}}</span>
        </mat-card>
      </div>
    </div>
    <div style="margin-top: 32px;">
      <mat-card style="box-shadow: 0px 1px 4px #00000052;">
        <mat-card-content>
          <div class="inputs-content">
            <mat-form-field id="search" appearance="fill">
              <mat-label>Busque por nome, CPF ou contrato</mat-label>
              <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="search-icon material-symbols-outlined"
                fontIcon="search" (click)="setPageStart()"></mat-icon>
              <input [formControl]="searchString" #searchQuery (keydown.tab)="setPageStart()" (keydown.enter)="setPageStart()"
                matInput>

            </mat-form-field>
            <mat-form-field id="date" appearance="fill">
              <mat-label>Data contratação</mat-label>
              <input type="hidden" [matDatepicker]="picker" [(ngModel)]="dataConsultaLabel">
              <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataConsulta" (ngModelChange)="setPageStart()"
                [ngModel]="dataConsultaLabel | date:'dd/MM/yyyy'">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="dataConsulta.hasError('invalidDate')">A data é inválida</mat-error>
            </mat-form-field>
            <mat-form-field id="status" appearance="fill">
              <mat-label>Status</mat-label>
              <mat-select [formControl]="status" [(ngModel)]="statusControl" (selectionChange)="setPageStart()">
                <mat-option *ngFor="let statusOption of statusOptions"
                  [value]="statusOption.value">{{statusOption.label}}</mat-option>
              </mat-select>
              <button *ngIf="statusControl >= '0'" matSuffix mat-icon-button type="button" aria-label="Clear"
                (click)="status.setValue(null); $event.stopPropagation(); setPageStart()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field id="concessionaria" appearance="fill" floatLabel="always">
              <mat-label >Concessionária</mat-label>
              <mat-select #mySel [formControl]="concessionaria" multiple placeholder="Todas" (selectionChange)="mySelOnchange($event)">
                <mat-option *ngFor="let concessionaria of listaConcessionarias"
                  [value]="concessionaria.IdConcessionaria"><span
                    class="select-label">{{ concessionaria.RazaoSocial }}</span></mat-option>
              </mat-select>
              <button *ngIf="concessionaria.value.length > 0" matSuffix mat-icon-button type="button" aria-label="Clear"
                (click)="concessionaria.setValue([]);  select?.close(); search(); ">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="concessionaria.invalid">Permitido apenas 10 itens selecionados</mat-error>
            </mat-form-field>
          </div>
          <div class="inputs-content">
            <mat-checkbox [(ngModel)]="checked" color="primary" (click)="setPageStart()"> <span>Somente contratos com
                assinaturas pendente</span></mat-checkbox>
          </div>
          <div class="scrollable-table-container" style="margin-top: 10px;">
            <table *ngIf="hasValue" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort" matSort
              (matSortChange)="announceSortChange($event)">

              <ng-container matColumnDef="numeroContrato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                  Contrato
                </th>
                <td mat-cell *matCellDef="let element"> {{element.numeroContrato}} </td>
              </ng-container>

              <ng-container matColumnDef="nomeCliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nomeCliente">
                  Cliente
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="td-icon">
                    <mat-icon (click)="openEditScreen(element)" aria-hidden="false" aria-label="Ícone do cliente"
                      class="icon-client" fontIcon="manage_accounts"></mat-icon>
                    <span>{{element.nomeCliente.toUpperCase()}}</span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="cpf">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by cpf">
                  CPF
                </th>
                <td mat-cell *matCellDef="let element"> {{element.cpf | cpf }} </td>
              </ng-container>

              <ng-container matColumnDef="dataContratacao">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by dataContratacao">
                  Data de contratação
                </th>
                <td mat-cell *matCellDef="let element"> {{element.dataContratacao}} </td>
              </ng-container>

              <ng-container matColumnDef="statusContrato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                  sortActionDescription="Sort by statusContratoNumero">
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="td-icon">
                    <mat-icon aria-hidden="false" aria-label="Ícone de status"
                      [style.color]="getIconColor(element.statusContratoNumero)"
                      class="material-symbols-{{ getIcon(element.statusContratoNumero).style }}"
                      [fontIcon]="getIcon(element.statusContratoNumero).icon">
                    </mat-icon>
                    <span>{{getStatusDescription(element.statusContratoNumero)}}</span>
                    <div class="align-right tooltip-desktop" (click)="$event.stopPropagation()">
                      <yfs-tooltip [tooltipText]="getTooltipText(element)" [wrap]="true">
                        <ng-container *ngIf="shouldShowIcon(element)">
                          <mat-icon class="tooltip-icon" aria-hidden="false" aria-label="Ícone de informação"
                            class="material-symbols" fontIcon="info">
                          </mat-icon>
                        </ng-container>
                      </yfs-tooltip>
                    </div>
                    <div class="tooltip-mobile align-right" (click)="$event.stopPropagation()">
                      <ng-container *ngIf="shouldShowIcon(element)">
                        <mat-icon class="tooltip-icon" aria-hidden="false" aria-label="Ícone de informação"
                          class="material-symbols" fontIcon="info"
                          matTooltip="{{ getTooltipTextMobile(element) }}">
                        </mat-icon>
                      </ng-container>
                    </div>
                  </div>
                </td>
                <div>
               </div>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row (click)="selectClientPlan(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="!hasValue" class="content-empty">
              <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
              <div>
                <span>Nenhum resultado encontrado.</span>
                <span>Tente novamente com outros filtros.</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="paginator-content">
        <mat-paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" aria-label="Select page"
          [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
  <yfs-client-plan *ngIf="currentFlow === 'clientPlan'" (currentFlowChanged)="changeCurrentFlow($event)"
    [contractedPlan]="contractedPlanSelected"></yfs-client-plan>
  <yfs-client-data *ngIf="currentFlow === 'clientData'" (currentFlowChanged)="changeCurrentFlow($event)"
    [cpfSelected]="cpfSelected" origin="contractedPlans"></yfs-client-data>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<div class="dialog-content" mat-dialog-content>
  <div class="container-title">
    <h3>Credenciar empresa</h3>
  </div>

  <div class="dialog-text">
      <span>Para cadastrar uma nova concessionária ou parceiro, informe o CNPJ abaixo:</span>
  </div>

  <div class="dialog-input-content">
      <mat-form-field style="width: 592px;" id="cnpj" appearance="fill">
        <mat-label>Digite o CNPJ</mat-label>
        <input matInput [formControl]="cnpj" mask="00.000.000/0000-00" (input)="formatarCNPJ()">
        <mat-error *ngIf="cnpj.hasError('cnpjInvalid') && !cnpj.hasError('required')">CNPJ inválido</mat-error>
      </mat-form-field>
  </div>

  <div *ngIf="message.length" class="info-content">
    <mat-icon aria-hidden="false" class="info-icon" aria-label="Ícone de informação" class="material-symbols" fontIcon="info"></mat-icon>
    <span class="info">
      {{message}}
    </span>
  </div>

  <div class="dialog-buttons">
      <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="closeModal()"></yfs-button>
      <yfs-button
      [state]="cnpj.hasError('cnpjInvalid') || cnpj.hasError('required') || isError || (message.length && !isSpecificMessage) ? 'disabled' : 'enabled'"
      label="CONTINUAR"
      type="primary"
      [press]="openDialogConfirmAccreditation">
  </yfs-button>
  </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<div *ngIf="paymentForms && paymentForms.length > 0">
  <mat-card>
    <mat-card-content>
  <div class="container-content">
    <div class="container-product-content">
    <div class="container-product">
      <div class="image-box">
        <img alt="imagem do produto" [src]="urlImage" />
      </div>
      <span data-test="model" class="model">{{productObj?.modelo}}</span>
      <span data-test="reference-value" class="product">{{productObj?.valorReferencia | currency : 'R$' }}</span>
    </div>
   </div>

    <div class="card-width">
      <div class="card">
        <mat-card-header class="card-header-centered">
          <mat-card-title class="card-title">Selecione a porcentagem do plano</mat-card-title>
        </mat-card-header>
        <div>
          <div class="card-percentage-content">
            <span data-test="minimal-percentage">{{productObj?.porcentagemMinima}}%</span>
            <mat-slider style="width: 100%;" [min]="productObj?.porcentagemMinima" [max]="productObj?.porcentagemMaxima"
              [step]="productObj?.porcentagemIncremento" showTickMarks discrete [displayWith]="formatLabel">
              <input (input)="hangleSliderChange($event)" matSliderThumb>
            </mat-slider>
            <span data-test="max-percentage">{{productObj?.porcentagemMaxima}}%</span>
          </div>

          <div class="card-payment-content">
            <mat-form-field appearance="fill">
              <mat-label>Método de pagamento da primeira recarga</mat-label>
              <mat-select [(value)]="selected">
                <mat-option *ngFor="let pagamento of paymentForms" [value]="pagamento.idFormaPagamento">
                  {{pagamento.descricao}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="card-values-content">
            <div class="value-description">
              <span class="installments">Valor mensal das recargas</span>
              <span class="installments-number ">{{productObj?.numeroParcelas}}X de <span
                data-test="monthly-value" class="installments-number-value">{{valorParcela | currency : 'R$'}}</span></span>
            </div>
            <div class="total-description ">
              <span class="total-label">Valor acumulado Liberacred</span>
              <span data-test="accumulated-value" class="total-value">{{valorTotal | currency : 'R$' }}</span>
            </div>
          </div>
        </div>
        <div class="container-buttons">
          <yfs-button class="button" state="enabled" label="CANCELAR" type="tertiary" [fullWidth]="isFullWidth" (click)="goHome()"></yfs-button>
          <div class="button" *ngIf="!isClienteUserOrNoPermission()"><yfs-button state="enabled" label="CONTRATAR" type="primary" [fullWidth]="isFullWidth" (click)="goToRegister()"></yfs-button></div>
        </div>
      </div>

    </div>
  </div>
</mat-card-content>
</mat-card>
</div>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

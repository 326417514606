import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CnpjValidator } from 'src/app/shared/validators/cnpj-validator';
import { ModalConfirmAccreditationComponent } from '../modal-confirm-accreditation/modal-confirm-accreditation.component';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../buttons/button/button.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
@Component({
    selector: 'yfs-modal-accreditation',
    templateUrl: './modal-accreditation.component.html',
    styleUrls: ['./modal-accreditation.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, NgxMaskDirective, FormsModule, ReactiveFormsModule, NgIf, MatError, MatIcon, ButtonComponent, LoadingSpinnerComponent]
})
export class ModalAccreditationComponent {
  public cnpj = new FormControl('',  [Validators.required, CnpjValidator.cnpj]);
  public cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public idConcessionariaAccredit = 0;
  public idTipoValue = 0;
  public isLoading = false;
  public message = ''
  public information = ''
  public disabledType = false
  public isSpecificMessage: boolean = false;
  public isError: boolean = false

  constructor(
    public dialogRef: MatDialogRef<ModalAccreditationComponent>,
    public dialog: MatDialog,
    private apiService: ApiHttpService,
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  next = () => {
    const cnpj = this.cnpj?.value?.replace(/[.\-\/]/g, '');
    this.isLoading = true;
    this.apiService.getDataConfirmation(cnpj).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          if(result){
            this.isLoading = false;
            this.information = result.body
            this.disabledType = false
            this.isError = false;
            this.message = result.body.avisoDescrendeciada || ''
            if(result.body.avisoDescrendeciada !== null ){
              this.isSpecificMessage = true
            } else {
              this.isSpecificMessage = false
            }
          }
        },
        error: (error) => {
          this.isError = error.error;
          const backendError = error.error;

          if (backendError && backendError.Errors && Array.isArray(backendError.Errors) && backendError.Errors.length > 0) {
              this.message = this.capitalizeFirstLetter(backendError.Errors[0]);
          }
          this.isLoading = false;
      }
      });
  }

  capitalizeFirstLetter(value: any) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }


  openDialogConfirmAccreditation = () => {
    const disabledType = this.disabledType;
    this.dialog.open(ModalConfirmAccreditationComponent, {
      width: '824px',
      data: {
        infos: this.information, disabledType,
        accreditEvent: (idTipo: number) => {this.idTipoValue = idTipo}
      }
    })
  }

  formatarCNPJ() {
    let valor = this.cnpj.value;
    if (valor) {
        valor = valor.replace(/\D/g, '');
        if (valor.length === 14) {
            this.next();
        }
        if (valor.length > 14) {
            valor = valor.slice(0, 14);
        }
        if (valor.length < 14) {
         this.message = ''
        }
        valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        this.cnpj.setValue(valor);
    }
}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'celular',
    standalone: true
})
export class CelularPipe implements PipeTransform {
    transform(value: string|number|null|undefined): string {
        if (!value)  return '-'
        const celular = value.toString()
        const ddd = celular.substring(0, 2)
        const primeiraParte = celular.substring(2, 7)
        const segundaParte = celular.substring(7, 11)
        return `${ddd} ${primeiraParte}-${segundaParte}`
    }
}

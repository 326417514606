<span class="title-category">Escolha a categoria:</span>
<div class="container-content">
    <div class="card-category" *ngFor="let category of categoryList">
        <img [src]="convertBase64 + category.imagemBase64" class="card-image" alt="imagem com a categoria" />
        <span [attr.data-test]="'category-' + category.nomeExibicao" class="card-name">{{category.nomeExibicao}}</span>
        <div data-test="card-category" class="overlap-card-category" (click)="changeCurrentFlow(category.produtos)">
            <span class="select-text">Selecionar</span>
        </div>
    </div>
</div>
<yfs-alert-box *ngIf="listIsEmpty || timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { DatePipe, NgIf, NgClass } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { dateFormat } from 'src/app/shared/date-format';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-commissions',
    templateUrl: './commissions.component.html',
    styleUrls: ['./commissions.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, NgIf, MatCard, MatCardContent, MatFormField, MatLabel, MatDatepickerInput, FormsModule, MatInput, NgxMaskDirective, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, NgClass, MatRadioGroup, MatRadioButton, ButtonComponent, LoadingSpinnerComponent, DatePipe]
})
export class CommissionsComponent {
  public currentDate = new Date();
  public isLoading = false;
  public timeoutError = false;
  public success: boolean = false;
  public generationError = false;
  public dataLabelInicio: Date;
  public dataLabelFinal: Date;
  public dateError = false;
  public file: any;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public dataInicioRelatorio = new FormControl('', [Validators.required, dateFormat.commissionsValidate]);
  public dataFimRelatorio = new FormControl('', [Validators.required, dateFormat.commissionsValidate]);
  public reportType = 'Analitico';
  public reportFormat = 'xlsx';
  public emptyDocument = false;

  constructor(private apiService: ApiHttpService, private userService: UserService, public dialog: MatDialog, private datePipe: DatePipe, private router: Router) {

    const mesAnterior = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
    this.dataInicioRelatorio.setValue(this.datePipe.transform(mesAnterior, 'dd/MM/yyyy'));
    this.dataLabelInicio = mesAnterior;

    const lastDayOfLastMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 0);
    this.dataFimRelatorio.setValue(this.datePipe.transform(lastDayOfLastMonth, 'dd/MM/yyyy'));
    this.dataLabelFinal = lastDayOfLastMonth;

  }

  openDialog() {
    this.dialog.open(ModalGenericComponent, {
      width: '393px',
      data: {
        text: 'Ao gerar um novo arquivo, o atual será apagado. Tem certeza que deseja continuar?',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.success = false },
        secundaryButtonText: 'SIM, GERAR NOVO ARQUIVO',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  onInputChange() {
    this.generationError = false;
    this.emptyDocument = false;
    const dataInicio = this.dataInicioRelatorio.value ? new Date(this.initialFormatDate(this.dataInicioRelatorio.value)) : null;
    const dataFim = this.dataFimRelatorio.value ? new Date(this.initialFormatDate(this.dataFimRelatorio.value)) : null;
    if (dataInicio && dataFim && dataInicio > dataFim) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  initialFormatDate(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  sendRequest = (): void => {
    this.isLoading = true;

    const dataInicio = this.dataInicioRelatorio.value;
    const dataFinal = this.dataFimRelatorio.value;
    const tipoRelatorio = this.reportType;
    const formatoArquivo = this.reportFormat;

    if (dataInicio && dataFinal && dataInicio.trim() !== '' && dataFinal.trim() !== '') {
      const [dia, mes, ano] = dataInicio.split('/');
      const [diaFinal, mesFinal, anoFinal] = dataFinal.split('/');
      const dataInicioObj = new Date(Number(ano), Number(mes) - 1, Number(dia));
      const dataFinalObj = new Date(Number(anoFinal), Number(mesFinal) - 1, Number(diaFinal));

      const dataInicioFormatada = this.formatDate(dataInicioObj);
      const dataFinalFormatada = this.formatDate(dataFinalObj);

      this.apiService.getCommissions(dataInicioFormatada, dataFinalFormatada, tipoRelatorio, formatoArquivo)
        .pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              this.timeoutError = true;
              this.generationError = true;
              this.emptyDocument = false;
              return throwError('A requisição demorou muito tempo e foi cancelada.');
            }
            return throwError(error);
          })
        )
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            if (response.status === 204) {
              this.isLoading = false;
              this.emptyDocument = true;
            } else {
              this.isLoading = false;
              if (response.body) {
                const isPDF = this.reportFormat === 'pdf';
                const monthName = this.getMonthName(dataFinalObj);
                const fileExtension = isPDF ? 'pdf' : 'xlsx';
                const contentType = isPDF ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const fileName = `COMISSAO REDE - ${monthName}.${fileExtension}`;

                const file = new Blob([response.body], { type: contentType });
                const fileURL = URL.createObjectURL(file);
                this.file = { name: fileName, url: fileURL };
                this.success = true;
              } else {
                this.success = false;
                this.isLoading = false;
                this.generationError = true;
                this.emptyDocument = false;
              }
            }
        },
          error: (error) => {
            this.isLoading = false;

            if (!this.timeoutError) {
              this.success = false;
            }

            console.log(error);
            this.generationError = true;
            this.emptyDocument = false;
          },
        });
    } else {
      this.isLoading = false;
      this.generationError = true;
      this.emptyDocument = false;
    }
  }

  getBack() {
    this.router.navigate(['area']);
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  private getMonthName(date: Date): string {
    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];
    const monthIndex = date.getMonth();
    return monthNames[monthIndex];
  }

  downloadFile = () : void => {
    const fileName = this.file.name;
    const fileURL = this.file.url;
    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  back() {
    this.success = false
  }

  getFormattedLabel(): string {
    if (this.reportFormat === 'xlsx') {
      return 'BAIXAR RELATÓRIO (.XLS)';
    }
     return `BAIXAR RELATÓRIO (.${this.reportFormat.toUpperCase()})`;
    }
}

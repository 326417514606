<mat-card>
  <mat-card-content>
<span class="title-document">Documento de identificação</span>
<div class="container-content">
    <div *ngIf="!timeoutError && !genericError" class="content">
        <mat-form-field class="inputs" appearance="fill">
            <mat-label>CPF</mat-label>
            <input data-test="input-cpf" matInput mask="000.000.000-00" placeholder="" (input)="validateCpf($event)"
                [formControl]="cpf" required>
            <mat-error *ngIf="cpf.hasError('required')">O campo é obrigatorio</mat-error>
            <mat-error *ngIf="cpf.value && cpf.hasError('cpfInvalid')">CPF não existe</mat-error>
            <mat-error *ngIf="cpf.hasError('invalidCpf')">CPF não existe</mat-error>
            <mat-error *ngIf="cpf.hasError('cpfUsed')">O cliente já possui cadastro</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="hideRg" class="inputs" appearance="fill">
            <mat-label>RG</mat-label>
            <input data-test="input-rg" matInput mask="99.999.999-0" placeholder="" (focusout)="validateRg($event)" [formControl]="rg" >
            <mat-error *ngIf="rg.hasError('invalidRg')">O RG não existe</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="hideRg" class="inputs" appearance="fill">
            <mat-label>UF do documento</mat-label>
            <mat-select data-test="select-uf" [formControl]="uf" [required]="rgValid">
                <mat-option *ngFor="let enum of listUfs | keyvalue" [value]="enum.key">{{enum.key}}</mat-option>
            </mat-select>
            <mat-error *ngIf="uf.invalid">
                O campo é obrigatorio
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="hideRg" class="inputs" appearance="fill">
            <mat-label>Órgão expedidor</mat-label>
            <input data-test="input-org-expedidor" matInput placeholder="" [formControl]="orgaoExpedidor" [required]="rgValid">
            <mat-error *ngIf="orgaoExpedidor.invalid">O campo é obrigatorio</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="hideRg" class="inputs" appearance="fill">
            <mat-label>Data de expedição</mat-label>
            <input type="hidden" [matDatepicker]="picker" [(ngModel)]="dataExpedicaoLabel" [disabled]="!cpfValido">
            <input data-test="input-date-rg" matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataExpedicao"
                [ngModel]="dataExpedicaoLabel | date:'dd/MM/yyyy'" [required]="rgValid">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="dataExpedicao.hasError('required')">O campo é obrigatorio</mat-error>
            <mat-error *ngIf="dataExpedicao.hasError('invalidDate')">A data é inválida</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="!timeoutError && !genericError" class="container-buttons">
        <div class="divisor-content">
            <yfs-button state="enabled" label="CANCELAR" type="tertiary" (click)="openDialog()"></yfs-button>
            <span class="remaining-number">{{ getRemainingNumber() }}</span>
        </div>
        <div class="divisor-content-next">
          <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="goBack()"></yfs-button>
          <yfs-button [state]="cpfValido ? 'enabled' : 'disabled'" label="PROSSEGUIR" type="primary" (click)="nextStep()"></yfs-button>
        </div>
    </div>
    <div *ngIf="!timeoutError && !genericError" class="mobile-buttons">
          <span class="remaining-number">{{ getRemainingNumber() }}</span>
          <yfs-button class="button" state="enabled" label="PROSSEGUIR" type="primary" [fullWidth]="isFullWidth" (click)="nextStep()"></yfs-button>
          <yfs-button class="button" state="enabled" label="VOLTAR" type="secondary" [fullWidth]="isFullWidth" (click)="goBack()"></yfs-button>
          <yfs-button class="button" state="enabled" label="CANCELAR" type="tertiary" [fullWidth]="isFullWidth" (click)="openDialog()"></yfs-button>
    </div>
</div>
</mat-card-content>
</mat-card>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
    <yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { HeaderComponent } from '../../../components/header/header.component';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgFor } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { AlertBoxComponent } from '../../../components/alert-box/alert-box.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-create-internal-user',
    templateUrl: './create-internal-user.component.html',
    styleUrls: ['./create-internal-user.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, MatSelect, NgFor, MatOption, ButtonComponent, AlertBoxComponent, LoadingSpinnerComponent]
})

export class CreateInternalUserComponent {
  public fullName = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(100)]);
  public email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]);
  public emailConfirm = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]);
  public registration = new FormControl('', [Validators.required, Validators.maxLength(100)]);

  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public userProfile = new FormControl('', [Validators.required]);
  public userProfileError = false;

  public userProfiles: any;

  constructor(private router: Router, public dialog: MatDialog, private apiService: ApiHttpService) {
    this.getPerfilAcesso();
  }

  getBackHandle() {
    this.router.navigate(['/user-management']);
  }

  checkEmailsMatch() {
    if (this.email.value !== this.emailConfirm.value) {
      this.emailConfirm.setErrors({ emailMismatch: true });
    } else {
      this.emailConfirm.setErrors(null);
    }
  }

  openDialogCreateUser = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Criar novo usuário interno?',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.sendRequest() },
        secundaryButtonText: 'SIM, CRIAR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogCreateUserSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Usuário criado com sucesso.',
        icon: 'success',
        primaryButtonText: 'IR PARA A LISTA DE USUÁRIOS',
        primaryButtonVariant: 'primary',
        primaryButtonAction: () => {
          dialogRef.close();
          this.goToUserList() },
      },
    });
  }

  openDialogCreateUserError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Houve um problema ao tentar criar o usuário.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.sendRequest() },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogAlreadyExists() {
    this.dialog.open(ModalGenericComponent, {
      width: '410px',
      data: {
        text: 'Não é possível cadastrar esse usuário pois ele já existe.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.goToUserList() },
        secundaryButtonText: 'IR PARA A LISTA DE USUÁRIOS',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogCancel() {
    this.dialog.open(ModalGenericComponent, {
      width: '424px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.goToUserList() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogAdError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não é possível cadastrar este usuário pois ele precisa estar previamente cadastrado no AD.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  verifyData() {
    this.fullName.markAsTouched();
    this.email.markAsTouched();
    this.emailConfirm.markAsTouched();
    this.registration.markAsTouched();
    this.userProfile.markAsTouched();
  }

  sendRequest() {
    this.verifyData();
    const usuario = {
      nome: this.fullName.value,
      email: this.email.value,
      idPerfilAcesso: this.userProfile.value,
      interno: true,
      login: this.registration.value,
    };
    this.isLoading = true;
    this.apiService
      .createInternalUser(usuario)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if(error.status === 400 || error.status === 404 || error.status === 500){
            this.openDialogCreateUserError();
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if(result.success === true && result.tipoErroCadastroUsuario === 0){
            this.openDialogCreateUserSuccess();
          }
          if(result.success === false && result.tipoErroCadastroUsuario === 3){
            this.openDialogAlreadyExists();
          }
          if(result.success === false && result.tipoErroCadastroUsuario === 1){
            this.openDialogCreateUserError();
            this.isLoading = false;
          }
          if(result.success === false && result.tipoErroCadastroUsuario === 2){
            this.openDialogAdError();
            this.isLoading = false;
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  sanitizeInput(control: FormControl) {
    let inputValue = control.value;
    inputValue = inputValue.replace(/[^\w\d]/g, '');
    control.setValue(inputValue, { emitEvent: false });
  }

  goToUserList() {
    this.router.navigate(['/user-management']);
  }

  getPerfilAcesso() {
    this.isLoading = true;
    this.apiService.getPerfilAcesso().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result && result.length > 0) {
            this.userProfiles = result.filter((el: { interno: any; }) => el.interno);
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

}

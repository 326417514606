import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cep',
    standalone: true
})
export class CepPipe implements PipeTransform {
    transform(value: number|string|undefined): string {
        if (!value)  return '-'
        let valueParse = value.toString()
        return `${valueParse.slice(0,5)}-${valueParse.slice(5)}`;
    }
}
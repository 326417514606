import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IClient } from 'src/app/models/register.interface';
import { dateFormat } from 'src/app/shared/date-format';
import { ModalValidateCellphoneComponent } from 'src/app/components/modal-validate-cellphone/modal-validate-cellphone.component';
import { CustomValidators } from 'src/app/shared/validators/phone-validator';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, DatePipe } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-simulation-contact',
    templateUrl: './simulation-contact.component.html',
    styleUrls: ['./simulation-contact.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardContent, MatIcon, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, NgxMaskDirective, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, ButtonComponent, LoadingSpinnerComponent, DatePipe]
})
export class SimulationContactComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter();
  public nomeCompleto = new FormControl('', [Validators.required, LocalValidators.nomeValidator, Validators.maxLength(35)]);
  public cpf = new FormControl('', [Validators.required, CpfValidator.cpf]);
  public email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
  public dataNascimento = new FormControl('', [Validators.required, dateFormat.yearsValidator]);
  public shiftPressed = false;
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public celularComDdd = new FormControl('', [Validators.required, CustomValidators.validPhoneNumber()]);
  public celularMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public isLoading = false;
  public cpfValido = false;
  public dataLabel = "";
  public maxDate = '';
  public isFullWidth = window.innerWidth < 768;

  constructor(private apiService: ApiHttpService, public dialog: MatDialog, private router: Router) {
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth() + 8, currentDate.getDate());
    this.maxDate = maxDate.toISOString().substring(0, 10);
  }

  ngOnInit() {
    if (localStorage.getItem("contatoSimulacao")) {
      let contatoSimulacao: {};
      contatoSimulacao = JSON.parse(atob(localStorage.getItem("contatoSimulacao") || '{}'))
      this.bidingValues(contatoSimulacao)
    }
  }

  bidingValues(values: any) {
    this.cpf.setValue(values?.cpf);
    this.email.setValue(values?.email);
    this.celularComDdd.setValue(this.formatPhoneNumber(values?.celular));
    this.nomeCompleto.setValue(values?.nomeCompleto);
    const dataNascimentoFormated = values?.dataNascimento ? this.convertDateToString(values?.dataNascimento) : ''
    this.dataLabel = values?.dataNascimento ? this.convertDataLabel(dataNascimentoFormated) : '';
    this.dataNascimento.setValue(dataNascimentoFormated);
  }

  formatPhoneNumber(phoneNumber: string): string {
    const cleaned = phoneNumber.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
    if (match) {
      return `(${match[1]})${match[2]}-${match[3]}-${match[4]}`;
    }

    return phoneNumber;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 768;
  }

  sanitizeInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '');
    this.nomeCompleto.setValue(input.value);
  }

  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const sanitizedText = pastedText.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '');
    this.nomeCompleto.setValue(sanitizedText);
  }

  cleanStorageDatas() {
    localStorage.removeItem("documentos")
    localStorage.removeItem("dadosPessoais")
    localStorage.removeItem("endereco")
    localStorage.removeItem("contato")
  }

  setStorageDatas(client: IClient) {
    localStorage.setItem("documentos", btoa(JSON.stringify(client.documentos)))
    localStorage.setItem("dadosPessoais", btoa(JSON.stringify(client.dadosPessoais)))
    localStorage.setItem("endereco", btoa(JSON.stringify(client.endereco)))
    localStorage.setItem("contato", btoa(JSON.stringify(client.contato)))
  }

  bidingValuesDocuments(documents: any) {
    this.cpf.setValue(documents.cpf.toString())
  }

  validateCpf(event: any) {
    const value = event.target.value
    if (value && value.length > 0) {
      const normalize = value.replace(/[^0-9]/g, '');
      if (normalize.length == 11) {
        this.isLoading = true;
        this.apiService.validateCpf(normalize).pipe(
          timeout(30000),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              this.isLoading = false;
              return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
            }
            return throwError(() => error)
          })
        )
          .subscribe({
            next: result => {
              if (result) {
                if (result.clienteCadastrado) {
                  this.cleanStorageDatas()
                  this.setStorageDatas(result.cliente)
                  this.bidingValuesDocuments(result.cliente.documentos)
                  this.cpfValido = true;
                } else {
                  this.cleanStorageDatas()
                  this.cpfValido = true;
                }
              }
              this.isLoading = false;
            },
            error: error => {
              this.isLoading = false;
              if (error.status
                && error.status == 400
                && error?.error?.errors?.Cpf) {
                if (error?.error?.errors?.Cpf[0] === "CPF inválido") {
                  this.cpf.setErrors({ 'invalidCpf': true })
                }
                if (error?.error?.errors?.Cpf[0] === "Identificamos que o CPF já possui um contrato em aberto no LiberaCred.") {
                  this.openDialogCpf()
                  this.cpf.setErrors({ 'cpfUsed': true })
                }
              } else {
                this.cleanStorageDatas()
                this.cpfValido = true;
              }
              console.log(error)
            }
          })
      }
    }
  }

  goToPlans() {
    this.router.navigate(["planos-contratados"])
  }

  goToHome() {
    this.router.navigate(["home"])
  }

  openDialogCpf() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '326px',
      data: {
        text: 'Identificamos que o CPF já possui um contrato em aberto no Liberacred. Acesse o perfil do cliente.',
        primaryButtonAction: () => {
          this.goToPlans();
          dialogRef.close();
        },
        primaryButtonText: 'ACESSAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openDialog(clientData: any, expirationDate: string) {
    this.dialog.open(ModalValidateCellphoneComponent, {
      width: '467px',
      maxWidth: '95vw',
      data: {
        continueHandle: () => { this.continueHandle() },
        clientData,
        expirationDate
      }
    })
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  convertDateToString(dataNascimento: any) {
    const dataOriginal = new Date(dataNascimento);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj.toString()
  }

  continueHandle() {
    const dataNascimentoValue = this.dataNascimento.value;
    const dataNascimentoFormated = dataNascimentoValue ? this.convertStringToDate(dataNascimentoValue) : undefined
    const obj = {
      celular: this.celularComDdd.value?.replace(/[^0-9]/g, ''),
      cpf: this.cpf.value?.replace(/[^0-9]/g, ''),
      nomeCompleto: this.nomeCompleto.value,
      dataNascimento: dataNascimentoFormated,
      email: this.email.value
    }
    localStorage.setItem("contatoSimulacao", btoa(JSON.stringify(obj)))
    this.changeCurrentFlow();
  }

  changeCurrentFlow() {
    this.currentFlowChanged.emit()
  }

  openDialogConfirm = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '404px',
      data: {
        text: 'Confirme o número do celular que iremos enviar o código de validação:',
        highlightText: this.celularComDdd.value,
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        primaryButtonAction: 'close',
        secundaryButtonText: 'CONFIRMAR NÚMERO',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.sendSms() }
      }
    })
  }

  isButtonEnabled() {
    return this.nomeCompleto.valid && this.cpf.valid && this.email.valid && this.dataNascimento.valid && this.celularComDdd.valid ? 'enabled' : 'disabled';
  }

  sendSms = () => {
    this.isLoading = true;
    const dataNascimentoValue = this.dataNascimento.value;
    const dataNascimentoFormated = dataNascimentoValue ? this.convertStringToDate(dataNascimentoValue) : undefined
    const obj = {
      cpf: this.cpf.value?.replace(/[^0-9]/g, ''),
      nomeCompleto: this.nomeCompleto.value,
      dataNascimento: dataNascimentoFormated,
      celularComDdd: this.celularComDdd.value?.replace(/[^0-9]/g, ''),
      email: this.email.value
    }
    this.apiService.validateSms(obj).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialog(obj, result?.expirationDate)
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

}

import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselConfig, CarouselModule } from 'ngx-bootstrap/carousel';
import { environment } from '../../../environments/environment';
import { HeaderComponent } from '../../components/header/header.component';
import { ButtonComponent } from '../../components/buttons/button/button.component';


@Component({
    selector: 'yfs-home',
    templateUrl: './home.component.html',
    providers: [
        { provide: CarouselConfig, useValue: { interval: 5000, noPause: false, showIndicators: true } }
    ],
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [
        HeaderComponent,
        CarouselModule,
        ButtonComponent,
    ],
})

export class HomeComponent {

  public urlVideo: string = environment.urlVideo;
  public isFullWidth = window.innerWidth < 820;

  constructor(private router: Router) {}

  goToSimulation() {
    this.router.navigate(["simulation"]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 820;
  }


}

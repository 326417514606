import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-alert-box',
    templateUrl: './alert-box.component.html',
    styleUrls: ['./alert-box.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardContent, ButtonComponent]
})
export class AlertBoxComponent {

  public isFullWidth = window.innerWidth < 1279;

  constructor (private router: Router) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
  }

  goToHome() {
    this.router.navigate(['home'])
  }

}

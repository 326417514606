<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
    <div class="button-back">
        <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>RELATÓRIO DE SEGREGAÇÃO CURTO E LONGO PRAZO </h1>
    <h2 class="subtitle">
        Utilize os filtros abaixo para selecionar os critérios para a geração do
        relatório.
    </h2>
    <h2 class="subtitle second-subtitle">
        Por padrão, a data preenchida é a de hoje e somente datas até o dia atual podem ser selecionadas.
    </h2>
    <mat-card class="card" *ngIf="!success">
        <mat-card-content class="card-content">
            <div *ngIf="emptyDocument && !generationError">
                <img src="assets/svg/empty.svg" alt="documento vazio">
                <div class="message-empty-document">
                    <span>Nenhum resultado encontrado para os critérios selecionados. Por favor, tente novamente com
                        outras
                        opções.</span>
                </div>
            </div>

            <div class="report-form">
                <div>
                    <mat-form-field class="inputs" appearance="fill">
                        <mat-label>Data base</mat-label>
                        <input type="hidden" [matDatepicker]="pickerDataBase" [max]="maxDate"
                            [(ngModel)]="dataLabelBase" [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="onInputChange()" />
                        <input data-test="input-date-movimentation" matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDate"
                            [formControl]="dataBase" [ngModel]="dataLabelBase | date : 'dd/MM/yyyy'"
                            (ngModelChange)="onInputChange();" />
                        <mat-datepicker-toggle matIconSuffix [for]="pickerDataBase"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDataBase></mat-datepicker>
                        <mat-error *ngIf="dataBase.hasError('required')">
                            Este campo não pode ficar vazio
                        </mat-error>
                        <mat-error class="date-error" *ngIf="dataBase.hasError('invalidDate')">Data
                            inválida.</mat-error>
                        <mat-error class="date-error" *ngIf="dataBase.hasError('dataValida')">Data inválida. Selecione
                            uma data até o
                            dia atual.</mat-error>
                    </mat-form-field>
                    <div class="yfs-radio-group" appearance="fill">
                        <mat-label class="label">Período de Segregação:</mat-label>
                        <mat-radio-group [formControl]="periodAnual">
                            <mat-radio-button [value]="false">Trimestral </mat-radio-button>
                            <mat-radio-button [value]="true">Anual</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="yfs-radio-group" appearance="fill">
                        <mat-label class="label">Tipo do relatório:</mat-label>
                        <mat-radio-group [formControl]="reportTypeAnalitico">
                            <mat-radio-button [value]="true">Analítico </mat-radio-button>
                            <mat-radio-button [value]="false">Sintético </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
                <yfs-button label="GERAR RELATÓRIO" data-test="button-generate" class="btn-generate"
                [press]="sendRequest" type="primary"
                [state]="!dataBase.valid || !periodAnual.valid ||!reportTypeAnalitico.valid || isLoading ? 'disabled' : 'enabled'" >
              </yfs-button>

            <span *ngIf="generationError" data-test="error-message-file" class="error-message"><img
                    src="assets/svg/atention.svg" alt="icone de atenção" /> Não foi possível gerar o relatório nesse
                momento.</span>
            <span *ngIf="generationError" data-test="error-message-file" class="error-try-again"> Tente novamente
                mais
                tarde.</span>

        </mat-card-content>
    </mat-card>

    <mat-card class="card" *ngIf="success">
        <mat-card-content class="card-content">
            <img src="assets/svg/success.svg" />
            <div class="card-message">
                <div class="message">
                    <span>Arquivo gerado com sucesso!</span>
                </div>
                <div class="message">
                    <span>Você já pode fazer o download abaixo.</span>
                </div>
            </div>
            <yfs-button data-test="button-download" class="btn-download" (click)="downloadFile()" state="enabled"
                label="BAIXAR ARQUIVO" type="primary"></yfs-button>
            <div>
                <a (click)="openDialog()">GERAR OUTRO ARQUIVO</a>
            </div>
            <div class="atention">
                <span>(O arquivo atual será apagado)</span>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

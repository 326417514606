import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-confirm-deaccreditation',
    templateUrl: './modal-confirm-deaccreditation.component.html',
    styleUrls: ['./modal-confirm-deaccreditation.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, MatIcon, MatFormField, MatLabel, MatInput, FormsModule, ButtonComponent]
})
export class ModalConfirmDeaccreditationComponent {
  public confirmString = "";
  public showAlertBox = true;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmDeaccreditationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  handleDescredenciarIdPress = () => {
    this.dialogRef.close()
    this.data.decertifyEvent(this.data?.hasPlan);
  }

}

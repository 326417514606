import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ButtonComponent } from '../buttons/button/button.component';
import { ContentViewer } from './dynamic-content-viewer';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'yfs-modal-preview-draft',
  templateUrl: './modal-preview-draft.component.html',
  styleUrls: ['./modal-preview-draft.component.scss'],
  standalone: true,
  imports: [CdkScrollable, MatDialogContent, ButtonComponent, NgClass, ContentViewer, MatIcon, MatCard, MatCardContent, MatFormField, MatLabel, FormsModule, MatInputModule, NgIf, NgFor]
})
export class ModalPreviewDraftComponent {
  public safePreviewContent: string | null = null;
  public search = "";
  public filtedListWildCards: any;
  public listWildCards: any;
  public previewContent: any;
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewDraftComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
  ) {
    if (this.data && this.data.previewContent) {

      this.safePreviewContent = this.data.previewContent;
    }
    if (this.data && this.data.listWildCards) {
      this.listWildCards = this.data.listWildCards;
      this.filtedListWildCards = [...this.listWildCards];
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.scrollToTop();
    }, 150);
  }

  scrollToTop() {
    const element = document.getElementById('top');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  applyFilter() {
    const cleanedInput = this.search.toLowerCase().replace(/[%\[\]]/g, '');
    this.filtedListWildCards = this.listWildCards.filter((tagObj: { tag: string; }) => {
      const cleanedTag = tagObj.tag.toLowerCase().replace(/[%\[\]]/g, '');
      return cleanedTag.includes(cleanedInput);
    });
  }
}

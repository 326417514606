<section class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para planos do cliente" (click)="getBack()"></yfs-link-back>
  </div>
  <div class="container-title">
    <h1>EXTRATO DE PAGAMENTOS</h1>
  </div>
  <div style="display: flex; justify-content: space-between; margin-top: 32px;
  margin-bottom: 15px;">
    <h2 style="color: var(--color-primary-2);">
      {{userName}}
      <span class="cpf-label">
        {{cpfClient | cpf }}
      </span>
    </h2>
  </div>
  <mat-card>
    <mat-card-content>
      <div class="card-content">
        <div class="inputs-content">

          <mat-form-field class="inputs" appearance="fill">
            <mat-label>Data inicial</mat-label>
            <input type="hidden" [matDatepicker]="pickerDataInicial" [max]="maxDateInicial"
              [(ngModel)]="dataInicialLabel" (ngModelChange)="onInputChange(true)" />
            <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDateInicial" [formControl]="dataInicial"
              [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'" (input)="onInputChange(false)" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerDataInicial"></mat-datepicker-toggle>
            <mat-datepicker #pickerDataInicial></mat-datepicker>
            <mat-error *ngIf="dataInicial.hasError('required')">
              Este campo não pode ficar vazio
            </mat-error>
            <mat-error class="date-error" *ngIf="dataInicial.hasError('invalidDate')">Data inválida. Utilize o formato DD/MM/AAAA</mat-error>
          </mat-form-field>
          <mat-form-field class="inputs" appearance="fill">
            <mat-label>Data final</mat-label>
            <input type="hidden" [matDatepicker]="pickerDataFinal" [max]="maxDateFinal"
              [(ngModel)]="dataFinalLabel" (ngModelChange)="onInputChange(true)" />
            <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDateFinal" [formControl]="dataFinal"
              [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange(false)" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerDataFinal"></mat-datepicker-toggle>
            <mat-datepicker #pickerDataFinal></mat-datepicker>
            <mat-error *ngIf="dataFinal.hasError('required')">
              Este campo não pode ficar vazio
            </mat-error>
            <mat-error class="date-error" *ngIf="dataFinal.hasError('dataValida')">Data inválida. Selecione uma data até
              o dia atual.</mat-error>
          </mat-form-field>
          <div class="button">
            <yfs-button
              [state]="(dataInicial.value && dataFinal.value) ? (dataInicial.value >= dataFinal.value ? 'disabled' : 'enabled') : 'disabled'"
              label="CONSULTAR" [fullWidth]="isFullWidth" type="primary" [press]="getData">
            </yfs-button>
          </div>
        </div>
      </div>
    </mat-card-content>
    <div class="table scrollable-table-container">
      <table *ngIf="!emptyDocument" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort" matSort
        (matSortChange)="announceSortChange($event)">
        <ng-container matColumnDef="dataPagamento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            Data de pagamento
          </th>
          <td mat-cell *matCellDef="let element"> {{element.dataPagamento | date:'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="dataVencimento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            Data de vencimento
          </th>
          <td mat-cell *matCellDef="let element"> {{element.dataVencimento | date:'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="descricao">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            Descrição
          </th>
          <td mat-cell *matCellDef="let element"> {{element.descricao}}</td>
        </ng-container>

        <ng-container matColumnDef="numeroParcela">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            Parcela
          </th>
          <td mat-cell *matCellDef="let element"> {{element.numeroParcela}}</td>
        </ng-container>

        <ng-container matColumnDef="valor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            Valor
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="getValorColor(element.valor)"> {{element.valor | currency }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsResume"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsResume;"></tr>
      </table>
      <div *ngIf="emptyDocument" class="content-empty">
        <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
        <div style="margin-top: 5px;">
          <span>Nenhum resultado encontrado.</span>
          <span>Tente novamente com outros filtros.</span>
        </div>
      </div>
    </div>
  </mat-card>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
<div class="paginator-content">
  <mat-paginator aria-label="Select page" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>

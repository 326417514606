import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { ModalEmailComponent } from '../modal-email/modal-email.component';
import { DataService } from 'src/app/services/data-service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgFor } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-documentation',
    templateUrl: './modal-documentation.component.html',
    styleUrls: ['./modal-documentation.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgFor, MatCheckbox, FormsModule, ButtonComponent]
})
export class ModalDocumentationComponent implements OnInit{

  @Output() selectedIdsEvent: EventEmitter<number[]> = new EventEmitter<number[]>();
  public dataList: any[] = [];
  public email: string = '';
  public selectedIds: number[] = [];
  public contrato: string = '';
  public cpf: string = '';
  public hasSelectedItem: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalDocumentationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private dataService: DataService
  ) {
   }

  ngOnInit() {
    this.dataList = this.dataService.getDataList();
    this.email = this.dataService.getEmail();
    this.selectedIds = this.dataService.getSelectedIds() || [];
    this.contrato = this.dataService.getContrato();
    this.cpf = this.dataService.getCPF();
    this.checkItens();
  }

  toggleCheckbox(id: number) {
    this.selectedIds = this.selectedIds || [];
    const index = this.selectedIds.indexOf(id);
    if (index > -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(id);
    }
    this.hasSelectedItem = this.selectedIds.length > 0;
  }

  checkSelecionado(): boolean {
    return this.hasSelectedItem;
  }

  next = () => {
    this.selectedIdsEvent.emit(this.selectedIds);
    this.dialogRef.close();
    this.dataService.setData(this.dataList, this.email, this.selectedIds, this.contrato, this.cpf);
    const dialogRef = this.dialog.open(ModalEmailComponent, {
      width: '600px'
    });
  }

  checkItens(){
    this.dataList.forEach(data => {
      data.checked = this.selectedIds.includes(data.idTermoLiberacred);
    });
    this.hasSelectedItem = this.selectedIds.length > 0;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}

<yfs-header *ngIf="previousFlow === 'menu'"></yfs-header>
<section class="container-content">
    <div class="button-back">
      <yfs-link-back [text]="getBackButtonText()" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
        <h1>TABELAS ANTIGAS - TARIFAS</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="card-content">
                <div class="inputs-content">
                    <mat-form-field appearance="fill">
                        <mat-label>Busque por ID</mat-label>
                        <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                            class="search-icon material-symbols-outlined" fontIcon="search"></mat-icon>
                        <input [formControl]="id" (input)="formatId()" (focusout)="setPageStart(); this.getHistoricParameterTariff()" matInput>
                    </mat-form-field>

                    <mat-form-field  appearance="fill">
                        <mat-label>Data inicial de vigência</mat-label>
                        <input type="hidden" [matDatepicker]="pickerInicial" [(ngModel)]="dataInicialLabel" (ngModelChange)="onInputChangeInicial(true)" (dateChange)="searchHistoricOnInputDateChange('inicial')"/>
                        <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataInicial" [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChangeInicial(false)" (focusout)="searchHistoricOnInputDateChange('inicial')"/>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerInicial"></mat-datepicker-toggle>
                        <mat-datepicker #pickerInicial></mat-datepicker>
                        <mat-error class="date-error" *ngIf="dataInicial.hasError('invalidDate')">Data inválida.</mat-error>
                    </mat-form-field>

                    <mat-form-field  appearance="fill">
                        <mat-label>Data Final de vigência</mat-label>
                        <input type="hidden" [matDatepicker]="pickerFinal" [(ngModel)]="dataFinalLabel" (ngModelChange)="onInputChangeFinal(true)" (dateChange)="searchHistoricOnInputDateChange('final')"/>
                        <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataFinal" [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChangeFinal(false)" (focusout)="searchHistoricOnInputDateChange('final')"/>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFinal></mat-datepicker>
                        <mat-error class="date-error" *ngIf="dataFinal.hasError('invalidDate')">Data inválida.</mat-error>
                    </mat-form-field>

                    <mat-form-field id="status" appearance="fill">
                        <mat-label>Status</mat-label>
                        <mat-select  [formControl]="status" multiple>
                            <div class="select-all" (click)="selectAllStatus()">
                                <mat-checkbox [(ngModel)]="checkedAllStatus"
                                    [indeterminate]="selectedStatus.length > 0 && selectedStatus.length < listaStatus.length"
                                    color="primary"><span class="select-label">Todos</span></mat-checkbox>
                            </div>
                            <mat-option *ngFor="let status of listaStatus" [value]="status.idStatus" (click)="checkHandleStatus(status.idStatus)"><span
                                    class="select-label">{{
                                    status.nome }}</span></mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field id="status" appearance="fill">
                      <mat-label>Categoria</mat-label>
                      <mat-select  [formControl]="categoria" multiple>
                          <div class="select-all" (click)="selectAllCategories()">
                              <mat-checkbox [(ngModel)]="checkedAllCategories"
                                  [indeterminate]="selectedCategories.length > 0 && selectedCategories.length < listaCategorias.length"
                                  color="primary"><span class="select-label">Todos</span></mat-checkbox>
                          </div>
                          <mat-option *ngFor="let categoria of listaCategorias" [value]="categoria.idCategoria" (click)="checkHandleCategories(categoria.idCategoria)"><span
                                  class="select-label">{{
                                  categoria.nome }}</span></mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <span class="card-title">Todos os dados serão exibidos em um único grid</span>
                <div *ngIf="hasValue">
                <div class="table-content" [ngClass]="{'card-vigente': parameter?.vigente}" *ngFor="let parameter of listHistorico">
                    <div class="table-date">
                        <div>
                            <span>Vigência</span>
                            <span>{{parameter?.dataVigencia | dateConvert }}</span>
                        </div>
                        <div>
                            <span>ID</span>
                            <span>{{parameter?.id || '-'}}</span>
                        </div>
                        <div class="column-icon">
                            <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                            class="vigencia-icon material-symbols-outlined" [fontIcon]="getIcon(parameter?.vigente)" [style.color]="getIconColor(parameter?.vigente)"></mat-icon>
                            <span [style.color]="getTextColor(parameter?.vigente)">{{parameter?.vigente === false ? 'Não Vigente' : 'Vigente'}}</span>
                        </div>
                    </div>
                    <div class="table-date">
                        <div>
                            <span>% Mínimo</span>
                              <span>{{parameter?.percentualCapitalizacaoMinimo || '-'}}%</span>
                        </div>
                        <div>
                            <span>TF - Mensalidade</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.mensalidade?.valueType != 1">{{parameter?.mensalidade?.value || '-'}}%</span>
                            <span *ngIf="parameter?.mensalidade?.valueType == 1">{{parameter?.mensalidade?.value | currency }}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.mensalidade?.valueType) }}</span>
                            </div>
                        </div>
                        <div>
                            <span>TF - Adesão</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.adesao?.valueType != 1">{{parameter?.adesao?.value || '-'}}%</span>
                            <span *ngIf="parameter?.adesao?.valueType == 1">{{parameter?.adesao?.value | currency}}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.adesao?.valueType) }}</span>
                          </div>
                        </div>
                    </div>
                    <div class="table-date">
                        <div>
                            <span>% Máximo</span>
                            <span>{{parameter?.percentualCapitalizacaoMaximo || '-'}}%</span>
                        </div>
                        <div>
                            <span>TF - Uso</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.usoRedeCredenciada?.valueType != 1">{{parameter?.usoRedeCredenciada?.value || '-'}}%</span>
                            <span *ngIf="parameter?.usoRedeCredenciada?.valueType == 1">{{parameter?.usoRedeCredenciada?.value | currency}}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.usoRedeCredenciada?.valueType) }}</span>
                          </div>
                        </div>
                        <div>
                            <span>Valor Máximo Cartão</span>
                            <div style="display: flex; flex-direction: row;">
                            <span>{{parameter?.valorMaximoCartaoVirtual?.value | currency}}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.valorMaximoCartaoVirtual?.valueType) }}</span>
                          </div>
                        </div>
                    </div>
                    <div class="table-date">
                        <div>
                            <span>Majoração</span>
                            <span>{{parameter?.percentualMajoracao || '-'}}%</span>
                        </div>
                        <div>
                            <span>TF - Inatividade</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.inatividade?.valueType != 1">{{parameter?.inatividade?.value || '-'}}%</span>
                            <span *ngIf="parameter?.inatividade?.valueType == 1">{{parameter?.inatividade?.value | currency}}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.inatividade?.valueType) }}</span>
                          </div>
                        </div>
                        <div>
                            <span>Categoria da tarifa</span>
                            <span *ngIf="parameter?.categorias">{{ parameter.categoria }}</span>
                          </div>
                    </div>
                    <div class="table-date">
                        <div>
                            <span>TF - Ativação</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.ativacao?.valueType != 1">{{parameter?.ativacao?.value || '-'}}%</span>
                            <span *ngIf="parameter?.ativacao?.valueType == 1">{{parameter?.ativacao?.value | currency}}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.ativacao?.valueType) }}</span>
                          </div>
                        </div>
                        <div>
                            <span>TF - transferência C/C</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.transferenciaContaCorrente?.valueType != 1">{{parameter?.transferenciaContaCorrente?.value || '-'}}%</span>
                            <span *ngIf="parameter?.transferenciaContaCorrente?.valueType == 1">{{parameter?.transferenciaContaCorrente?.value | currency}}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.transferenciaContaCorrente?.valueType) }}</span>
                          </div>
                        </div>
                    </div>
                    <div class="table-date">
                        <div>
                            <span>TF - Carga / Recarga</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.cargaRecarga?.valueType != 1">{{parameter?.cargaRecarga?.value || '-'}}%</span>
                            <span *ngIf="parameter?.cargaRecarga?.valueType == 1">{{parameter?.cargaRecarga?.value | currency}}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.cargaRecarga?.valueType) }}</span>
                          </div>
                        </div>
                        <div>
                            <span>TF - Desistência</span>
                            <div style="display: flex; flex-direction: row;">
                            <span *ngIf="parameter?.cancelamento?.valueType != 1">{{parameter?.cancelamento?.value || '-'}}%</span>
                            <span *ngIf="parameter?.cancelamento?.valueType == 1">{{parameter?.cancelamento?.value | currency }}</span>
                            <span class="icon-value-type">{{ getTipoDescription(parameter?.cancelamento?.valueType) }}</span>
                          </div>
                        </div>
                    </div>
                </div>
                </div>
                <div *ngIf="!hasValue" class="content-empty">
                    <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
                    <div>
                      <span>Nenhum resultado encontrado.</span>
                      <span>Tente novamente com outros filtros.</span>
                    </div>
                </div>
            </div>
            <div class="paginator-content">
                <mat-paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" aria-label="Select page"
                  [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)">
                </mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<yfs-header></yfs-header>
<section class="container-content" *ngIf="currentFlow == 'parameter'">
    <div class="button-back">
      <yfs-link-back [text]="previousScreen === 'parameters' ? 'Voltar para parâmetros técnicos' : 'Voltar para minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
        <h1>CADASTRO DE PARÂMETROS DE TARIFAS</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="category-content">
                <mat-form-field id="categoria" appearance="fill">
                    <mat-label>Categoria</mat-label>
                    <mat-select #perfilAcessoSelect [formControl]="categoria" multiple>
                        <div class="select-all" (click)="selectAllCategoria()">
                            <mat-checkbox [(ngModel)]="checkedAllCategoria"
                                [indeterminate]="selectedCategoria.length > 0 && selectedCategoria.length < listaCategoria.length"
                                color="primary"><span class="select-label">Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let categoria of listaCategoria" [value]="categoria.idCategoriaExibicao"
                            (click)="checkHandleCategoria(categoria.idCategoriaExibicao)"><span class="select-label">{{
                                categoria.nomeExibicao }}</span></mat-option>
                    </mat-select>
                    <mat-error *ngIf="categoria.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="inputs" appearance="fill">
                    <mat-label>Data de vigência da tabela</mat-label>
                    <input type="hidden" [matDatepicker]="pickerVigencia" [min]="minDate"
                        [(ngModel)]="dataVigenciaLabel" (ngModelChange)="onInputChange(true)" />
                    <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [min]="minDate" [formControl]="dataVigencia"
                        [ngModel]="dataVigenciaLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange(false)" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerVigencia"></mat-datepicker-toggle>
                    <mat-datepicker #pickerVigencia></mat-datepicker>
                    <mat-error *ngIf="dataVigencia.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                    <mat-error class="date-error" *ngIf="dataVigencia.hasError('invalidDate')">Data
                        inválida.</mat-error>
                    <mat-error class="date-error" *ngIf="dataVigencia.hasError('dataValida')">Data inválida. Coloque uma
                        data futura.</mat-error>
                </mat-form-field>
            </div>
            <yfs-button state="enabled" label="TABELAS ANTIGAS" type="secondary" size="small"
                (click)="openOldTables()"></yfs-button>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-content>
            <span class="card-title">Atributos gerais</span>
            <div class="general-attributes-content">
                <mat-form-field appearance="fill">
                    <mat-label>Percentual mínimo de capitalização</mat-label>
                    <input [formControl]="percentualMinimo" maxlength="4" (input)="formatPercentualMinimo()" matInput>
                    <mat-error *ngIf="percentualMinimo.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                    <mat-error *ngIf="percentualMinimo.hasError('rangeError')">
                        O percentual deve ser entre 20% e 100%
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Percentual máximo de capitalização</mat-label>
                    <input [formControl]="percentualMaximo" maxlength="4" (input)="formatPercentualMaximo()" matInput>
                    <mat-error *ngIf="percentualMaximo.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                    <mat-error *ngIf="percentualMaximo.hasError('rangeError')">
                        O percentual deve ser entre 0% e 80%
                    </mat-error>
                    <mat-error *ngIf="percentualMaximo.hasError('minValueError')">
                        O percentual máximo deve ser maior que o mínimo
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Fator de majoração</mat-label>
                    <input [formControl]="majoracao" maxlength="4" (input)="formatPercentualMajoracao()" matInput>
                    <mat-error *ngIf="majoracao.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                    <mat-error *ngIf="majoracao.hasError('rangeError')">
                        O fator de majoração deve ser entre 0% e 100%.
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-content>
            <span class="card-title">Tarifas</span>
            <div class="labels-content">
                <span class="label">Tarifa</span>
                <span class="label">Periodicidade</span>
                <span class="label">Tipo</span>
                <span class="label">Valor</span>
            </div>
            <div class="tariff-content">
                <span>Tarifa de ativação</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaAtivacaoPeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaAtivacaoTipo" (selectionChange)="cleanField(this.tarifaAtivacao)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaAtivacaoTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="tarifaAtivacao" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaAtivacao.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaAtivacaoTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input  [formControl]="tarifaAtivacao" maxlength="5" (input)="formatValor(this.tarifaAtivacao)" matInput>
                    <mat-error *ngIf="tarifaAtivacao.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="tariff-content">
                <span>Tarifa de carga / recarga via boleto</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaRecargaPeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaRecargaTipo" (selectionChange)="cleanField(this.tarifaRecarga)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaRecargaTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input  [formControl]="tarifaRecarga" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaRecarga.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaRecargaTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input [formControl]="tarifaRecarga" maxlength="5" (input)="formatValor(this.tarifaRecarga)" matInput>
                    <mat-error *ngIf="tarifaRecarga.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="tariff-content">
                <span>Tarifa de mensalidade</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaMensalidadePeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaMensalidadeTipo" (selectionChange)="cleanField(this.tarifaMensalidade)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaMensalidadeTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="tarifaMensalidade" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaMensalidade.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaMensalidadeTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input [formControl]="tarifaMensalidade" maxlength="5" (input)="formatValor(this.tarifaMensalidade)" matInput>
                    <mat-error *ngIf="tarifaMensalidade.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="tariff-content">
                <span>Tarifa de uso / Rede credenciada</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaUsoPeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaUsoTipo" (selectionChange)="cleanField(this.tarifaUso)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaUsoTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="tarifaUso" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaUso.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaUsoTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input [formControl]="tarifaUso" maxlength="5" (input)="formatValor(this.tarifaUso)" matInput>
                    <mat-error *ngIf="tarifaUso.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="tariff-content">
                <span>Tarifa de inatividade</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaInatividadePeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaInatividadeTipo" (selectionChange)="cleanField(this.tarifaInatividade)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaInatividadeTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="tarifaInatividade" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaInatividade.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaInatividadeTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input [formControl]="tarifaInatividade" maxlength="5" (input)="formatValor(this.tarifaInatividade)" matInput>
                    <mat-error *ngIf="tarifaInatividade.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="tariff-content">
                <span>Tarifa de transferência de valores para C/C</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaTransferenciaPeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaTransferenciaTipo" (selectionChange)="cleanField(this.tarifaTransferencia)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaTransferenciaTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="tarifaTransferencia" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaTransferencia.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaTransferenciaTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input [formControl]="tarifaTransferencia" maxlength="5" (input)="formatValor(this.tarifaTransferencia)" matInput>
                    <mat-error *ngIf="tarifaTransferencia.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="tariff-content">
                <span>Tarifa de cancelamento</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaCancelamentoPeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaCancelamentoTipo" (selectionChange)="cleanField(this.tarifaCancelamento)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaCancelamentoTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="tarifaCancelamento" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaCancelamento.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaCancelamentoTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input [formControl]="tarifaCancelamento" maxlength="5" (input)="formatValor(this.tarifaCancelamento)" matInput>
                    <mat-error *ngIf="tarifaCancelamento.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <span class="card-title">Tarifas Cartão Liberacred</span>
            <div class="labels-content">
                <span class="label">Tarifa</span>
                <span class="label">Periodicidade</span>
                <span class="label">Tipo</span>
                <span class="label">Valor</span>
            </div>
            <div class="tariff-content br-bottom">
                <span>Adesão ao programa de recargas programadas</span>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <input [(ngModel)]="tarifaAdesaoPeriodicidade" disabled matInput>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="tarifaAdesaoTipo" (selectionChange)="cleanField(this.tarifaAdesao)">
                      <mat-option *ngFor="let tipo of tipoList" [value]="tipo.tipoValue">{{tipo.tipoDescription}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaAdesaoTipo === 1" appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="tarifaAdesao" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="tarifaAdesao.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tarifaAdesaoTipo !== 1" appearance="fill">
                    <mat-label>%</mat-label>
                    <input [formControl]="tarifaAdesao" maxlength="5" (input)="formatValor(this.tarifaAdesao)" matInput>
                    <mat-error *ngIf="tarifaAdesao.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <span class="card-title">Valor máximo do Cartão Virtual Liberacred</span>
            <div class="tariff-card-content">
                <span>Valor máximo do Cartão Virtual Liberacred</span>
                <mat-form-field appearance="fill">
                    <mat-label>R$</mat-label>
                    <input [formControl]="valorMaximo" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                    <mat-error *ngIf="valorMaximo.hasError('required')">
                        O campo é obrigatório
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="check-box-container">
        <mat-checkbox color="primary" [(ngModel)]="updateCurrentContracts">
            <span class="check-box-label">Atualizar os contratos vigentes com a nova versão</span>
        </mat-checkbox>
    </div>

    <div class="button-container">
        <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="openDialogExist()"></yfs-button>
        <div class="button-content">
            <div class="warning-notification" *ngIf="checkFields() === 'disabled'">
                <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined" fontIcon="warning"></mat-icon>
                <span>É necessário preencher todos os campos para salvar</span>
            </div>
            <yfs-button [state]="checkFields()" label="SALVAR" type="primary" [press]="openDialogConfirmSave"></yfs-button>
        </div>
    </div>
</section>
<yfs-old-tables-tariff *ngIf="currentFlow == 'oldTables'"
    (currentFlowChanged)="changeCurrentFlow($event)" previousFlow="tariffPage"></yfs-old-tables-tariff>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.general';
import { of, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppCacheInterceptor implements HttpInterceptor {
    private readonly cache = new Map<string, { expiresIn: Date, response: HttpResponse<any> }>();

    /** Endpoints list and expiresIn */
    private endpointsCache = new Map([
        ['/v2/produtos/ativos', 4],
        ['/formas-pagamento', 4],
    ]);

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const url = req.url.replace(environment.urlApi, '');

        if (this.endpointsCache.has(url)) {
            const timeInHours = this.endpointsCache.get(url) ?? 0;
            const cacheResponse = this.verifyCache(url, req);
            if (!cacheResponse) {
                return next.handle(req).pipe(
                    tap((response) => {
                        if (response instanceof HttpResponse) {
                            const expiresIn = new Date();
                            expiresIn.setHours(expiresIn.getHours() + timeInHours);
                            this.cache.set(url, { response, expiresIn: expiresIn })
                        }
                    })
                );
            }
            return of(cacheResponse.response);
        }

        return next.handle(req);
    }

    verifyCache(key: string, req: HttpRequest<any>): { expiresIn: Date; response: HttpResponse<any>; } | undefined {
        const cacheResponse = this.cache.get(req.url.replace(environment.urlApi, ''))
        const now = new Date();
        if (cacheResponse?.expiresIn && cacheResponse?.expiresIn.valueOf() < now.valueOf()) {
            this.cache.delete(key);
            return undefined;
        }
        return cacheResponse;
    }
}
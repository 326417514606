<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
  </div>
  <h1>GERAÇÃO DE EXTRATO DO CONTRATO</h1>
  <h2 class="subtitle">
    Utilize os filtros abaixo para selecionar os critérios para a geração do
    relatório.
  </h2>
  <h2 class="subtitle second-subtitle">
    Escolha uma opção entre Número do contrato, CPF ou Nome do cliente. Caso queira filtrar por um período específico, marque o campo "Selecionar datas" e selecione o intervalo de tempo.
  </h2>
  <mat-card class="card" *ngIf="!success">
    <mat-tab-group (selectedIndexChange)="changeTab($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tab-label">Número do contrato</span>
        </ng-template>
        <mat-card-content class="card-content">
          <div *ngIf="emptyDocument && !generationError">
            <img src="assets/svg/empty.svg" alt="documento vazio" />
            <div class="message-empty-document">
              <span
                >Nenhum resultado encontrado para os critérios selecionados. Por
                favor, tente novamente com outras opções.</span
              >
            </div>
          </div>
          <mat-form-field class="inputs" appearance="fill">
            <mat-label>N° Contrato</mat-label>
            <input [formControl]="contrato" matInput />
            <mat-error *ngIf="contrato.hasError('required')">
              Campo obrigatório
            </mat-error>
          </mat-form-field>
          <div class="check-period">
            <mat-checkbox
              color="primary"
              [(ngModel)]="checkedShowDate"
              ><span>Selecionar datas</span></mat-checkbox
            >
          </div>
          <div *ngIf="checkedShowDate">
          <mat-form-field class="inputs" appearance="fill">
            <mat-label>Início do relatório</mat-label>
            <input type="hidden" [matDatepicker]="pickerInicio" [(ngModel)]="dataInicialLabel" />
            <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataInicial"
              [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange()" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
            <mat-datepicker #pickerInicio></mat-datepicker>
            <mat-error *ngIf="dataInicial.hasError('required')">
              Campo obrigatório
            </mat-error>
            <mat-error *ngIf="dataInicial.hasError('invalidDate')">Data inválida. Utilize o formato DD/MM/AAAA</mat-error>
          </mat-form-field>
          <mat-form-field class="inputs" appearance="fill" [ngClass]="{'mat-form-field-invalid': dateError}">
            <mat-label>Fim do relatório</mat-label>
            <input type="hidden" [matDatepicker]="pickerFinal" [(ngModel)]="dataFinalLabel" />
            <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataFinal"
              [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange()" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
            <mat-datepicker #pickerFinal (dateChange)="onInputChange()"></mat-datepicker>
            <mat-error *ngIf="dataFinal.hasError('required') && !dateError">
              Campo obrigatório
            </mat-error>
            <mat-error *ngIf="dataFinal.hasError('invalidDate') && !dateError">Data inválida. Utilize o formato DD/MM/AAAA</mat-error>
          </mat-form-field>
          <span class="date-error" *ngIf="dateError"><img src="assets/svg/atention.svg" alt="icone de atenção" /> A data
            final não pode ser anterior à data inicial.</span>
        </div>
        <div class="file-format ">
          <div class="recipient-content">
            <span>Formato do arquivo</span>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="formatoArquivo">
              <mat-radio-button style="margin-left: -10px;" value="pdf">PDF</mat-radio-button>
              <mat-radio-button value="xlsx">XLS</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
          <yfs-button
            [press]="clickNumero"
            state="enabled"
            label="GERAR RELATÓRIO"
            class="btn-generate"
            type="primary"
            [state]="isDisabledContrato()"
          ></yfs-button>
          <span
            *ngIf="generationError"
            data-test="error-message-file"
            class="error-message"
          >
            <img src="assets/svg/atention.svg" alt="icone de atenção" />
            Não foi possível gerar o relatório nesse momento.
          </span>
          <span
            *ngIf="generationError"
            data-test="error-message-file"
            class="error-try-again"
          >
            Tente novamente mais tarde.
          </span>
        </mat-card-content>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tab-label">CPF</span>
        </ng-template>
        <mat-card-content class="card-content" style="overflow: hidden">
          <div *ngIf="emptyDocument && !generationError">
            <img src="assets/svg/empty.svg" alt="documento vazio" />
            <div class="message-empty-document">
              <span
                >Nenhum resultado encontrado para os critérios selecionados. Por
                favor, tente novamente com outras opções.</span
              >
            </div>
          </div>
          <mat-form-field class="inputs" appearance="fill">
            <mat-label>CPF do cliente</mat-label>
            <input [formControl]="cpf" matInput (ngModelChange)="formatarCPF()" mask="000.000.000-00"/>
            <mat-error *ngIf="cpf.hasError('required')">
              Campo obrigatório
            </mat-error>
            <mat-error *ngIf="cpf.hasError('cpfInvalid') && !cpf.hasError('required')">CPF inválido</mat-error>
          </mat-form-field>
          <div class="check-period">
            <mat-checkbox
              color="primary"
              [(ngModel)]="checkedShowDate"
              ><span>Selecionar datas</span></mat-checkbox
            >
          </div>
          <div *ngIf="checkedShowDate">
            <mat-form-field class="inputs" appearance="fill">
              <mat-label>Início do relatório</mat-label>
              <input type="hidden" [matDatepicker]="pickerInicio" [(ngModel)]="dataInicialLabel" />
              <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataInicial"
                [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange()" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
              <mat-datepicker #pickerInicio></mat-datepicker>
              <mat-error *ngIf="dataInicial.hasError('required')">
                Campo obrigatório
              </mat-error>
              <mat-error *ngIf="dataInicial.hasError('invalidDate')">Data inválida. Utilize o formato DD/MM/AAAA</mat-error>
            </mat-form-field>
            <mat-form-field class="inputs" appearance="fill" [ngClass]="{'mat-form-field-invalid': dateError}">
              <mat-label>Fim do relatório</mat-label>
              <input type="hidden" [matDatepicker]="pickerFinal" [(ngModel)]="dataFinalLabel" />
              <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataFinal"
                [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange()" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
              <mat-datepicker #pickerFinal (dateChange)="onInputChange()"></mat-datepicker>
              <mat-error *ngIf="dataFinal.hasError('required') && !dateError">
                Campo obrigatório
              </mat-error>
              <mat-error *ngIf="dataFinal.hasError('invalidDate') && !dateError">Data inválida. Utilize o formato DD/MM/AAAA</mat-error>
            </mat-form-field>
            <span class="date-error" *ngIf="dateError"><img src="assets/svg/atention.svg" alt="icone de atenção" /> A data
              final não pode ser anterior à data inicial.</span>
        </div>
          <div class="file-format ">
            <div class="recipient-content">
              <span>Formato do arquivo</span>
              <mat-radio-group aria-label="Select an option" [(ngModel)]="formatoArquivo">
                <mat-radio-button style="margin-left: -10px;" value="pdf">PDF</mat-radio-button>
                <mat-radio-button value="xlsx">XLS</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <yfs-button
            [press]="clickCpf"
            state="enabled"
            label="GERAR RELATÓRIO"
            class="btn-generate"
            type="primary"
            [state]="isDisabledCpf()"
          ></yfs-button>
          <span
            *ngIf="generationError"
            data-test="error-message-file"
            class="error-message"
          >
            <img src="assets/svg/atention.svg" alt="icone de atenção" />
            Não foi possível gerar o relatório nesse momento.
          </span>
          <span
            *ngIf="generationError"
            data-test="error-message-file"
            class="error-try-again"
          >
            Tente novamente mais tarde.
          </span>
        </mat-card-content>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="tab-label">Nome</span>
        </ng-template>
        <mat-card-content class="card-content" style="overflow: hidden">
          <div *ngIf="emptyDocument && !generationError">
            <img src="assets/svg/empty.svg" alt="documento vazio" />
            <div class="message-empty-document">
              <span
                >Nenhum resultado encontrado para os critérios selecionados. Por
                favor, tente novamente com outras opções.</span
              >
            </div>
          </div>
          <mat-form-field class="inputs" appearance="fill">
            <mat-label>Nome do cliente</mat-label>
            <input [formControl]="nome" matInput (input)="formatarNome()" />
            <mat-error *ngIf="nome.hasError('required')">
              Campo obrigatório
            </mat-error>
          </mat-form-field>
          <div class="check-period">
            <mat-checkbox
              color="primary"
              [(ngModel)]="checkedShowDate"
              ><span>Selecionar datas</span></mat-checkbox
            >
          </div>
          <div *ngIf="checkedShowDate">
            <mat-form-field class="inputs" appearance="fill">
              <mat-label>Início do relatório</mat-label>
              <input type="hidden" [matDatepicker]="pickerInicio" [(ngModel)]="dataInicialLabel" />
              <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataInicial"
                [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange()" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
              <mat-datepicker #pickerInicio></mat-datepicker>
              <mat-error *ngIf="dataInicial.hasError('required')">
                Campo obrigatório
              </mat-error>
              <mat-error *ngIf="dataInicial.hasError('invalidDate')">Data inválida. Utilize o formato DD/MM/AAAA</mat-error>
            </mat-form-field>
            <mat-form-field class="inputs" appearance="fill" [ngClass]="{'mat-form-field-invalid': dateError}">
              <mat-label>Fim do relatório</mat-label>
              <input type="hidden" [matDatepicker]="pickerFinal" [(ngModel)]="dataFinalLabel" />
              <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataFinal"
                [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange()" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
              <mat-datepicker #pickerFinal (dateChange)="onInputChange()"></mat-datepicker>
              <mat-error *ngIf="dataFinal.hasError('required') && !dateError">
                Campo obrigatório
              </mat-error>
              <mat-error *ngIf="dataFinal.hasError('invalidDate') && !dateError">Data inválida. Utilize o formato DD/MM/AAAA</mat-error>
            </mat-form-field>
            <span class="date-error" *ngIf="dateError"><img src="assets/svg/atention.svg" alt="icone de atenção" /> A data
              final não pode ser anterior à data inicial.</span>
        </div>
          <div class="file-format ">
            <div class="recipient-content">
              <span>Formato do arquivo</span>
              <mat-radio-group aria-label="Select an option" [(ngModel)]="formatoArquivo">
                <mat-radio-button style="margin-left: -10px;" value="pdf">PDF</mat-radio-button>
                <mat-radio-button value="xlsx">XLS</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <yfs-button
            [press]="clickName"
            state="enabled"
            label="GERAR RELATÓRIO"
            class="btn-generate"
            type="primary"
            [state]="isDisabledNome()"
          ></yfs-button>
          <span
            *ngIf="generationError"
            data-test="error-message-file"
            class="error-message"
          >
            <img src="assets/svg/atention.svg" alt="icone de atenção" />
            Não foi possível gerar o relatório nesse momento.
          </span>
          <span
            *ngIf="generationError"
            data-test="error-message-file"
            class="error-try-again"
          >
            Tente novamente mais tarde.
          </span>
        </mat-card-content>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <mat-card class="card" *ngIf="success">
    <mat-card-content class="card-content">
      <img src="assets/svg/success.svg" />
      <div class="card-message">
        <div class="message">
          <span>Arquivo gerado com sucesso!</span>
        </div>
        <div class="message">
          <span>Você já pode fazer o download abaixo.</span>
        </div>
      </div>
      <yfs-button
        data-test="button-download"
        class="btn-download"
        (click)="downloadFile()"
        state="enabled"
        label="BAIXAR ARQUIVO"
        type="primary"
      ></yfs-button>
      <div>
        <a (click)="openDialog()">GERAR OUTRO ARQUIVO</a>
      </div>
      <div class="atention">
        <span>(O arquivo atual será apagado)</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'yfs-privacy-message',
    templateUrl: './privacy-message.component.html',
    styleUrls: ['./privacy-message.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon]
})
export class PrivacyMessageComponent {

  public showComponent = true;

  ngOnInit() {
    const isFirstVisit = localStorage.getItem('isFirstVisit');
    if (!isFirstVisit) {
      localStorage.setItem('isFirstVisit', 'true');
    }
    this.showComponent = !isFirstVisit;
  }

  close() {
    localStorage.setItem('isFirstVisit', 'true');
    this.showComponent = false;
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalDeleteMessageComponent } from 'src/app/components/modal-delete-message/modal-delete-message.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalPreviewMessageComponent } from 'src/app/components/modal-preview-message/modal-preview-message.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { HeaderComponent } from '../../components/header/header.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { NewMessageComponent } from './new-message/new-message.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { EllipsisPipe } from '../../shared/pipes/ellipsis.pipe';

@Component({
    selector: 'yfs-communication',
    templateUrl: './communication.component.html',
    styleUrls: ['./communication.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NgIf, LinkBackComponent, MatCard, MatCardContent, NgClass, MatIcon, ButtonComponent, NgFor, TooltipComponent, NewMessageComponent, LoadingSpinnerComponent, EllipsisPipe]
})
export class CommunicationComponent {
  public previousScreen: string = '';
  public currentFlow = 'communication'
  public isLoading = false;
  public timeoutError = false;
  public isCardSelected: boolean[] = [false, false];
  public templateList: any[] = [];
  public templates: number = 0;
  public pageSize = 8;
  public pageNumber = 1;
  public showErrorMessage = false;
  public empty = false;
  public templateSelected: any;

  constructor(private apiService: ApiHttpService,private router: Router, public dialog: MatDialog) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  toggleCard(index: number) {
    this.isCardSelected = this.isCardSelected.map((_, i) => i === index);

    if (index === 0) {
      this.clearQuery()
      this.getTemplates();
    }
  }

  previewMessage(event: Event, templateSelected: any) {
    event.stopPropagation();
    this.templateSelected = templateSelected;
    this.openModalPreview(templateSelected)
  }

  editMessage(event: Event, templateSelected: any) {
    event.stopPropagation();
    this.templateSelected = templateSelected;
    this.currentFlow = 'newMessage';
  }

  deleteMessage(event: Event, messageNome: string, idTemplate: number) {
    event.stopPropagation();
    this.openDialogDeleteMessage(messageNome, idTemplate)
  }

  goToMessage() {
    this.templateSelected = undefined;
    this.currentFlow = 'newMessage';
  }

  closeNewMessage() {
    this.currentFlow = 'communication'
    this.clearQuery()
    this.getTemplates();
  }

  clearQuery() {
    this.pageNumber = 1;
    this.empty = false;
    this.templateList = []
  }

  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  moreItens() {
    this.pageNumber = this.pageNumber + 1;
    this.getTemplates();
  }

  getTemplates(){
    this.isLoading = true;
    this.apiService.getComunication(this.pageNumber, this.pageSize).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        if(result.status === 200){
          this.isLoading = false;
          if (result?.body?.items && result?.body?.items?.length > 0) {
            this.templateList = [...this.templateList, ...result.body.items];
          }
          this.templates = result.body.totalCount;
        }
        else if (result.status === 204)
        {
          this.isLoading = false;
          this.empty = true;
          this.templates = 0;
        }
      },
      error: error => {
        this.isLoading = false;
        this.showErrorMessage = true;
        if (this.timeoutError === false)
        console.log(error)
      }
    })
  }

  handleDeleteTemplate(idTemplate: number){
    this.isLoading = true;
    this.apiService.deleteNewMessage(idTemplate).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        this.isLoading = false;
        this.openModalSucess()
        this.clearQuery()
        this.getTemplates();
      },
      error: error => {
        this.isLoading = false;
        if (this.timeoutError === false)
        console.log(error)
      }
    })
  }

  openDialogDeleteMessage(messageNome: string, idTemplate: number) {
    this.dialog.open(ModalDeleteMessageComponent, {
      width: '419px',
      data: {
        templateName: messageNome,
        handleDeleteTemplate: () => {
          this.handleDeleteTemplate(idTemplate);
        },
      }
    })
  }

  openModalSucess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: 'O template foi excluído com sucesso.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalPreview(templateSelected: any) {
    this.dialog.open(ModalPreviewMessageComponent, {
      width: '960px',
      height: '900px',
      data: {
        previewContent: templateSelected?.mensagem,
        rotinas: templateSelected?.rotinaEmail,
        destinatarios: templateSelected?.destinatariosEmail,
        anexos: templateSelected?.anexosTemplates,
        templateName: templateSelected?.nome,
        openEditTemplate: () => { this.currentFlow = 'newMessage'; }
      }
    })
  }


}

<section *ngIf="dataSource" class="container-content">
  <div>
    <div class="button-back">
      <yfs-link-back text="Voltar para plano do cliente" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
      <h1>DETALHES DA LIBERAÇÃO</h1>
    </div>

    <div *ngIf="showMessage()" class="general-container">
      <mat-card>
        <mat-card-content class="message-card">
          <span class="message-plan"><mat-icon
            aria-hidden="false"
            aria-label="Ícone de atenção"
            class="material-symbols-outlined"
            fontIcon="warning"
          ></mat-icon>Este plano não tem dados bancários cadastrados</span>
          <span class="sub-message"><a (click)="openBankEdit()">Clique aqui</a> para cadastrar e continuar com a devolução</span>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="side-container">
      <mat-card class="flutuante-card">
        <mat-card-content>
          <div class="content-side-card">
              <span class="total-liberar">Total a devolver</span>
              <span class="valor">{{ dataSource.totalLiberar | currency}}</span>
              <span class="valor">{{ valorAcumulado | currency }}</span>
            <yfs-button [state]="showMessage() || isCleanDisabled ? 'disabled' : 'enabled' " [press]="openModalComplete" label="CONCLUIR DEVOLUÇÃO" type="primary" color="white" [fullWidth]="isFullWidth"></yfs-button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

   <div class="general-container">
    <mat-card>
      <mat-card-content class="card">
        <div class="container-content-product">
          <div class="container-product">
            <div class="image-box">
              <img alt="imagem do produto" [src]="convertBase64 + dataSource.imagemProduto" />
            </div>
            <span class="product">{{produto.modelo}}</span>
          </div>
          <div class="container-cards">
              <mat-card class="mini-card">
                <span class="h1">Contrato</span>
                <span class="h2">{{dataSource.numeroContrato}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Nome do cliente</span>
                <span class="h2">{{dataSource.cliente}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Data de adesão</span>
                <span class="h2">{{dataSource.dataAdesao | date: 'dd/MM/yyyy'}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">CPF do cliente</span>
                <span class="h2">{{cpfMask(dataSource.cpfCliente)}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Status do plano</span>
                <div class="status">
                <mat-icon aria-hidden="false" aria-label="Ícone de status"
                      [style.color]="getIconColor(dataSource.statusContrato)" class="material-symbols-outlined"
                      [fontIcon]="getIcon(dataSource.statusContrato)"></mat-icon>
                <span class="h2">{{getStatusDescription(dataSource.statusContrato)}}</span>
                </div>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Revenda</span>
                <span class="h2"
                 > <strong>{{ getNome(dataSource.canalVenda) }}</strong></span
                >
                <span class="label"> {{ getCnpj(dataSource.canalVenda) }}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Valor do plano</span>
                <span class="h2">{{dataSource.valorContratacao | currency}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Vendedor</span>
                <span class="h2">{{dataSource.nomeVendedor}}</span>
              </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="flutuante-card-wrapper" id="flutuante-card-wrapper">
    <div *ngIf="showHistoric > 0" class="historic-table">
      <mat-card style="box-shadow: 0px 1px 4px #00000052">
        <mat-card-content>
          <div style="margin-top: 10px" class="scroll-container scrollable-table-container" #scrollContainer id="scrollContainer">
            <table
              mat-table
              [dataSource]="displayedData"
              #firstTable #firstTableSort="matSort" matSort
              (matSortChange)="announceSortChange($event)"
            >

              <caption>
                HISTÓRICO DE TARIFAS
              </caption>

              <caption class="sub-caption">
                Selecione a tarifa que deseja devolver
              </caption>

              <caption class="clean-selection" (click)="cleanSelection()" [ngClass]="{'clean-disabled': isCleanDisabled}">X  LIMPAR SELEÇÃO</caption>

              <ng-container matColumnDef="selecionar">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by selecionar"
                >
                  Selecionar
                </th>
                <td mat-cell *matCellDef="let tarifa">
                  <mat-checkbox
                  color="primary"
                  (change)="selectRow(tarifa, $event)"
                  [checked]="tarifasSelecionadas.includes(tarifa)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="tipo">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by tipo"
                >
                  Tipo
                </th>
                <td mat-cell *matCellDef="let tarifa">{{ tarifa.tipo }}</td>
              </ng-container>

              <ng-container matColumnDef="descricaoTarifa">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by descricaoTarifa"
                >
                  Descritivo
                </th>
                <td mat-cell *matCellDef="let tarifa">
                  {{ tarifa.descricaoTarifa }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dataCadastro">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by dataCadastro"
                >
                  Data
                </th>
                <td mat-cell *matCellDef="let tarifa">
                  {{ tarifa.dataCadastro | date:'dd/MM/yyyy' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="valorTarifa">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by valorTarifa"
                >
                  Valor
                </th>
                <td mat-cell *matCellDef="let tarifa">{{ tarifa.valorTarifa | currency }}</td>
              </ng-container>

               <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="showRefundTable > 0" class="general-container">
      <mat-card style="box-shadow: 0px 1px 4px #00000052">
        <mat-card-content>
          <div style="margin-top: 10px;" class="scroll-container scrollable-table-container" #scrollContainer id="scrollContainer">
            <table
              mat-table
              [dataSource]="displayedDataRefund"
              #secondTable #secondTableSort="matSort" matSort
              (matSortChange)="announceSortChange($event)"
            >

              <caption>
                DEVOLUÇÃO DE TARIFAS COM INCONSISTÊNCIAS NO PAGAMENTO
              </caption>

              <ng-container matColumnDef="numeroRequisicao">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by numeroRequisicao"
                >
                  Requisição
                </th>
                <td mat-cell *matCellDef="let tarifa">{{ tarifa.numeroRequisicao }}</td>
              </ng-container>

              <ng-container matColumnDef="tarifas">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by tarifas"
                >
                  Tarifas
                </th>
                <td mat-cell *matCellDef="let tarifa">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Ícone informativo"
                    class="table-icon material-symbols-outlined"
                    (click)="openTarifaDetails(tarifa.tarifas)"
                  >
                    info
                  </mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="mensagemErro">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by mensagemErro"
                >
                  Descritivo
                </th>
                <td mat-cell *matCellDef="let tarifa">{{ tarifa.mensagemErro }}</td>
              </ng-container>

              <ng-container matColumnDef="dataCadastro">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by dataCadastro"
                >
                  Data
                </th>
                <td mat-cell *matCellDef="let tarifa">
                  {{ tarifa.dataCadastro | date:'dd/MM/yyyy' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="valorRequisicao">
                <th
                  style="min-width: 172px;"
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by valorRequisicao"
                >
                  Valor da Reversão
                </th>
                <td mat-cell *matCellDef="let tarifa">{{ tarifa.valorRequisicao | currency }}</td>
              </ng-container>

              <ng-container matColumnDef="download">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by download"
                >

                </th>
                <td mat-cell *matCellDef="let tarifa" class="try-button" (click)="tryAgain(tarifa.idRegistroLiberacaoValor)">Realizar nova tentativa</td>
              </ng-container>

               <tr mat-header-row *matHeaderRowDef="displayedColumnsRefund"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumnsRefund;"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    </div>
 </div>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { dateFormat } from 'src/app/shared/date-format';
import { MatPaginatorIntl, PageEvent, MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { NgIf, NgFor, NgClass, CurrencyPipe, DatePipe } from '@angular/common';
import { HeaderComponent } from '../../../components/header/header.component';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { NgxMaskDirective } from 'ngx-mask';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';
import { DateConvertPipe } from '../../../shared/pipes/date-convert.pipe';

interface CategoriaMapeamento {
  [codigo: number]: string;
}

@Component({
    selector: 'yfs-old-tables-tariff',
    templateUrl: './old-tables-tariff.component.html',
    styleUrls: ['./old-tables-tariff.component.scss'],
    standalone: true,
    imports: [NgIf, HeaderComponent, LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatIcon, MatInput, FormsModule, ReactiveFormsModule, MatDatepickerInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, MatSelect, MatCheckbox, NgFor, MatOption, NgClass, MatPaginator, LoadingSpinnerComponent, CurrencyPipe, DatePipe, DateConvertPipe]
})
export class OldTablesTariffComponent implements OnInit {
  public previousScreen: string = '';
  @Output() currentFlowChanged = new EventEmitter<string>();
  @Input() previousFlow: string = "menu"
  public isLoading = false;
  public listHistorico: any;
  public dataInicialLabel: Date | undefined;
  public dataInicial = new FormControl('', [dateFormat.dateValidator]);
  public dataFinalLabel: Date | undefined;
  public dataFinal = new FormControl('', [dateFormat.dateValidator]);
  public id = new FormControl('');
  public status = new FormControl();
  public categoria = new FormControl();
  public selectedStatus: number[] = [];
  public selectedCategories: number[] = [];
  public idsStatus: number[] = [];
  public idsCategorias: number[] = [];
  public checkedAllStatus = false;
  public checkedAllCategories = false;
  public hasValue = true;
  public length = 50;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public listaStatus: any[] = [
    {
      idStatus: 1,
      nome: "Vigente"
    },
    {
      idStatus: 0,
      nome: "Não vigente"
    },
  ];
  public listaCategorias: any[] = [
    {
      idCategoria: 1,
      nome: "Motocicleta"
    },
    {
      idCategoria: 2,
      nome: "Náutica"
    },
  ]
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  constructor(private apiService: ApiHttpService,
    public dialog: MatDialog,
    private customPaginator: MatPaginatorIntl,
    private router: Router) {
    customPaginator.itemsPerPageLabel = "Itens por página";
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  ngOnInit(): void {
    this.getHistoricParameterTariff()
  }

  getBack() {
    if (this.previousFlow === 'menu' && this.previousScreen !== 'parameters') {
      this.router.navigate(['area']);
    } else if (this.previousScreen === 'parameters' && this.previousFlow === 'menu') {
      this.router.navigate(['parameters']);
    } else {
      this.currentFlowChanged.emit('parameter');
    }
  }

  getBackButtonText(): string {
    if (this.previousFlow === 'menu' && this.previousScreen !== 'parameters') {
      return 'Voltar para minha área';
    } else if (this.previousScreen === 'parameters' && this.previousFlow === 'menu') {
      return 'Voltar para parâmetros técnicos';
    } else {
      return 'Voltar para cadastro de parâmetros';
    }
  }
  getTipoDescription(valueType: number): string {
    const dePara = {
      1: 'Valor',
      2: 'Saldo reserva',
      3: 'Valor da parcela',
      4: 'Valor do plano',
    } as { [key: number]: string };


    return dePara[valueType] || '';
  }

  setPageStart() {
    this.pageIndex = 0;
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  getIcon(vigente: boolean) {
    return vigente == false ? 'close' : 'check_circle'
  }

  getIconColor(vigente: boolean) {
    return vigente == false ? 'var(--color-neutral-gray-4)' : 'var(--color-functional-positive-1)'
  }

  getTextColor(vigente: boolean) {
    return vigente == false ? 'var(--color-neutral-gray-4)' : 'var(--color-neutral-gray-1)'
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getHistoricParameterTariff()
  }

  formatId() {
    const idValue = this.id.value;
    if (idValue) {
      this.id.setValue(idValue.replace(/\D/g, ''))
    }
  }

  onInputChangeInicial(isCalendar: boolean) {
    if (this.dataInicial.value?.length === 0) {
      this.dataInicialLabel = undefined;
    }

    if (this.dataInicial && this.dataInicial.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataInicial2 = this.dataInicial.value ?
        this.getDateByString(this.dataInicial.value) : null;

      if (!isCalendar) {
        this.dataInicialLabel = dataInicial2 || undefined
      }
    }
  }

  onInputChangeFinal(isCalendar: boolean) {
    if (this.dataFinal.value?.length === 0) {
      this.dataFinalLabel = undefined;
    }

    if (this.dataFinal && this.dataFinal.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataFinal2 = this.dataFinal.value ?
        this.getDateByString(this.dataFinal.value) : null;

      if (!isCalendar) {
        this.dataFinalLabel = dataFinal2 || undefined
      }
    }
  }

  searchHistoricOnInputDateChange(data: string) {
    const isValidDate = data === 'inicial' ? this.dataInicial.valid : this.dataFinal.valid;

    if (isValidDate) {
      this.setPageStart();
      this.getHistoricParameterTariff();
    }
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  selectAllStatus() {
    if (this.selectedStatus.length === this.listaStatus.length) {
      this.selectedStatus = [];
      this.idsStatus = [];
      this.checkedAllStatus = false;
    } else {
      this.selectedStatus = this.listaStatus.map(status => status.idStatus);
      this.idsStatus = this.selectedStatus.slice();
      this.checkedAllStatus = true;
    }
    this.status.setValue(this.selectedStatus);
    this.setPageStart();
    this.getHistoricParameterTariff();
  }

  selectAllCategories() {
    if (this.selectedCategories.length === this.listaCategorias.length) {
      this.selectedCategories = [];
      this.idsCategorias = [];
      this.checkedAllCategories = false;
    } else {
      this.selectedCategories = this.listaCategorias.map(categoria => categoria.idCategoria);
      this.idsCategorias = this.selectedCategories.slice();
      this.checkedAllCategories = true;
    }
    this.categoria.setValue(this.selectedCategories);
    this.setPageStart();
    this.getHistoricParameterTariff();
  }

  checkHandleStatus(idStatus: number) {
    if (this.selectedStatus.includes(idStatus)) {
      this.selectedStatus = this.selectedStatus.filter(item => item !== idStatus);
    } else {
      this.selectedStatus.push(idStatus);
    }

    this.idsStatus = this.selectedStatus.slice();

    if (this.selectedStatus.length === this.listaStatus.length) {
      this.checkedAllStatus = true;
    } else {
      this.checkedAllStatus = false;
    }
    this.status.setValue(this.selectedStatus);
    this.setPageStart();
    this.getHistoricParameterTariff();
  }

  checkHandleCategories(idCategoria: number) {
    if (this.selectedCategories.includes(idCategoria)) {
      this.selectedCategories = this.selectedCategories.filter(item => item !== idCategoria);
    } else {
      this.selectedCategories.push(idCategoria);
    }

    this.idsCategorias = this.selectedCategories.slice();

    if (this.selectedCategories.length === this.listaCategorias.length) {
      this.checkedAllCategories = true;
    } else {
      this.checkedAllCategories = false;
    }
    this.categoria.setValue(this.selectedCategories);
    this.setPageStart();
    this.getHistoricParameterTariff();
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  getHistoricParameterTariff() {
    const idValue = this.id.value ?? "";
    let dataInicialFormatada = "";
    let dataFinalFormatada = "";
    let vigente = undefined;
    if (this.dataInicial.value) {
      const datadataBaseObj = this.getDateByString(this.dataInicial.value);
      dataInicialFormatada = this.formatDate(datadataBaseObj);
    }
    if (this.dataFinal.value) {
      const datadataBaseObj = this.getDateByString(this.dataFinal.value);
      dataFinalFormatada = this.formatDate(datadataBaseObj);
    }
    if (this.idsStatus.length == 1) {
      if (this.idsStatus[0] == 1) vigente = true
      else vigente = false;
    }
    const categoria = this.idsCategorias
    this.isLoading = true;
    this.apiService.getHistoricParameterTariff(idValue, dataInicialFormatada, dataFinalFormatada, vigente, this.pageIndex + 1, this.pageSize, categoria).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.listHistorico = result.items;
          if (this.listHistorico && this.listHistorico.length === 0) this.hasValue = false
          else this.hasValue = true;
          this.length = result.totalCount
          this.customPaginator.getRangeLabel = this.getRangeLabel

        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }
}

import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationExtras } from '@angular/router';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { EUfs } from 'src/app/shared/enums/profile-access.enum';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { NgFor, NgIf } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-register-delivery-concessionaire',
    templateUrl: './register-delivery-concessionaire.component.html',
    styleUrls: ['./register-delivery-concessionaire.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardContent, MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, NgFor, MatOption, NgIf, MatError, MatInput, MatAutocompleteTrigger, MatAutocomplete, ButtonComponent, LoadingSpinnerComponent]
})
export class RegisterDeliveryConcessionaireComponent {
  @Output() currentFlowChanged = new EventEmitter<string>();
  public listUfs: any[] = [];
  public listCities: any[] = [];
  public estado = new FormControl('', [Validators.required]);
  public cidade = new FormControl({ value: '', disabled: true }, [Validators.required]);
  public concessionarias = new FormControl({ value: '', disabled: true }, [Validators.required]);
  public listaConcessionarias: any[] = [];
  public isLoading: boolean = false;
  public locations: any[] = [];
  public isFullWidth = window.innerWidth < 820;

  constructor(private apiService: ApiHttpService, public dialog: MatDialog, private router: Router, public userService: UserService) { }

  ngOnInit(): void {
    this.getEstadosCidades();
    this.setupValueChanges();
  }

  setupValueChanges() {
    this.estado.valueChanges.subscribe(estado => {
      this.updateCidades(estado || '');
    });

    this.cidade.valueChanges.subscribe(cidade => {
      this.concessionarias.setValue('');
      this.concessionarias.disable();
      this.listaConcessionarias = [];
      if (cidade) {
        this.loadConcessionarias();
      }
    });

    this.loadInitialState();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 820;
  }

  getEstadosCidades(): void {
    this.apiService.getEstadosCidadesEntrega().subscribe(
      (result: any) => {
        this.locations = result;
        this.listUfs = result.map((item: any) => ({ key: item.estado, value: item.estado }));
        this.loadInitialState();
      }
    );
  }

  loadInitialState(): void {
    if (localStorage.getItem("local")) {
      const local = JSON.parse(atob(localStorage.getItem('local') || '{}'));
      this.estado.setValue(local.estado);
      this.updateCidades(local.estado);

      setTimeout(() => {
        if (local.cidade) {
          this.cidade.setValue(local.cidade);
          this.loadConcessionarias();
        }
      }, 0);
    }

    if (localStorage.getItem("concessionaria")) {
      const concessionaria = JSON.parse(atob(localStorage.getItem('concessionaria') || '{}'));
      setTimeout(() => {
        this.concessionarias.setValue(concessionaria);
        this.concessionarias.enable();
      }, 0);
    }
  }

  updateCidades(estado: string): void {
    const selectedEstado = this.locations.find(item => item.estado === estado);
    if (selectedEstado) {
      this.listCities = selectedEstado.cidades.map((cidade: any) => ({ key: cidade, value: cidade }));
      this.cidade.enable();
    } else {
      this.listCities = [];
      this.cidade.disable();
    }
    this.cidade.setValue('');
    this.listaConcessionarias = [];
    this.concessionarias.setValue('');
    this.concessionarias.disable();
  }

  loadConcessionarias(): void {
    const estado = this.estado.value || '';
    const cidade = this.cidade.value || '';
    this.apiService.getConcessionariaEntrega(estado, cidade).subscribe(
      (result: any) => {
        this.listaConcessionarias = result.body;
        this.concessionarias.enable();
      },
      (error: any) => {
        this.listaConcessionarias = [];
        this.concessionarias.setValue('');
        this.concessionarias.disable();
      }
    );
  }

  displayFn(concessionaria?: any): string {
    return concessionaria ? concessionaria.razaoSocial : '';
  }

  nextStep = () => {
    if (this.concessionarias.disabled) {
      this.proceedToNextStep();
    }

    if (this.concessionarias.valid && this.concessionarias.value !== null) {
      this.proceedToNextStep();
    }
  }

  proceedToNextStep() {
    let concessionaria: any = this.concessionarias.value;

    const localData = {
      estado: this.estado.value,
      cidade: this.cidade.value
    }

    const concessionariaData = {
      idConcessionaria: concessionaria.idConcessionaria,
      razaoSocial: concessionaria.razaoSocial,
    };

    localStorage.setItem("concessionaria", btoa(JSON.stringify(concessionariaData)));
    localStorage.setItem("local", btoa(JSON.stringify(localData)));

    this.currentFlowChanged.emit("verifyData");
  }

  goBack() {
    this.currentFlowChanged.emit("contact");
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }
}

<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
  </div>
  <h1>RELATÓRIO DE LIBERAÇÃO DE VALORES</h1>
  <h2 class="subtitle">
    Utilize os filtros abaixo para selecionar os critérios para a geração do
    relatório.
  </h2>
  <h2 class="subtitle second-subtitle">
    Por padrão, a data preenchida é a de hoje e somente datas até o dia atual podem ser selecionadas.
  </h2>
  <mat-card class="card" *ngIf="!success">
    <div>
      <mat-tab-group (selectedIndexChange)="changeTab($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-label">Data e situação</span>
          </ng-template>
          <mat-card-content class="card-content" style="overflow: hidden">
            <div *ngIf="emptyDocument && !generationError">
              <img src="assets/svg/empty.svg" alt="documento vazio" />
              <div class="message-empty-document">
                <span>Nenhum resultado encontrado para os critérios selecionados. Por
                  favor, tente novamente com outras opções.</span>
              </div>
            </div>
            <div class="check-period">
              <mat-checkbox color="primary" [(ngModel)]="checkedAllPeriod" (change)="allPeriodChange()"><span>Selecionar
                  todo periodo</span></mat-checkbox>
            </div>
            <mat-form-field class="inputs" appearance="fill">
              <mat-label>Data inicial</mat-label>
              <input type="hidden" [disabled]="checkedAllPeriod" [matDatepicker]="pickerDataInicial" [max]="maxDate"
                [(ngModel)]="dataInicialLabel" (ngModelChange)="onInputChange(true)" />
              <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDate" [formControl]="dataInicial"
                [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'" (input)="onInputChange(false)" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerDataInicial"></mat-datepicker-toggle>
              <mat-datepicker #pickerDataInicial></mat-datepicker>
              <mat-error *ngIf="dataInicial.hasError('required')">
                Este campo não pode ficar vazio
              </mat-error>
              <mat-error class="date-error" *ngIf="dataInicial.hasError('invalidDate')">Data inválida.</mat-error>
              <mat-error class="date-error" *ngIf="dataInicial.hasError('dataValida')">Data inválida. Selecione uma data
                até o dia atual.</mat-error>
            </mat-form-field>
            <mat-form-field class="inputs" appearance="fill">
              <mat-label>Data final</mat-label>
              <input type="hidden" [disabled]="checkedAllPeriod" [matDatepicker]="pickerDataFinal" [max]="maxDate"
                [(ngModel)]="dataFinalLabel" (ngModelChange)="onInputChange(true)" />
              <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDate" [formControl]="dataFinal"
                [ngModel]="dataFinalLabel | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChange(false)" />
              <mat-datepicker-toggle matIconSuffix [for]="pickerDataFinal"></mat-datepicker-toggle>
              <mat-datepicker #pickerDataFinal></mat-datepicker>
              <mat-error *ngIf="dataFinal.hasError('required')">
                Este campo não pode ficar vazio
              </mat-error>
              <mat-error class="date-error" *ngIf="dataFinal.hasError('invalidDate')">Data inválida.</mat-error>
              <mat-error class="date-error" *ngIf="dataFinal.hasError('dataValida')">Data inválida. Selecione uma data
                até o dia atual.</mat-error>
            </mat-form-field>
            <mat-form-field id="situacao" appearance="fill">
              <mat-label>Situação</mat-label>
              <mat-select #mySel [formControl]="situacao" multiple>
                <div class="select-all" (click)="selectAll()">
                  <mat-checkbox [(ngModel)]="checkedAll" [indeterminate]="interteminate" color="primary"
                    (change)="selectAll()"><span>Todos</span></mat-checkbox>
                </div>
                <mat-option *ngFor="let situacao of situacaoList" [value]="situacao.value" (click)="checkHandle()">{{
                  situacao.description }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="recipient-content">
              <mat-label style="display: block;">Formato do arquivo</mat-label>
              <mat-radio-group aria-label="Formato do arquivo"  class="inputs" fxLayout="column"  appearance="fill" [(ngModel)]="reportFormat">
                <mat-radio-button style="margin-left: -10px;" value="pdf">PDF</mat-radio-button>
                <mat-radio-button value="xlsx">XLS</mat-radio-button>
              </mat-radio-group>
            </div>
            <span class="date-error" style="margin-right: 40px" *ngIf="dateError && !checkedAllPeriod"><img
                src="assets/svg/atention.svg" alt="icone de atenção" /> A data
              final não pode ser anterior à data inicial.</span>
            <yfs-button (click)="click('dates')" [state]="isDisabled()" label="GERAR RELATÓRIO" class="btn-generate"
              type="primary"></yfs-button>
            <span *ngIf="generationError" data-test="error-message-file" class="error-message">
              <img src="assets/svg/atention.svg" alt="icone de atenção" />
              Não foi possível gerar o relatório nesse momento.
            </span>
            <span *ngIf="generationError" data-test="error-message-file" class="error-try-again">
              Tente novamente mais tarde.
            </span>
          </mat-card-content>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-label">Número do contrato</span>
          </ng-template>
          <mat-card-content class="card-content">
            <div *ngIf="emptyDocument && !generationError">
              <img src="assets/svg/empty.svg" alt="documento vazio" />
              <div class="message-empty-document">
                <span>Nenhum resultado encontrado para os critérios selecionados. Por
                  favor, tente novamente com outras opções.</span>
              </div>
            </div>
            <mat-form-field class="inputs" appearance="fill">
              <mat-label>N° Contrato</mat-label>
              <input [formControl]="contrato" matInput />
            </mat-form-field>
            <div class="recipient-content">
              <mat-label style="display: block;">Formato do arquivo</mat-label>
              <mat-radio-group aria-label="Formato do arquivo"  class="inputs" fxLayout="column"  appearance="fill" [(ngModel)]="reportFormat">
                <mat-radio-button style="margin-left: -10px;" value="pdf">PDF</mat-radio-button>
                <mat-radio-button value="xlsx">XLS</mat-radio-button>
              </mat-radio-group>
            </div>
            <yfs-button (click)="click('contract')" state="enabled" label="GERAR RELATÓRIO" class="btn-generate"
              type="primary"></yfs-button>
            <span *ngIf="generationError" data-test="error-message-file" class="error-message">
              <img src="assets/svg/atention.svg" alt="icone de atenção" />
              Não foi possível gerar o relatório nesse momento.
            </span>
            <span *ngIf="generationError" data-test="error-message-file" class="error-try-again">
              Tente novamente mais tarde.
            </span>
          </mat-card-content>
        </mat-tab>
      </mat-tab-group>

    </div>
  </mat-card>
  <mat-card class="card" *ngIf="success">
    <mat-card-content class="card-content">
      <img src="assets/svg/success.svg" />
      <div class="card-message">
        <div class="message">
          <span>Arquivo gerado com sucesso!</span>
        </div>
        <div class="message">
          <span>Você já pode fazer o download abaixo.</span>
        </div>
      </div>
      <yfs-button data-test="button-download" class="btn-download" (click)="downloadFile()" state="enabled"
        label="BAIXAR ARQUIVO" type="primary"></yfs-button>
      <div>
        <a (click)="openDialog()">GERAR OUTRO ARQUIVO</a>
      </div>
      <div class="atention">
        <span>(O arquivo atual será apagado)</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
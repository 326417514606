<span class="title-products">Selecione o modelo desejado:</span>
<div class="container-content">
  <div data-test="card-products" class="card-products" *ngFor="let product of productsList">
      <img alt="imagem com o produto" [src]="convertBase64 + product.imagemBase64" class="card-image" />
      <h1 [attr.data-test]="'modelo-' + product.modelo">{{product.modelo}}</h1>
      <h2>{{product.valorReferencia | currency : 'R$'}}</h2>
      <div class="overlap-card-products" (click)="changeCurrentFlow(product.idProduto)">
          <span class="select-text">Selecionar</span>
      </div>
  </div>
</div>
<yfs-alert-box *ngIf="listIsEmpty || timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

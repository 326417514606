import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { NgIf } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RecaptchaModule } from 'ng-recaptcha';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { SimulationCategoryComponent } from './simulation-category/simulation-category.component';
import { SimulationProductViewComponent } from './simulation-product-view/simulation-product-view.component';
import { SimulationProductsComponent } from './simulation-products/simulation-products.component';
import { SimulationContactComponent } from './simulation-contact/simulation-contact.component';
import { AlertBoxComponent } from '../../components/alert-box/alert-box.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

interface IRelationBackFlow {
  [key: string]: string;
}

@Component({
    selector: 'yfs-simulation',
    templateUrl: './simulation.component.html',
    styleUrls: ['./simulation.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, NgIf, MatCard, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatError, RecaptchaModule, ButtonComponent, SimulationCategoryComponent, SimulationProductViewComponent, SimulationProductsComponent, SimulationContactComponent, AlertBoxComponent, LoadingSpinnerComponent]
})

export class SimulationComponent implements OnInit, OnDestroy {
  public invalidName = false;
  public invalidCheck = false;
  public token = "";
  public name = new FormControl("", [Validators.required]);
  public siteKey = environment.reCaptchaSiteKey;
  public currentFlow = "contact";
  public relationBackFlow: IRelationBackFlow = { contact:"home", start: "home", category: "contact", product: "category", productView: 'product' }
  public productsList: any;
  public idProduct = 0;
  public isLoading = false;
  public retrie = 500;
  public timeoutError = false;
  public RETRIELIMIT = 30000;
  public recaptchaReady = false;
  public back = '';
  public role = '';
  public permissions: string[] = [];
  private tokenSubscription: any;

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef, private userService: UserService) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      const state = navigation?.extras.state as { flow: string };
      if(state.flow){
        this.currentFlow = state.flow;
      }
    }
  }

  ngOnInit() {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.role = user.PerfilAcesso;
      this.permissions = user.PermissaoAcesso || [];
    }
    this.verifyRole();
    if (this.currentFlow === "start" && !this.isLogged()) {
      this.verifyRecapcha();
    }
    this.tokenSubscription = this.userService.getLoggedUserObservable().subscribe(user => {
      const userLocal: any = user;
      if (userLocal && this.currentFlow == 'contact') {
        this.currentFlow = 'category';
      }
    });
  }

  ngOnDestroy() {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
  }

  verifyRole() {
    if (this.role === 'Vendedor' || this.role === 'Master' || this.role === 'Vendedor Representante' || this.role === 'Master Representante' || this.role === 'Produtos' || this.hasPermission('PERMISSAO_ACESSO_DIRETO_CATEGORIA')) {
      this.currentFlow = "category";
      this.back = "Voltar para a página inicial";
      this.token = "";
    } else {
      this.currentFlow = "contact";
      this.verifyFlow();
    }
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  verifyFlow() {
    switch (this.currentFlow) {
      case 'start':
        this.back = "Voltar para a página inicial";
        break;
      case "category":
        this.back = "Voltar para inserção do nome";
        break;
      case "product":
        this.back = "Voltar para a seleção de categoria";
        break;
      case "productView":
        this.back = "Voltar para a seleção de produto";
        break;
      default:
        this.back = "Voltar para a página inicial";
        break;
    }
  }

  verifyRecapcha() {
    this.isLoading = true;
    if ((window as any)['grecaptcha']) {
      this.verifyRecapchaIsReady()
    } else {
      this.handleRetrieRecapcha(() => this.verifyRecapcha())
    }
  }

  verifyRecapchaIsReady() {
    (window as any).grecaptcha.ready(() => {
      this.isLoading = false;
      this.recaptchaReady = true;
      this.changeDetectorRef.detectChanges()
    })
    if (!this.recaptchaReady) {
      this.handleRetrieRecapcha(() => this.verifyRecapchaIsReady())
    }
  }

  handleRetrieRecapcha(func: () => void) {
    if (this.retrie <= this.RETRIELIMIT) {
      setTimeout(() => {
        this.retrie += 500;
        func()
      }, 500);
    } else {
      this.isLoading = false;
      this.timeoutError = true;
    }
  }

  nameIsInvalid(): boolean {
    const value = this.name.value;
    if (value === null || value.trim() === "") {
      this.name.setValue("");
      this.name.markAsTouched();
      return true;
    }
    return false;
  }

  submitForm() {
    this.invalidName = this.nameIsInvalid();
    this.invalidCheck = !this.isLogged() && this.token.length === 0;

    if (!this.invalidName && !this.invalidCheck) {
      this.currentFlow = "category"
      this.token = ""
    }
    this.verifyFlow();
  }

  changeCurrentFlowContact() {
    this.currentFlow = "category";
    this.verifyFlow();
  }

  changeCurrentFlow(event: { flow: string, productsList: any }) {
    this.currentFlow = event.flow;
    this.productsList = event.productsList;
    this.verifyFlow();
  }

  changeCurrentFlowProduct(event: { flow: string, idProduct: number }) {
    this.idProduct = event.idProduct;
    this.currentFlow = event.flow;
    this.verifyFlow();
    window.scrollTo(0, 0);
  }

  getBackHandle() {
    if (this.currentFlow === "start" || this.currentFlow === 'contact') {
      this.router.navigate(["home"]);
    } else if (this.currentFlow === "category" && (this.isLogged())) {
      this.router.navigate(["home"]);
    } else if (this.currentFlow === "product" && (this.isLogged())) {
      this.currentFlow = "category";
      this.back = "Voltar para a página inicial";
      this.token = "";
    } else {
      const flowToGo = this.relationBackFlow[this.currentFlow];
      this.currentFlow = flowToGo;
      this.verifyFlow();
    }
    if (this.currentFlow === "start" && !this.isLogged()) {
      this.verifyRecapcha();
    }
  }

  resolved(captchaResponse: string | null) {
    if (captchaResponse) {
      this.token = captchaResponse
      this.invalidCheck = false;
    } else {
      this.token = "";
      this.invalidCheck = true;
    }
  }

  isLogged() {
    return this.userService.userIsLogged();
  }
}

<mat-card>
    <mat-card-content>
        <span class="title">Contato</span>

        <div class="container-content">
            <div class="alert-box">
                <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
                    fontIcon="info"></mat-icon>
                <span>Será enviado um <strong>código de validação</strong> para seu celular para confirmar que ele é
                    seu.</span>
            </div>

            <div class="inputs-container">
                <div>
                    <mat-form-field appearance="fill" id="nome">
                        <mat-label>Nome completo</mat-label>
                        <input data-test="input-name-person-data"
                        inputmode="text"
                        [formControl]="nomeCompleto"
                        matInput
                        maxlength="35"
                        (input)="sanitizeInput($event)"
                        (paste)="handlePaste($event)"
                        pattern="[a-zA-ZÀ-ÖØ-öø-ÿ]+( [a-zA-ZÀ-ÖØ-öø-ÿ]+)*" />
                        <mat-error *ngIf="nomeCompleto.hasError('required')">
                            O campo é obrigatório
                        </mat-error>
                        <mat-error *ngIf="nomeCompleto.hasError('maxlength')">
                            O limite de caracteres é de 35
                        </mat-error>
                        <mat-error *ngIf="nomeCompleto.hasError('invalidName')">Nome incompleto</mat-error>
                    </mat-form-field>

                    <mat-form-field class="inputs" appearance="fill" id="cpf">
                        <mat-label>CPF</mat-label>
                        <input data-test="input-cpf" matInput mask="000.000.000-00" placeholder=""
                            (input)="validateCpf($event)" [formControl]="cpf" required>
                        <mat-error *ngIf="cpf.hasError('required')">O campo é obrigatorio</mat-error>
                        <mat-error *ngIf="cpf.value && cpf.hasError('cpfInvalid')">CPF não existe</mat-error>
                        <mat-error *ngIf="cpf.hasError('invalidCpf')">CPF não existe</mat-error>
                        <mat-error *ngIf="cpf.hasError('cpfUsed')">O cliente já possui cadastro</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="inputs" appearance="fill" id="email">
                        <mat-label>E-mail</mat-label>
                        <input data-test="input-email" [formControl]="email" matInput maxlength="50">
                        <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                            Insira um e-mail válido
                        </mat-error>
                        <mat-error *ngIf="email.invalid && email.hasError('required')">
                            O campo é obrigatorio
                        </mat-error>
                        <mat-error *ngIf="email.hasError('maxlength')">
                            O limite de caracteres é de 50
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="inputs" appearance="fill" id="data">
                        <mat-label>Data de nascimento</mat-label>
                        <input type="hidden" [matDatepicker]="picker" [max]="maxDate" [(ngModel)]="dataLabel">
                        <input data-test="input-name-born-data" matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDate"
                            [formControl]="dataNascimento" [ngModel]="dataLabel | date:'dd/MM/yyyy'">

                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="dataNascimento.hasError('required')">O campo é obrigatório</mat-error>
                        <mat-error *ngIf="dataNascimento.hasError('invalidDate')">A data é inválida</mat-error>
                        <mat-error *ngIf="dataNascimento.hasError('dataValida')">
                            Idade mínima não permitida. Somente pessoas que completarão 18 anos dentro de 8 meses podem
                            se cadastrar.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="inputs" appearance="fill" id="celular">
                        <mat-label>Celular com DDD</mat-label>
                        <input data-test="input-phone-cell-ddd" [formControl]="celularComDdd"
                            mask="(00) 0 0000-0000" matInput>
                        <mat-error *ngIf="celularComDdd.invalid">
                            O campo é obrigatorio
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>

            <div class="button-container">
                <yfs-button state="enabled" label="CANCELAR" type="tertiary" [fullWidth]="isFullWidth" (click)="goToHome()"></yfs-button>
                <div>
                    <yfs-button state="enabled" label="VOLTAR" type="secondary" [fullWidth]="isFullWidth" (click)="goToHome()"></yfs-button>
                    <yfs-button [state]="isButtonEnabled()" label="VALIDAR CELULAR" type="primary" [fullWidth]="isFullWidth" [press]="openDialogConfirm"></yfs-button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

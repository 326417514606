import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';

interface IDataModalInfoCancel {
  title: string;
  secondaryText?: string;
  icon: string;
  primaryButtonAction: any;
  primaryButtonText: string;
  primaryButtonVariant: string;
  secundaryButtonAction?: any;
  secundaryButtonText?: string;
  secundaryButtonVariant?: string;
}

@Component({
    selector: 'yfs-modal-info-cancel',
    templateUrl: './modal-info-cancel.component.html',
    styleUrls: ['./modal-info-cancel.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, ButtonComponent]
})

export class ModalInfoCancelComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalInfoCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataModalInfoCancel
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  handlePrimaryButton() {
    if (this.data.primaryButtonAction === 'close')
      this.closeModal()
    else {
      this.data.primaryButtonAction()
    }
  }

  handleSecundaryButton() {
    if (this.data.secundaryButtonAction === 'close')
      this.closeModal()
    else {
      this.closeModal()
      this.data.secundaryButtonAction()
    }
  }

  getIcon() {
    let urlIcon = 'assets/svg/'
    switch (this.data.icon) {
      case 'warning':
        return urlIcon + 'warning-icon.svg';
      case 'waiting':
        return urlIcon + 'waiting-icon.svg';
      case 'error':
        return urlIcon + 'error-icon.svg';
      case 'info':
        return urlIcon + 'info-icon.svg';
      case 'success':
        return urlIcon + 'success.svg';
      default:
        return urlIcon + 'warning-icon.svg'
    }
  }
}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { ButtonComponent } from '../buttons/button/button.component';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { CnpjPipe } from '../../shared/pipes/cnpj.pipe';

@Component({
    selector: 'yfs-modal-list-concessionaires',
    templateUrl: './modal-list-concessionaires.component.html',
    styleUrls: ['./modal-list-concessionaires.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatIcon, MatInput, FormsModule, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatRadioGroup, MatRadioButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, ButtonComponent, NgIf, LoadingSpinnerComponent, CnpjPipe]
})
export class ModalListConcessionairesComponent {
  public search = "";
  public isLoading = false;
  public pageSizeOptions = [5, 10, 20];
  public listaConcessionarias: any;
  public selectedConcessionaria: any;
  displayedColumns: string[] = ['razaoSocial', 'cnpj'];
  dataSource: any

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private customPaginator: MatPaginatorIntl,
    public dialogRef: MatDialogRef<ModalListConcessionairesComponent>,
    private apiService: ApiHttpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getConcessionarias();
    customPaginator.itemsPerPageLabel = "Itens por página";
  }

  closeModal() {
    this.dialogRef.close();
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  selectConcessionaria = () => {
    this.dialogRef.close();
    this.data?.callbackHandle(this.selectedConcessionaria)
  }

  getConcessionarias() {
    this.isLoading = true;
    this.apiService.getConcessionaria(undefined, [1], 1).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource<any>(result);
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.dataSource.sort = this.firstTableSort;
          }, 100)
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            let dataStr = JSON.stringify(data).toLowerCase();
            return dataStr.indexOf(filter) != -1;
          };
          this.customPaginator.getRangeLabel = this.getRangeLabel
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  applyFilter() {
    this.dataSource.filter = this.search.trim().toLowerCase();
  }

}

import { Component, HostListener } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-account-generation',
    templateUrl: './modal-account-generation.component.html',
    styleUrls: ['./modal-account-generation.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatIcon, ButtonComponent]
})
export class ModalAccountGenerationComponent {

  public isFullWidth = window.innerWidth < 1279;

  constructor(
    public dialogRef: MatDialogRef<ModalAccountGenerationComponent>,
    public dialog: MatDialog
    ){}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
  }

  close() {
      this.dialogRef.close();
    }

}

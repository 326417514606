import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-upload-term',
    templateUrl: './modal-upload-term.component.html',
    styleUrls: ['./modal-upload-term.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, ButtonComponent]
})
export class ModalUploadTermComponent {
  public fileSizeError: boolean = false;
  public srcResult: any;
  public fileName: any;
  public termo: string | undefined;
  constructor(
    public dialogRef: MatDialogRef<ModalUploadTermComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileSelected(event: any) {
    const inputNode = event.target;
    const selectedFile = inputNode.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5242880) {
        this.fileSizeError = true;
      } else {
        this.fileSizeError = false;
      }

      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        this.srcResult = reader.result;
        this.termo = this.srcResult.replace("data:application/pdf;base64,", '');
      };

      this.fileName = selectedFile.name;
    }
  }

  onRemoveFile() {
    this.fileName = null;
    const inputNode = document.querySelector('input[type="file"]') as HTMLInputElement;
    inputNode.value = null ?? '';
    this.fileSizeError = false;
  }

  handleUploadFile = () => {
    this.dialogRef.close();
    this.data.uploadTerm(this.termo)
  }
  
}

<yfs-header></yfs-header>
<section class="container-content">
    <div class="button-back">
        <yfs-link-back text="Voltar para minha área" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
        <h1>TABELA DE USUÁRIOS</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <yfs-button *ngIf="typePerfil === 'Master'" state="enabled" label="CADASTRAR USUÁRIO" type="primary" icon="person_add" (click)="goToRegisterExternalUser()"></yfs-button>
            <yfs-button *ngIf="typePerfil !== 'Master'" state="enabled" label="CADASTRAR USUÁRIO" type="primary" icon="person_add" [matMenuTriggerFor]="menuAcoes"></yfs-button>
            <mat-menu #menuAcoes="matMenu">
                <button mat-menu-item (click)="goToRegisterInternalUser()" *appHasAuthorization="addInternalUserPermission">
                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined" fontIcon="person_add"></mat-icon>
                    <span class="label-menu">USUÁRIO INTERNO</span>
                </button>
                <button mat-menu-item (click)="goToRegisterExternalUser()" *appHasAuthorization="addExternalUserPermission">
                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined" fontIcon="person_add"></mat-icon>
                    <span class="label-menu">USUÁRIO EXTERNO</span>
                </button>
            </mat-menu>
            <div class="inputs-content">
                <mat-form-field id="search" appearance="fill">
                    <mat-label>Busque por nome do usuário</mat-label>
                    <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                        class="search-icon material-symbols-outlined" fontIcon="search"></mat-icon>
                    <input [formControl]="nomeUsuario" (focusout)="setPageStart(); this.searchUsers()" matInput>

                </mat-form-field>
                <mat-form-field id="perfil" appearance="fill">
                    <mat-label>Perfil de acesso</mat-label>
                    <mat-select #perfilAcessoSelect [formControl]="perfilAcesso" multiple>
                        <div class="select-all" (click)="selectAllPerfilAcesso()">
                            <mat-checkbox [(ngModel)]="checkedAllPerfilAcesso"
                                [indeterminate]="selectedPerfilAcesso.length > 0 && selectedPerfilAcesso.length < listaPerfilAcesso.length"
                                color="primary"><span class="select-label">Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let perfilAcesso of listaPerfilAcesso" [value]="perfilAcesso.idPerfilAcesso"
                            (click)="checkHandlePerfilAcesso(perfilAcesso.idPerfilAcesso)"><span class="select-label">{{
                                perfilAcesso.nome }}</span></mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field id="tipo" appearance="fill">
                    <mat-label>Tipo usuário</mat-label>
                    <mat-select #mySel [formControl]="tipoUsuario" multiple>
                        <div class="select-all" (click)="selectAllTipoUsuario()">
                            <mat-checkbox [(ngModel)]="checkedAllTipoUsuario"
                                [indeterminate]="selectedTipoUsuario.length > 0 && selectedTipoUsuario.length < listaTipoUsuario.length"
                                color="primary"><span class="select-label">Todos</span></mat-checkbox>
                        </div>
                        <mat-option *ngFor="let tipoUsuario of listaTipoUsuario" [value]="tipoUsuario.idTipoUsuario"
                            (click)="checkHandleTipoUsuario(tipoUsuario.idTipoUsuario)"><span class="select-label">{{
                                tipoUsuario.nome }}</span></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <table *ngIf="hasValue" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort"
                    matSort (matSortChange)="announceSortChange($event)">

                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by nome">
                            Nome do usuário
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email">
                            E-mail
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="perfilAcesso">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by perfilAcesso">
                            Perfil
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.perfilAcesso?.nome || '-'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ultimoLogin">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by ultimoLogin">
                            Último login
                        </th>
                        <td mat-cell *matCellDef="let element"> {{(element?.dataUltimoLogin | date:'dd/MM/yyyy') || '-' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="concessionaria">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by concessionaria">
                            Concessionária
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element?.usuariosConcessionarias[0]?.concessionaria?.razaoSocial || '-' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="ativo">
                        <th mat-header-cell *matHeaderCellDef>
                            Atividade
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon aria-hidden="false" aria-label="Ícone do cliente" (click)="handleSituationClick(element.ativo, element.nome, element.login)"
                            class="icon-toggle material-symbols-outlined"  [ngClass]="{'icon-toggle-on': element.ativo }" [fontIcon]="element.ativo ? 'toggle_on' : 'toggle_off'"></mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acoes">
                        <th mat-header-cell *matHeaderCellDef>
                            Ações
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon aria-hidden="false" aria-label="Ícone do cliente"
                                class="icon-edit material-symbols-outlined" fontIcon="edit" (click)="sendEditData(element.login, element.interno, element.idPerfilAcesso)"></mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="!hasValue" class="content-empty">
                    <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
                    <div>
                      <span>Nenhum resultado encontrado.</span>
                <span>Tente novamente com outros filtros.</span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="paginator-content">
        <mat-paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" aria-label="Select page"
          [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)">
        </mat-paginator>
      </div>

</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<div class="dialog-content" mat-dialog-content>
  <button *ngIf="data.showCloseButton" mat-dialog-close class="close-button">&times;</button>
    <img [src]="getIcon()" alt="logo modal alert" />

    <div class="dialog-text" *ngIf="data.text || data.textBold">
        <strong *ngIf="data.textBold">{{data.textBold}}</strong>
        <span>{{data.text}}</span>
    </div>

    <div *ngIf="data.secondaryText" [innerHTML]="data.secondaryText" class="dialog-text" [ngStyle]="{'margin-top': (data.text || data.textBold) ? '0px' : '24px'}">
    </div>

    <div *ngIf="data.highlightText || data.highlightTextInner" class="dialog-highlight-area">
        <span class="name">{{data.highlightText}}</span>
        <span *ngIf="data.highlightTextInner" [innerHTML]="data.highlightTextInner"></span>
    </div>

    <div class="button-content">
        <yfs-button [state]="data.primaryButtonDisabled ? 'disabled' : 'enabled'"  [label]="data.primaryButtonText" [type]="data.primaryButtonVariant" [fullWidth]="isFullWidth"
            (click)="handlePrimaryButton()" [color]="data.primaryButtonColor ? data.primaryButtonColor : ''"></yfs-button>
        <yfs-button *ngIf="data.secundaryButtonAction" [state]="data.secundaryButtonDisabled ? 'disabled' : 'enabled'" [label]="data.secundaryButtonText ?? ''" [fullWidth]="isFullWidth"
            [type]="data.secundaryButtonVariant ?? ''" (click)="handleSecundaryButton()" [color]="data.secundaryButtonColor ? data.secundaryButtonColor : ''"></yfs-button>
    </div>
</div>

<div class="container-login">
  <button data-test="button-login-in" class="login-button" (click)="showLogin()" [disableRipple]="true" mat-button>
    <mat-icon class="login-icon">person</mat-icon>Login
  </button>
  <div class="login-card" *ngIf="show">
    <mat-form-field>
      <mat-label>Login</mat-label>
      <input data-test="input-login" matInput [formControl]="login" (ngModelChange)="checkForErrors()">
    </mat-form-field>
    <div class="error message-punctuation" *ngIf="showPunctuationError">
      Não é permitido pontuações
    </div>
    <mat-form-field class="mt-16">
      <mat-label>Senha</mat-label>
      <input data-test="input-password" matInput [formControl]="senha" (ngModelChange)="onLoginChange()" [type]="hide ? 'password' : 'text'">
      <button data-test="button-hide" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon class="visibility-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <div data-test="error-message-login" class="error message" *ngIf="showError">
      Login ou senha inválidos
    </div>
    <yfs-button label="ENTRAR" [state]="showPunctuationError ? 'disabled' : 'enabled'" type="tertiary" [fullWidth]="isFullWidth" class="enter" data-test="button-login" [press]="authentication"></yfs-button>
    <span class="forgot-password" (click)="redirectToForgotPassword()">
      Esqueci minha Senha
    </span>
  </div>
</div>
<div class="mobile-card">
  <div class="login-card-mobile" *ngIf="show">
    <mat-form-field>
      <mat-label>Login</mat-label>
      <input data-test="input-login" matInput [formControl]="login" (ngModelChange)="checkForErrors()">
    </mat-form-field>
    <div class="error message-punctuation" *ngIf="showPunctuationError">
      Não é permitido pontuações
    </div>
    <mat-form-field class="mt-16">
      <mat-label>Senha</mat-label>
      <input data-test="input-password" matInput [formControl]="senha" (ngModelChange)="onLoginChange()" [type]="hide ? 'password' : 'text'">
      <button data-test="button-hide" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon class="visibility-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <div data-test="error-message-login" class="error message" *ngIf="showError">
      Login ou senha inválidos
    </div>
    <yfs-button label="ENTRAR" [state]="showPunctuationError ? 'disabled' : 'enabled'" type="tertiary" class="enter" data-test="button-login" [press]="authentication"></yfs-button>
    <span class="forgot-password" (click)="redirectToForgotPassword()">
      Esqueci minha Senha
    </span>
  </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

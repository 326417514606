import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';


@Component({
    selector: 'yfs-modal-send-email',
    templateUrl: './modal-send-email.component.html',
    styleUrls: ['./modal-send-email.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, ButtonComponent]
})
export class ModalSendEmailComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalSendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  sendEmail() {
    this.closeModal()
    this.data.handleSendEmail()
  }
}

import { Component, Input, ElementRef, Output, EventEmitter, ComponentFactory, ComponentRef, Injector } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SafeHtmlPipe } from "../../shared/pipes/safe-html.pipe";

@Component({
  selector: 'content-viewer',
  template: '<div [innerHTML]="processedContent | safeHtml"></div>',
  standalone: true,
  imports: [SafeHtmlPipe],
})

export class ContentViewer {

  @Input() content: string | null = null;
  @Input() listWildCards: any[] = [];

  private embeddedComponentFactories: Map<string, ComponentFactory<any>> = new Map();
  private embeddedComponentRefs: ComponentRef<any>[] = [];

  @Output() docRendered = new EventEmitter<void>();

  processedContent: string = '';

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges() {
    if (this.content) {
      const contentAsString = this.content || '';
      const updatedContent = this.applyWildcardStyles(contentAsString);

      this.processedContent = updatedContent;
      this.docRendered.emit();
    }
  }

  private applyWildcardStyles(content: string): string {
    const wildcardRegex = /\[%.*?%\]/g;
    return content.replace(wildcardRegex, (match) => {
      return `<span style="background-color: #ccff91;">${match}</span>`;
    });
  }

  ngOnDestroy() {
    this.embeddedComponentRefs.forEach(comp => comp.destroy());
    this.embeddedComponentRefs.length = 0;
  }
}

import { Component, Inject } from '@angular/core';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgFor } from '@angular/common';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCheckbox } from '@angular/material/checkbox';
import { ButtonComponent } from '../buttons/button/button.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-modal-edit-profile',
    templateUrl: './modal-edit-profile.component.html',
    styleUrls: ['./modal-edit-profile.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, MatRadioGroup, MatRadioButton, NgFor, MatCheckbox, ButtonComponent, LoadingSpinnerComponent]
})
export class ModalEditProfileComponent {
  public name = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  public isLoading = false;
  public profileType = new FormControl();
  public permissions: { label: string, control: FormControl }[] = [];
  public hasSelectedItem = true;

  constructor(
    private apiService: ApiHttpService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
      this.getProfilesList(this.data.idEdit);
    }

  getBack() {
    this.router.navigate(['user-profile']);
  }

  openDialogEditError() {
    this.dialog.open(ModalGenericComponent, {
      data: {
        icon: 'error',
        text: `Houve um problema ao tentar editar o perfil.`,
        primaryButtonText: 'VOLTAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.sendRequest()
        }
      }
    })
  }

  openDialogEditSucess = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: `Perfil editado com sucesso.`,
        primaryButtonText: 'IR PRA A LISTA DE PERFIS',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'primary'
      }
    }).afterClosed().subscribe(() => this.reload())
  }

  getProfilesList(idEdit: number) {
    this.isLoading = true;
    this.apiService
      .getAccessProfile(idEdit)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if (error.status === 400 || error.status === 404 || error.status === 500) {
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.name.setValue(this.data.nomeEdit || '');
          this.profileType.setValue(this.data.flagInterno);
          if (result) {
            this.permissions = result.body;
          } else {
            this.permissions = [];
          }
          this.permissions = result.body.map((permission: any) => {
            const control = new FormControl(permission.isflagged);
            control.valueChanges.subscribe(() => this.checkIfAnySelected());
            return{
              idPermissaoAcesso: permission.idPermissaoAcesso,
              label: permission.descricao,
              control: control
            }            
          });
        },
      });
  }

  checkIfAnySelected() {
    const selected = this.permissions.some(dept => dept.control.value === true);
    this.hasSelectedItem = selected;
  }

  checkSelecionado(): boolean {
    return  this.hasSelectedItem;
  }

  sendRequest = () => {
    this.isLoading = true;
    const data = {
      idPerfilAcesso: this.data.idEdit,
      nome: this.name.value,
      interno: this.profileType.value,
      idsPermissaoAcesso: this.permissions
      .filter((permission: any) => permission.control.value)
      .map((permission: any) => permission.idPermissaoAcesso)
    };
    this.apiService
      .editProfile(data)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if(error.status === 400 || error.status === 404 || error.status === 500){
            this.openDialogEditError();
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {

          if (result.status === 200) {
            this.openDialogEditSucess()
            this.isLoading = false;
          }  else {
            this.isLoading = false;
            this.openDialogEditError();
          }
        },
      });
  }

  openDialogCancel() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Você tem certeza que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.`,
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.dialog.closeAll()}
      }
    })
  }

  reload(){
    window.location.reload();
  }

}

<div class="container-title">
    <div class="button-back">
        <yfs-link-back text="Voltar para planos contratados" (click)="getBack('close')"></yfs-link-back>
    </div>
    <h1>INCLUIR / EDITAR DADOS BANCÁRIOS</h1>
</div>
<div class="container-content">
    <mat-card>
        <mat-card-content>
            <span class="title-document">Dados do beneficiário</span>
            <div class="recipient-content">
                <span class="content-title">O beneficiário é um terceiro?</span>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="terceiro" (change)="verifyBankData()">
                    <mat-radio-button style="margin-left: -10px;" (click)="recoverData()" value="nao">Não</mat-radio-button>
                    <mat-radio-button style="margin-left: 40px;" value="sim" (click)="cleanFields()">Sim</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="recipient-person-content">
                <span class="content-title">O beneficiário é uma:</span>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoPessoa" (change)="verifyBankData()">
                    <mat-radio-button [disabled]="terceiro === 'nao'" value="pessoaFisica" (click)="verifyAccountType()">Pessoa física</mat-radio-button>
                    <mat-radio-button [disabled]="terceiro === 'nao'" value="pessoaJuridica" (click)="verifyAccountType()">Pessoa jurídica</mat-radio-button>
                    <mat-radio-button [disabled]="terceiro === 'nao'" value="concessionaria" (click)="handleConcessionariaClick()">Concessionária</mat-radio-button>
                </mat-radio-group>
                <div class="message" *ngIf="tipoPessoa === 'concessionaria'">
                    <p>Os dados apresentados no campo concessionária são os dados bancárias da concessionária que originou a venda. Para recebimento em outra concessionária, selecione a opção <strong>Beneficiário Terceiros</strong> e <strong>Pessoa Jurídica</strong>, para a inclusão dos dados.</p>
                </div>
            </div>
            <div class="account-data-content">
            <span class="title-document">Dados da conta</span>
             <div>
                <mat-form-field id="beneficiario" style="flex-basis: 64%;" appearance="fill">
                    <mat-label>Nome do beneficiário</mat-label>
                    <input type="text" matInput [formControl]="beneficiario" (input)="formatarNome()" [readonly]="terceiro === 'nao'">
                    <mat-error *ngIf="beneficiario.hasError('required')">Campo obrigatório</mat-error>
                  </mat-form-field>
                  <mat-form-field style="flex-basis: 35%;" id="cpf" appearance="fill" *ngIf="tipoPessoa === 'pessoaFisica'">
                    <mat-label>CPF</mat-label>
                    <input matInput [formControl]="cpf" mask="000.000.000-00" [readonly]="terceiro === 'nao'" (ngModelChange)="formatarCPF()">
                    <mat-error *ngIf="cpf.hasError('required')">{{ genericMenssage }}</mat-error>
                    <mat-error *ngIf="cpf.hasError('cpfInvalid') && !cpf.hasError('required')">CPF inválido</mat-error>
                  </mat-form-field>
                  <mat-form-field style="flex-basis: 35%;" id="cnpj" appearance="fill" *ngIf="tipoPessoa !== 'pessoaFisica'">
                    <mat-label>CNPJ</mat-label>
                    <input matInput [formControl]="cnpj" mask="00.000.000/0000-00" [readonly]="terceiro === 'nao'" (input)="formatarCNPJ()">
                    <mat-error *ngIf="cnpj.hasError('required')">{{ genericMenssage }}</mat-error>
                    <mat-error *ngIf="cnpj.hasError('cnpjInvalid') && !cnpj.hasError('required')">CNPJ inválido</mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="bank-data-content">
                <span class="content-title">Tipo da conta</span>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoConta">
                  <mat-radio-button [disabled]="tipoPessoa === 'concessionaria'" style="margin-left: -10px;" value="corrente">Conta Corrente</mat-radio-button>
                  <mat-radio-button [disabled]="tipoPessoa === 'concessionaria'" class="margin-radio" value="poupanca">Conta Poupança</mat-radio-button>
                </mat-radio-group>
                <div>
                  <mat-form-field style="flex-basis: 50%;" id="banco" appearance="fill">
                      <mat-label>Banco</mat-label>
                      <input [matAutocomplete]="auto" [formControl]="banco" type="text" matInput placeholder="Selecione" (click)="clearAndOpenOptions()">
                      <mat-error *ngIf="banco.hasError('required')">{{genericMenssage}}</mat-error>
                      <mat-error *ngIf="banco.hasError('invalidBank')">Banco inválido. Selecione uma opção da lista.</mat-error>
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option?.nome">
                              {{option?.codBanco}} - {{option?.nome}}
                          </mat-option>
                      </mat-autocomplete>
                  </mat-form-field>
                      <mat-form-field style="flex-basis: 15%;" id="agencia" appearance="fill">
                          <mat-label>Agência</mat-label>
                          <input [formControl]="agencia" maxlength="5" matInput>
                          <mat-error *ngIf="agencia.hasError('required')">{{genericMenssage}}</mat-error>
                      </mat-form-field>
                      <mat-form-field style="flex-basis: 25%;" id="agencia" appearance="fill">
                        <mat-label>
                          {{ tipoConta === 'corrente' ? 'Conta Corrente' : tipoConta === 'poupanca' ? 'Conta Poupança' : '' }}
                        </mat-label>
                          <input [formControl]="contaCorrente" maxlength="12" matInput>
                          <mat-error *ngIf="contaCorrente.hasError('required')">{{genericMenssage}}</mat-error>
                      </mat-form-field>
                      <mat-form-field style="flex-basis: 10%;" id="digito" appearance="fill">
                          <mat-label>Dígito</mat-label>
                          <input [formControl]="contaCorrenteDigito" maxlength="2" matInput>
                          <mat-error *ngIf="contaCorrenteDigito.hasError('required')">{{genericMenssage}}</mat-error>
                      </mat-form-field>
                  </div>
              </div>
              <div class="container-buttons">
                <yfs-button [fullWidth]="isFullWidth" label="CANCELAR" type="secondary" (click)="openModalClose()"></yfs-button>
                <yfs-button [fullWidth]="isFullWidth" label="SALVAR" [state]="agencia.hasError('required') || contaCorrente.hasError('required') || contaCorrenteDigito.hasError('required') || banco.hasError('required') || banco.hasError('invalidBank') ? 'disabled' : 'enabled'" type="primary" [press]="openModalSave"></yfs-button>
              </div>
        </mat-card-content>
    </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<div class="modal-container">
  <span class="title">Prévia</span>
  <div id="top" class="content">
    <div class="dialog-content">
      <div class="container-preview">
        <div class="content-preview">
          <content-viewer [content]="safePreviewContent" [listWildCards]="filtedListWildCards"></content-viewer>
        </div>
      </div>
    </div>
    <div class="wild">
      <mat-card class="card-wilds">
        <mat-card-content>
          <div class="title-card-content">
            <span class="title-card">Wildcards</span>
          </div>
          <span class="subtitle-card">
            Os wildcards destacados em verde foram identificados no documento fornecido. Caso haja a necessidade de incluir algum outro, revise o documento adicionando o termo literal dessa lista e faça um novo upload.
          </span>

          <mat-form-field id="search" appearance="fill">
            <mat-label>Busque por wildcards</mat-label>
            <input [(ngModel)]="search" (keyup)="applyFilter()" (keydown.tab)="applyFilter()" (keydown.enter)="applyFilter()" matInput>
            <mat-icon matSuffix aria-hidden="false" aria-label="Ícone de busca" class="search-icon material-symbols-outlined">
              search
            </mat-icon>
          </mat-form-field>

          <div class="wildcard-content">
            <div *ngIf="isLoading">Carregando wildcards...</div>
            <div *ngFor="let wildcard of filtedListWildCards"
              [ngClass]="{
                'wildcard-selected': safePreviewContent?.toString()?.includes(wildcard?.tag),
                'wildcard': !safePreviewContent?.toString()?.includes(wildcard?.tag)
              }">
              {{ wildcard?.tag }}
            </div>
            <div *ngIf="!isLoading && filtedListWildCards.length === 0">Nenhum wildcard encontrado.</div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="button-container">
    <yfs-button state="enabled" label="FECHAR" type="secondary" (click)="closeModal()"></yfs-button>
  </div>
</div>

import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { DataService } from 'src/app/services/data-service';
import { ModalEmailComponent } from '../modal-email/modal-email.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ButtonComponent } from '../buttons/button/button.component';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-modal-confirmation',
    templateUrl: './modal-confirmation.component.html',
    styleUrls: ['./modal-confirmation.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatIcon, MatFormField, MatInput, FormsModule, ReactiveFormsModule, ButtonComponent, NgIf, LoadingSpinnerComponent]
})
export class ModalConfirmationComponent {

  public emailCliente: string = '';
  public selectedIds: number[] = [];
  public dataList: any[] = [];
  public contrato: string = '';
  public cpf: string = '';
  public isLoading = false;
  public email: FormControl = new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email, Validators.maxLength(50)]);

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmationComponent>,
    public dialog: MatDialog,
    private apiService: ApiHttpService,
    private dataService: DataService
  ) {

   }

   ngOnInit() {
    this.dataList = this.dataService.getDataList();
    this.emailCliente = this.dataService.getEmail();
    this.selectedIds = this.dataService.getSelectedIds() || [];
    this.contrato = this.dataService.getContrato();
    this.cpf = this.dataService.getCPF();
    this.email = new FormControl({ value: this.emailCliente, disabled: true }, [Validators.required, Validators.email, Validators.maxLength(50)]);
  }

  confirm() {
    this.isLoading = true;
    this.apiService
      .postReenvioDocumentos(this.contrato, this.selectedIds)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result: any) => {
          this.dialogRef.close();
          this.isLoading = false;
          this.openDialogSucessUpload('O kit documentação foi enviado com sucesso.');
        },
        error: (error: any) => {
          this.dialogRef.close();
          this.isLoading = false;
          this.openDialogErrorDocument();
        },
      });
  }

  openDialogSucessUpload(menssage: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: menssage,
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorDocument() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Algo deu errado e não conseguimos enviar o kit documentação.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }


  back() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ModalEmailComponent, {
      width: '600px',
    });
  }

}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, ViewChild } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl, MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ModalEditProfileComponent } from 'src/app/components/modal-edit-profile/modal-edit-profile.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { LocalValidators } from 'src/app/shared/validators/localValidators.validator';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgFor, NgIf, DatePipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, MatCard, MatCardContent, ButtonComponent, MatFormField, MatLabel, MatIcon, MatInput, FormsModule, ReactiveFormsModule, MatSelect, MatCheckbox, NgFor, MatOption, NgIf, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatTooltip, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, LoadingSpinnerComponent, DatePipe]
})
export class UserProfileComponent implements AfterViewInit {
  public nomeUsuario = new FormControl('');
  public perfilAcesso = new FormControl();
  public tipoUsuario = new FormControl();
  public hasValue = true;
  public isLoading = false;
  public listaUsuarios = [];
  public selectedPerfilAcesso: number[] = [];
  public idsPerfilAcesso: number[] = [];
  public selectedTipoUsuario: number[] = [];
  public idsTipoUsuario: number[] = [];
  public checkedAllTipoUsuario = false;
  public displayedColumns: string[] = ["nome", "dataAlteracao", "acoes"];
  public dataSource = new MatTableDataSource<any>();
  public deleteId = 0;
  public listaPerfilAcesso: any[] = [];
  public selectedLabels: string[] = [];
  public checkedAllPerfilAcesso = false;
  public isFullWidth = window.innerWidth < 820;

  constructor(
    private cd : ChangeDetectorRef,
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
    public dialog: MatDialog,
    private router: Router,
  ) {
  }

  ngAfterViewInit(): void {
   this.cd.detectChanges();
  }

  ngOnInit() {
    this.getProfilesList()
    this.populatePerfilAcesso();
  }

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 820;
  }


  getBack() {
    this.router.navigate(['area']);
  }

  getProfilesList(nome?: string) {
    const Identificador = this.selectedLabels;
    this.isLoading = true;
    this.apiService
      .getListProfile(nome, Identificador)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if (error.status === 400 || error.status === 404 || error.status === 500) {
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.body && result.body.length > 0) {
            this.dataSource.data = result.body;
            this.dataSource.sort = this.firstTableSort;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => {
              if (sortHeaderId === 'nome') {
                return data.nome;
              }
              return data[sortHeaderId];
            };
            this.hasValue = true;
          } else {
            this.hasValue = false;
          }

          this.isLoading = false;
        },
        error: (err) => {
          console.error('Erro ao carregar os perfis:', err);
          this.isLoading = false;
        }
      });
  }


  onSelectPerfil(event: MatSelectChange) {
    const selectedValues = event.value || [];

    if (this.listaPerfilAcesso && selectedValues.length === this.listaPerfilAcesso.length) {
        this.checkedAllPerfilAcesso = true;
        this.selectedLabels = this.listaPerfilAcesso.map((perfil: any) => perfil.label);
    } else {
        this.checkedAllPerfilAcesso = false;
        this.selectedLabels = selectedValues.map((perfil: any) => perfil.label);
    }
}

  populatePerfilAcesso() {
    this.isLoading = true;
    this.apiService
      .getAccessProfile()
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if (error.status === 400 || error.status === 404 || error.status === 500) {
            this.isLoading = false;
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.body) {
            this.listaPerfilAcesso = result.body.map((permission: any) => ({
              idPermissaoAcesso: permission.idPermissaoAcesso,
              label: permission.identificador,
              control: new FormControl(false)
            }));
          }
          this.isLoading = false;
        },
      });
  }

  selectAllPerfilAcesso() {
    if (this.perfilAcesso.value?.length === this.listaPerfilAcesso.length) {
        this.perfilAcesso.setValue([]);
        this.checkedAllPerfilAcesso = false;
        this.selectedLabels = [];
    } else {
        this.perfilAcesso.setValue(this.listaPerfilAcesso.map(perfil => perfil));
        this.checkedAllPerfilAcesso = true;
        this.selectedLabels = this.listaPerfilAcesso.map(perfil => perfil.label);
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  delete(deleteId: number) {
    this.isLoading = true;
    this.apiService.deleteProfile(deleteId).pipe(
      timeout(30000),
      catchError((error) => {
        this.isLoading = false;

        if (error instanceof TimeoutError) {
          this.openDialogDeleteError();
          return throwError(() => 'A requisição demorou muito tempo e foi cancelada.');
        }

        if (error.status === 400 || error.status === 404 || error.status === 500) {
          if (error.error && error.error.Errors && error.error.Errors.length > 0) {
            this.openDialogDeleteErrorWithMessage(error.error.Errors[0]);
          } else {
            this.openDialogDeleteError();
          }
        }

        return throwError(() => error);
      })
    )
    .subscribe({
      next: (result) => {
        if(result.status === 200) {
          this.openDialogDeleteSucess();
          this.deleteId = 0;
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
}

openDialogEdit(idEdit: any, nomeEdit: string, flagInterno: boolean) {
  this.dialog.open(ModalEditProfileComponent, {
    width: '600px',
    data:
    {idEdit,
    nomeEdit,
    flagInterno
    }
  });
}

openDialogDelete(data: any) {
  this.deleteId = data.idPerfilAcesso;
  this.dialog.open(ModalGenericComponent, {
    width: '384px',
    data: {
      icon: 'warning',
      text: `Tem certeza que deseja remover o perfil abaixo? Essa ação não poderá ser desfeita.`,
      highlightText: data.nome,
      primaryButtonAction: 'close',
      primaryButtonText: 'VOLTAR',
      primaryButtonVariant: 'secondary',
      secundaryButtonText: 'SIM, REMOVER',
      secundaryButtonVariant: 'primary',
      secundaryButtonColor: 'red',
      secundaryButtonAction: () => {this.dialog.closeAll(); this.delete(this.deleteId)}
    },
  });
}

  openDialogDeleteSucess = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: `Perfil removido com sucesso.`,
        primaryButtonText: 'IR PRA A LISTA DE PERFIS',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'primary'
      }
    }).afterClosed().subscribe(() => this.getProfilesList())
  }

  openDialogDeleteError() {
    this.dialog.open(ModalGenericComponent, {
      data: {
        icon: 'error',
        text: `Houve um problema ao tentar remover o perfil.`,
        primaryButtonText: 'VOLTAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.delete(this.deleteId)}
      }
    })
  }

  openDialogDeleteErrorWithMessage(errorMessage: string) {
    this.dialog.open(ModalGenericComponent, {
      data: {
        icon: 'error',
        text: errorMessage,
        primaryButtonText: 'VOLTAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.delete(this.deleteId) }
      }
    });
}

  goToCreate(){
    this.router.navigate(['create-user-profile']);
  }

}

import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError, Subscription, combineLatest, startWith, skip } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalPreviewDraftComponent } from 'src/app/components/modal-preview-draft/modal-preview-draft.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { HeaderComponent } from '../../../components/header/header.component';
import { NgIf, NgFor, DatePipe, formatDate } from '@angular/common';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { TooltipComponent } from '../../../components/tooltip/tooltip.component';
import { MatIcon } from '@angular/material/icon';
import { NewDraftComponent } from '../new-draft/new-draft.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';
import { EllipsisPipe } from '../../../shared/pipes/ellipsis.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

interface TipoMinuta {
  idTipoMinuta: number;
  descricao: string;
}

@Component({
    selector: 'yfs-list-draft',
    templateUrl: './list-draft.component.html',
    styleUrls: ['./list-draft.component.scss'],
    standalone: true,
    imports: [HeaderComponent, MatTooltipModule, NgIf, LinkBackComponent, MatCard, MatCardContent, ButtonComponent, MatFormField, MatLabel, MatDatepickerInput, FormsModule, MatInput, NgxMaskDirective, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, MatSelect, MatCheckbox, NgFor, MatOption, TooltipComponent, MatIcon, NewDraftComponent, LoadingSpinnerComponent, DatePipe, EllipsisPipe]
})
export class ListDraftComponent {
  public previousScreen: string = '';
  public currentFlow = 'communication'
  public isLoading = false;
  public listWildCards: any
  public filtedListWildCards: any;
  public timeoutError = false;
  public isCardSelected: boolean[] = [false, false];
  public templateList: any[] = [];
  public templates: number = 0;
  public pageSize = 8;
  public pageNumber = 1;
  public showErrorMessage = false;
  public empty = false;
  public emptyDocument = false;
  public templateSelected: any;
  public selectedStatus: number[] = [];
  public status = new FormControl();
  public idsStatus: number[] = [];
  public checkedAllStatus = false;
  public listaStatus: any[] = [
    {
      idStatus: 1,
      nome: "Termo de Adesão"
    },
    {
      idStatus: 2,
      nome: "Termo de Cancelamento"
    },
    {
      idStatus: 3,
      nome: "Termo de Prorrogação"
    },
  ];
  public pageIndex = 0;


  public dataInicialLabel: Date | undefined;
  public dataInicial = new FormControl('');
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public draftOptions: any;
  public idTemplateDelete = '';
  public moreItensData: boolean = false;
  public hasNextPage: any

  constructor(private apiService: ApiHttpService,private router: Router, public dialog: MatDialog) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  ngOnInit(){
    this.getWildCards();
    this.getMinutas();

    this.dataInicial.valueChanges.subscribe(value => {
      this.onFieldChange();
    });

    combineLatest([
      this.dataInicial.valueChanges.pipe(startWith(this.dataInicial.value), skip(1))
    ]).subscribe(() => {
    });
  }

  onFieldChange() {
    this.templateList = [];
    this.pageNumber = 1;
    this.getMinutas();
  }

  onStatusChange(event: any) {
    this.selectedStatus = event;
    if (this.selectedStatus.length === this.listaStatus.length) {
      this.checkedAllStatus = true;
    } else {
      this.checkedAllStatus = false;
    }
    this.getMinutas();
  }

  previewMessage(event: Event, templateSelected: any) {
    event.stopPropagation();
    this.templateSelected = templateSelected;
    this.openModalPreview(templateSelected)
  }

  setPageStart() {
    this.pageIndex = 0;
  }

  goToMessage() {
    this.templateSelected = undefined;
    this.currentFlow = 'newDraft';
  }

  closeNewMessage() {
    this.currentFlow = 'communication'
    this.clearQuery()
    this.getMinutas();
  }

  clearQuery() {
    this.pageNumber = 1;
    this.empty = false;
    this.templateList = []
  }


  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  moreItens() {
    this.pageNumber = this.pageNumber + 1;
    this.moreItensData = true;
    this.getMinutas();
  }

  selectAllStatus() {
    if (this.selectedStatus.length === this.listaStatus.length) {
      this.selectedStatus = [];
      this.idsStatus = [];
      this.checkedAllStatus = false;
    } else {
      this.selectedStatus = this.listaStatus.map(status => status.idStatus);
      this.idsStatus = this.selectedStatus.slice();
      this.checkedAllStatus = true;
    }
    this.status.setValue(this.selectedStatus);
    this.setPageStart();
  }

  getWildCards() {
    this.isLoading = true;
    this.apiService.getWildcards().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result) {
            this.listWildCards = result.wildcards;
            this.filtedListWildCards = result.wildcards;
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }


  getMinutas() {
    const dataInicial = this.dataInicial.value || '';
    const tipoMinuta = this.selectedStatus || '';

    const formattedDate = dataInicial ? this.formatDateToApiFormat(dataInicial) : '';

    this.isLoading = true;

    this.apiService.getTemplatesMinuta('', this.pageNumber, this.pageSize, formattedDate, tipoMinuta)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            this.timeoutError = true;
            return throwError(() => "A requisição demorou muito tempo e foi cancelada.");
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: result => {
          if (result.items.length === 0 && this.templateList.length === 0) {
            this.isLoading = false;
            this.emptyDocument = true;
            return;
          }

          if (result.items.length === 0 && result.hasNextPage === false) {
            this.isLoading = false;
            this.moreItensData = false;
            this.hasNextPage = false;
            return;
          }

          if (result?.items?.length > 0) {
            if (this.moreItensData) {
              this.templateList = [...this.templateList, ...result.items];
            } else {
              this.templateList = [...result.items];
            }

            this.templates = result.totalCount;
            this.isLoading = false;
            this.emptyDocument = false;
            this.moreItensData = false;
            this.hasNextPage = result.hasNextPage;
          }
        },
        error: error => {
          this.isLoading = false;
          this.showErrorMessage = true;
          if (this.timeoutError === false) {
            console.log(error);
          }
        }
      });
  }

  formatDateToApiFormat(date: string): string {
    const dateParts = date.split('/');
    const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T00:00:00`);
    return formatDate(formattedDate, 'yyyy-MM-ddTHH:mm:ss', 'en-US');
  }

  tiposDeMinuta() {
    this.apiService.getTipoMinuta().subscribe({
      next: (tipos: TipoMinuta[]) => {
        this.draftOptions = tipos.map((tipo) => ({
          value: tipo.idTipoMinuta,
          nome: tipo.descricao,
        }));
      },
      error: (erro) => {
        console.error('Erro ao carregar os tipos de minuta', erro);
      }
    });
  }

  openModalPreview(templateSelected: any) {
    this.dialog.open(ModalPreviewDraftComponent, {
      height: '800px',
      width: '90vw',
      panelClass: 'preview-draft-dialog',
      data: {
        previewContent: templateSelected?.template,
        nomenclatura: templateSelected?.nomenclatura,
        dataVigenciaInicial: templateSelected?.dataInicioVigencia,
        dataVigenciaFinal: templateSelected?.dataFinalVigencia,
        tipoMinuta: templateSelected?.idTipoMinuta,
        listWildCards: this.listWildCards,
      }
    })
  }

  openModalDelete(message: any) {
    this.idTemplateDelete = message?.idTemplateMinuta;
    this.dialog.open(ModalGenericComponent, {
      width: '482px',
      data: {
        text: 'Tem certeza que deseja excluir a minuta abaixo?',
        highlightText: message?.nomenclatura,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.deleteMinuta() },
        secundaryButtonText: 'SIM, EXCLUIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalSuccess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: 'Minuta excluida com sucesso.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR PARA LISTA DE MINUTAS',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalErrorVigente(message: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: message,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openDialogError() {
    this.dialog.open(ModalGenericComponent, {
      width: '397px',
      data: {
        icon: 'error',
        secondaryText: 'Não foi possível excluir a minuta nesse momento. Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.deleteMinuta() }
      },
    });
  }

  deleteMinuta() {
    this.isLoading = true;
    this.apiService.deleteMinutaTemplate(this.idTemplateDelete).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => 'A requisição demorou muito tempo e foi cancelada.');
        }
        return throwError(() => error);
      })
    ).subscribe({
      next: (result) => {
        this.isLoading = false;
        this.openModalSuccess()
      },
      error: (error) => {
        this.isLoading = false;
        if (error?.error?.Errors && Array.isArray(error.error.Errors) && error.error.Errors.length > 0) {
          this.isLoading = false;
          this.openModalErrorVigente(error.error.Errors[0]);
        } else this.openDialogError()
        console.log(error);
      },
    });
  }


}

<div class="dialog-content" mat-dialog-content>

  <img [src]="getIcon()" alt="logo modal alert" />

  <div class="dialog-title">
      <span>{{ data.title }}</span>
  </div>

  <div class="dialog-text">
      <span>O cancelamento do plano poderá ser feito por meio de contato telefônico com a Central de Relacionamento com o Cliente através do número <strong>(11) 2088-7700</strong> (segunda à sexta-feira das 8h às 18h) ou através do envio de e-mail para <strong>liberacred&#64;yamaha-motor.com.br</strong></span>
  </div>

  <div *ngIf="data.secondaryText" [innerHTML]="data.secondaryText" class="dialog-text" style="margin-top: 0px;">
  </div>

  <div class="button-content">
      <yfs-button state="enabled" [label]="data.primaryButtonText" [type]="data.primaryButtonVariant"
          (click)="handlePrimaryButton()"></yfs-button>
      <yfs-button *ngIf="data.secundaryButtonAction" state="enabled" [label]="data.secundaryButtonText ?? ''"
          [type]="data.secundaryButtonVariant ?? ''" (click)="handleSecundaryButton()"></yfs-button>
  </div>
</div>

<div class="container-title">
  <div class="button-back">
      <yfs-link-back text="Voltar para plano do cliente" (click)="getBack()"></yfs-link-back>
  </div>
  <h1>ALTERAR CANAL DE RELACIONAMENTO</h1>
</div>
<mat-card>
  <mat-card-content>
<span class="title-document">Concessionária atual</span>
<div class="container-content">
  <div class="content">
    <div class="form-row">
      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Concessionária</mat-label>
        <input matInput [formControl]="concessionariaAtual">
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-field">
        <mat-label>CNPJ</mat-label>
        <input matInput [formControl]="cnpj" mask="00.000.000/0000-00">
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Endereço</mat-label>
        <input matInput [formControl]="endereco">
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Contato</mat-label>
        <input matInput [formControl]="celularComDdd">
      </mat-form-field>
    </div>
  </div>
</div>
</mat-card-content>
<mat-card-content>
  <span class="title-document">Selecione concessionária nova</span>
  <div class="container-content">
    <div class="content">
      <div class="form-row">
      <mat-form-field appearance="fill">
        <mat-label>Estado</mat-label>
        <mat-select data-test="select-uf" [formControl]="estado">
          <mat-option *ngFor="let enum of listUfs" [value]="enum.key">{{enum.value}}</mat-option>
        </mat-select>
        <mat-error *ngIf="estado.invalid">
          O campo é obrigatorio
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Cidade</mat-label>
        <mat-select data-test="select-city" [formControl]="cidade" [disabled]="!estado.value">
          <mat-option *ngFor="let city of listCities" [value]="city.key">{{city.value}}</mat-option>
        </mat-select>
        <mat-error *ngIf="cidade.invalid">
          O campo é obrigatorio
        </mat-error>
      </mat-form-field>
    </div>

      <mat-form-field appearance="fill">
        <mat-label>Concessionária</mat-label>
        <input matInput [matAutocomplete]="auto" [formControl]="concessionariaNova" placeholder="Selecione" [disabled]="!cidade.value">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let concessionaria of listaConcessionarias" [value]="concessionaria">
            {{ concessionaria.razaoSocial }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="concessionariaNova.invalid">
          Selecione uma concessionária.
        </mat-error>
      </mat-form-field>

      <div class="form-row">
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Endereço</mat-label>
          <input matInput [formControl]="enderecoNovo">
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Contato</mat-label>
          <input matInput [formControl]="celularComDddNovo">
        </mat-form-field>
      </div>
    </div>
      <div class="container-buttons">
        <div class="divisor-content-next">
          <yfs-button [fullWidth]="isFullWidth" state="enabled" label="CANCELAR" type="secondary" (click)="getBack()"></yfs-button>
          <yfs-button [fullWidth]="isFullWidth" [state]="concessionariaNova.hasError('required') || !estado.value || !cidade.value || !concessionariaNova.value ? 'disabled' : 'enabled'" label="SALVAR" type="primary" [press]="openModalUpdate"></yfs-button>
        </div>
    </div>
  </div>
  </mat-card-content>
</mat-card>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

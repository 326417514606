import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatCard, MatCardContent } from '@angular/material/card';
import { SealEligibilityComponent } from '../seal-eligibility/seal-eligibility.component';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../buttons/button/button.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { CpfPipe } from '../../shared/pipes/cpf.pipe';
import { TelefonePipe } from '../../shared/pipes/telefone.pipe';

@Component({
    selector: 'yfs-modal-client-detail',
    templateUrl: './modal-client-detail.component.html',
    styleUrls: ['./modal-client-detail.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatCard, SealEligibilityComponent, MatCardContent, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, ButtonComponent, CurrencyPipe, DatePipe, CpfPipe, TelefonePipe]
})
export class ModalClientDetailComponent implements OnInit {
  public contractedPlan: any;
  public dataSource2: any = [];
  public displayedColumns2: string[] = [
    'numero',
    'valor',
    'dataVencimento',
    'dataLimitePagamento',
    'dataPagamento',
    'pontual',
    'status',
  ];

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public dialogRef: MatDialogRef<ModalClientDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @ViewChild('secondTableSort')
  secondTableSort!: MatSort;


  ngOnInit() {
    this.dataSource2 = new MatTableDataSource(this.data?.detail?.recargas);
  }

  ngAfterViewInit() {
    this.dataSource2.sort = this.secondTableSort;
    this.dataSource2.sortingDataAccessor = (data: any, sortHeaderId: any) => {
      if (sortHeaderId === 'status') {
        return data.statusParcelaDescricao;
      }
      return data[sortHeaderId];
    };
  }

  announceSortChange2(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getPontualidadeColor(value: boolean) {
    switch (value) {
      case true:
        return 'var(--color-functional-positive-1)';
      case false:
        return 'var(--color-functional-danger-2)';
      default:
        return 'var(--color-neutral-gray-4)';
    }
  }

  getPontualidadeIcon(value: boolean) {
    switch (value) {
      case true:
        return 'check';
      case false:
        return 'close';
      case null:
        return 'check_indeterminate_small';
      default:
        return 'check_indeterminate_small';
    }
  }

  getStatusName(status: string) {
    if (status === 'EmAberto') return 'Em Aberto';
    if (status === 'EmCartorio') return 'Em Cartorio';
    return status;
  }
}

<yfs-header></yfs-header>
<section class="container-content">
    <div class="button-back">
        <yfs-link-back text="Voltar para minha área" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
        <h1>PERFIS DE USUÁRIO</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <yfs-button state="enabled" [fullWidth]="isFullWidth" label="CADASTRAR PERFIL" type="primary" icon="person_add" (click)="goToCreate()"></yfs-button>
            <div class="inputs-content">
              <mat-form-field id="search" appearance="fill">
                <mat-label>Busque por nome do perfil</mat-label>
                <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                          class="search-icon material-symbols-outlined"
                          fontIcon="search"
                          (click)="getProfilesList(nomeUsuario.value || undefined)">search</mat-icon>
                <input [formControl]="nomeUsuario"
                       (keyup.enter)="getProfilesList(nomeUsuario.value || undefined)"
                       matInput>
              </mat-form-field>
              <mat-form-field id="perfil" appearance="fill">
                <mat-label>Perfil de acesso</mat-label>
                <mat-select #perfilAcessoSelect [formControl]="perfilAcesso" multiple
                            (selectionChange)="onSelectPerfil($event)"
                            (closed)="getProfilesList()">
                  <div class="select-all" (click)="selectAllPerfilAcesso()">
                    <mat-checkbox [(ngModel)]="checkedAllPerfilAcesso"
                                  [indeterminate]="(perfilAcesso.value?.length || 0) > 0 && perfilAcesso.value?.length < listaPerfilAcesso.length"
                                  color="primary">
                      <span class="select-label">Todos</span>
                    </mat-checkbox>
                  </div>
                  <mat-option *ngFor="let perfil of listaPerfilAcesso" [value]="perfil">
                    <span class="select-label">{{ perfil.label }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="scrollable-table-container">
                <table *ngIf="hasValue" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort"
                    matSort (matSortChange)="announceSortChange($event)">

                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by nome">
                            Nome do perfil
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dataAlteracao">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by dataAlteracao">
                            Última alteração
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element?.dataAlteracao ? (element.dataAlteracao | date:'dd/MM/yyyy' ) + ' às ' + (element.dataAlteracao | date:'HH:mm') : '-' }}
                          <mat-icon
                          *ngIf="element?.dataAlteracao"
                          aria-hidden="false"
                          aria-label="Ícone do cliente"
                          class="icon-info material-symbols information"
                          fontIcon="info"
                          matTooltip="Última alteração feita por {{element.emailUsuarioAlteracao}} em {{element.dataAlteracao | date:'dd/MM/yyyy'}} às {{element.dataAlteracao | date:'HH:mm'}}.">
                        </mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acoes">
                        <th mat-header-cell *matHeaderCellDef>
                            Ações
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon aria-hidden="false" aria-label="Ícone do cliente"
                                class="icon-edit material-symbols-outlined" fontIcon="edit" (click)="openDialogEdit(element.idPerfilAcesso, element.nome, element.interno)"></mat-icon>
                                <mat-icon aria-hidden="false" aria-label="Ícone do cliente"
                                class="icon-delete material-symbols-outlined delete" fontIcon="delete" (click)="openDialogDelete(element)"></mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div *ngIf="!hasValue" class="content-empty">
                    <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
                    <div>
                      <span>Nenhum resultado encontrado.</span>
                      <span>Tente novamente com outros filtros.</span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

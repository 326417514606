import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { HeaderComponent } from '../../components/header/header.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    imports: [HeaderComponent, MatCard, MatCardContent, NgIf, ButtonComponent, MatFormField, MatLabel, MatInput, NgxMaskDirective, FormsModule, ReactiveFormsModule, MatError, MatIcon, MatIconButton, MatSuffix, LoadingSpinnerComponent]
})
export class ForgotPasswordComponent {

  public hide = false;
  public cpf = new FormControl('', [Validators.required, CpfValidator.cpf]);
  public novaSenha = new FormControl('', [Validators.required]);
  public senhaConfirmacao = new FormControl('', [Validators.required]);
  public hideNovaSenha = true;
  public hideSenhaConfirmacao = true;
  public isLoading = false;
  public validatesPassword = {
    size: 'default',
    smallLetters: 'default',
    capitalLetters: 'default',
    number: 'default',
    specialCharacter: 'default'
  }
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public flow: 'forgot' | 'reset' | 'invalid' | null = null;
  public hash: string | null = '';
  public invalidState: 'invalid' | 'expired' = 'invalid';

  constructor(private route: ActivatedRoute, public dialog: MatDialog, private userService: UserService, private router: Router) {
    this.route.paramMap.subscribe(params => {
      this.hash = params.get('hash');
      if (this.hash) {
        this.validationHash()
      } else {
        this.flow = 'forgot';
      }
    })
  }

  goHome() {
    this.router.navigate(['home']);
  }

  validatePassword(fromInput: boolean) {
    if (this.novaSenha.value == '') {
      this.cleanValidates()
      return;
    }
    const value = this.novaSenha.value || ''
    const stateType = fromInput ? 'default' : 'invalid'

    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharacterRegex = /[!@#$%*]/;

    this.validatesPassword.size = value.length >= 8 && value.length <= 56 ? 'valid' : this.validatesPassword.size === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.smallLetters = lowercaseRegex.test(value) ? 'valid' : this.validatesPassword.smallLetters === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.capitalLetters = uppercaseRegex.test(value) ? 'valid' : this.validatesPassword.capitalLetters === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.number = numberRegex.test(value) ? 'valid' : this.validatesPassword.number === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.specialCharacter = specialCharacterRegex.test(value) ? 'valid' : this.validatesPassword.specialCharacter === 'invalid' ? 'invalid' : stateType;

    if (this.validatesPassword.smallLetters == 'valid'
      && this.validatesPassword.capitalLetters == 'valid'
      && this.validatesPassword.number == 'valid'
      && this.validatesPassword.specialCharacter == 'valid'
      && this.validatesPassword.size == 'valid') {
      this.novaSenha.setErrors(null);
    } else {
      this.novaSenha.setErrors({ 'invalidPassword': true });
    }

  }

  cleanValidates() {
    this.validatesPassword = {
      size: 'default',
      smallLetters: 'default',
      capitalLetters: 'default',
      number: 'default',
      specialCharacter: 'default'
    }

  }

  checkPasswords() {
    if (this.senhaConfirmacao.value == '') return

    if (this.novaSenha.value === this.senhaConfirmacao.value) {
      this.senhaConfirmacao.setErrors(null);
    } else {
      this.senhaConfirmacao.setErrors({ 'invalidPassword': true });
    }
  }

  redefinePassword() {
    this.novaSenha.markAllAsTouched()
    this.senhaConfirmacao.markAllAsTouched()
    this.validatePassword(false)
    this.checkPasswords()
    if (this.novaSenha.valid && this.senhaConfirmacao.valid) {
      this.sendReset();
    }
  }

  getColor(parameter: string) {
    if (parameter === 'valid') return '#27AE60'
    else if (parameter === 'invalid') return 'var(--color-functional-danger-2)'
    else return 'var(--color-neutral-gray-3)'
  }

  getIcon(parameter: string) {
    if (parameter === 'valid') return 'check_circle'
    else if (parameter === 'invalid') return 'close'
    else return 'remove'
  }

  buttonValidaIsEnabled() {
    if (this.cpf.valid) return 'enabled';
    return 'disabled';
  }

  handleButtonValidationClick = () => {
    if (this.buttonValidaIsEnabled() == 'enabled') {
      this.sendValidation();
    }
  } 

  validationHash() {
    this.isLoading = true;

    this.userService.forgotPasswordConfirmHash(this.hash).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.flow = 'reset';
        },
        error: (error) => {
          this.isLoading = false;
          if (error?.error?.Errors && error?.error.Errors[0] == 'Registro expirado para o token informado.') {
            this.invalidState = 'expired';
          } else this.invalidState = 'invalid';
          this.flow = 'invalid';
          console.log(error);
        },
      });
  }

  sendValidation() {
    this.isLoading = true;

    this.userService.forgotPassword(this.cpf.value?.replace(/[^0-9]/g, '')).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSucessValidation();
        },
        error: (error) => {
          this.isLoading = false;
          this.openDialogErrorValidation();
          console.log(error);
        },
      });
  }

  sendReset() {
    this.isLoading = true;
    const newPassword64 = btoa(this.novaSenha.value || '');
    this.userService.forgotPasswordChange(newPassword64, this.hash || '').pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result && result?.isValidPassword)
          this.openDialogSucessReset();
          else 
          this.openDialogErrorReset('Por favor, informe uma senha que atenda os critérios necessários.', true)
        },
        error: (error) => {
          this.isLoading = false;
          this.openDialogErrorReset('Algo deu errado e não conseguimos criar sua nova senha. Tente outra vez ou volte mais tarde.', false);
          console.log(error);
        },
      });
  }

  openDialogSucessValidation() {
    this.dialog.open(ModalGenericComponent, {
      width: '540px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        icon: 'info',
        textBold: 'Solicitação realizada com sucesso.',
        text: 'Caso os dados de acesso informados estejam válidos, em até 24 horas será enviado uma mensagem ao e-mail cadastrado no LiberaCred contendo as principais instruções para redefinição de senha.',
        secondaryText: 'Verifique eventualmente a caixa de spam caso não localize o e-mail com o título "Redefinição de senha", Liberacred.',
        primaryButtonAction: () => {this.goHome(); this.dialog.closeAll()},
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorValidation() {
    this.dialog.open(ModalGenericComponent, {
      width: '456px',
      data: {
        icon: 'error',
        text: 'Não conseguimos processar sua solicitação. tente novamente em alguns minutos ou retorne aqui mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.handleButtonValidationClick() }
      },
    });
  }

  openDialogSucessReset() {
    this.dialog.open(ModalGenericComponent, {
      width: '383px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        icon: 'success',
        textBold: 'Senha alterada com sucesso',
        text: 'Sua senha foi alterada com sucesso, você já pode fazer login novamente.',
        primaryButtonAction: () => {this.goHome(); this.dialog.closeAll()},
        primaryButtonText: 'FAZER LOGIN',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorReset(mensagem: string, bold?: boolean) {
    this.dialog.open(ModalGenericComponent, {
      width: '383px',
      data: {
        icon: 'error',
        textBold: bold ? 'Senha não alterada.' : null,
        text: mensagem,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.redefinePassword() }
      },
    });
  }

}

import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterStateSnapshot } from '@angular/router';
import { filter } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { MatToolbarRow } from '@angular/material/toolbar';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';
import { LoginComponent } from '../login/login.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { HasAuthorizationDirective } from '../../shared/directives/has-authorization.directive';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'yfs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarRow,
    MatIconButton,
    MatIcon,
    NgIf,
    LoginComponent,
    MatButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    NgClass,
    HasAuthorizationDirective,
    MenuComponent,
    RouterModule
  ],
  providers: [UserService]
})
export class HeaderComponent implements OnInit {
  @Input() flowSeller: boolean = false;

  public userName: string | null = ''
  public role: string | null = ''

 public showPlan = false;
 public consultAllowedPermissions = [EPermissaoAcesso.CONSULTAR_PLANO]
 public registerAllowedPermissions = [EPermissaoAcesso.CONTRATAR_PLANO]
 public comunicationAllowedPermissions = [EPermissaoAcesso.CADASTRAR_MENSAGEM]
 public isMenuVisible: boolean = false;
 public showPlans: boolean = false;
 public showReport: boolean = false;
 public showAccounting: boolean = false;
 public showUser: boolean = false;
 public showParameters: boolean = false;
 public heightMenu: string | undefined;
 public userAllowedPermissions = [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO, EPermissaoAcesso.EDITAR_USUARIO_INTERNO, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO, EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA]
 public addInternalUserPermission = [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO];
 public addExternalUserPermission = [EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO];
 public releaseValuesReportAlowedPermissions = [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES]
 public plansAlowedPermissions = [EPermissaoAcesso.CONSULTAR_PLANO, EPermissaoAcesso.CONTRATAR_PLANO, EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES]
 public generateReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_POSICAO_CONTABIL, EPermissaoAcesso.EXTRAIR_RELATORIO_COMISSAO, EPermissaoAcesso.EXTRAIR_RELATORIO_LIBERACAO_VALORES, EPermissaoAcesso.EXTRAIR_RELATORIO_EXTRATO_CONTRATO, EPermissaoAcesso.EXTRAIR_RELATORIO_INELEGIBILIDADE]
 public generatePositionReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_POSICAO_CONTABIL]
 public generateComissionsReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_COMISSAO]
 public generateReleaseValuesReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_LIBERACAO_VALORES]
 public generateContractExtractReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_EXTRATO_CONTRATO]
 public accountingAlowedPermissions = [EPermissaoAcesso.GERAR_ARQUIVO_CONTABIL]
 public releaseValuesAlowedPermissions = [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES]
 public technicalParametersAllowedPermissions = [EPermissaoAcesso.CADASTRAR_MENSAGEM, EPermissaoAcesso.LISTAR_PRODUTOS, EPermissaoAcesso.LISTAR_PARAMETROS, EPermissaoAcesso.HISTORICO_PARAMETROS, EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA, EPermissaoAcesso.EXTRAIR_RELATORIO_MUDANCA_STATUS, EPermissaoAcesso.GERENCIAR_MINUTA]
 public productsAllowedPermissions = [EPermissaoAcesso.LISTAR_PRODUTOS]
 public parameterTariffAllowedPermissions = [EPermissaoAcesso.LISTAR_PARAMETROS]
 public tariffHistoricAllowedPermissions = [EPermissaoAcesso.HISTORICO_PARAMETROS]
 public ineligibilityReportAllowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_INELEGIBILIDADE]
 public concessionairesAndPartnersAllowedPermissions = [EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA]
 public generateFinancialMovementAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_CONCILIACAO_MOVIMENTACAO]
 public generateChangeReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_MUDANCA_STATUS]
 public manageMinutaAllowedPermissions = [EPermissaoAcesso.GERENCIAR_MINUTA]
 public createProfilePermission = [EPermissaoAcesso.CADASTRAR_PERFIL_USUARIO];
 public generateSegregationReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_SEGREGACAO]
 public showMenu = true;
 public isHomeRoute = false;
 public showComunication = false;
 menuOpen = false;
 showOverlay = false;

  constructor(private cdr: ChangeDetectorRef, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.isLogged();
    this.isHome();
    const user = this.userService.getCurrentUser()
    if (user && user.Nome) {
      this.userName = user.Nome;
      this.role = user.PerfilAcesso
    }
    const observer = new MutationObserver(() => {
      this.setHeight();
      this.cdr.detectChanges();
    });

    observer.observe(document.body, { attributes: true, childList: true, subtree: true });
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    this.showOverlay = !this.showOverlay;
  }

  isLogged() {
    if (this.userService.userIsLogged()) {
      this.showMenu = true;
      this.showPlan = true;
      this.showComunication = true;
    } else {
      this.showMenu = false;
      this.showPlan = false;
      this.showComunication = false;
    }
  }

  isClienteUser(): boolean {
    const user = this.userService.getCurrentUser();
    return user.PerfilAcesso === 'Cliente';
  }

  isHome() {
     const stateUrl = this.router.url;

    if (stateUrl === '/' || stateUrl === '/home' || stateUrl === '/#Saiba' || stateUrl === '/#Requisitos') {
      this.showPlan = false;
      this.showMenu = false;
      this.showComunication = false;
      this.isHomeRoute = true;
    } else {
      if (this.userService.userIsLogged()) {
        this.showPlan = true;
        this.showComunication = true;
      }
    }
  }

  logout() {
    this.userService.logout();
    this.userName = null;
    this.role = null;
    this.router.navigate(["home"])
    this.removeCachedValuesRegister()
  }

  removeCachedValuesRegister() {
    localStorage.removeItem("documentos");
    localStorage.removeItem("produto");
    localStorage.removeItem("dadosPessoais");
    localStorage.removeItem("endereco");
    localStorage.removeItem("contato");
    localStorage.removeItem("concessionaria");
    localStorage.removeItem("local");
    localStorage.removeItem("contatoSimulacao");
    sessionStorage.removeItem("parametros");
  }

  atualizarNome(result: any) {
    this.userName = result.nome;
    this.role = result.role
  }

  goToAccounting() {
    this.router.navigate(["accounting"]);
  }

  goToPositionReport() {
    this.router.navigate(["position-report"]);
  }

  goToCommissions() {
    this.router.navigate(["commissions"]);
  }

  goToArea() {
    this.router.navigate(["area"]);
  }

  goToSimulation() {
    this.router.navigate(["simulation"]);
    this.reloadOnNavigationEnd();
  }

  goToComunication() {
    this.router.navigate(["communication"])
  }

  goToParameters() {
    this.router.navigate(["parameters"])
  }

  goToPlans() {
    if (this.role === 'Cliente') {
      this.router.navigate(["customer-area"])
    } else {
      this.router.navigate(["planos-contratados"])
    }
    this.reloadOnNavigationEnd();
  }

  verifyClienteUser(): boolean {
    const user = this.userService.getCurrentUser();
    return user.PerfilAcesso === 'Cliente';
  }


  togglePlans(show: boolean) {
    this.showPlans = show;
  }

  toggleReports(show: boolean) {
    this.showReport = show;
  }

  toggleAccounting(show: boolean) {
    this.showAccounting = show;
  }

  toggleParameters(show: boolean) {
    this.showParameters = show;
  }

  toggleUser(show: boolean) {
    this.showUser = show;
  }

  toggleMenuPlans(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showPlans = show;
  }

  toggleMenuReports(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showReport = show;
  }

  toggleMenuAccounting(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showAccounting = show;
  }

  toggleMenuUser(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showUser = show;
  }

  toggleMenuParameters(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showParameters = show;
  }


  setHeight() {
    const bodyHeight = document.body.scrollHeight;
    const height = bodyHeight - 117;
    this.heightMenu = `${height}px`;
  }

  goToContractsPlans() {
    this.router.navigate(["planos-contratados"])
  }

  goTofinancialMovement() {
    this.router.navigate(["financial-movement"]);
  }

  goToReleaseValuesReport() {
    this.router.navigate(["release-values-report"])
  }

  goToReleaseValues() {
    this.router.navigate(["release-values"])
  }
  goToContractExtractReport() {
    this.router.navigate(["contract-extract-report"])
  }

  goToMyPlan() {
    this.router.navigate(["customer-area"])
  }

  goToUserList() {
    this.router.navigate(["user-management"])
  }

  goToRegisterInternalUser() {
    this.router.navigate(["create-internal-user"])
  }

  goToRegisterExternalUser() {
    this.router.navigate(["create-external-user"])
  }

  goToProducts() {
    this.router.navigate(["products"])
  }

  goToParameterTariff() {
    this.router.navigate(["parameter-tariff"])
  }

  goToParameterCommission() {
    this.router.navigate(["parameter-commission"])
  }

  goToOldTablesTariff() {
    this.router.navigate(["old-tables-tariff"])
  }

  goToOldTablesCommissions() {
    this.router.navigate(["old-tables-commissions"])
  }

  descriptionAddExternal() {
    const user = this.userService.getCurrentUser();
    if (user.PerfilAcesso === 'Master') return "Cadastrar usuário"
    return "Cadastrar usuário Externo";
  }

  goToDraftList() {
    this.router.navigate(["draft-list"])
  }

  goToIneligibilityReport() {
    this.router.navigate(["ineligibility-report"])
  }

  goToConcessionairesAndPartners() {
    this.router.navigate(["concessionaires-and-partners"])
  }

  goToChangeReport() {
    this.router.navigate(["change-report"])
  }

  goToSegregationReport() {
    this.router.navigate(["segregation-report"]);
  }

  goToHome() {
    this.router.navigate(["home"])
  }

  goToUserProfile() {
    this.router.navigate(["user-profile"])
  }
  reloadOnNavigationEnd(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      window.location.reload();
    });
  }

}

<yfs-header [flowSeller]="true"></yfs-header>
<div class="container-content">
  <div class="button-back">
    <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
  </div>
  <h1>CONCILIAÇÃO DE MOVIMENTAÇÃO FINANCEIRA</h1>
  <h2>
   Utilize os filtros abaixo para selecionar os critérios para a geração do relatório.
  </h2>
  <mat-card class="card" *ngIf="!success">
    <mat-card-content class="card-content">
      <div *ngIf="emptyDocument && !generationError">
        <img src="assets/svg/empty.svg" alt="documento vazio">
        <div class="message-empty-document">
          <span>Nenhum resultado encontrado para os critérios selecionados. Por favor, tente novamente com outras
            opções.</span>
        </div>
      </div>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Início do relatório</mat-label>
        <input type="hidden" [matDatepicker]="pickerInicio" [(ngModel)]="dataLabelInicio"  (ngModelChange)="onInputChangeInicial(true)"/>
        <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false"  [formControl]="dataInicioRelatorio"
          [ngModel]="dataLabelInicio | date : 'dd/MM/yyyy'" (ngModelChange)="onInputChangeInicial(false)" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
        <mat-datepicker #pickerInicio></mat-datepicker>
        <mat-error *ngIf="dataInicioRelatorio.hasError('required')">
          O campo é obrigatorio
        </mat-error>
        <mat-error *ngIf="dataInicioRelatorio.hasError('invalidDate')">A data é inválida</mat-error>
      </mat-form-field>
      <mat-form-field class="inputs" appearance="fill" [ngClass]="{'mat-form-field-invalid': dateError}">
        <mat-label>Fim do relatório</mat-label>
        <input type="hidden" [matDatepicker]="pickerFinal" [(ngModel)]="dataLabelFinal" (ngModelChange)="onInputChangeFinal(true)"/>
        <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [formControl]="dataFimRelatorio"
           (ngModelChange)="onInputChangeFinal(false)" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerFinal"></mat-datepicker-toggle>
        <mat-datepicker #pickerFinal></mat-datepicker>
        <mat-error *ngIf="dataFimRelatorio.hasError('required') && !dateError">
          O campo é obrigatorio
        </mat-error>
        <mat-error *ngIf="dataFimRelatorio.hasError('invalidDate') && !dateError">A data é inválida</mat-error>
      </mat-form-field>
      <span class="date-error" *ngIf="dateError"><img src="assets/svg/atention.svg" alt="icone de atenção" /> A data
        final não pode ser anterior à data inicial.</span>
        <div class="content-radio">
          <div class="recipient-content">
              <span class="content-title">Tipo de relatório:</span>
              <mat-radio-group aria-label="Select an option" [(ngModel)]="reportType" (change)="onReportTypeChange()">
                  <mat-radio-button value="Analitico">Analítico</mat-radio-button>
                  <mat-radio-button value="Sintetico">Sintético</mat-radio-button>
              </mat-radio-group>
          </div>
          <div class="recipient-content">
              <span class="content-title">Formato do arquivo:</span>
              <mat-radio-group aria-label="Select an option" [(ngModel)]="reportFormat">
                  <mat-radio-button value="pdf" [disabled]="reportType === 'Analitico'">PDF</mat-radio-button>
                  <mat-radio-button value="xlsx">XLS</mat-radio-button>
              </mat-radio-group>
          </div>
      </div>
      <yfs-button label="GERAR ARQUIVO" data-test="button-generate" class="btn-generate"
        [press]="sendRequest" type="primary"
        [state]="dateError || dataInicioRelatorio.hasError('invalidDate') || dataFimRelatorio.hasError('invalidDate') ? 'disabled' : 'enabled'" >
      </yfs-button>

      <span *ngIf="generationError" data-test="error-message-file" class="error-message"><img
          src="assets/svg/atention.svg" alt="icone de atenção" /> Não foi possível gerar o
        relatório</span>

    </mat-card-content>
  </mat-card>
  <mat-card class="card" *ngIf="success">
    <mat-card-content class="card-content">
      <img class="icon-success" src="assets/svg/success.svg" alt="logo modal success" />
      <div class="message">
        <span>Relatório gerado com sucesso.</span>
      </div>
      <div class="message">
        <span>Você já pode fazer o download abaixo.</span>
      </div>
      <yfs-button [state]="!dataInicioRelatorio.value && !dataFimRelatorio.value && dataInicioRelatorio.hasError('dataValida') && dataFimRelatorio.hasError('dataValida') ? 'disabled' : 'enabled'"
        data-test="button-download" class="btn-download" icon="download-sharp"  [label]="getFormattedLabel()"  type="primary" [press]="downloadFile">
      </yfs-button>
      <div>
        <yfs-button [state]="!dataInicioRelatorio.value && !dataFimRelatorio.value && dataInicioRelatorio.hasError('dataValida') && dataFimRelatorio.hasError('dataValida') ? 'disabled' : 'enabled'"
        data-test="button-download" class="btn-download" icon="arrow_back" label="VOLTAR" type="tertiary" (click)="back()">
      </yfs-button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>


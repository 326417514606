import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { NgFor, NgIf, CurrencyPipe } from '@angular/common';
import { AlertBoxComponent } from '../../../components/alert-box/alert-box.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-simulation-products',
    templateUrl: './simulation-products.component.html',
    styleUrls: ['./simulation-products.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, AlertBoxComponent, LoadingSpinnerComponent, CurrencyPipe]
})
export class SimulationProductsComponent {
  @Input() productsList: any;
  @Output() currentFlowChanged = new EventEmitter<{ flow: string, idProduct: number }>();
  
  public isLoading = false;
  public listIsEmpty = false;
  public timeoutError = false;
  public genericError = false;
  public convertBase64 = 'data:image/png;base64,'

  constructor() {}

  changeCurrentFlow(idProduct: number) {
    localStorage.setItem("idProduct", idProduct.toString()),
    this.currentFlowChanged.emit({ flow: "productView", idProduct: idProduct })
  }

}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReleaseValueItem, ReleaseValueResponse } from 'src/app/models/release-values.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ELiberacao } from 'src/app/shared/enums/tipo-liberacao-enum';
import { HeaderComponent } from '../../components/header/header.component';
import { NgIf, NgFor, CurrencyPipe, DatePipe } from '@angular/common';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatIconButton } from '@angular/material/button';
import { ReleaseValuesDetailsComponent } from './release-values-details/release-values-details.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

export interface TipoLiberacaoOption {
  label: string;
  value: number;
}

@Component({
    selector: 'yfs-release-values',
    templateUrl: './release-values.component.html',
    styleUrls: ['./release-values.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NgIf, LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatIcon, MatInput, FormsModule, ReactiveFormsModule, MatSelect, NgFor, MatOption, MatIconButton, MatSuffix, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, ReleaseValuesDetailsComponent, LoadingSpinnerComponent, CurrencyPipe, DatePipe]
})
export class ReleaseValuesComponent implements AfterViewInit{

  public searchString = new FormControl('');
  public currentFlow = "releaseValues"
  public pageIndex = 0;
  public isLoading = false;
  public hasValue = true;
  public releaseValueSelected: ReleaseValueItem | undefined
  public tipoLiberacaoOptions: TipoLiberacaoOption[] | undefined;
  public tipoLiberacaoControl = "";
  public tipoLiberacao = new FormControl('');
  public listaDados: ReleaseValueResponse[] | undefined

  dataSource: any

  displayedColumns: string[] = ['numeroContrato', 'nomeCliente', 'canalVenda', 'dataSolicitacaoLiberacaoValor', 'tipoLiberacao', 'valor'];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
  ) {
    this.tipoLiberacaoOptions = [
      {
        label: 'Desistência',
        value: ELiberacao.CONTRATO
      },
      {
        label: 'CDC',
        value: ELiberacao.CDC
      },
      {
        label: 'Plano Quitado - Elegível',
        value: ELiberacao.QUITADO_ELEGIVEL
      },
      {
        label: 'Plano Quitado - Inelegível',
        value: ELiberacao.QUITADO_INELEGIVEL
      },
    ]
   }

   @ViewChild('releaseTableSort') releaseTableSort = new MatSort();

   ngAfterViewInit() {
    this.search()
  }

  setPageStart() {
    this.pageIndex = 0;
    this.search()
  }

  calcularDiasAtras(data: number): string {
    if (data === 0) {
      return `Solicitado hoje`;
    }
    if (data === 1) {
      return `1 dia atrás`;
    }
    else {
      return `${data} dias atrás`;
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  selectClientPlan(row: any) {
    this.releaseValueSelected = row;
    this.currentFlow = "releaseValuesDetails"
  }

  changeCurrentFlow(flow: string) {
    this.search()
    this.currentFlow = flow;
  }

  getBack() {
    this.router.navigate(['area']);
  }

  search() {
    const searchValue = this.searchString.value ?? ""
    const tipoLiberacaoValue = this.tipoLiberacao.value ?? ""

    this.isLoading = true
    this.apiService.getReleaseValues(searchValue, tipoLiberacaoValue).pipe().subscribe((response) => {
      this.isLoading = false
      this.listaDados = response
      this.dataSource = new MatTableDataSource<ReleaseValueResponse>(this.listaDados);
      this.dataSource.sort = this.releaseTableSort;
    })
  }

}

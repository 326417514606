import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SimulationComponent } from './pages/simulation/simulation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from "ng-recaptcha";
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SimulationCategoryComponent } from './pages/simulation/simulation-category/simulation-category.component';
import { SimulationProductsComponent } from './pages/simulation/simulation-products/simulation-products.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApiHttpService } from './services/api-http.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SimulationProductViewComponent } from './pages/simulation/simulation-product-view/simulation-product-view.component'
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu';
import { LOCALE_ID, DEFAULT_CURRENCY_CODE, isDevMode } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { DatePipe, registerLocaleData } from '@angular/common';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { UserService } from './services/user.service';
import { JwtInterceptor } from './helpers/interceptors/jwt.inteceptor';
import { ErrorInterceptor } from './helpers/interceptors/error.interceptor';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RegisterDocumentsComponent } from './pages/register/register-documents/register-documents.component';
import { RegisterPersonalDataComponent } from './pages/register/register-personal-data/register-personal-data.component';
import { RegisterAddressComponent } from './pages/register/register-address/register-address.component';
import { RegisterContactComponent } from './pages/register/register-contact/register-contact.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { VerifyDataComponent } from './pages/register/verify-data/verify-data.component';
import { CpfPipe } from './shared/pipes/cpf.pipe';
import { RgPipe } from './shared/pipes/rg.pipe';
import { DateConvertPipe } from './shared/pipes/date-convert.pipe';
import { CepPipe } from './shared/pipes/cep.pipe';
import { TelefonePipe } from './shared/pipes/telefone.pipe';
import { CelularPipe } from './shared/pipes/celular.pipe';
import { CompleteComponent } from './pages/register/complete/complete.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalCancelComponent } from './components/modal-cancel/modal-cancel.component';
import { AccountingComponent } from './pages/accounting/accounting.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ContractedPlansComponent } from './pages/contracted-plans/contracted-plans.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ClientPlanComponent } from './pages/contracted-plans/client-plan/client-plan.component';
import { ModalGenericComponent } from './components/modal-generic/modal-generic.component';
import { CancelPlanComponent } from './pages/contracted-plans/client-plan/cancel-plan/cancel-plan.component';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalUploadTermComponent } from './components/modal-upload-term/modal-upload-term.component';
import { CommissionsComponent } from './pages/commissions/commissions.component';
import { HasAuthorizationDirective } from './shared/directives/has-authorization.directive';
import { HasRoleDirective } from './shared/directives/has-role.directive';
import { AreaComponent } from './pages/area/area.component';
import { MenuComponent } from './components/menu/menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PositionReportComponent } from './pages/position-report/position-report.component';
import { provideUserIdleConfig } from 'angular-user-idle';
import { environment } from '../environments/environment';
import { ButtonComponent } from './components/buttons/button/button.component';
import { ModalCancelPlanComponent } from './components/modal-cancel-plan/modal-cancel-plan.component';
import { PrivacyMessageComponent } from './components/privacy-message/privacy-message.component';
import { ContractExtractReportComponent } from './pages/contract-extract-report/contract-extract-report.component';
import { ReleaseValuesReportComponent } from './pages/release-values-report/release-values-report.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReleaseValuesComponent } from './pages/release-values/release-values.component';
import { ReleaseValuesDetailsComponent } from './pages/release-values/release-values-details/release-values-details.component';

import { ClientDataComponent } from './pages/contracted-plans/client-data/client-data.component';
import { RefundRateComponent } from './pages/contracted-plans/refund-rate/refund-rate.component';
import { BankDataComponent } from './pages/contracted-plans/client-plan/bank-data/bank-data.component';
import { CommunicationComponent } from './pages/communication/communication.component';
import { NewMessageComponent } from './pages/communication/new-message/new-message.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ModalPreviewMessageComponent } from './components/modal-preview-message/modal-preview-message.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ModalDeleteMessageComponent } from './components/modal-delete-message/modal-delete-message.component';
import { EllipsisPipe } from './shared/pipes/ellipsis.pipe';
import { ModalPasswordChangeComponent } from './components/modal-password-change/modal-password-change.component';
import { ModalDocumentationComponent } from './components/modal-documentation/modal-documentation.component';
import { ModalEmailComponent } from './components/modal-email/modal-email.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { LinkBackComponent } from './components/link-back/link-back.component';
import { DataService } from './services/data-service';
import { CustomerAreaComponent } from './pages/customer-area/customer-area.component';
import { ModalInfoCancelComponent } from './components/modal-info-cancel/modal-info-cancel.component';
import { ModalSendEmailComponent } from './components/modal-send-email/modal-send-email.component';
import { ModalExpiredTicketComponent } from './components/modal-expired-ticket/modal-expired-ticket.component';
import { CreateInternalUserComponent } from './pages/user-management/create-internal-user/create-internal-user.component';
import { EditInternalUserComponent } from './pages/user-management/edit-internal-user/edit-internal-user.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { CreateExternalUserComponent } from './pages/user-management/create-external-user/create-external-user.component';
import { EditExternalUserComponent } from './pages/user-management/edit-external-user/edit-external-user.component';
import { ProductsComponent } from './pages/products/products.component';
import { EditProductComponent } from './pages/products/edit-product/edit-product.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { SealEligibilityComponent } from './components/seal-eligibility/seal-eligibility.component';
import { ModalSummaryComponent } from './components/modal-summary/modal-summary.component';
import { ParameterRegistrationTariffComponent } from './pages/parameter-registration-tariff/parameter-registration-tariff.component';
import { OldTablesTariffComponent } from './pages/parameter-registration-tariff/old-tables-tariff/old-tables-tariff.component';
import { ParameterRegistrationCommissionComponent } from './pages/parameter-registration-commission/parameter-registration-commission.component';
import { RegisterConcessionaireComponent } from './pages/register/register-concessionaire/register-concessionaire.component';
import { ModalTermCancelComponent } from './components/modal-term-cancel/modal-term-cancel.component';
import { ModalAccountGenerationComponent } from './components/modal-account-generation/modal-account-generation.component';
import { ListDraftComponent } from './pages/draft/list-draft/list-draft.component';
import { NewDraftComponent } from './pages/draft/new-draft/new-draft.component';
import { ModalImportFileComponent } from './components/modal-import-file/modal-import-file.component';
import { TemplateListingMinutaComponent } from './components/template-listing-minuta/template-listing-minuta.component';
import { ModalPreviewDraftComponent } from './components/modal-preview-draft/modal-preview-draft.component';
import { OldTablesCommissionsComponent } from './pages/parameter-registration-commission/old-tables-commissions/old-tables-commissions.component';
import { ReleasePaidAmountsComponent } from './pages/contracted-plans/client-plan/release-paid-amounts/release-paid-amounts.component';
import { ProofPaymentComponent } from './pages/contracted-plans/client-plan/proof-payment/proof-payment.component';
import { IneligibilityReportComponent } from './pages/ineligibility-report/ineligibility-report.component';
import { ListIneligibilityReportComponent } from './pages/ineligibility-report/list-ineligibility-report/list-ineligibility-report.component';
import { ModalClientDetailComponent } from './components/modal-client-detail/modal-client-detail.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { PlanExtractComponent } from './pages/contracted-plans/client-plan/plan-extract/plan-extract.component';
import { ModalInformationComponent } from './components/modal-information/modal-information.component';
import { ConcessionairesAndPartnersComponent } from './pages/concessionaires-and-partners/concessionaires-and-partners.component';
import { ModalConfirmAccreditationComponent } from './components/modal-confirm-accreditation/modal-confirm-accreditation.component';
import { CnpjPipe } from './shared/pipes/cnpj.pipe';
import { ModalListConcessionairesComponent } from './components/modal-list-concessionaires/modal-list-concessionaires.component';
import { ModalAccreditationComponent } from './components/modal-accreditation/modal-accreditation.component';
import { ModalConfirmDeaccreditationComponent } from './components/modal-confirm-deaccreditation/modal-confirm-deaccreditation.component';
import { SimulationContactComponent } from './pages/simulation/simulation-contact/simulation-contact.component';
import { ModalValidateCellphoneComponent } from './components/modal-validate-cellphone/modal-validate-cellphone.component';
import { RegisterDeliveryConcessionaireComponent } from './pages/register/register-delivery-concessionaire/register-delivery-concessionaire.component';
import { ChangeRelationshipComponent } from './pages/customer-area/change-relationship/change-relationship.component';
import { FinancialMovementComponent } from './pages/financial-movement/financial-movement.component';
import { SegregationReportComponent } from './pages/segregation-report/segregation-report.component';
import { ChangeReportComponent } from './pages/change-report/change-report.component';
import { AppCacheInterceptor } from './helpers/interceptors/app-cache.interceptor';
import { ParametersComponent } from './pages/parameters/parameters.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { CreateProfileComponent } from './pages/user-profile/create-profile/create-profile.component';
import { ModalEditProfileComponent } from './components/modal-edit-profile/modal-edit-profile.component';
import { ExtensionComponent } from './pages/contracted-plans/client-plan/extension/extension.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NewAppVersionTrackerService } from './services/new-version-checker.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';

registerLocaleData(localePt, 'pt');


@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        NgxMaskDirective,
        NgxMaskPipe,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        RecaptchaModule,
        CarouselModule.forRoot(),
        MatProgressSpinnerModule,
        MatIconModule,
        MatCardModule,
        MatDividerModule,
        MatSliderModule,
        MatSelectModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatTabsModule,
        MatCheckboxModule,
        EditorModule,
        CurrencyMaskModule,
        MatExpansionModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            // enabled: !isDevMode(),
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerImmediately',
        }),
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        SimulationComponent,
        SimulationCategoryComponent,
        SimulationProductsComponent,
        LoadingSpinnerComponent,
        SimulationProductViewComponent,
        AlertBoxComponent,
        LoginComponent,
        RegisterComponent,
        RegisterDocumentsComponent,
        RegisterPersonalDataComponent,
        RegisterAddressComponent,
        RegisterContactComponent,
        VerifyDataComponent,
        CpfPipe,
        RgPipe,
        DateConvertPipe,
        CepPipe,
        TelefonePipe,
        CelularPipe,
        CompleteComponent,
        ModalCancelComponent,
        AccountingComponent,
        ContractedPlansComponent,
        ClientPlanComponent,
        ModalGenericComponent,
        CancelPlanComponent,
        ModalUploadTermComponent,
        CommissionsComponent,
        HasAuthorizationDirective,
        HasRoleDirective,
        AreaComponent,
        MenuComponent,
        PositionReportComponent,
        ButtonComponent,
        ModalCancelPlanComponent,
        PrivacyMessageComponent,
        ContractExtractReportComponent,
        ReleaseValuesReportComponent,
        ReleaseValuesComponent,
        ReleaseValuesDetailsComponent,
        ClientDataComponent,
        RefundRateComponent,
        BankDataComponent,
        CommunicationComponent,
        NewMessageComponent,
        ModalPreviewMessageComponent,
        TooltipComponent,
        ModalDeleteMessageComponent,
        EllipsisPipe,
        SafeHtmlPipe,
        ModalPasswordChangeComponent,
        ModalDocumentationComponent,
        ModalEmailComponent,
        ModalConfirmationComponent,
        LinkBackComponent,
        CustomerAreaComponent,
        ModalInfoCancelComponent,
        ModalSendEmailComponent,
        ModalExpiredTicketComponent,
        CreateInternalUserComponent,
        EditInternalUserComponent,
        UserManagementComponent,
        CreateExternalUserComponent,
        EditExternalUserComponent,
        ProductsComponent,
        EditProductComponent,
        SealEligibilityComponent,
        ModalSummaryComponent,
        ParameterRegistrationTariffComponent,
        OldTablesTariffComponent,
        ParameterRegistrationCommissionComponent,
        RegisterConcessionaireComponent,
        ModalTermCancelComponent,
        ModalAccountGenerationComponent,
        ListDraftComponent,
        NewDraftComponent,
        ModalImportFileComponent,
        TemplateListingMinutaComponent,
        ModalPreviewDraftComponent,
        OldTablesCommissionsComponent,
        ReleasePaidAmountsComponent,
        ProofPaymentComponent,
        IneligibilityReportComponent,
        ListIneligibilityReportComponent,
        ModalClientDetailComponent,
        ForgotPasswordComponent,
        PlanExtractComponent,
        ModalInformationComponent,
        ConcessionairesAndPartnersComponent,
        ModalConfirmAccreditationComponent,
        CnpjPipe,
        ModalListConcessionairesComponent,
        ModalAccreditationComponent,
        ModalConfirmDeaccreditationComponent,
        SimulationContactComponent,
        ModalValidateCellphoneComponent,
        RegisterDeliveryConcessionaireComponent,
        ChangeRelationshipComponent,
        FinancialMovementComponent,
        SegregationReportComponent,
        ParametersComponent,
        ChangeReportComponent,
        UserProfileComponent,
        CreateProfileComponent,
        ModalEditProfileComponent,
        ExtensionComponent
    ],
    providers: [
        ApiHttpService, UserService, NewAppVersionTrackerService, DataService, MatDatepickerModule, DatePipe,
        provideNgxMask({ /* opções de cfg */}),
        {
            provide: LOCALE_ID,
            useValue: 'pt'
        },
        /* if you don't provide the currency symbol in the pipe,
        this is going to be the default symbol (R$) ... */
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppCacheInterceptor,
            multi: true
        },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        provideUserIdleConfig({
            idle: environment.idleConfiguration.idle,
            timeout: environment.idleConfiguration.timeOut,
            ping: environment.idleConfiguration.ping
        }), provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }

import { Component, Inject } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-password-change',
    templateUrl: './modal-password-change.component.html',
    styleUrls: ['./modal-password-change.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatIcon, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatIconButton, MatSuffix, NgIf, MatError, ButtonComponent]
})
export class ModalPasswordChangeComponent {

  public senhaAtual = new FormControl('', [Validators.required]);
  public novaSenha = new FormControl('', [Validators.required]);
  public senhaConfirmacao = new FormControl('', [Validators.required]);
  public hideSenhaAtual = true;
  public hideNovaSenha = true;
  public hideSenhaConfirmacao = true;
  public validatesPassword = {
    size: 'default',
    smallLetters: 'default',
    capitalLetters: 'default',
    number: 'default',
    specialCharacter: 'default'
  }

  constructor(
    public dialogRef: MatDialogRef<ModalPasswordChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeModal() {
    this.dialogRef.close();
  }

  validatePassword(fromInput: boolean) {
    if (this.novaSenha.value == '') {
      this.cleanValidates()
      return;
    }
    const value = this.novaSenha.value || ''
    const stateType = fromInput ? 'default' : 'invalid'

    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharacterRegex = /[!@#$%*]/;

    this.validatesPassword.size = value.length >= 8 && value.length <= 56 ? 'valid' : this.validatesPassword.size === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.smallLetters = lowercaseRegex.test(value) ? 'valid' : this.validatesPassword.smallLetters === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.capitalLetters = uppercaseRegex.test(value) ? 'valid' : this.validatesPassword.capitalLetters === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.number = numberRegex.test(value) ? 'valid' : this.validatesPassword.number === 'invalid' ? 'invalid' : stateType;
    this.validatesPassword.specialCharacter = specialCharacterRegex.test(value) ? 'valid' : this.validatesPassword.specialCharacter === 'invalid' ? 'invalid' : stateType;

    if (this.validatesPassword.smallLetters == 'valid'
      && this.validatesPassword.capitalLetters == 'valid'
      && this.validatesPassword.number == 'valid'
      && this.validatesPassword.specialCharacter == 'valid'
      && this.validatesPassword.size == 'valid') {
      this.novaSenha.setErrors(null);
    } else {
      this.novaSenha.setErrors({ 'invalidPassword': true });
    }

  }

  cleanValidates() {
    this.validatesPassword = {
      size: 'default',
      smallLetters: 'default',
      capitalLetters: 'default',
      number: 'default',
      specialCharacter: 'default'
    }

  }

  checkPasswords() {
    this.checkSamePassword()
    if (this.senhaConfirmacao.value == '') return

    if (this.novaSenha.value === this.senhaConfirmacao.value) {
      this.senhaConfirmacao.setErrors(null);
    } else {
      this.senhaConfirmacao.setErrors({ 'invalidPassword': true });
    }
  }

  checkSamePassword() {
    if (this.senhaAtual.value == '') return
    if (this.novaSenha.value === this.senhaAtual.value) {
      this.novaSenha.setErrors({ 'samePassword': true });
    }
  }

  redefinePassword() {
    this.senhaAtual.markAllAsTouched()
    this.novaSenha.markAllAsTouched()
    this.senhaConfirmacao.markAllAsTouched()
    this.validatePassword(false)
    this.checkPasswords()
    if (this.senhaAtual.valid && this.novaSenha.valid && this.senhaConfirmacao.valid) {
      this.closeModal()
      this.data.redefineFunction(this.senhaAtual.value, this.novaSenha.value)
    }
  }

  checkCurrentPassword() {
    const senhaAtualValue = this.senhaAtual.value;
    if (senhaAtualValue?.length === 0) return;
    if (senhaAtualValue !== this.data.currentPassword) this.senhaAtual.setErrors({ 'invalidPassword': true });
  }

  getColor(parameter: string) {
    if (parameter === 'valid') return '#27AE60'
    else if (parameter === 'invalid') return 'var(--color-functional-danger-2)'
    else return 'var(--color-neutral-gray-3)'
  }

  getIcon(parameter: string) {
    if (parameter === 'valid') return 'check_circle'
    else if (parameter === 'invalid') return 'close'
    else return 'remove'
  }

}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ModalCancelPlanComponent } from 'src/app/components/modal-cancel-plan/modal-cancel-plan.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ICancelPlan } from 'src/app/models/cancel-plan.interface';
import { DadosBancarios } from 'src/app/models/contracted-plans.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { LinkBackComponent } from '../../../../components/link-back/link-back.component';
import { NgIf, CurrencyPipe, DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { HasAuthorizationDirective } from '../../../../shared/directives/has-authorization.directive';
import { ButtonComponent } from '../../../../components/buttons/button/button.component';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { LoadingSpinnerComponent } from '../../../../components/loading-spinner/loading-spinner.component';
import { ReleasePaidAmountsComponent } from '../release-paid-amounts/release-paid-amounts.component';

interface Parametro {
  assinaturaEletronicaHabilitada: boolean;
}

@Component({
    selector: 'yfs-extension',
    templateUrl: './extension.component.html',
    styleUrls: ['./extension.component.scss'],
    standalone: true,
    imports: [LinkBackComponent, ReleasePaidAmountsComponent, NgIf, MatCard, MatCardContent, MatIcon, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, 
      MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, 
      HasAuthorizationDirective, ButtonComponent, MatRadioGroup, FormsModule, MatRadioButton, MatFormField, MatLabel, 
      MatInput, ReactiveFormsModule, NgxMaskDirective, LoadingSpinnerComponent, CurrencyPipe, DatePipe]
})
export class ExtensionComponent {
  @Output() currentFlowChanged = new EventEmitter<string>();
  @Output() openCancelPlan = new EventEmitter<void>();
  @Input() statusContratoNumero: number | undefined;
  @Input() idContrato: number | undefined;
  @Input() podeCancelar: boolean | undefined;
  @Input() userName: string | undefined;
  @Input() cpfClient: string | undefined = '';
  @Input() concessionariaValue: number | undefined;
  @Input() dadosBancariosData: DadosBancarios | undefined;
  @Input() emailCliente: string | undefined = '';
  @Input() telefoneCliente: { tipo: string, ddd: string, numero: string }[] | undefined = [];
  public cancelPlanIsOpen = false;
  public restricaoCredito = 0;
  public quitacao = 0;
  public displayedColumns: string[] = [
    'recarga',
    'valor',
    'dataVencimento',
    'statusSituacaoTitulo',
  ];
  public cancelAllowedPermissions = [EPermissaoAcesso.DESISTIR_PLANO]
  public dataSource: any;
  public isLoading = false;
  public saldoProjetado = 0
  public isFullWidth = window.innerWidth < 1279;
  public showSendCard = false;
  public showExtension = true;
  public assinaturaEletronicaHabilitada: boolean | null = false;
  public formaEnvio = 'Whatsapp'
  public celular = new FormControl({ value: '', disabled: true });
  public sms = new FormControl({ value: '', disabled: true });
  public email = new FormControl({ value: '', disabled: true }, [
    Validators.email,
    Validators.maxLength(50)
  ]);
  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public infoDataEnvio = ''
  public termSent = false;
  public dataExpiracaoTermoProrrogacao = ''
  public fileSizeError: boolean = false;
  public releaseValuesIsOpen = false;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private cdref: ChangeDetectorRef,
    public dialog: MatDialog,
    private apiService: ApiHttpService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.assinaturaEletronicaHabilitada = this.obterAssinaturaEletronicaHabilitada();
    this.getData();
  }

  @ViewChild('primaryTableSort')
  primaryTableSort!: MatSort;


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getBack() {
    this.currentFlowChanged.emit('contractedPlans');
  }

  obterAssinaturaEletronicaHabilitada(): boolean | null {
    const parametrosJSON = sessionStorage.getItem('parametros');

    if (parametrosJSON) {
        const parametros: Parametro[] = JSON.parse(parametrosJSON);

        if (parametros.length > 0 && typeof parametros[0].assinaturaEletronicaHabilitada === 'boolean') {
            return parametros[0].assinaturaEletronicaHabilitada;
        }
    }

    return null;
  }

  getStatusName(status: number) {
    if (status === 1) return 'Em Aberto';
    return status;
  }


  getData(){
    this.isLoading = true;
    this.apiService
      .getExtensionInfo(this.idContrato || 0)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(result?.body.parcelas);
          this.saldoProjetado = result?.body.saldoProjetado;
          this.dataSource.sort = this.primaryTableSort;
          this.quitacao = result?.body.quitacao;
          this.restricaoCredito = result?.body.restricaoCredito;
          this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => {
            if (sortHeaderId === 'status') {
              return data.statusParcelaDescricao;
            }
            return data[sortHeaderId];
          };
          this.getUser();
          this.cdref.detectChanges();
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  handleCancelButtonPress() {
    if (!this.podeCancelar)
      return;

    if (this.statusContratoNumero === 0 || this.statusContratoNumero === 14) {
      this.openDialogCancelPlan();
      return;
    }

    this.cancelPlanIsOpen = true;
  }

  openModalExtension = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        textBold: 'Tem certeza que deseja prorrogar seu plano?',
        text: 'Você pode prorrogar apenas uma vez.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.handleExtensionButtonClick() },
        secundaryButtonText: 'SIM, PRORROGAR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalExtensionSuccess = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: 'Plano prorrogado com sucesso.',
        secundaryButtonText: 'VOLTAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: 'close',
      }
    })
  }

  openModalExtensionError = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: 'Não foi possível prorrogar seu plano. ',
        secondaryText: 'Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',

      }
    })
  }

  openDialogCancelPlan() {
    this.dialog.open(ModalCancelPlanComponent, {
      width: '393px',
      data: {
        clientName: this.userName,
        contractNumber: this.idContrato,
        handleCancelPlan: () => {
          this.handleCancelPlan();
        },
      }
    })
  }

  handleCancelPlan() {
    this.cancelPlan();
  }

  cancelPlan() {
    const payLoad: ICancelPlan = {
      idContrato: this.idContrato,
      statusContratoNumero: this.statusContratoNumero,
    };
    this.isLoading = true;
    this.apiService
      .cancelPlan(payLoad)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSucess('O plano foi cancelado com sucesso.', 'FECHAR');
          this.statusContratoNumero = 4;
          this.podeCancelar = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  openDialogSucess(message: string, textButton: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: message,
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: textButton,
        primaryButtonVariant: 'primary',
      },
    });
  }


  isButtonDisabled(): boolean {
    return this.quitacao !== 1 || this.restricaoCredito !== 1;
  }

  someMethodToTriggerCancelPlan() {
    this.openCancelPlan.emit();
  }

  verifyShipping(){
    switch (this.formaEnvio) {
      case "Email":
    return this.openDialogEmail();
      case "Whatsapp":
    return this.openDialogWhatsapp();
      case "Sms":
    return this.openDialogSms();
    }
  }

  aplicarMascara(valor: string, mascara: any[]): string {
    const numeros = valor.replace(/\D/g, '');
    const valorArray = numeros.split('');
    const mascaraArray = mascara;

    let resultado = '';
    let j = 0;

    for (let i = 0; i < mascaraArray.length; i++) {
      if (mascaraArray[i] instanceof RegExp) {
        resultado += mascaraArray[i].test(valorArray[j]) ? valorArray[j] : '';
        j++;
      } else {
        resultado += mascaraArray[i];
      }
    }

    return resultado;
  }

  formatarNumeroTelefone(numero: any): string {
    if (numero) {
      return this.aplicarMascara(numero, ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]);
    } else {
      return '';
    }
  }

  openDialogEmail() {
    this.infoDataEnvio = this.email.value ?? '';
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Tem certeza que deseja enviar o termo de cancelamento para o e-mail abaixo?`,
        highlightText: this.email.value,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ENVIAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.extendPlan()}
      },
    });
  }

  openDialogWhatsapp() {
    this.infoDataEnvio = this.formatarNumeroTelefone(this.celular.value).replace(/[^\d]/g, "");
    const numeroFormatado = this.formatarNumeroTelefone(this.celular.value);
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Tem certeza que deseja enviar o termo de cancelamento para o whatsapp abaixo?`,
        highlightText: numeroFormatado,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ENVIAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.extendPlan()}
      },
    });
  }

  openDialogSms() {
    this.infoDataEnvio = this.formatarNumeroTelefone(this.celular.value).replace(/[^\d]/g, "");
    const numeroFormatado = this.formatarNumeroTelefone(this.sms.value);
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Tem certeza que deseja enviar o termo de cancelamento para o SMS abaixo?`,
        highlightText: numeroFormatado,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ENVIAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.extendPlan()}
      },
    });
  }

  goToPlan(){
    this.showSendCard = false
    this.showExtension = false
    window.location.reload();
  }

  getUser() {
    this.email.disable()
    this.sms.disable()
    this.celular.disable()
    this.isLoading = true;
    this.apiService.getUser(this.cpfClient).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
            if (result) {
              if(result.cliente){
                const emailValue = result.cliente.contato.email ?? null;
                const celularComDdd = result.cliente.contato.celularComDdd ?? null;
                this.email.patchValue(emailValue);
                this.celular.patchValue(celularComDdd ? celularComDdd.toString() : null);
                this.sms.patchValue(celularComDdd ? celularComDdd.toString() : null);
              }
            }
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  openDialogSuccessExtension(message: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: message,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openDialogErrorExtension(message: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        secondaryText: `${message}<br/><strong>Tente novamente mais tarde.</strong>`,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  handleExtensionButtonClick() {
    if (this.assinaturaEletronicaHabilitada) {
      this.showSendCard = true;
      this.showExtension = false;
      this.termSent = false;
      document.documentElement.scrollTop = 0;
    } else {
      this.extendPlan();
    }
  }

  extendPlan() {
    const payLoad = {
      idContrato: this.idContrato,
      formaEnvioDocumento: this.formaEnvio,
    };
    this.isLoading = true;
    this.apiService
      .extendPlan(payLoad)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.dataExpiracaoTermoProrrogacao = result?.dataLimite;
          let message = '';
          if (this.assinaturaEletronicaHabilitada) {
            message = 'Termo de prorrogação enviado com sucesso.'
          } else {
             message = 'Plano prorrogado com sucesso.'
          }
          this.openDialogSuccessExtension(message);
          this.termSent = true;
          this.showSendCard = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.showSendCard = true;
          let messageErro = '';
          if (this.assinaturaEletronicaHabilitada) {
            messageErro = 'Não foi possível enviar o termo de prorrogação.'
          } else {
            messageErro = 'Não foi possível prorrogar o plano.'
          }
          this.openDialogErrorExtension(messageErro);
          console.log(error);
        },
      });
  }

  openReleaseValues = () => {
    this.releaseValuesIsOpen = true
    this.dialog.closeAll();
  }

  closeReleaseValues() {
    this.releaseValuesIsOpen = false;
    window.scrollTo(0, 0);
  }
}

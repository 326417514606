import { Component } from '@angular/core';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-cancel',
    templateUrl: './modal-cancel.component.html',
    styleUrls: ['./modal-cancel.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, ButtonComponent]
})
export class ModalCancelComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalCancelComponent> ,
    private router: Router
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearStorage() {
    localStorage.removeItem("documentos")
    localStorage.removeItem("dadosPessoais")
    localStorage.removeItem("endereco")
    localStorage.removeItem("contato")
    this.dialogRef.close()
    this.router.navigate(["home"])
  }
}

import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalCancelComponent } from 'src/app/components/modal-cancel/modal-cancel.component';
import { IContact } from 'src/app/models/register.interface';
import { UserService } from 'src/app/services/user.service';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { NgxMaskDirective } from 'ngx-mask';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { AlertBoxComponent } from '../../../components/alert-box/alert-box.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-register-contact',
    templateUrl: './register-contact.component.html',
    styleUrls: ['./register-contact.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardContent, MatFormField, MatLabel, NgxMaskDirective, MatInput, FormsModule, ReactiveFormsModule, NgIf, MatError, ButtonComponent, AlertBoxComponent, LoadingSpinnerComponent]
})
export class RegisterContactComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public celularComDdd = new FormControl('', [Validators.required]);
  public telefoneResidencialComDdd = new FormControl ('');
  public email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
  public timeoutError = false;
  public genericError = false;
  public isLoading = false;
  public celularFromStorage = false;
  public isFullWidth = window.innerWidth < 820;

  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public telefoneMask = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(public dialog: MatDialog, private userService: UserService) { }

  ngOnInit() {
    this.isLogged()
    if (localStorage.getItem("contato")) {
      let contato: IContact;
      contato = JSON.parse(atob(localStorage.getItem('contato') || '{}'))
      this.bidingValuesContact(contato)
    }
    const user = this.userService.getCurrentUser();
    if (localStorage.getItem("contatoSimulacao")) {
      let contatoSimulacao = JSON.parse(atob(localStorage.getItem('contatoSimulacao') || '{}'))
      this.celularComDdd.setValue(contatoSimulacao.celular);
      this.email.setValue(contatoSimulacao.email);
      this.celularComDdd.disable();
      this.celularFromStorage = true;
    }
  }

  isLogged(): boolean {
    return this.userService.userIsLogged();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 820;
  }

  getRemainingNumber(): string {
    return this.isLogged() ? '5 de 5' : '4 de 5';
  }

  goBack() {
    this.currentFlowChanged.emit("address");
  }

  bidingValuesContact(contato: IContact) {
    this.celularComDdd.setValue(contato.celularComDdd ? contato.celularComDdd.toString() : null)
    this.telefoneResidencialComDdd.setValue(contato.telefoneResidencialComDdd ? contato.telefoneResidencialComDdd.toString() : null);
    this.email.setValue(contato.email ?? '');
  }

  nextStep() {
    this.celularComDdd.markAllAsTouched()
    this.telefoneResidencialComDdd.markAllAsTouched()
    this.email.markAllAsTouched()

    const isCelularComDddValid = this.celularComDdd.valid || this.celularFromStorage;
    if (isCelularComDddValid && this.email.valid) {

      const celularValue = this.celularComDdd.value?.replace(/[^0-9]/g, '');
      const telefoneValue = this.telefoneResidencialComDdd.value?.replace(/[^0-9]/g, '');

      let contato: IContact = {
        celularComDdd: celularValue ? parseInt(celularValue) : null,
        telefoneResidencialComDdd: telefoneValue ? parseInt(telefoneValue) : null,
        email: this.email.value ?? undefined,
      };

      localStorage.setItem("contato", btoa(JSON.stringify(contato)))

      if (this.isLogged()){
        this.currentFlowChanged.emit("verifyData")
      }
      else {
        this.currentFlowChanged.emit("delivery")
      }

    }
  }

  openDialog() {
    this.dialog.open(ModalCancelComponent, {
      width: '381px'
    })
  }

}

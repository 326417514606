import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { dateFormat } from 'src/app/shared/date-format';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatCheckbox } from '@angular/material/checkbox';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { ListIneligibilityReportComponent } from './list-ineligibility-report/list-ineligibility-report.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-ineligibility-report',
    templateUrl: './ineligibility-report.component.html',
    styleUrls: ['./ineligibility-report.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, NgIf, MatCard, MatCardContent, MatFormField, MatLabel, MatDatepickerInput, FormsModule, MatInput, NgxMaskDirective, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, MatSelect, MatCheckbox, NgFor, MatOption, ButtonComponent, ListIneligibilityReportComponent, LoadingSpinnerComponent, DatePipe]
})
export class IneligibilityReportComponent {
  @ViewChild('mySel') select: MatSelect | undefined;
  @ViewChild('mySel2') select2: MatSelect | undefined;
  public currentDate = new Date();
  public yesterdayDate = new Date();
  public isLoading = false;
  public dataInicialLabel: Date | undefined = this.yesterdayDate;
  public dataInicial = new FormControl('', [dateFormat.dateValidator, Validators.required]);
  public dataFinalLabel: Date | undefined = this.currentDate;
  public dataFinal = new FormControl('', [dateFormat.dateValidator, Validators.required]);
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public canalOriginacao: FormControl = new FormControl([1], [Validators.required]);
  public canalRelacionamento: FormControl = new FormControl('', [Validators.required]);
  public searchString = new FormControl('');
  public checkedAllOriginacao = false;
  public interteminateOriginacao = false;
  public checkedAllRelacionamento = false;
  public interteminateRelacionamento = false;
  public generationError = false;
  public maxDateInicial = new Date();
  public maxDateFinal = new Date();
  public dataInicialError = '';
  public dataFinalError = '';
  public currentFlow: 'parameters' | 'results' = 'parameters';
  public nofiles = false;
  public listItens: any;
  public originacaoList = [
    {
      value: 1,
      description: 'Correspondente / Concessionária'
    },
    {
      value: 1,
      description: 'E-Commerce'
    }
  ]
  public relacionamentoList: any;

  constructor(private router: Router, private apiService: ApiHttpService) {
    this.yesterdayDate.setDate(this.yesterdayDate.getDate() - 1);
    this.maxDateInicial.setDate(this.currentDate.getDate());
    this.maxDateFinal.setDate(this.yesterdayDate.getDate() + 90);
    this.dataFinal.setValue(this.formatDate(this.dataFinalLabel as any))
    this.canalOriginacao.disable();
    this.getConcessionarias();
  }

  getBack() {
    if (this.currentFlow == 'results') this.currentFlow = 'parameters';
    else this.router.navigate(['area']);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  onInputChangeInicial(isCalendar: boolean) {
    if (this.dataInicial.value?.length === 0) {
      this.dataInicialLabel = undefined;
    }
    if (this.dataInicial && this.dataInicial.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataInicial2 = this.dataInicial.value ?
        this.getDateByString(this.dataInicial.value) : null;
      if (!isCalendar) {
        this.dataInicialLabel = dataInicial2 || undefined
        this.validateDataInicial();
      }
    }
  }

  onInputChangeFinal(isCalendar: boolean) {
    if (isCalendar) {
      this.dataFinal.setValue(this.formatDate(this.dataFinalLabel as any))
    }

    if (this.dataFinal.value?.length === 0) {
      this.dataFinalLabel = undefined;
    }
    if (this.dataFinal && this.dataFinal.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataFinal2 = this.dataFinal.value ?
        this.getDateByString(this.dataFinal.value) : null;
      if (!isCalendar) {
        this.dataFinalLabel = dataFinal2 || undefined
      }
    }
    this.validateDataFinal()
  }

  validateDataInicial() {
    const dataInicialLabelConvert = new Date(this.dataInicialLabel || '');
    if (dataInicialLabelConvert > this.currentDate) {
      this.dataInicial.setErrors({});
      this.dataInicialError = 'A data inicial não pode ser uma data futura.'
    } else {
      this.dataInicialError = '';
    }
    this.validateDataFinal()
  }

  updateMaxFinalDate() {
    if (!this.dataInicialLabel) return false;
    const ninetyDaysFromNow = new Date(this.dataInicialLabel.getFullYear(), this.dataInicialLabel.getMonth(), this.dataInicialLabel.getDate() + 90);
    this.maxDateFinal = ninetyDaysFromNow;

    return ninetyDaysFromNow;
  }

  isDateGreaterThan90DaysFromNow(date: Date): boolean {
    if (!this.dataInicialLabel) return false;
    const ninetyDaysFromNow = this.updateMaxFinalDate()
    return date <= ninetyDaysFromNow;
  }

  validateDataFinal() {
    const isDataInicialValid = this.dataInicial.value && this.dataInicial.value.replace(/[/_]/g, "").length == 8;
    const isDataFinalValid = this.dataFinal.value && this.dataFinal.value.replace(/[/_]/g, "").length == 8;
    const dataInicio = isDataInicialValid ? new Date(this.getDateByString(this.dataInicial.value || '')) : null;
    const dataFim = isDataFinalValid ? new Date(this.getDateByString(this.dataFinal.value || '')) : null;

    if (dataInicio && dataFim && dataInicio > dataFim) {
      this.dataFinalError = 'A data final não pode ser anterior a data inicial.'
      this.dataFinal.setErrors({ "incorrect": true })
    } else if (dataFim && !this.isDateGreaterThan90DaysFromNow(dataFim)) {
      this.dataFinalError = 'A data final não pode passar dos 90 dias.';
      this.dataFinal.setErrors({ "incorrect": true })
    } else {
      this.dataFinalError = '';
      const errors = { ...this.dataFinal.errors };
      delete errors['incorrect'];
      this.dataFinal.setErrors(Object.keys(errors).length !== 0 ? errors : null);
    }
    this.dataFinal.markAllAsTouched()
    this.updateMaxFinalDate()
  }

  selectAllOriginacao() {
    if (!this.checkedAllOriginacao) {
      this.select?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateOriginacao = false;
    this.checkedAllOriginacao = !this.checkedAllOriginacao;
  }

  checkHandleOriginacao() {
    const optionFound = this.select?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllOriginacao = false;
    }

    if (optionFound?.length === this.originacaoList.length) {
      this.checkedAllOriginacao = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.originacaoList.length) {
      this.interteminateOriginacao = true;
    } else {
      this.interteminateOriginacao = false;
    }
  }

  selectAllRelacionamento() {
    if (!this.checkedAllRelacionamento) {
      this.select2?.options.forEach((item: MatOption) => {
        item.select();
      });
    } else {
      this.select2?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.interteminateRelacionamento = false;
    this.checkedAllRelacionamento = !this.checkedAllRelacionamento
  }

  checkHandleRelacionamento() {
    const optionFound = this.select2?.options.filter(
      (item: MatOption) => item.selected
    );
    if (optionFound?.length === 0) {
      this.checkedAllRelacionamento = false;
    }

    if (optionFound?.length === this.relacionamentoList.length) {
      this.checkedAllRelacionamento = true;
    }

    if (optionFound && optionFound?.length > 0 && optionFound?.length < this.relacionamentoList.length) {
      this.interteminateRelacionamento = true;
    } else {
      this.interteminateRelacionamento = false;
    }
  }

  isEnabladButton() {
    if (!this.dataFinalError && this.dataInicial.valid && this.dataFinal.valid && !this.dataInicialError && this.canalRelacionamento.valid) return 'enabled';
    return 'disabled';
  }

 converteDataToISO(data: string): string {
    const partes = data.split('/');
    const dataISO = partes[2] + '-' + partes[1].padStart(2, '0') + '-' + partes[0].padStart(2, '0');
    return dataISO;
}

  search = () => {
    this.isLoading = true
    this.apiService.getIneligibilityReport(this.converteDataToISO(this.dataInicial.value || ''), this.converteDataToISO(this.dataFinal.value || ''), this.canalRelacionamento.value).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          if (result.status == 200 && result.body?.relatorio) {
            this.listItens = result.body?.relatorio;
            this.currentFlow = 'results';
            this.nofiles = false;
          } else {
            this.nofiles = true;
          }
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  orderbyList(a: any, b: any) {
    if (a.razaoSocial < b.razaoSocial) {
        return -1;
    }
    if (a.razaoSocial > b.razaoSocial) {
        return 1;
    }
    return 0;
  }

  getConcessionarias() {
    this.isLoading = true;
    this.apiService.getConcessionarias().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.relacionamentoList = result;
          this.relacionamentoList.sort(this.orderbyList)
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }




}

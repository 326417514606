import { Component, ElementRef, Inject, QueryList, ViewChildren, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { NgxMaskDirective } from 'ngx-mask';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-modal-validate-cellphone',
    templateUrl: './modal-validate-cellphone.component.html',
    styleUrls: ['./modal-validate-cellphone.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatIcon, FormsModule, ReactiveFormsModule, NgxMaskDirective, NgIf, ButtonComponent, LoadingSpinnerComponent]
})
export class ModalValidateCellphoneComponent implements OnInit, OnDestroy {

  public codeForm: FormGroup;
  public isLoading = false;
  public timeLeft: number = 120;
  public interval: any;
  public mask = [/\d/];
  public currentFlow: 'wait' | 'success' | 'resent' | 'error' = 'wait';
  public incorrectCode: boolean = false;
  public correctCode: boolean = false;
  public expirationDate: string = "";
  public isFullWidth = window.innerWidth < 768;

  @ViewChildren('codeInput') codeInputs: QueryList<ElementRef> | undefined;

  constructor(
    public dialogRef: MatDialogRef<ModalValidateCellphoneComponent>,
    public dialog: MatDialog,
    private apiService: ApiHttpService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.codeForm = this.fb.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]],
      code5: ['', [Validators.required]],
      code6: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.expirationDate = this.data?.expirationDate;
    this.calculateTimeLeft();
    this.startTimer();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 768;
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  calculateTimeLeft(): void {
    const expiration = new Date(this.expirationDate).getTime();
    const now = new Date().getTime();
    this.timeLeft = Math.floor((expiration - now) / 1000);
  }

  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  formatTimeLeft(): string {
    const minutes: number = Math.floor(this.timeLeft / 60);
    const seconds: number = this.timeLeft % 60;
    return `${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;

    if (event.key === 'Backspace' && input.value.length === 0 && index > 1) {
      this.codeInputs?.toArray()[index - 2].nativeElement.focus();
    } else if (input.value.length === 1 && index < 6) {
      this.codeInputs?.toArray()[index].nativeElement.focus();
    }

    this.incorrectCode = false;
    this.correctCode = false;
  }

  hasValue(controlName: string): boolean {
    return this.codeForm?.get(controlName)?.value.length > 0;
  }

  getMessage() {
    switch (this.currentFlow) {
      case 'wait':
        return { message: `O código expira em ${this.formatTimeLeft()}`, icon: 'nest_clock_farsight_analog', color: 'var(--color-neutral-gray-3)' }
      case 'success':
        return { message: 'Código validado com sucesso', icon: 'check_circle', color: 'var(--color-functional-positive-1)' }
      case 'error':
        return { message: 'Houve um erro ao enviar o código. Tente outra vez.', icon: 'cancel', color: 'var(--color-functional-danger-2)' }
      case 'resent':
        return { message: 'Código reenviado por SMS.', icon: 'check_circle', color: 'var(--color-primary-3)' }
      default:
        return { message: '', icon: 'nest_clock_farsight_analog', color: 'var(--color-neutral-gray-3)' };
    }
  }

  sendCode = () => {
    this.isLoading = true;
    this.incorrectCode = false;
    this.correctCode = false;
    const code = Object.values(this.codeForm.value).join('');
    this.apiService.sendCode(this.data?.clientData, code).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.correctCode = true;
          this.currentFlow = 'success';
          this.codeForm.disable();
        },
        error: (error) => {
          this.isLoading = false;
          if (error.status == 400) {
            this.incorrectCode = true;
            this.currentFlow = 'wait';
          } else {
            this.currentFlow = 'error';
          }
          console.log(error);
        },
      });
  }

  resendCode = () => {
    this.isLoading = true;
    this.apiService.validateSms(this.data?.clientData).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.currentFlow = 'resent';
          this.resetValues();
          this.expirationDate = result?.expirationDate;
          this.calculateTimeLeft();
          clearInterval(this.interval);
          this.startTimer();
          setTimeout(() => {
            this.currentFlow = 'wait';
          }, 1000)
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  resetValues() {
    this.codeForm.patchValue({
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: ''
    });
  }

  continueHandleClick() {
    this.closeModal();
    this.data?.continueHandle()
  }

}

<yfs-header></yfs-header>
<section class="container-content">
  <div *ngIf="currentFlow === 'releaseValues'">
    <div class="button-back">
      <yfs-link-back text="Voltar para Minha área" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
      <h1>LIBERAÇÃO DE VALORES</h1>
    </div>

    <div style="margin-top: 32px;">
      <mat-card style="box-shadow: 0px 1px 4px #00000052;">
        <mat-card-content>
          <div class="inputs-content">
            <mat-form-field id="search" appearance="fill">
              <mat-label>Busque por CPF ou número do contrato</mat-label>
              <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="search-icon material-symbols-outlined"
                fontIcon="search"></mat-icon>
              <input [formControl]="searchString" (focusout)="setPageStart()" matInput>
            </mat-form-field>
            <mat-form-field id="tipoLiberacao" appearance="fill">
              <mat-label>Tipo da liberação</mat-label>
              <mat-select [formControl]="tipoLiberacao" [(ngModel)]="tipoLiberacaoControl" (selectionChange)="setPageStart()">
                <mat-option *ngFor="let statusOption of tipoLiberacaoOptions"
                  [value]="statusOption.value">{{statusOption.label}}</mat-option>
              </mat-select>
              <button *ngIf="tipoLiberacaoControl >= '0'" matSuffix mat-icon-button type="button" aria-label="Clear"
                (click)="tipoLiberacao.setValue(null); $event.stopPropagation(); setPageStart()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="scrollable-table-container" style="margin-top: 10px;">
              <table *ngIf="hasValue"
              mat-table
              [dataSource]="dataSource"
              matSort
              #releaseTableSort="matSort"
              (matSortChange)="announceSortChange($event)">

              <ng-container matColumnDef="numeroContrato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                  Contrato
                </th>
                <td mat-cell *matCellDef="let element"> {{element.numeroContrato}} </td>
              </ng-container>

              <ng-container matColumnDef="nomeCliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nomeCliente">
                  Cliente
                </th>
                <td mat-cell *matCellDef="let element"> {{element.nomeCliente}} </td>
              </ng-container>

              <ng-container matColumnDef="canalVenda">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by canalVenda">
                  Canal de venda
                </th>
                <td mat-cell *matCellDef="let element"> {{element.canalVenda}} </td>
              </ng-container>

              <ng-container matColumnDef="dataSolicitacaoLiberacaoValor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by dataSolicitacaoLiberacaoValor">
                  Data da solicitação
                </th>
                <span class="material-symbols-outlined">nest_clock_farsight_analog</span>
                <td mat-cell *matCellDef="let element">
                  {{ element.dataSolicitacaoLiberacaoValor | date: 'dd/MM/yyyy' }}
                  <br />
                  <span class="dias-atras">{{ calcularDiasAtras(element.diasSolicitacaoLiberacaoValor) }} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="tipoLiberacao">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tipoLiberacao">
                  Tipo da liberação
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.tipoLiberacao }} </td>
              </ng-container>

              <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by valor">
                  Valor
                </th>
                <td mat-cell *matCellDef="let element"> {{element.valor | currency }} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row (click)="selectClientPlan(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="!hasValue" class="content-empty">
              <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
              <div>
                <span>Nenhum resultado encontrado.</span>
                <span>Tente novamente com outros filtros.</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <yfs-release-values-details *ngIf="currentFlow === 'releaseValuesDetails'" (currentFlowChanged)="changeCurrentFlow($event)"
  [releaseValueSelected]="releaseValueSelected"></yfs-release-values-details>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>


<section class="container-content">
    <div *ngIf="showCancel" class="container-title">
        <div class="button-back">
          <yfs-link-back text="Voltar para planos do cliente" (click)="emitCloseCancelPlan()"></yfs-link-back>
        </div>
        <h1>CANCELAR PLANO</h1>
    </div>

    <mat-card *ngIf="showCancel">
    <mat-card-content>
    <div class="warning-content">
        <mat-icon aria-hidden="false" aria-label="Ícone de atenção" class="material-symbols-outlined"
            fontIcon="warning"></mat-icon>
        <span>Este plano já possui pagamentos efetuados</span>
    </div>
    <div class="value-returned-content">
        <span class="value-span">Valores a serem devolvidos:</span>
        <span class="value">{{valueToReceive | currency}}</span>
    </div>
    <div class="recipient-content">
        <span>O beneficiário é um terceiro?</span>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="terceiro" (change)="verifyBankData()">
          <mat-radio-button (click)="recoverData()" value="nao">Não</mat-radio-button>
          <mat-radio-button value="sim" (click)="cleanFields()">Sim</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="bank-data-content">
        <span>Dados bancários para depósito</span>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoPessoa" (change)="verifyBankData()">
            <mat-radio-button style="width: 145px; margin-left: -10px;" [disabled]="terceiro === 'nao'" value="pessoaFisica" (click)="verifyAccountType()">Pessoa física</mat-radio-button>
            <mat-radio-button style="width: 145px; margin-left: -10px;" [disabled]="terceiro === 'nao'" value="pessoaJuridica" (click)="verifyAccountType()">Pessoa jurídica</mat-radio-button>
            <mat-radio-button style="width: 145px; margin-left: -10px;" [disabled]="terceiro === 'nao'" value="concessionaria" (click)="handleConcessionariaClick()">Concessionária</mat-radio-button>
        </mat-radio-group>
        <div class="message" *ngIf="tipoPessoa === 'concessionaria'">
          <p>Os dados apresentados no campo concessionária são os dados bancárias da concessionária que originou a venda. Para recebimento em outra concessionária, selecione a opção <strong>Beneficiário Terceiros</strong> e <strong>Pessoa Jurídica</strong>, para a inclusão dos dados.</p>
        </div>
    </div>
    <div class="inputs-container">
    <div style="display:  flex; flex-direction: column; margin-top: 30px;">
      <mat-form-field id="beneficiario" style="width: 100%;" appearance="fill">
        <mat-label>Nome do beneficiário</mat-label>
        <input type="text" matInput [formControl]="beneficiario" (input)="formatarNome()" [readonly]="terceiro === 'nao'">
        <mat-error *ngIf="beneficiario.hasError('required')">Campo obrigatório</mat-error>
      </mat-form-field>

      <mat-form-field id="cpf" appearance="fill" *ngIf="tipoPessoa === 'pessoaFisica'">
      <mat-label>CPF</mat-label>
      <input matInput [formControl]="cpf" mask="000.000.000-00" [readonly]="terceiro === 'nao'" (ngModelChange)="formatarCPF()">
      <mat-error *ngIf="cpf.hasError('required')">{{ genericMenssage }}</mat-error>
      <mat-error *ngIf="cpf.hasError('cpfInvalid') && !cpf.hasError('required')">CPF inválido</mat-error>
    </mat-form-field>

      <mat-form-field id="cnpj" appearance="fill" *ngIf="tipoPessoa !== 'pessoaFisica'">
        <mat-label>CNPJ</mat-label>
        <input matInput [formControl]="cnpj" mask="00.000.000/0000-00" [readonly]="terceiro === 'nao'" (input)="formatarCNPJ()">
        <mat-error *ngIf="cnpj.hasError('required')">{{ genericMenssage }}</mat-error>
        <mat-error *ngIf="cnpj.hasError('cnpjInvalid') && !cnpj.hasError('required')">CNPJ inválido</mat-error>
      </mat-form-field>
    </div>
    <div style="display:  flex; flex-direction: column; margin-top: 30px;">
      <span class="account">Tipo da conta</span>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoConta" class="space-left">
        <mat-radio-button [disabled]="tipoPessoa === 'concessionaria'" value="corrente">Conta Corrente</mat-radio-button>
        <mat-radio-button [disabled]="tipoPessoa === 'concessionaria'" value="poupanca">Conta Poupança</mat-radio-button>
      </mat-radio-group>
        <mat-form-field style="width: 100%" id="banco" appearance="fill">
            <mat-label>Banco</mat-label>
            <input [matAutocomplete]="auto" [formControl]="banco" type="text" matInput placeholder="Selecione" (click)="clearAndOpenOptions()">
            <mat-error *ngIf="banco.hasError('required')">{{genericMenssage}}</mat-error>
            <mat-error *ngIf="banco.hasError('invalidBank')">Banco inválido. Selecione uma opção da lista.</mat-error>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option?.nome">
                    {{option?.codBanco}} - {{option?.nome}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="bank-numbers-content">
            <mat-form-field style="width: 139px;" id="agencia" appearance="fill">
                <mat-label>Agência</mat-label>
                <input [formControl]="agencia" maxlength="5" matInput>
                <mat-error *ngIf="agencia.hasError('required')">{{genericMenssage}}</mat-error>
            </mat-form-field>
            <mat-form-field style="width: 165px;" id="agencia" appearance="fill">
              <mat-label>
                {{ tipoConta === 'corrente' ? 'Conta Corrente' : tipoConta === 'poupanca' ? 'Conta Poupança' : '' }}
              </mat-label>
                <input [formControl]="contaCorrente" maxlength="12" matInput>
                <mat-error *ngIf="contaCorrente.hasError('required')">{{genericMenssage}}</mat-error>
            </mat-form-field>
            <mat-form-field style="width: 80px;" id="digito" appearance="fill">
                <mat-label>Dígito</mat-label>
                <input [formControl]="contaCorrenteDigito" maxlength="2" matInput>
                <mat-error *ngIf="contaCorrenteDigito.hasError('required')">{{genericMenssage}}</mat-error>
            </mat-form-field>
        </div>
    </div>
  </div>
    <div class="button">
      <yfs-button state="enabled" [label]="!assinaturaEletronicaHabilitada ? 'FECHAR' : 'CANCELAR'" type="secondary" (click)="openModalClose()"></yfs-button>
      <yfs-button [state]="agencia.hasError('required') || contaCorrente.hasError('required') || banco.hasError('required') || contaCorrenteDigito.hasError('required') || banco.hasError('invalidBank') ? 'disabled' : 'enabled'"  [label]="!assinaturaEletronicaHabilitada ? 'SALVAR' : 'CONTINUAR'" type="primary" [press]="openModalNext"></yfs-button>
    </div>
    </mat-card-content>
  </mat-card>
  <div class="container-general">
  <mat-card *ngIf="showSendCard && assinaturaEletronicaHabilitada" class="card">
    <mat-card-content class="card-content">
       <div class="recipient-content">
        <span>Escolha como quer receber o termo de cancelamento:</span>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="formaEnvio">
          <mat-radio-button value="Email">E-mail</mat-radio-button>
          <mat-radio-button value="Whatsapp">Whatsapp</mat-radio-button>
          <mat-radio-button value="Sms">SMS</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field *ngIf="formaEnvio === 'Email'" style="width: 100%;" appearance="fill">
        <mat-label>E-mail</mat-label>
        <input data-test="input-email" [formControl]="email" matInput maxlength="50">
      </mat-form-field>
      <mat-form-field *ngIf="formaEnvio === 'Whatsapp'" style="width: 100%;" appearance="fill">
        <mat-label>Whatsapp</mat-label>
        <input data-test="input-phone-cell-ddd"  [formControl]="celular" mask="(00) 0 0000-0000" matInput>
      </mat-form-field>
      <mat-form-field *ngIf="formaEnvio === 'Sms'" style="width: 100%;" appearance="fill">
        <mat-label>SMS</mat-label>
        <input data-test="input-phone-cell-ddd"  [formControl]="sms" mask="(00) 0 0000-0000" matInput>
      </mat-form-field>
      <div style="margin-bottom: 24px; width: 100%;">
        <div class="alert-message">
          <span class="icon-alert-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
      fontIcon="info"></mat-icon></span>
          <span class="title-alert-message">Certifique-se que os dados estejam corretos, pois não poderão ser alterados após a confirmação.</span>
        </div>
    </div>
    <div class="button-confirm">
      <yfs-button data-test="button-regulamento" label="CONFIRMAR FORMA DE ENVIO" icon="check_circle" (click)="verifyShipping()" type="primary" state="enabled"></yfs-button>
    </div>
    </mat-card-content>
</mat-card>
<div *ngIf="termSent && assinaturaEletronicaHabilitada" class="container-general">
  <div style="text-align: center;">
    <span class="verify-title-message">Termo de cancelamento enviado e aguardando assinatura.</span>
  </div>
  <div class="verify-message">
    <span class="verify-subtitle-message">Verifique o e-mail, whatsapp ou sms escolhido para envio e <b>assine o termo de cancelamento</b> para efetivar a desistência do contrato.</span>
  </div>
  <div  style="margin-bottom: 40px;">
    <div class="card-signature-message">
      <span class="icon-signature-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
  fontIcon="info"></mat-icon></span>
      <span class="signature-message"><b>Assine o termo até {{ dataExpiracaoTermoCancelamento | date : 'dd/MM/yyyy  HH:mm' }}</b> para efetivar a devolução de valores do contrato. A não assinatura do mesmo resultará no retorno do ao status anterior.</span>
    </div>
  </div>
  <div class="plan">
  <yfs-button data-test="button-regulamento" label="IR PARA PLANOS CONTRATADOS" class="plan" (click)="goToPlan()" type="primary" state="enabled"></yfs-button>
  </div>
</div>
</div>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

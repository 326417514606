<section>
  <div class="container-content">
    <div class="go-top-button" (click)="goToTop()">
      <img src="assets/img/arrow-up.png" alt="voltar para o topo" class="arrow-img" />
      <span>Voltar para o topo</span>
    </div>

    <div class="about-content">
      <div class="about-section">
        <div class="about-section-left">
          <p class="title-information">Produto do Banco Yamaha Motor do Brasil S.A</p>
          <p class="description-information">CNPJ: 10.371.492/0001-85</p>
        </div>
        <div class="about-section-right">
          <p class="title-information">Av. Magalhães de Castro, 4.800 - Ed. Continental Tower - 7º Andar Cj. 71 e 72</p>
          <p class="description-information">05676-120 - Cidade Jardim - São Paulo - SP</p>
        </div>
      </div>
      <div class="about-section about-section-margin">
        <div class="about-section-left">
          <p class="title-information">SAC</p>
          <p class="description-information">liberacred&#64;yamaha-motor.com.br</p>
          <p class="description-information">(11) 2088-7700</p>
        </div>
        <div class="about-section-right">
          <p class="title-information">Ouvidoria</p>
          <p class="description-information">ouvidoria&#64;yamaha-motor.com.br</p>
          <p class="description-information">0800 774-9000</p>
        </div>
      </div>
    </div>

    <div class="logo-section">
      <img src="assets/img/liberacred2x.png" height="15" width="138" alt="logo yamaha serviços financeiros">
      <p class="copyright-information"><span>Copyright</span>&copy;<span> Liberacred. Todos os direitos reservados.</span></p>
    </div>
  </div>
</section>

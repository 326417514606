import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-expired-ticket',
    templateUrl: './modal-expired-ticket.component.html',
    styleUrls: ['./modal-expired-ticket.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, ButtonComponent]
})
export class ModalExpiredTicketComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalExpiredTicketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  getText() {
    return this.data.type == 'baixar' ? 
    `"Gerar e baixar novo boleto" para fazer o download.` : 
    `"Gerar e enviar por e-mail" para receber o boleto no e-mail cadastrado.`
  }

  getTextButton() {
    return this.data.type == 'baixar' ?
    "GERAR E BAIXAR NOVO BOLETO" :
    "GERAR E ENVIAR POR E-MAIL"
  }

  handleClickButton() {
    this.closeModal()
    this.data.handleClickButton()
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { NgFor, NgIf } from '@angular/common';
import { AlertBoxComponent } from '../../../components/alert-box/alert-box.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-simulation-category',
    templateUrl: './simulation-category.component.html',
    styleUrls: ['./simulation-category.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, AlertBoxComponent, LoadingSpinnerComponent]
})
export class SimulationCategoryComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<{ flow: string, productsList: any }>();

  public categoryList: any;
  public isLoading = false;
  public listIsEmpty = false;
  public timeoutError = false;
  public genericError = false;
  public convertBase64 = 'data:image/png;base64,'

  constructor(private apiService: ApiHttpService) { }

  ngOnInit() {
    this.isLoading = true;
    this.apiService.getProductAndCategory().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.categoryList = result.categoriasProduto;
          if (!this.categoryList || this.categoryList.length === 0) this.listIsEmpty = true;
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          if (this.timeoutError === false) this.genericError = true;
          console.log(error)
        }
      })
  }

  changeCurrentFlow(productsList: any) {
    this.currentFlowChanged.emit({ flow: "product", productsList: productsList })
  }

}

<yfs-header></yfs-header>
<section class="container-content">
  <div>
    <div class="button-back">
      <yfs-link-back text="Voltar para minha área" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>PARÂMETROS TÉCNICOS</h1>
    <div class="card-container">
      <mat-card class="card">
        <mat-card *appHasAuthorization="comunicationAllowedPermissions" (click)="goToComunication()" class="min-card">
          <span class="h1">COMUNICAÇÃO</span>
        </mat-card>
        <mat-card *appHasAuthorization="productsAllowedPermissions" (click)="goToProducts()" class="min-card">
          <span class="h1">PRODUTOS</span>
        </mat-card>
        <mat-card *appHasAuthorization="parameterTariffAllowedPermissions" (click)="goToParameterCommission()" class="min-card">
          <span class="h1">CADASTRAR COMISSÃO</span>
        </mat-card>
        <mat-card *appHasAuthorization="parameterTariffAllowedPermissions" (click)="goToParameterTariff()" class="min-card">
          <span class="h1">CADASTRAR TARIFAS</span>
        </mat-card>
        <mat-card *appHasAuthorization="tariffHistoricAllowedPermissions" (click)="goToOldTablesTariff()" class="min-card">
          <span class="h1">CONSULTAR TARIFAS</span>
        </mat-card>
        <mat-card *appHasAuthorization="tariffHistoricAllowedPermissions" (click)="goToOldTablesCommissions()" class="min-card">
          <span class="h1">CONSULTAR COMISSÕES</span>
        </mat-card>
        <mat-card *appHasAuthorization="concessionairesAndPartnersAllowedPermissions" (click)="goToConcessionairesAndPartners()" class="min-card">
          <span class="h1">CONCESSIONÁRIAS E PARCEIROS</span>
        </mat-card>
        <mat-card *appHasAuthorization="manageMinutaAllowedPermissions" (click)="goToDraftList()" class="min-card">
          <span class="h1">MINUTAS</span>
        </mat-card>
      </mat-card>
    </div>
  </div>
</section>

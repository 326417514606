<section class="container-content">
  <div class="container-title">
      <h1>Editar perfil</h1>
      <p>Apenas as permissões do perfil podem ser editadas.</p>
  </div>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Título do perfil</mat-label>
          <input data-test="input-name" [formControl]="name" matInput maxlength="100">
          <mat-error *ngIf="name.invalid">
            O campo é obrigatório
          </mat-error>
        </mat-form-field>

        <div class="radio-group">
          <p>Tipo de Perfil:</p>
          <mat-radio-group [formControl]="profileType">
            <mat-radio-button [value]="true">Interno</mat-radio-button>
            <mat-radio-button [value]="false">Externo</mat-radio-button>
          </mat-radio-group>
        </div>

        <div style="margin-top: 12px;">
          <p>Permissões do perfil:</p>
        </div>
        <div class="checkbox-group" style="max-height: 450px; overflow-y: auto;">
          <mat-checkbox color="primary" *ngFor="let permission of permissions" [formControl]="permission.control">
            {{ permission.label }}
          </mat-checkbox>
        </div>
        <div class="container-buttons">
          <div class="content-buttons">
            <yfs-button state="enabled" label="CANCELAR" (click)="openDialogCancel()" type="secondary"></yfs-button>
            <yfs-button [state]="name.hasError('required') || !checkSelecionado() ? 'disabled' : 'enabled'" [press]="sendRequest" label="SALVAR" type="primary"></yfs-button>
          </div>
        </div>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<div class="container-title">
    <div class="button-back">
        <yfs-link-back text="Voltar para listagem" (click)="getBack()"></yfs-link-back>
    </div>
    <h1>CADASTRO DE NOVA MINUTA</h1>
</div>

<div class="container-content">
    <mat-card class="card-message">
        <mat-card-content>
            <div class="title-card-content">
                <span class="title-card">Dados da minuta</span>
            </div>

            <div class="required-message-container" *ngIf="mensagemInvalid">
                <div class="required-message-bar"></div>
                <span>{{typeErrorMessage === 'required' ? 'Preenchimento obrigatório' : 'O campo deve possuir no mínimo
                    20 caracteres.'}}</span>
            </div>

            <div class="fields-content">
                <div class="col-fields">
                    <mat-form-field id="nomenclatura" appearance="fill">
                        <mat-label>Nomenclatura para armazenamento</mat-label>
                        <input [formControl]="nomenclatura" maxlength="150" matInput>
                        <mat-error *ngIf="nomenclatura.hasError('required')">{{genericMenssage}}</mat-error>
                        <mat-error *ngIf="nomenclatura.hasError('maxlength')">só é permitido 150 caracteres</mat-error>
                    </mat-form-field>

                    <mat-form-field id="tipoMinuta" appearance="fill">
                        <mat-label>Tipo de minuta</mat-label>
                        <mat-select data-test="tipoMinuta" [formControl]="tipoMinuta">
                            <mat-option *ngFor="let tipo of tipoMinutaList" [value]="tipo?.idTipoMinuta">
                                <span class="select-label"> {{tipo?.descricao }}</span>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="tipoMinuta.invalid">
                            {{genericMenssage}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-fields">
                    <mat-form-field appearance="fill">
                        <mat-label>Data de início de vigência</mat-label>
                        <input type="hidden" [matDatepicker]="pickerInicio" [min]="minDate"
                            [(ngModel)]="dataInicialLabel" (ngModelChange)="onInputChangeInicial(true)" />
                        <input matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [min]="minDate" [formControl]="dataInicial"
                            [ngModel]="dataInicialLabel | date : 'dd/MM/yyyy'"
                            (ngModelChange)="onInputChangeInicial(false)" />
                        <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                        <mat-datepicker #pickerInicio></mat-datepicker>
                        <mat-error *ngIf="dataInicial.hasError('required')">
                            Campo obrigatório
                        </mat-error>
                        <mat-error *ngIf="dataInicial.hasError('invalidDate')">Data inválida.</mat-error>
                        <mat-error class="date-error" *ngIf="dataInicial.hasError('dataValida')">Data inválida. Coloque
                            uma
                            data futura.</mat-error>
                    </mat-form-field>

                    <div style="flex: 1"></div>
                </div>
                <div class="col-fields">
                    <div class="minuta-upload-description">
                        <span>Insira aqui o arquivo da minuta</span>
                        <span>Somente um arquivo em HTML</span>
                    </div>

                    <div class="file-container">
                        <a class="link-button" (click)="fileInput.click()">Escolher o arquivo</a>
                        <input hidden data-test="input-file" (change)="onFileSelected($event)" #fileInput type="file" accept=".html"
                            id="file">
                        <div class="file-name-label">{{fileName}}<span *ngIf="fileName" (click)="onRemoveFile()"><img
                                    class="trash-icon" src="assets/svg/trash.svg" alt="Ícone para deletar"></span></div>
                        <span data-test="error-message-file-size" *ngIf="fileSizeError || invalidFormat" class="error-message"><img
                                src="assets/svg/atention.svg" alt="Ícone de atenção">
                                {{fileSizeError ? 'O arquivo ultrapassou o tamanho máximo de 5MB' :  'O formato do arquivo é inválido'}}
                            </span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="card-wilds">
        <mat-card-content>
            <div class="title-card-content">
                <span class="title-card">Wildcards</span>
            </div>
            <span class="subtitle-card">
                Os wildcards destacados em verde foram identificados no documento fornecido. Caso haja a necessidade de incluir algum outro, revise o documento adicionando o termo literal dessa lista e faça um novo upload.
            </span>

            <mat-form-field appearance="fill">
                <mat-label>Busque por wildcards</mat-label>
                <mat-icon aria-hidden="false" aria-label="Ícone de busca" class="search-icon material-symbols-outlined"
                    fontIcon="search"></mat-icon>
                <input [(ngModel)]="search" (keyup)="applyFilter()"  matInput>
            </mat-form-field>

            <div class="wildcard-content">
                <div [ngClass]="previewContent && previewContent.includes(wildcard?.tag) ? 'wildcard-selected' : 'wildcard' " *ngFor="let wildcard of filtedListWildCards">
                    {{wildcard?.tag}}</div>
            </div>
        </mat-card-content>
    </mat-card>

</div>
<div class="buttons-container">
    <yfs-button [fullWidth]="isFullWidth" state="enabled" label="CANCELAR" type="secondary" (click)="cancelButtonHandle()"></yfs-button>

    <div>
      <yfs-button [fullWidth]="isFullWidth" [state]="fileName ? 'enabled' : 'disabled'" label="VISUALIZAR" type="secondary" icon="visibility" [press]="openModalPreview"></yfs-button>
        <yfs-button [state]="isEnbledButton() ? 'enabled' : 'disabled'" label="SALVAR" type="primary" [press]="handleSaveButtonClick" [fullWidth]="isFullWidth"></yfs-button>
    </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmAccreditationComponent } from 'src/app/components/modal-confirm-accreditation/modal-confirm-accreditation.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalListConcessionairesComponent } from 'src/app/components/modal-list-concessionaires/modal-list-concessionaires.component';
import { ModalAccreditationComponent } from 'src/app/components/modal-accreditation/modal-accreditation.component';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { ModalConfirmDeaccreditationComponent } from 'src/app/components/modal-confirm-deaccreditation/modal-confirm-deaccreditation.component';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { HasAuthorizationDirective } from '../../shared/directives/has-authorization.directive';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { CnpjPipe } from '../../shared/pipes/cnpj.pipe';

@Component({
    selector: 'yfs-concessionaires-and-partners',
    templateUrl: './concessionaires-and-partners.component.html',
    styleUrls: ['./concessionaires-and-partners.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, HasAuthorizationDirective, ButtonComponent, MatCard, MatCardContent, NgIf, MatIcon, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatSelect, MatCheckbox, NgFor, MatOption, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, TooltipComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, NgClass, MatPaginator, LoadingSpinnerComponent, CnpjPipe]
})
export class ConcessionairesAndPartnersComponent implements OnInit {
  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  public previousScreen: string = '';
  public hasConcessionaire = [EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA]
  public searchString = new FormControl('');
  public tipo = new FormControl();
  public status = new FormControl();
  public grupoFinanceiro = new FormControl();
  public hasValue = true;
  public length = 50;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public isLoading = false;
  public idTipoValue = 0;
  public showAlertBox = false;
  public inative = 0;
  public currentFlow = '';
  public listaTipo = [{
    IdTipo: 1,
    Nome: "Concessionária"
  },
  {
    IdTipo: 2,
    Nome: "Parceiro"
  }
  ];
  listaStatus = [{
    IdStatus: 1,
    Nome: 'Credenciada para a venda'
  },
  {
    IdStatus: 5,
    Nome: 'Inativas'
  },
  {
    IdStatus: 2,
    Nome: 'Não credenciada para a venda'
  },
  {
    IdStatus: 3,
    Nome: 'Não definidas'
  },]
  public listaGrupoFinanceiro: { codigoGrupo: number; nomeGrupo: string }[] = [];
  public selectedTipo: number[] = []
  public selectedGroup: number[] = []
  public idsTipo: number[] = []
  public idsGroup: number[] = []
  public checkedAllTipo = false;
  public checkedAllGroup = false;
  public checkedAllStatus = false;
  public selectedStatus: number[] = [];
  public idsStatus: number[] = [];
  public listRecords: any;
  public idConcessionariaAccredit = 0;
  public idConcessionariaDecertify = 0;
  public idConcessionariaDecertifyDestino = 0;

  displayedColumns: string[] = ['codigo', 'razaoSocial', 'grupoFinanceiroNome', 'tipo', 'status', 'acoes'];
  dataSource: any

  constructor(
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
    private customPaginator: MatPaginatorIntl,
    public dialog: MatDialog,
  ) {
    customPaginator.itemsPerPageLabel = "Itens por página";
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  ngOnInit() {
    this.search();
    this.getFinancialGroup();
  }

  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  getFinancialGroup(){
    this.apiService.getFinancialGroup().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          if(result){

            this.listaGrupoFinanceiro = result.body
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  checkHandleFinancialGroup(idGrupo: number) {
    if (this.selectedGroup.includes(idGrupo)) {
      this.selectedGroup = this.selectedGroup.filter(item => item !== idGrupo);
    } else {
      this.selectedGroup.push(idGrupo);
    }

    this.idsGroup = this.selectedGroup.slice();

    if (this.selectedGroup.length === this.listaGrupoFinanceiro.length) {
      this.checkedAllGroup = true;
    } else {
      this.checkedAllGroup = false;
    }
    this.grupoFinanceiro.setValue(this.selectedGroup);
  }

  selectAllFinancialGroup() {
    if (this.selectedGroup.length === this.listaGrupoFinanceiro.length) {
      this.selectedGroup = [];
      this.idsGroup = [];
      this.checkedAllGroup = false;
    } else {
      this.selectedGroup = this.listaGrupoFinanceiro.map(grupoFinanceiro => grupoFinanceiro.codigoGrupo);
      this.idsGroup = this.selectedGroup.slice();
      this.checkedAllGroup = true;
    }
    this.grupoFinanceiro.setValue(this.selectedGroup);
    this.search();
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  formatCNPJ(cnpj: string) {
    if (!cnpj) return '';
    const cleanedCNPJ = cnpj.replace(/\D/g, '');
    if (cleanedCNPJ.length !== 14) return ''
    const formattedCNPJ = cleanedCNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');

    return formattedCNPJ;
  }

  getTextToToolTip(infos: any) {
    return `
      <strong>Descredenciada em:</strong> ${this.formatDate(infos?.historico?.dataAlteracao)}
      <br />
      <strong>Responsável:</strong> ${infos?.historico?.nomeResponsavel}
      <br/><br/>
      <strong>Clientes atribuídos para:</strong>
      <br/>
      ${infos?.historico?.nomeConcessionariaDestino || '-'}
      <br/>
      CNPJ: ${this.formatCNPJ(infos?.historico?.cnpjConcessionariaDestino)}
    `
  }

  getTipoDescription(tipo: number): string {
    const tipoObj = this.listaTipo.find(item => item.IdTipo === tipo);
    return tipoObj ? tipoObj.Nome : '';
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.search()
  }

  checkHandleTipo(IdTipo: number) {
    if (this.selectedTipo.includes(IdTipo)) {
      this.selectedTipo = this.selectedTipo.filter(item => item !== IdTipo);
    } else {
      this.selectedTipo.push(IdTipo);
    }

    this.idsTipo = this.selectedTipo.slice();

    if (this.selectedTipo.length === this.listaTipo.length) {
      this.checkedAllTipo = true;
    } else {
      this.checkedAllTipo = false;
    }
    this.tipo.setValue(this.selectedTipo);
  }

  selectAllTipo() {
    if (this.selectedTipo.length === this.listaTipo.length) {
      this.selectedTipo = [];
      this.idsTipo = [];
      this.checkedAllTipo = false;
    } else {
      this.selectedTipo = this.listaTipo.map(tipo => tipo.IdTipo);
      this.idsTipo = this.selectedTipo.slice();
      this.checkedAllTipo = true;
    }
    this.tipo.setValue(this.selectedTipo);
    this.search();
  }

  checkHandleStatus(IdStatus: number) {
    if (this.selectedStatus.includes(IdStatus)) {
      this.selectedStatus = this.selectedStatus.filter(item => item !== IdStatus);
    } else {
      this.selectedStatus.push(IdStatus);
    }

    this.idsStatus = this.selectedStatus.slice();

    if (this.selectedStatus.length === this.listaStatus.length) {
      this.checkedAllStatus = true;
    } else {
      this.checkedAllStatus = false;
    }
    this.status.setValue(this.selectedStatus);
  }

  selectAllStatus() {
    if (this.selectedStatus.length === this.listaStatus.length) {
      this.selectedStatus = [];
      this.idsStatus = [];
      this.checkedAllStatus = false;
    } else {
      this.selectedStatus = this.listaStatus.map(status => status.IdStatus);
      this.idsStatus = this.selectedStatus.slice();
      this.checkedAllStatus = true;
    }
    this.status.setValue(this.selectedStatus);
    this.search();
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  setPageStart() {
    this.pageIndex = 0;
  }

  search() {
    if(this.idsTipo.length > 1){
      this.idsTipo = []
    }
    const searchValue = this.searchString.value ?? ""
    const idsStatusValues = this.idsStatus.length == this.listaStatus.length ? [] : this.idsStatus;
    this.isLoading = true;
    this.apiService.getConcessionariasIntegracao(searchValue, this.idsTipo, this.idsGroup, idsStatusValues, this.pageIndex + 1, this.pageSize).pipe(
      timeout(60000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        this.isLoading = false;
        this.listRecords = result.items;
        this.inative = result.counterContracts;
        if (result?.hasContract) this.showAlertBox = true;
        else this.showAlertBox = false;
        this.dataSource = new MatTableDataSource<any>(this.listRecords);
        if (this.listRecords && this.listRecords.length === 0) this.hasValue = false
        else this.hasValue = true;
        setTimeout(() => {
          this.dataSource.sort = this.firstTableSort;
        }, 100)
        this.length = result.totalCount;
        this.customPaginator.getRangeLabel = this.getRangeLabel;
      },
      error: error => {
        this.isLoading = false;
        console.log(error)
      }
    })
  }

  getStatusColor(value: number) {
    switch (value) {
      case 1:
        return 'var(--color-functional-positive-1)';
      case 2:
        return 'var(--color-functional-danger-2)';
      case 4:
        return 'var(--color-functional-danger-2)';
      case 5:
        return 'var(--color-functional-danger-2)';
      default:
        return 'var(--color-neutral-gray-4)';
    }
  }

  getStatusIcon(value: number) {
    switch (value) {
      case 1:
        return 'check';
      case 2:
        return 'close';
      case 4:
        return 'close';
      case 5:
        return 'close';
      case null:
        return 'check_indeterminate_small';
      default:
        return 'check_indeterminate_small';
    }
  }

  getStatusDescription(value: number) {
    if (value == 1) return 'Credenciada'
    else if (value == 2) return 'Não credenciada'
    else if (value == 3) return 'Não definida'
    else if (value == 4 || value == 5) return 'Inativa'
    else return '';
  }

  decertify() {
    this.isLoading = true;
    this.apiService.descredenciar(this.idConcessionariaDecertify, this.idConcessionariaDecertifyDestino || undefined).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (this.currentFlow === 'transfer') {
            this.openDialogSucessTransfer();
          } else {
            this.openDialogSucess('Descredenciamento concluído com sucesso.');
          }
          this.search();
        },
        error: (error) => {
          this.isLoading = false;
          if (this.currentFlow === 'transfer') {
            this.openDialogErrorTransfer();
          } else {
            this.openDialogError('descredenciamento');
          }
          console.log(error);
        },
      });
  }

  verifyPlan(infos: any, flow: string){
    this.currentFlow = ''
    this.isLoading = true;
    this.idConcessionariaAccredit = infos?.idConcessionaria;
    this.apiService.getLinkedPlan(this.idConcessionariaAccredit, true).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          if(result){
            this.isLoading = false;
            const disabledType = result.body
            this.openDialogConfirmDeaccreditation(infos, disabledType)
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  openDialogConfirmDeaccreditation(infos: any, hasPlan: boolean) {
    this.idConcessionariaDecertify = infos?.idConcessionaria;
    this.dialog.open(ModalConfirmDeaccreditationComponent, {
      width: '442px',
      data: {
        hasPlan,
        decertifyEvent: (hasPlan: boolean) => {
          if (hasPlan) {
            this.openDialogConcessionarias()
          } else {
            this.idConcessionariaDecertifyDestino = 0;
            this.decertify()
          }
        }
      }
    });
  }

  openDialogSucess(mensage: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '383px',
      data: {
        icon: 'success',
        textBold: mensage,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogError(flow: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '397px',
      data: {
        icon: 'error',
        secondaryText: `Algo deu errado e não conseguimos fazer o descredenciamento. <br /> <strong>Tente outra vez ou volte mais tarde.</strong>`,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.decertify() }
      },
    });
  }

  openDialogConcessionarias() {
    this.dialog.open(ModalListConcessionairesComponent, {
      width: '620px',
      data: {
        title: 'Indicar nova concessionária',
        subTitle: 'Para descredenciar a concessionária é obrigatório indicar uma nova para os clientes.',
        callbackHandle: (concessionaria: any) => {
          this.idConcessionariaDecertifyDestino = concessionaria.idConcessionaria;
          this.decertify()
        }
      }
    })
  }

  openDialogSucessTransfer() {
    this.dialog.open(ModalGenericComponent, {
      width: '383px',
      data: {
        icon: 'success',
        text: 'Transferência concluída com sucesso.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorTransfer() {
    this.dialog.open(ModalGenericComponent, {
      width: '397px',
      data: {
        icon: 'error',
        secondaryText: `Algo deu errado e não conseguimos fazer a transferência. <br /> <strong>Tente outra vez ou volte mais tarde.</strong>`,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => { this.decertify() }
      },
    });
  }

  openModalAccreditation() {
    this.dialog.open(ModalAccreditationComponent, {
      width: '680px',
    });
  }

  transfer(element: any){
    this.currentFlow = 'transfer';
    this.openDialogConcessionarias();
    this.idConcessionariaDecertify = element.idConcessionaria;
  }

}

import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { DadosBancarios, IContractedItem } from 'src/app/models/contracted-plans.interface';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ICancelPlan } from 'src/app/models/cancel-plan.interface';
import { ModalUploadTermComponent } from 'src/app/components/modal-upload-term/modal-upload-term.component';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { ModalCancelPlanComponent } from 'src/app/components/modal-cancel-plan/modal-cancel-plan.component';
import { UserService } from 'src/app/services/user.service';
import { ModalDocumentationComponent } from 'src/app/components/modal-documentation/modal-documentation.component';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/services/data-service';
import { ModalSendEmailComponent } from 'src/app/components/modal-send-email/modal-send-email.component';
import { ModalExpiredTicketComponent } from 'src/app/components/modal-expired-ticket/modal-expired-ticket.component';
import { ModalSummaryComponent } from 'src/app/components/modal-summary/modal-summary.component';
import { ModalTermCancelComponent } from 'src/app/components/modal-term-cancel/modal-term-cancel.component';
import { ModalAccountGenerationComponent } from 'src/app/components/modal-account-generation/modal-account-generation.component';
import { Router } from '@angular/router';
import { NgIf, NgFor, CurrencyPipe, DatePipe } from '@angular/common';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';
import { SealEligibilityComponent } from '../../../components/seal-eligibility/seal-eligibility.component';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { HasAuthorizationDirective } from '../../../shared/directives/has-authorization.directive';
import { TooltipComponent } from '../../../components/tooltip/tooltip.component';
import { CancelPlanComponent } from './cancel-plan/cancel-plan.component';
import { ClientDataComponent } from '../client-data/client-data.component';
import { RefundRateComponent } from '../refund-rate/refund-rate.component';
import { BankDataComponent } from './bank-data/bank-data.component';
import { ReleasePaidAmountsComponent } from './release-paid-amounts/release-paid-amounts.component';
import { PlanExtractComponent } from './plan-extract/plan-extract.component';
import { ProofPaymentComponent } from './proof-payment/proof-payment.component';
import { ExtensionComponent } from './extension/extension.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';
import { CpfPipe } from '../../../shared/pipes/cpf.pipe';
import { DateConvertPipe } from '../../../shared/pipes/date-convert.pipe';
import { TelefonePipe } from '../../../shared/pipes/telefone.pipe';

interface Documento {
  nome: string;
  assinado: boolean;
  dataExpiracao: string | null;
  tipoAssinatura: string;
}

interface Parametro {
  assinaturaEletronicaHabilitada: boolean;
}

@Component({
    selector: 'yfs-client-plan',
    templateUrl: './client-plan.component.html',
    styleUrls: ['./client-plan.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        LinkBackComponent,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatIcon,
        MatCard,
        SealEligibilityComponent,
        MatCardContent,
        ButtonComponent,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        HasAuthorizationDirective,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        MatCellDef,
        MatCell,
        TooltipComponent,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        NgFor,
        MatPaginator,
        CancelPlanComponent,
        ClientDataComponent,
        RefundRateComponent,
        BankDataComponent,
        ReleasePaidAmountsComponent,
        PlanExtractComponent,
        ProofPaymentComponent,
        ExtensionComponent,
        LoadingSpinnerComponent,
        CurrencyPipe,
        DatePipe,
        CpfPipe,
        DateConvertPipe,
        TelefonePipe,
    ],
})

export class ClientPlanComponent implements AfterViewInit {
  @Input() contractedPlan: IContractedItem | undefined;
  @Output() currentFlowChanged = new EventEmitter<string>();
  @Output() emitEventToContractedPlans = new EventEmitter<string>();

  public termoAdesaoEletronicoAssinado: boolean | undefined;
  public termoAdesaoManualAssinado: boolean | undefined;
  public termoAssinado = false
  public documentos: Documento[] = [];
  dadosBancarios: DadosBancarios[] = []
  public parcelaBoletoList: number[] = []

  public displayedColumns2: string[] = [
    'recarga',
    'valor',
    'dataPrimeiroVencimento',
    'dataLimitePagamento',
    'dataPagamento',
    'pontual',
    'status',
    'boleto'
  ];
  public dataSource2: any;
  public cancelPlanIsOpen = false;
  public editClientDataIsOpen = false;
  public refundRateIsOpen = false;
  public editBankDataIsOpen = false;
  public proofPaymentIsOpen = false;
  public proofPaymentIsEnabled = false;
  public isLoading = false;
  public cancelAllowedPermissions = [EPermissaoAcesso.DESISTIR_PLANO]
  public showRefundRate = true;
  public isRefundRateFlow = false;
  public isRefundRateFlowOk = false;
  public refundRateAlowedPermissions = [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES]
  public proofPaymentAlowedPermissions = [EPermissaoAcesso.REGULARIZAR_RESTRICAO_CREDITO]
  public showSacItens = false;
  public dataList: any[] = [];
  public selectedIds: number[] = [];
  public pageSize = 10;
  public pageIndex = 0;
  public length = 50;
  public pageSizeOptions = [5, 10, 20];
  public hasValue = true;
  public showTable = false;
  public tableList: any[] = [];
  public dataSource: any
  public isSinglePage = false;
  public totalPages = 0;
  public totalCount = 0;
  public status = '';
  public pontualidade = '';
  public quitacao = '';
  public restricao = '';
  public termoCancelamentoAssinado = true
  public cancelPlanShow = false
  public displayedColumns: string[] = ['nomeUsuario', 'termosLiberacred', 'dataSolicitacao'];
  public assinaturaEletronicaHabilitada: boolean | null = false;
  public isBoletoBeingGenerated = false;
  public tentativas = 0;
  public boleto: string = '';
  public showGenerateButton = false;
  public showReleaseValues = true
  public releaseValuesIsOpen = false;
  public planExtractIsOpen = false;
  public extractAlowedPermissions = [EPermissaoAcesso.EXTRATO_PLANO]
  public amountAlowedPermissions = [EPermissaoAcesso.SOLICITA_VALORES_PLANO_QUITADO]
  public isFullWidth = window.innerWidth < 1279;
  public extensionIsOpen = false;
  public tabInfo: any;
  public isHoveringRelacionamento: boolean = false;
  public isHoveringOriginacao: boolean = false;
  public permissions: string[] = [];
  isMobile: boolean = false;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private cdref: ChangeDetectorRef,
    public dialog: MatDialog,
    private apiService: ApiHttpService,
    private userService: UserService,
    private dataService: DataService,
    private router: Router
  ) {

  }

  @ViewChild('secondTableSort')
  secondTableSort!: MatSort;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
    this.checkIfMobile();
  }

  ngOnInit() {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.permissions = user.PermissaoAcesso || [];
    }
    this.assinaturaEletronicaHabilitada = this.obterAssinaturaEletronicaHabilitada();
    this.proofPaymentIsEnabled = this.verifyProofPaymentIsEnabled(
      this.contractedPlan?.pontualidade,
      this.contractedPlan?.quitacao,
      this.contractedPlan?.restricaoCredito,
      this.contractedPlan?.statusContratoNumero
    );
    this.showGenerateButton = this.contractedPlan?.parcelas && this.contractedPlan?.parcelas.length < 1 || false;
    this.cdref.detectChanges();
    this.showExtension();
    this.getTabInfo(this.contractedPlan?.idContrato);
  }

  ngAfterViewInit() {
    this.verifySacPermission();
    this.verifyRefundRate();
    this.dataSource2 = new MatTableDataSource(this.contractedPlan?.parcelas);
    this.dataSource2.sort = this.secondTableSort;
    this.dataSource2.sortingDataAccessor = (data: any, sortHeaderId: any) => {
      if (sortHeaderId === 'status') {
        return data.statusParcelaDescricao;
      }
      return data[sortHeaderId];
    };
    this.cdref.detectChanges();
    this.isSinglePage = Math.ceil(this.length / this.pageSize) === 1;
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 820;
  }

  onMouseEnterRelacionamento() {
    if (!this.isMobile) {
      this.isHoveringRelacionamento = true;
    }
  }

  onMouseLeaveRelacionamento() {
    if (!this.isMobile) {
      this.isHoveringRelacionamento = false;
    }
  }

  onMouseEnterOriginacao() {
    if (!this.isMobile) {
      this.isHoveringOriginacao = true;
    }
  }

  onMouseLeaveOriginacao() {
    if (!this.isMobile) {
      this.isHoveringOriginacao = false;
    }
  }

  getTabInfo(IdContrato: any) {
    this.isLoading = true;
    this.apiService
      .getConcessionariasContrato(IdContrato)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result: any) => {
          if (result) {
            this.tabInfo = result
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        }
      });
  }

  verifyAmountPermission() {
    const quitado = this.contractedPlan?.quitacao === 1;
    const statusElegivel = this.contractedPlan?.statusContratoDescricao.toUpperCase() === 'ELEGIVEL'
    const statusInelegivel = this.contractedPlan?.statusContratoDescricao.toUpperCase() === 'INELEGÍVEL'
    const statusInativo = this.contractedPlan?.statusInativoPermitido;
    const statusPermitido = [2, 3, 8].includes(this.contractedPlan?.statusContratoNumero as number);

    return quitado && (statusElegivel || statusInelegivel || statusInativo) && statusPermitido;
  }

  obterAssinaturaEletronicaHabilitada(): boolean | null {
    const parametrosJSON = sessionStorage.getItem('parametros');

    if (parametrosJSON) {
      const parametros: Parametro[] = JSON.parse(parametrosJSON);

      if (parametros.length > 0 && typeof parametros[0].assinaturaEletronicaHabilitada === 'boolean') {
        return parametros[0].assinaturaEletronicaHabilitada;
      }
    }

    return null;
  }

  assinaturaEletronicaNaoAssinada(): boolean {
    return this.contractedPlan?.documentos?.some(doc => doc.tipoAssinatura === 'Eletrônica' && !doc.assinado) ?? false;
  }

  getExpiracaoTermoAdesao(): string | null {
    const termoAdesao = this.documentos.find(doc => doc.nome === 'TermoAdesao');
    return termoAdesao ? termoAdesao.dataExpiracao : null;
  }

  verificarAssinaturaCancelamento(): boolean {
    if (this.contractedPlan?.documentos) {
      const documentoCancelamento = this.contractedPlan.documentos.find(doc => doc.nome === 'TermoCancelamento');
      return !!documentoCancelamento;
    }
    return false;
  }

  verificarAssinaturaEletronica(): boolean {
    if (this.contractedPlan?.documentos) {
      const documentoEletronico = this.contractedPlan.documentos.find(doc => doc.tipoAssinatura === 'Eletrônica');
      return !!documentoEletronico && !documentoEletronico.assinado;
    }
    return false;
  }

  verificarAssinaturaManual(): boolean {
    if (this.contractedPlan?.documentos) {
      const documentoManual = this.contractedPlan.documentos.find(doc => doc.tipoAssinatura === 'Manual');
      const assinaturaManualNaoEfetuada = !!documentoManual && !documentoManual.assinado;
      return assinaturaManualNaoEfetuada;
    }
    return false;
  }

  showInstallmentTable(): boolean {
    if ((this.contractedPlan?.statusContratoNumero === 18 || this.contractedPlan?.statusContratoNumero === 19) && this.contractedPlan?.parcelas && this.contractedPlan.parcelas.length > 0) {
      return true;
    }
    return !this.verificarAssinaturaEletronica() || this.verificarAssinaturaCancelamento();
  }

  showEmpty(): boolean {
    if (this.contractedPlan?.statusContratoNumero !== 18 && this.contractedPlan?.statusContratoNumero !== 19) {
      return this.verificarAssinaturaEletronica() && !this.verificarAssinaturaManual() && !this.verificarAssinaturaCancelamento();
    }
    return false;
  }

  isTermoCancelamentoPendente(): boolean {
    const hoje = new Date();
    if (this.contractedPlan?.documentos) {
      const termoCancelamento = this.contractedPlan.documentos.find(doc => doc.nome === 'TermoCancelamento');
      if (termoCancelamento && termoCancelamento.dataExpiracao) {
        const dataExpiracao = new Date(termoCancelamento.dataExpiracao);
        return !termoCancelamento.assinado && dataExpiracao > hoje;
      }
    }
    return false;
  }

  isTermoCancelamentoExpiradoSigned(): boolean {
    const hoje = new Date();

    if (this.contractedPlan?.documentos) {
      const termoCancelamento = this.contractedPlan.documentos.find(doc => doc.nome === 'TermoCancelamento');
      if (termoCancelamento && termoCancelamento.dataExpiracao) {
        const dataExpiracao = new Date(termoCancelamento.dataExpiracao);
        return !termoCancelamento.assinado && dataExpiracao < hoje;
      }
    }
    return false;
  }

  verificarAssinaturaEletronicaAdesao(): boolean {
    if (this.contractedPlan?.documentos) {
      const termoAdesaoEletronico = this.contractedPlan.documentos.find(doc =>
        doc.nome === 'TermoAdesao' && doc.tipoAssinatura === 'Eletrônica'
      );
      return !!termoAdesaoEletronico && !termoAdesaoEletronico.assinado;
    }
    return false;
  }

  verificarAssinaturaManualAdesao(): boolean {
    if (this.contractedPlan?.documentos) {
      const termoAdesaoEletronico = this.contractedPlan.documentos.find(doc =>
        doc.nome === 'TermoAdesao' && doc.tipoAssinatura === 'Manual'
      );
      return !!termoAdesaoEletronico && !termoAdesaoEletronico.assinado;
    }
    return false;
  }

  obterDataExpiracao(): string | undefined {
    if (this.contractedPlan?.documentos && this.contractedPlan.documentos.length > 0) {
        return this.contractedPlan.documentos[0].dataExpiracao;
    }
    return undefined;
}

  isCanceladoOrFinalizado() {
    if (this.contractedPlan?.statusContratoNumero === 4 || this.contractedPlan?.statusContratoNumero === 7) return true;
    return false;
  }

  isCanceladoOrFinalizadoOrDesistenciaFinalizado() {
    if (this.contractedPlan?.statusContratoNumero === 4 || this.contractedPlan?.statusContratoNumero === 7 || this.contractedPlan?.statusContratoNumero === 5) return true;
    return false;
  }

  verifySacPermission() {
    const user = this.userService.getCurrentUser()
    if (user?.PerfilAcesso === 'SAC / Ouvidoria' || this.hasPermission('SERVICO_USUARIO_OUVIDORIA')) {
      this.showSacItens = true;
    } else {
      this.showSacItens = false;
    }
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  getTooltipText(element: any): string {
    return `Esse boleto foi reemitido. A nova data de vencimento do boleto é ${element.dataVencimento}.`;
  }

  showDate(dataAtualizacao: any) {
    if (dataAtualizacao === null || dataAtualizacao === "") {
      return false;
    } else {
      return true;
    }
  }

  verifySealEligibility() {
    this.pontualidade = this.mapValues("pontualidade", this.contractedPlan?.pontualidade);
    this.quitacao = this.mapValues("quitacao", this.contractedPlan?.quitacao);
    this.restricao = this.mapValues("restricaoCredito", this.contractedPlan?.restricaoCredito);
  }

  mapValues(chave?: string, valor?: number) {
    switch (chave) {
      case "pontualidade":
        switch (valor) {
          case 0:
            return "Em andamento";
          case 1:
            return "Atingida";
          case 2:
            return "Não atingida";
          default:
            return "";
        }
      case "quitacao":
        switch (valor) {
          case 0:
            return "Em andamento";
          case 1:
            return "Atingida";
          case 2:
            return "Não atingida";
          default:
            return "";
        }
      case "restricaoCredito":
        switch (valor) {
          case 0:
            return "Não consultada";
          case 1:
            return "Nada Consta";
          case 2:
            return "Consta Pendência";
          default:
            return "";
        }
      default:
        return "";
    }
  }

  verifyStatusEligibility(pontualidade: any, quitacao: any, restricaoCredito: any) {
    if (pontualidade === 2 || quitacao === 2) {
      return "INELEGÍVEL";
    }

    if (pontualidade === 1 && quitacao === 1 && restricaoCredito === 1) {
      return "ELEGÍVEL";
    }

    if (pontualidade === 1 && quitacao === 1 && restricaoCredito === 2) {
      return "INELEGÍVEL";
    }

    return "ATIVO";
  }

  verifyProofPaymentIsEnabled(pontualidade: any, quitacao: any, restricaoCredito: any, statusContratoNumero: any) {
    if (pontualidade === 1 && quitacao == 1 && restricaoCredito === 2 && statusContratoNumero !== 6) return true;
    return false;
  }

  onDetailClick(): void {
    this.openModalSummary();
  }

  announceSortChange2(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  openModalSummary() {
    this.dialog.open(ModalSummaryComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        values: this.contractedPlan
      }
    })
  }

  getIcon() {
    switch (this.contractedPlan?.statusContratoNumero) {
      case 0:
        return 'nest_clock_farsight_analog';
      case 1:
      case 2:
      case 19:
        return 'check_circle';
      case 3:
        return 'warning';
      case 9:
      case 11:
      case 16:
      case 17:
      case 12:
        return 'attach_money';
      case 4:
        return 'cancel';
      case 5:
        return 'check_circle';
      case 6:
        return 'attach_money'
      case 7:
        return 'check_circle';
      case 8:
      case 13:
        return 'block';
      case 10:
        return 'check_circle';
      case 14:
      case 15:
      case 18:
        return 'signature';
      default:
        return '';
    }
  }

  getPontualidadeIcon(value: boolean) {
    switch (value) {
      case true:
        return 'check';
      case false:
        return 'close';
      case null:
        return 'check_indeterminate_small';
      default:
        return 'check_indeterminate_small';
    }
  }

  getPontualidadeColor(value: boolean) {
    switch (value) {
      case true:
        return 'var(--color-functional-positive-1)';
      case false:
        return 'var(--color-functional-danger-2)';
      default:
        return 'var(--color-neutral-gray-4)';
    }
  }


  changeStatus(dataCancelamento: any) {
    this.contractedPlan!.statusContratoNumero = 10;
    this.contractedPlan!.dataCancelamento = dataCancelamento;
  }

  getIconColor() {
    if (this.contractedPlan?.statusContratoNumero === 0) return 'var(--color-functional-positive-1)';
    if (this.contractedPlan?.statusContratoNumero === 1 ||
      this.contractedPlan?.statusContratoNumero === 18 ||
      this.contractedPlan?.statusContratoNumero === 19
    ) return 'var(--color-primary-2)';
    if (this.contractedPlan?.statusContratoNumero === 2) return 'var(--color-primary-3)';
    if (this.contractedPlan?.statusContratoNumero === 3 ||
      this.contractedPlan?.statusContratoNumero === 11 ||
      this.contractedPlan?.statusContratoNumero === 14 ||
      this.contractedPlan?.statusContratoNumero === 16 ||
      this.contractedPlan?.statusContratoNumero === 12 ||
      this.contractedPlan?.statusContratoNumero === 17) return 'var(--color-functional-warning-1)';
    if (this.contractedPlan?.statusContratoNumero === 10) return 'var(--color-complementary-yellow-1)';
    if (this.contractedPlan?.statusContratoNumero === 15) return 'var(--color-functional-danger-2)';

    return 'var(--color-neutral-gray-4)';
  }

  getStatusDescription() {
    switch (this.contractedPlan?.statusContratoNumero) {
      case 0:
        return "Em Aberto";
      case 1:
        return "Ativo";
      case 2:
        return "Elegível";
      case 3:
        return "Inelegível";
      case 4:
        return "Cancelado"
      case 5:
        return "Desistência finalizado"
      case 6:
        return "Liberação de valores finalizado"
      case 7:
        return "Finalizado"
      case 8:
        return "Inativo"
      case 9:
        return "Estorno finalizado"
      case 10:
        return "Desistência em andamento"
      case 11:
        return "Liberação de valores CDC"
      case 12:
        return "Estorno em processo"
      case 13:
        return "Inatividade finalizada"
      case 14:
        return 'Aguardando assinatura do termo de adesão';
      case 15:
        return 'Aguardando assinatura do termo de cancelamento';
      case 16:
        return 'Liberação de valores QUITADO ELEGÍVEL';
      case 17:
        return 'Liberação de valores QUITADO INELEGÍVEL';
      case 18:
        return 'Aguardando assinatura do termo de prorrogação';
      case 19:
        return 'Ativo - Prorrogação';
      default:
        return ""
    }
  }

  verifyRefundRate() {
    switch (this.contractedPlan?.statusContratoNumero) {
      case 0:
        return this.showRefundRate = false;
      case 4:
        return this.showRefundRate = false;
      case 5:
        return this.showRefundRate = false;
      case 7:
        return this.showRefundRate = false;
      case 9:
        return this.showRefundRate = false;
      case 12:
        return this.showRefundRate = false;
      default:
        return this.showRefundRate = true;
    }
  }

  getBack() {
    this.currentFlowChanged.emit('contractedPlans');
  }

  getStatusName(status: string) {
    if (status === 'EmAberto') return 'Em Aberto';
    if (status === 'EmCartorio') return 'Em Cartorio';
    return status;
  }

  handleCancelButtonPress() {
    if (!this.contractedPlan?.podeCancelar)
      return;

    if (this.contractedPlan?.statusContratoNumero === 0 || this.contractedPlan?.statusContratoNumero === 14) {
      this.openDialogCancelPlan();
      return;
    }

    this.cancelPlanIsOpen = true;
  }

  handleCancelPlan() {
    this.cancelPlan();
  }

  cancelPlan() {
    const payLoad: ICancelPlan = {
      idContrato: this.contractedPlan?.idContrato,
      statusContratoNumero: this.contractedPlan?.statusContratoNumero,
    };
    this.isLoading = true;
    this.apiService
      .cancelPlan(payLoad)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSucess('O plano foi cancelado com sucesso.', 'FECHAR');
          this.contractedPlan!.statusContratoNumero = 4;
          this.contractedPlan!.statusContratoDescricao = 'Cancelado';
          this.contractedPlan!.podeCancelar = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  getTermoCancelamento(assinado: boolean) {
    const payLoad = {
      idContrato: this.contractedPlan?.idContrato,
      termoAssinado: assinado,
    };
    this.isLoading = true;
    this.apiService
      .getTermoCancelamento(payLoad)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 204) {
            this.openDialogEmptyDocument()
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.downloadDocument(result.body.termoCancelamento, result.body.nomeArquivo);
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  getTermoAdesao(assinado: boolean) {
    const idContrato = this.contractedPlan?.idContrato
    this.isLoading = true;
    this.apiService
      .getTermoAdesao(idContrato, assinado)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 204) {
            this.openDialogEmptyDocument()
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.downloadDocument(result.body.minuta, result.body.nomeArquivo);
          }
        },
        error: (error) => {
          this.openDialogErrorDocument()
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  getRegulamento() {
    const idContrato = this.contractedPlan?.idContrato
    this.isLoading = true;
    this.apiService
      .getRegulamento(idContrato)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 204) {
            this.openDialogErrorDocument()
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.downloadDocument(result.body.regulamento, result.body.nomeArquivo);
          }
        },
        error: (error) => {
          this.openDialogErrorDocument()
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  handleDownloadClick(idContrato: number, parcela: number, boletoExpirado: boolean) {
    if (boletoExpirado && this.hasTicketPermission()) {
      this.openDialogExpired('baixar', undefined, idContrato, parcela)
    } else
      this.postParcela(idContrato, parcela, 'normal')
  }

  postParcela(contrato?: number, parcela?: number, flow?: string) {
    this.isLoading = true;
    this.apiService
      .postParcela(this.contractedPlan?.idContrato, parcela)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.body.sucesso === false || result.status !== 200) {
            this.openDialogErrorDocument()
            this.isLoading = false;
          } else {
            this.isLoading = false;
            if (result.body.boleto === null) {
              this.openModalProcessing()
            }
            if (result?.body?.boleto?.boleto) {
              this.downloadDocument(result.body.boleto.boleto, result.body.boleto.nomeArquivo);
              if (flow == 'expired') this.getBack()
            }
          }
        },
        error: (error) => {
          this.openDialogErrorDocument()
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  openDocumentation() {
    this.isLoading = true;
    this.apiService.getDocuments(this.contractedPlan?.idContrato).pipe(
      timeout(30000),
      catchError((error) => {
        this.isLoading = false;
        this.openDialogErrorDocumentAccess();
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => 'A requisição demorou muito tempo e foi cancelada.');
        }
        return throwError(() => error);
      })
    ).subscribe((result) => {
      if (result) {
        this.isLoading = false;
        this.dataService.setData(result.body.termosLiberacred, result.body.emailCliente, [], this.contractedPlan?.numeroContrato, this.contractedPlan?.cpf);
        const dialogRef = this.dialog.open(ModalDocumentationComponent, {
          width: '440px'
        });
      } else {
        this.isLoading = false;
        this.openDialogErrorDocument();
      }
    });
  }

  openSolicitation() {
    this.showTable = true;
    this.isLoading = true;
    this.apiService.getHistoric(this.contractedPlan?.idContrato, this.pageIndex + 1, this.pageSize).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    ).subscribe((result) => {
      if (result.items.length > 0) {
        this.hasValue = true;
      } else {
        this.hasValue = false;
      }
      if (result) {
        this.isLoading = false;
        this.tableList = result.items;
        this.totalPages = result.totalPages;
        this.totalCount = result.totalCount;
        this.dataSource = new MatTableDataSource<any>(this.tableList);
        this.atualizationPaginator();
      }
      else {
        this.isLoading = false;
        this.openDialogErrorDocument();
      }
    });
  }

  atualizationPaginator() {
    this.length = this.totalCount;
    this.isSinglePage = this.totalPages === 1;
  }

  prepareTermsDisplay(terms: any[]): string {
    return terms.map(term => term.descricao).join(', ');
  }

  setPageStart() {
    this.pageIndex = 0;
    this.openSolicitation()
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.openSolicitation()
  }

  getPlan() {
    this.showTable = false;
  }

  openModalProcessing() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'info',
        text: 'Não foi possível gerar o boleto neste momento, o boleto encontra-se em processamento. Tente novamente mais tarde.',
        primaryButtonAction: () => {
          dialogRef.close();
          this.getBack();
        },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    });
  }

  openDialogErrorDocument() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível concluir o download do documento neste momento. Tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogEmptyDocument() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'O download deste documento só estará disponível após o upload da versão assinada.',
        icon: 'info',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorDocumentAccess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível acessar o kit documentação. Tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  downloadDocument(document64: string, nomeDocument: string) {
    const linkSource = `data:application/octet-stream;base64,${document64}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = `${nomeDocument}.pdf`;
    downloadLink.click();
    this.isLoading = false;
  }

  closeCancelPlan() {
    this.cancelPlanIsOpen = false;
    window.scrollTo(0, 0);
  }

  closeReleaseValues() {
    this.releaseValuesIsOpen = false;
    window.scrollTo(0, 0);
  }

  closePlanExtract() {
    this.planExtractIsOpen = false;
    window.scrollTo(0, 0);
  }

  openEditClientData() {
    this.editClientDataIsOpen = true;
    window.scrollTo(0, 0);
  }

  closeEditClientData() {
    this.editClientDataIsOpen = false;
    window.scrollTo(0, 0);
  }

  openRefundRate() {
    this.refundRateIsOpen = true;
    window.scrollTo(0, 0);
  }

  openProofPayment() {
    this.proofPaymentIsOpen = true;
    window.scrollTo(0, 0);
  }

  closeProofPayment() {
    this.proofPaymentIsOpen = false;
    window.scrollTo(0, 0);
  }

  closeExtension() {
    this.extensionIsOpen = false;
    window.scrollTo(0, 0);
  }

  closeRefundRate(event: string) {
    window.scrollTo(0, 0);
    if (event === 'bankFlow') {
      this.isRefundRateFlow = true;
      this.refundRateIsOpen = false;
      this.editBankDataIsOpen = true;
    }
    else {
      this.isRefundRateFlow = false;
      this.refundRateIsOpen = false;
    }
  }

  openEditBankData() {
    this.editBankDataIsOpen = true;
    window.scrollTo(0, 0);
  }

  closeEditBankData(event: any) {
    window.scrollTo(0, 0);
    this.isRefundRateFlow = false;
    if (event.action === 'redirect') {
      this.contractedPlan!.dadosBancarios = event.newBankData
      this.editBankDataIsOpen = false;
      this.isRefundRateFlowOk = true;
      this.refundRateIsOpen = true;
    } else {
      this.editBankDataIsOpen = false;
      this.getBack()
    }
  }

  uploadCancelTerm(termo: string) {
    this.isLoading = true;
    this.apiService
      .sendTermo(this.contractedPlan?.cpf, termo)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSucessUpload('O termo de cancelamento foi enviado com sucesso.');
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  uploadMinuta(minuta: string) {
    this.isLoading = true;
    this.apiService
      .postMinuta(this.contractedPlan?.cpf, minuta)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSucessUpload('O termo de adesão foi enviado sucesso.');
          this.termoAssinado = true;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  openDialogSucessUpload(menssage: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: menssage,
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogCancelPlan() {
    this.dialog.open(ModalCancelPlanComponent, {
      width: '393px',
      data: {
        clientName: this.contractedPlan?.nomeCliente,
        contractNumber: this.contractedPlan?.numeroContrato,
        handleCancelPlan: () => {
          this.handleCancelPlan();
        },
      }
    })
  }

  openDialogUpload() {
    this.dialog.open(ModalUploadTermComponent, {
      width: '476px',
      data: {
        type: 'cancelamento',
        uploadTerm: this.uploadCancelTerm.bind(this),
      },
    });
  }

  openDialogUploadMinuta() {
    this.dialog.open(ModalUploadTermComponent, {
      width: '476px',
      data: {
        type: 'adesão',
        uploadTerm: this.uploadMinuta.bind(this),
      },
    });
  }

  openDialogSucess(message: string, textButton: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: message,
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: textButton,
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogErrorSendEmail(flow: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: 'Algo deu errado e não conseguimos enviar o boleto para seu e-mail.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.handleSendEmail(flow) },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogSendEmail() {
    this.dialog.open(ModalSendEmailComponent, {
      width: '396px',
      data: {
        email: null,
        handleSendEmail: () => { this.handleSendEmail('normal') }
      }
    })
  }

  openDialogExpired(type: string, email?: string, numeroContrato?: number, parcela?: number) {
    this.dialog.open(ModalExpiredTicketComponent, {
      width: '558px',
      data: {
        type: type,
        email: email,
        handleClickButton: () => {
          if (type === 'baixar') this.postParcela(numeroContrato, parcela, 'expired')
          else this.handleSendEmail('expired')
        }
      }
    })
  }

  handleSendEmailClick(idParcelaBoleto: number, boletoExpirado: boolean) {
    this.parcelaBoletoList = [];
    this.parcelaBoletoList.push(idParcelaBoleto)
    if (boletoExpirado && this.hasTicketPermission()) {
      this.openDialogExpired('enviar')
    } else
      this.openDialogSendEmail()
  }

  handleSendEmail(flow: string) {
    this.isLoading = true;
    this.apiService
      .resendEmail({ nomeRotina: 'SegundaViaBoleto', idContrato: this.contractedPlan?.idContrato, idsParcelaBoleto: this.parcelaBoletoList })
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              () => 'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openDialogSucess('Boleto foi enviado com sucesso.', 'VOLTAR')
          this.parcelaBoletoList = []
          if (flow === 'expired') this.getBack()
        },
        error: (error) => {
          this.openDialogErrorSendEmail(flow)
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  hasTicketPermission(): boolean {
    const user = this.userService.getCurrentUser();
    const isAllowed = user.PermissaoAcesso.includes(EPermissaoAcesso.REMITIR_BOLETO)
    if (isAllowed) return true;
    else return false;
  }

  openModalTermCancel() {
    const termoCancelamento = this.contractedPlan?.documentos.find(doc => doc.nome === 'TermoCancelamento');
    const dataExpiracao = termoCancelamento ? termoCancelamento.dataExpiracao : null;

    this.dialog.open(ModalTermCancelComponent, {
      width: 'auto',
      height: 'auto',
      data: dataExpiracao
    });
  }

  gerarBoleto = () => {
    this.isBoletoBeingGenerated = true;
    this.tryGenerate();
  }

  tryGenerate() {
    if (this.boleto) {
      this.generateBoleto();
    } else if (this.tentativas < 3) {
      this.tentativas++;
      this.apiService.getInstallment(this.contractedPlan?.idContrato, 1).subscribe({
        next: (response) => {
          const boleto = response.body?.boleto?.boleto;
          if (boleto) {
            this.boleto = boleto;
            this.generateBoleto();
          } else {
            setTimeout(() => {
              this.tryGenerate();
            }, 2000);
          }
        },
        error: (error) => {
          console.error('Erro ao obter o boleto:', error);

          if (this.tentativas < 3) {
            setTimeout(() => {
              this.tryGenerate();
            }, 2000);
          } else {
            this.openModalModalAccountGeneration();
            this.isBoletoBeingGenerated = false;
            this.tentativas = 0;
          }
        }
      });
    } else {
      this.openModalModalAccountGeneration();
      this.isBoletoBeingGenerated = false;
      this.tentativas = 0;
    }
  }

  generateBoleto() {
    const byteCharacters = atob(this.boleto as string);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    setTimeout(() => {
      link.click();
      this.isBoletoBeingGenerated = false;
      this.tentativas = 0;
      this.getBack();
    }, 3000);

  }

  openModalModalAccountGeneration() {
    this.dialog.open(ModalAccountGenerationComponent, {
      width: 'auto',
      height: 'auto',
    })
  }

  openReleaseValues() {
    this.releaseValuesIsOpen = true
    this.dialog.closeAll();
  }

  openPlanExtract(){
    this.planExtractIsOpen = true
  }

  showExtension(){
    if(this.contractedPlan?.podeProrrogar === true){
      this.openDialogExtension()
    }
  }

  openCancelPlanComponent() {
    this.cancelPlanIsOpen = true;
    this.extensionIsOpen = false;
  }


  openDialogExtension() {
    this.dialog.open(ModalGenericComponent, {
      width: '460px',
      data: {
        icon: 'info',
        text: 'Deseja prorrogar seu plano?',
        textBold: `Seu plano está inelegível.`,
        primaryButtonColor: 'red',
        primaryButtonText: 'QUERO RESGATAR O SALDO',
        primaryButtonVariant: 'secondary',
        primaryButtonAction: () => { this.openReleaseValues() },
        secundaryButtonAction: () => { this.openExtension() },
        secundaryButtonText: 'QUERO PRORROGAR',
        secundaryButtonVariant: 'primary',
        showCloseButton: true,
      },
      autoFocus: false,
      disableClose: true
    });
  }

  openExtension(){
    this.extensionIsOpen = true
    window.scrollTo(0, 0);
  }

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../buttons/button/button.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'yfs-modal-term-cancel',
    templateUrl: './modal-term-cancel.component.html',
    styleUrls: ['./modal-term-cancel.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, MatIcon, ButtonComponent, DatePipe]
})
export class ModalTermCancelComponent {
  public date: string = '';

  constructor(
    public dialogRef: MatDialogRef<ModalTermCancelComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.date = data;
    }

  close() {
    this.dialogRef.close();
  }
}

<section *ngIf="dataSource" class="container-content">
  <div>
    <div class="button-back">
      <yfs-link-back text="Voltar para liberação de valores" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
      <h1>DETALHES DA LIBERAÇÃO{{dataSource.tipoLiberacao === 'CDC' ? ' - CDC' : ''}}</h1>
    </div>

   <div class="card-container">
    <div class="side-container">
      <mat-card class="flutuante-card">
        <mat-card-content>
          <div class="content-side-card">
              <span class="recarga">Total de recargas</span>
              <span class="total-recarga">{{ dataSource.totalRecarga | currency}}</span>
              <span class="tarifa">- Tarifas + taxas</span>
              <span class="total-tarifa">{{ dataSource.taxaTarifa | currency}}</span>
              <span class="multa" [ngClass]="{'hidden': dataSource.tipoLiberacao === 'CDC' || dataSource.tipoLiberacao === 'Plano Quitado - Elegível' || 'Plano Quitado - Inelegível'}">- Multa de desistência</span>
              <span class="total-multa" [ngClass]="{'hidden': dataSource.tipoLiberacao === 'CDC' || dataSource.tipoLiberacao === 'Plano Quitado - Elegível' || 'Plano Quitado - Inelegível'}">{{ dataSource.multaDesistencia | currency}}</span>
              <span class="total-liberar"> <div class="tooltip-container">
                <mat-icon aria-hidden="false" aria-label="Ícone de alerta" class="material-symbols" fontIcon="info">info</mat-icon>
                <div *ngIf="!canceladoAntesDosSeteDias" class="tooltip-text">
                  <b>Após 7 dias úteis iniciais</b>, o plano poderá ser cancelado com as tarifas normais aplicadas.
                </div>
                <div *ngIf="canceladoAntesDosSeteDias" class="tooltip-text">
                  Plano cancelado <b>dentro dos 7 dias úteis iniciais</b>, com direito a reembolso total do valor pago.
                </div>
              </div> Total a liberar</span>
              <span class="valor-integral">(Reserva do cliente)</span>
              <span class="valor">{{ dataSource.totalLiberar | currency}}</span>
            <yfs-button state="enabled" label="LIBERAR VALORES" (click)="openModalComplete()" type="primary" color="white" [fullWidth]="isFullWidth"></yfs-button>
            <yfs-button *ngIf="dataSource.tipoLiberacao !== 'CDC'" style="margin-top: 10px;" state="enabled" label="CANCELAR DESISTÊNCIA" (click)="openModalCancel()" type="secondary" color="white" [fullWidth]="isFullWidth"></yfs-button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <mat-card>
      <mat-card-content class="card">
        <div *ngIf="canceladoAntesDosSeteDias" class="plan-cancel">
          <div>
            <mat-icon aria-hidden="false" aria-label="Ícone de alerta" class="material-symbols-outlined icon-warning" fontIcon="warning">warning</mat-icon>
          </div>
          <span class="plan-cancel-text">Plano cancelado dentro de 7 dias úteis</span>
        </div>
        <div class="container-content-product">
          <div class="container-product">
            <div class="image-box">
              <img alt="imagem do produto" [src]="convertBase64 + dataSource.imagemProduto" />
            </div>
            <span class="product">{{dataSource.produto.modelo}}</span>
          </div>
          <div class="container-cards">
              <mat-card class="mini-card">
                <span class="h1">Contrato</span>
                <span class="h2">{{dataSource.contrato}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Data de adesão</span>
                <span class="h2">{{dataSource.dataAdesao | date: 'dd/MM/yyyy'}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Cliente</span>
                <span class="h2">{{dataSource.cliente}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">CPF do cliente</span>
                <span class="h2">{{cpfMask(dataSource.cpfCliente)}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Valor do contrato</span>
                <span class="h2">{{dataSource.valorContratacao | currency}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Tipo da liberação</span>
                <span class="h2">{{dataSource.tipoLiberacao}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Recargas realizadas</span>
                <span class="h2">{{dataSource.recargaRealizadas}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Recargas elegíveis</span>
                <span class="h2">{{dataSource.recargaRealizadas}}</span>
              </mat-card>
              <mat-card class="mini-card">
                <span class="h1">Canal de venda</span>
                <span class="h2"
                  > <strong>{{ getNome(dataSource.canalVenda) }}</strong></span
                >
                <span class="h1"> {{ getCnpj(dataSource.canalVenda) }}</span>
              </mat-card>
          </div>
        </div>
        <div class="cancellation-term">
          <div *ngIf="dataSource.termoCancelamentoAssinado === true" class="download-content" (click)="getTermoCancelamento()">
            <mat-icon aria-hidden="false" class="download-icon" aria-label="Ícone de informação" class="material-symbols-outlined" fontIcon="download"></mat-icon>
            <span class="download">
              Termo de cancelamento assinado
            </span>
        </div>
        <div *ngIf="dataSource.termoCancelamentoAssinado === false" class="info-content">
          <div>
            <mat-icon aria-hidden="false" class="info-icon" aria-label="Ícone de informação" class="material-symbols" fontIcon="info"></mat-icon>
          </div>
          <span class="info">
            Termo de cancelamento pendente de assinatura
          </span>
      </div>
      </div>
        <div *ngIf="dataSource.tipoLiberacao === 'CDC'" class="cdc-container">
        <div class="cdc-content">
          <caption>
            CDC
          </caption>
        <div class="container-cards-cdc">
            <mat-card class="mini-card">
              <span class="h1">Número da proposta</span>
              <span class="h2">{{dataSource.contratoCdc?.numeroProposta}}</span>
            </mat-card>
            <mat-card class="mini-card">
              <span class="h1">Número do contrato</span>
              <span class="h2">{{dataSource.contratoCdc?.numeroContrato}}</span>
            </mat-card>
            <mat-card class="mini-card">
              <span class="h1">Data de contratação</span>
              <span class="h2">{{dataSource.contratoCdc?.dataContratacao | date: 'dd/MM/yyyy'}}</span>
            </mat-card>
        </div>
      </div>
    </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="flutuante-card-wrapper" id="flutuante-card-wrapper">
    <div class="card-table-container">
      <mat-card style="box-shadow: 0px 1px 4px #00000052">
        <mat-card-content>
          <div class="scrollable-table-container" style="margin-top: 10px">
            <table
            *ngIf="hasValue"
            mat-table
            [dataSource]="recargas"
            matSort
            #releaseDetailTableSort="matSort"
            (matSortChange)="announceSortChange($event)"
          >
              <caption>
                RECARGAS
              </caption>
              <ng-container matColumnDef="parcelaPaga">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by parcelaPaga"
                >
                  Elegível
                </th>
                <td mat-cell *matCellDef="let recarga">
                  <mat-icon aria-hidden="false" aria-label="Ícone de status"
                  [style.color]="getIconColor(recarga.parcelaPaga)" class="material-symbols-outlined"
                  [fontIcon]="getIcon(recarga.parcelaPaga)"></mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="numero">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by numero"
                >
                  Recarga
                </th>
                <td mat-cell *matCellDef="let recarga">
                  {{ recarga.numero }}
                </td>
              </ng-container>

              <ng-container matColumnDef="statusSituacaoTitulo">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by statusSituacaoTitulo"
                >
                  Status
                </th>
                <td mat-cell *matCellDef="let recarga">{{ recarga.parcelaPaga? 'Pago' : 'Não pago'}}</td>
              </ng-container>

              <ng-container matColumnDef="dataVencimento">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by dataVencimento"
                >
                  Vencimento
                </th>
                <td mat-cell *matCellDef="let recarga">
                  {{ recarga.dataVencimento | date:'dd/MM/yyyy' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dataPagamento">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by dataPagamento"
                >
                  Pagamento
                </th>
                <td mat-cell *matCellDef="let recarga">
                  {{ recarga.dataPagamento | date:'dd/MM/yyyy' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="valorParcela">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by valorParcela"
                >
                  Valor
                </th>
                <td mat-cell *matCellDef="let recarga">{{ recarga.valorParcela | currency }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    </div>
 </div>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

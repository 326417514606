import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { dateFormat } from 'src/app/shared/date-format';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { HeaderComponent } from '../../components/header/header.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { MatIcon } from '@angular/material/icon';
import { OldTablesCommissionsComponent } from './old-tables-commissions/old-tables-commissions.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

interface Parcela {
  comissaoPagar: string;
  comissaoEstornar: string;
}

@Component({
    selector: 'yfs-parameter-registration-commission',
    templateUrl: './parameter-registration-commission.component.html',
    styleUrls: ['./parameter-registration-commission.component.scss'],
    standalone: true,
    imports: [HeaderComponent, NgIf, LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatCheckbox, NgFor, MatOption, MatError, MatDatepickerInput, MatInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, ButtonComponent, MatIcon, OldTablesCommissionsComponent, LoadingSpinnerComponent, DatePipe]
})
export class ParameterRegistrationCommissionComponent {
  public previousScreen: string = '';
  public categoria = new FormControl('', [Validators.required]);
  public listaCategoria: any;
  public selectedCategoria: number[] = [];
  public idsCategoria: number[] = [];
  public checkedAllCategoria = false;
  public isLoading = false;
  public minDate: Date | undefined;
  public dataVigenciaLabel: Date | undefined;
  public dataVigencia = new FormControl('', [Validators.required, dateFormat.parameterTariffValidator]);
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public controlsParcelas: FormControl[][] = [];
  public currentFlow = 'parameter';
  public updateCurrentContracts = false;
  constructor(
    private router: Router,
    private apiService: ApiHttpService,
    public dialog: MatDialog
  ) {
    this.getCategories();
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);
    this.minDate = tomorrowDate;
    for (let i = 1; i <= 12; i++) {
      const pagarControl = new FormControl('', []);
      const estornarControl = new FormControl('', []);
      this.controlsParcelas.push([pagarControl, estornarControl]);
    }
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  onInputChange(isCalendar: boolean) {
    if (this.dataVigencia.value?.length === 0) {
      this.dataVigenciaLabel = undefined;
    }

    if (this.dataVigencia && this.dataVigencia.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataVigencia2 = this.dataVigencia.value ?
        this.getDateByString(this.dataVigencia.value) : null;

      if (!isCalendar) {
        this.dataVigenciaLabel = dataVigencia2 || undefined
      }
    }
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  checkInvalidControls() {
    return this.controlsParcelas.some(parControles => {
      const pagarControl = parControles[0];
      const estornarControl = parControles[1];

      return pagarControl.invalid || estornarControl.invalid;
    });
  }

  checkFields() {
    if (this.dataVigencia.value) {
      this.dataVigencia.markAllAsTouched();
    }
    if (
      this.categoria.valid &&
      this.dataVigencia.valid &&
      !this.checkInvalidControls()
    ) {
      return 'enabled'
    }
    return 'disabled'
  }

  formatParcela(currentControl: any, controlPagar: any, controlEstornar: any, parcelaNumber: number) {
    let currentValue = currentControl.value?.replace(/[^\d,]/g, '');
    const defaultCurretValue = currentValue;
    currentValue = currentValue += '%';
    if (currentValue == '%') currentControl.setValue('')
    else
      currentControl.setValue(currentValue);
    this.validPercentual(defaultCurretValue, currentControl);
  }

  validPercentual(minimoValue?: string, control?: any) {
    if (!minimoValue) return;
    const minimoInt = parseInt(minimoValue);
    if (minimoInt > 100) {
      control.setErrors({ rangeError: true });
    }
  }

  selectAllCategoria() {
    if (this.selectedCategoria.length === this.listaCategoria.length) {
      this.selectedCategoria = [];
      this.idsCategoria = [];
      this.checkedAllCategoria = false;
    } else {
      this.selectedCategoria = this.listaCategoria.map((categoria: any) => categoria.idCategoriaExibicao);
      this.idsCategoria = this.selectedCategoria.slice();
      this.checkedAllCategoria = true;
    }
    this.categoria.setValue(this.selectedCategoria as any);
  }

  checkHandleCategoria(idCategoria: number) {
    if (this.selectedCategoria.includes(idCategoria)) {
      this.selectedCategoria = this.selectedCategoria.filter(item => item !== idCategoria);
    } else {
      this.selectedCategoria.push(idCategoria);
    }

    this.idsCategoria = this.selectedCategoria.slice();

    if (this.selectedCategoria.length === this.listaCategoria.length) {
      this.checkedAllCategoria = true;
    } else {
      this.checkedAllCategoria = false;
    }
    this.categoria.setValue(this.selectedCategoria as any);
  }

  getParameters() {
    this.isLoading = true;
    this.apiService.getParameterCommission().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          if (result) {
            this.bidingParametersValues(result)
          }
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  bidingParametersValues(parameters: any) {
    if (parameters.dataVigencia) {
      const dataVigenciaFormated = parameters.dataVigencia ? this.convertDateToString(parameters.dataVigencia) : ''
      this.dataVigencia.setValue(dataVigenciaFormated);
    }
    if (parameters.categorias && parameters.categorias.length > 0) {
      this.pickCategoria(parameters.categorias);
    }
    for (const chave in parameters) {
      if (chave.startsWith('parcela')) {
        const numeroParcela = parseInt(chave.substring(7));
        const pagarControl = this.controlsParcelas[numeroParcela - 1][0];
        const estornarControl = this.controlsParcelas[numeroParcela - 1][1];

        const valorParcela = parameters[chave];
        const comissaoPagar = valorParcela.comissaoPagar;
        const comissaoEstornar = valorParcela.comissaoEstornar;

        pagarControl.setValue(comissaoPagar ? comissaoPagar + '%' : '');
        estornarControl.setValue(comissaoEstornar ? comissaoEstornar + '%' : '');
      }
    }
  }

  pickCategoria(categorias: any) {
    const categoriasSelecionadas: any[] | null = [];
    categorias.forEach((categoriaRetorno: any) => {
      const categoriaSelecionada = this.listaCategoria.find((categoria: any) => categoria.idCategoriaExibicao === categoriaRetorno)
      if (categoriaSelecionada) {
        categoriasSelecionadas.push(categoriaSelecionada)
      }
    })

    this.categoria.setValue(categoriasSelecionadas as any)
    if (categoriasSelecionadas.length > 0) {
      categoriasSelecionadas.forEach((categoria) => {
        this.checkHandleCategoria(categoria.idCategoriaExibicao)
      })
    }
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj.toString()
  }

  convertDateToString(dataNascimento: any) {
    const dataOriginal = new Date(dataNascimento);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }

  getCategories() {
    this.isLoading = true;
    this.apiService.getCategory().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.listaCategoria = result;
          this.getParameters();
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  parseValueToNumber(value: any) {
    if (!value || value == undefined || typeof value !== 'string') return value;
    return parseFloat(value.replace('%', '').replace(',', '.'));
  }

  getValoresParcelas(): { [key: string]: Parcela } {
    const valoresParcelas: { [key: string]: Parcela } = {};

    this.controlsParcelas.forEach((parControles, index) => {
      const pagarControl = parControles[0];
      const estornarControl = parControles[1];

      const valorPagar = this.parseValueToNumber(pagarControl.value) || 0;
      const valorEstornar = this.parseValueToNumber(estornarControl.value) || 0;

      const chaveNumber = index + 1
      const chave =  chaveNumber < 10 ? `parcela0${chaveNumber}` : `parcela${chaveNumber}`;

      valoresParcelas[chave] = { comissaoPagar: valorPagar, comissaoEstornar: valorEstornar };
    });

    return valoresParcelas;
  }

  updateParameters() {
    this.isLoading = true;
    const dataVigenciaValue = this.dataVigencia.value;
    const dataVigenciaFormated = dataVigenciaValue ? this.convertStringToDate(dataVigenciaValue) : undefined;
    const parameterCommissionPayload = {
      categorias: this.idsCategoria,
      dataVigencia: dataVigenciaFormated,
      atualizarContratos: this.updateCurrentContracts,
      ...this.getValoresParcelas()
    }
    this.apiService.updateParameterCommission(parameterCommissionPayload).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.openDialogSaveProductSuccess();
        },
        error: error => {
          this.isLoading = false;
          if (error?.error?.status == 400 && error?.error?.errors && this.hasPercentualError(error.error.errors)) {
            this.openDialogPercentualError();
          } else {
            this.openDialogSaveChangesError();
          }
          console.log(error)
        }
      })
  }

  hasPercentualError(retorno: any) {
    for (const chave in retorno) {
      if (retorno[chave].includes("Valor estorno inválido")) {
          return true;
      }
    }
    return false;
  }

  openDialogPercentualError() {
    this.dialog.open(ModalGenericComponent, {
      width: '551px',
      data: {
        text: 'O percentual cadastrado para estorno não pode ser maior que a soma dos percentuais da 1ª parcela + percentuais cadastrados até o prazo em questão.',
        icon: 'error',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'primary',
        primaryButtonAction: 'close'
      },
    });
  }

  openDialogConfirmSave = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '392px',
      data: {
        text: this.updateCurrentContracts ?
        'Todos os contratos vigentes terão suas configurações de comissão alteradas para esta nova tabela, deseja continuar?':
        'As novas configurações de comissão valerão somente para contratos iniciados a partir da data de vigência da tabela, deseja continuar?',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.updateParameters() },
        secundaryButtonText: 'SIM, ATUALIZAR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openDialogExist() {
    this.dialog.open(ModalGenericComponent, {
      width: '425px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        icon: 'warning',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openDialogSaveProductSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Os dados foram alterados com sucesso.',
        icon: 'success',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        primaryButtonAction: 'close'
      },
    });
  }

  openDialogSaveChangesError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não foi possível alterar os dados nesse momento. Tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.updateParameters(); },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openOldTables() {
    this.currentFlow = 'oldTables';
  }

  changeCurrentFlow(flow: string) {
    this.currentFlow = flow;
  }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';

import { Router } from '@angular/router';
import { IPaymentForms, IProductView } from 'src/app/models/product-view.interface';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { NgIf, NgFor, CurrencyPipe } from '@angular/common';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { AlertBoxComponent } from '../../../components/alert-box/alert-box.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-simulation-product-view',
    templateUrl: './simulation-product-view.component.html',
    styleUrls: ['./simulation-product-view.component.scss'],
    standalone: true,
    imports: [NgIf, MatCard, MatCardContent, MatCardHeader, MatCardTitle, MatSlider, MatSliderThumb, MatFormField, MatLabel, MatSelect, NgFor, MatOption, ButtonComponent, AlertBoxComponent, LoadingSpinnerComponent, CurrencyPipe]
})
export class SimulationProductViewComponent implements OnInit {
  @Input() idProduct: number = 0;

  public urlImage: string = '';
  public valorParcela = 0
  public valorTotal = 0
  public productObj?: IProductView
  public paymentForms?: Array<IPaymentForms>
  public isLoading = false;
  public timeoutError = false;
  public genericError = false;
  public currentPercentage?= 0;
  public selected = 1;
  public isFullWidth = window.innerWidth < 768;

  constructor(
    private apiService: ApiHttpService,
    private router: Router,
    private userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.searchProduct()
    const user = this.userService.getCurrentUser()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 820;
  }

  isClienteUserOrNoPermission(): boolean {
    if (!this.userService.userIsLogged()) return true;
    if (!this.userService.getCurrentUser()) return false;
    const user = this.userService.getCurrentUser();
    return user.PerfilAcesso === 'Cliente' || !user.PermissaoAcesso.includes(EPermissaoAcesso.CONTRATAR_PLANO);
  }

  searchProduct() {
    this.isLoading = true;
    if(localStorage.getItem('idProduct') !== null) {
      this.idProduct = Number(localStorage.getItem('idProduct'))
    }
    this.apiService.getProductById(this.idProduct).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.productObj = result;
          this.urlImage = `data:image/png;base64, ${result.imagemBase64}`;
          if (!this.productObj || Object.keys(this.productObj).length === 0) {
            this.genericError = true;
            this.isLoading = false;
          }
          else this.searchPaymentsForms()
        },
        error: error => {
          this.isLoading = false;
          if (this.timeoutError === false) this.genericError = true;
          console.log(error)
        }
      })
  }

  searchPaymentsForms() {
    this.apiService.getPaymentForm().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.paymentForms = result;
          if (!this.paymentForms || this.paymentForms.length === 0) {
            this.genericError = true;
            this.isLoading = false
          }
          else {
            this.selectFirstElement()
            this.selected = this.paymentForms[0].idFormaPagamento
            this.isLoading = false;
          }
        },
        error: error => {
          this.isLoading = false;
          if (this.timeoutError === false) this.genericError = true;
          console.log(error)
        }
      })
  }

  selectFirstElement() {
    const first = this.productObj?.porcentagemMinima
    this.selectValuePrice(first)
  }

  hangleSliderChange(event: any) {
    this.selectValuePrice(event.target.value)
  }

  selectValuePrice(percentualValue?: number) {
    const simulations = this.productObj?.simulacoes;
    const valueFound = simulations?.filter(el => el.percentual == percentualValue)
    if (valueFound && valueFound.length > 0) {
      this.currentPercentage = percentualValue;
      this.valorParcela = valueFound[0].valorParcelaBruta
      this.valorTotal = valueFound[0].valorTotalLiquido
    } else {
      console.log("nao há simulação correspondente para a porcentagem")
      this.paymentForms = []
      this.genericError = true;
    }
  }

  formatLabel(value: number): string {
    return `${value}%`;
  }

  goHome() {
    this.router.navigate(["home"])
  }

  goToRegister() {
      const productRegister = {
        idProduto: this.productObj?.idProduto,
        modelo: this.productObj?.modelo,
        valorReferencia: this.productObj?.valorReferencia,
        porcentagemPlano: this.currentPercentage,
        formaPagamento: this.selected == 1 ? "Boleto" : "Boleto",
        idFormaPagamento: this.selected,
        numeroParcelas: this.productObj?.numeroParcelas,
        valorTotalLiquido: this.valorTotal,
        valorParcelaBruta: this.valorParcela
      }
      localStorage.setItem("produto", btoa(JSON.stringify(productRegister)))
      this.router.navigate(["cadastro"])
  }

  openDialog() {
    this.dialog.open(ModalGenericComponent, {
      width: '600px',
      data: {
        icon: 'info',
        textBold: 'Contrate o seu Liberacred em uma concessionária ou representante autorizados.',
        text: 'Para mais informações sobre o LiberaCred e opções de contratação entre em contato através dos nossos canais oficiais:',
        highlightTextInner: '<strong>SAC</strong><br/><span>liberacred@yamaha-motor.com.br</span><br/><span>11 2088-7700</span>',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

}

<mat-card>
  <mat-card-content>
<span class="title-document">Dados pessoais</span>
<div class="container-content">
    <div class="content">
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Nome completo</mat-label>
        <input data-test="input-name-person-data" [formControl]="nomeCompleto" matInput maxlength="35"  (input)="sanitizeInput($event, 'nomeCompleto')"
        (paste)="handlePaste($event, 'nomeCompleto')"
        pattern="[a-zA-ZÀ-ÖØ-öø-ÿ]+( [a-zA-ZÀ-ÖØ-öø-ÿ]+)*">
        <mat-error *ngIf="nomeCompleto.hasError('required')">
          O campo é obrigatório
      </mat-error>
      <mat-error *ngIf="nomeCompleto.hasError('maxlength')">
        O limite de caracteres é de 35
      </mat-error>
      <mat-error *ngIf="nomeCompleto.hasError('invalidName')">Nome incompleto</mat-error>
      </mat-form-field>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Data de nascimento</mat-label>
        <input type="hidden" [matDatepicker]="picker" [max]="maxDate"  [(ngModel)]="dataLabel">
        <input data-test="input-name-born-data" matInput mask="d0/M0/0000" [dropSpecialCharacters]="false" [max]="maxDate" [formControl]="dataNascimento"
            [ngModel]="dataLabel | date:'dd/MM/yyyy'">

        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dataNascimento.hasError('required')">O campo é obrigatório</mat-error>
        <mat-error *ngIf="dataNascimento.hasError('invalidDate')">A data é inválida</mat-error>
        <mat-error *ngIf="dataNascimento.hasError('dataValida')">
          Idade mínima não permitida. Somente pessoas que completarão 18 anos dentro de 8 meses podem se cadastrar.
        </mat-error>
    </mat-form-field>
      <mat-form-field class="inputs" appearance="fill">
        <mat-label>Estado civil</mat-label>
        <mat-select data-test="select-civil" [formControl]="estadoCivil">
            <mat-option *ngFor="let enum of listEstadoCivil" [value]="enum">{{enum}}</mat-option>
        </mat-select>
        <mat-error *ngIf="estadoCivil.invalid">
            O campo é obrigatório
        </mat-error>
    </mat-form-field>
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Sexo</mat-label>
      <mat-select data-test="select-biologic-sex" [formControl]="sexo">
          <mat-option *ngFor="let enum of listSexo | keyvalue" [value]="enum.key">{{enum.value}}</mat-option>
      </mat-select>
      <mat-error *ngIf="sexo.invalid">
          O campo é obrigatório
      </mat-error>
  </mat-form-field>
  <mat-form-field class="inputs" appearance="fill">
    <mat-label>Nome da mãe</mat-label>
    <input data-test="input-mother" [formControl]="nomeMae" inputmode="text" matInput maxlength="120" (input)="sanitizeInput($event, 'nomeMae')"
    (paste)="handlePaste($event, 'nomeMae')"
    pattern="[a-zA-ZÀ-ÖØ-öø-ÿ]+( [a-zA-ZÀ-ÖØ-öø-ÿ]+)*">
    <mat-error *ngIf="nomeMae.hasError('required')">
      O campo é obrigatório
  </mat-error>
  <mat-error *ngIf="nomeMae.hasError('maxlength')">
    O limite de caracteres é de 120
  </mat-error>
  <mat-error *ngIf="nomeMae.hasError('invalidName')">Nome incompleto</mat-error>
  </mat-form-field>
    </div>
    <div *ngIf="!timeoutError && !genericError" class="container-buttons">
      <div class="divisor-content">
          <yfs-button state="enabled" label="CANCELAR" type="tertiary" (click)="openDialog()"></yfs-button>
          <span class="remaining-number">{{ getRemainingNumber() }}</span>
      </div>
      <div class="divisor-content-next">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="goBack()"></yfs-button>
        <yfs-button state="enabled" label="PROSSEGUIR" type="primary" (click)="nextStep()"></yfs-button>
      </div>
  </div>
  <div *ngIf="!timeoutError && !genericError" class="mobile-buttons">
    <span class="remaining-number">{{ getRemainingNumber() }}</span>
    <yfs-button state="enabled" label="PROSSEGUIR" type="primary" class="button" [fullWidth]="isFullWidth" (click)="nextStep()"></yfs-button>
    <yfs-button state="enabled" label="VOLTAR" type="secondary" class="button" [fullWidth]="isFullWidth" (click)="goBack()"></yfs-button>
    <yfs-button state="enabled" label="CANCELAR" type="tertiary" class="button" [fullWidth]="isFullWidth" (click)="openDialog()"></yfs-button>
</div>
</div>
</mat-card-content>
</mat-card>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
    <yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-import-file',
    templateUrl: './modal-import-file.component.html',
    styleUrls: ['./modal-import-file.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, NgIf, ButtonComponent]
})
export class ModalImportFileComponent {
  public fileSizeError: boolean = false;
  public srcResult: any;
  public fileName: any;
  public fileHtml: string | undefined;
  constructor(
    public dialogRef: MatDialogRef<ModalImportFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const htmlString: string = reader.result as string;
        this.fileHtml = htmlString;
        this.fileName = file.name;
      };

      reader.readAsText(file);
    }
  }

  processHtml(htmlString: string) {
    htmlString = htmlString.replace(/<ul[^>]*>/g, '<ul>');
    htmlString = htmlString.replace(/<li[^>]*>/g, '<li data-mce-style="text-indent: -7.1pt; font-size: 11pt;" style="text-indent: -7.1pt; font-size: 11pt;">');

    return htmlString;
  }

  onRemoveFile() {
    this.fileName = null;
    const inputNode = document.querySelector('input[type="file"]') as HTMLInputElement;
    inputNode.value = null ?? '';
    this.fileSizeError = false;
  }

  handleUploadFile = () => {
    this.dialogRef.close();
    this.data.importFile(this.fileHtml)
  }
}

<yfs-header></yfs-header>
<section class="container-content">
  <div class="button-back">
      <yfs-link-back text="Voltar para tabela de perfis" (click)="getBack()"></yfs-link-back>
  </div>
  <div class="container-title">
      <h1>CADASTRAR PERFIL</h1>
  </div>
  <mat-card>
      <mat-card-content>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Título do perfil</mat-label>
          <input data-test="input-name" [formControl]="name" matInput maxlength="100">
          <mat-error *ngIf="name.invalid">
            O campo é obrigatório
          </mat-error>
        </mat-form-field>

        <div>
          <p>Tipo de Perfil:</p>
          <mat-radio-group [formControl]="profileType">
            <mat-radio-button [value]="true">Interno</mat-radio-button>
            <mat-radio-button [value]="false">Externo</mat-radio-button>
          </mat-radio-group>
        </div>

        <div style="margin-top: 12px;">
          <p>Permissões do perfil:</p>
        </div>
          <div class="checkbox-group" style="max-height: 450px;  overflow-y: auto;">
          <mat-checkbox color="primary" *ngFor="let permission of permissions" [formControl]="permission.control">
            {{ permission.label }}
          </mat-checkbox>
        </div>

        <div class="container-buttons">
          <div class="content-buttons">
            <yfs-button state="enabled" label="CANCELAR" (click)="openDialogCancel()" type="secondary"></yfs-button>
            <yfs-button [state]="isFormValid() ? 'enabled' : 'disabled'" [press]="sendRequest" label="CRIAR PERFIL" type="primary"></yfs-button>
          </div>
        </div>
      </mat-card-content>
  </mat-card>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

<section class="container-content" *ngIf="!releaseValuesIsOpen">
<div class="container-title">
  <div class="button-back">
      <yfs-link-back text="Voltar para planos contratados" (click)="getBack()"></yfs-link-back>
    </div>
  <h1>PLANO DO CLIENTE</h1>
</div>
<div class="margin-top-32" *ngIf="showExtension" >
  <mat-card class="box-shadow-card">
<mat-card-content>
  <div class="message-document-info">
    <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols" fontIcon="info"> </mat-icon>
    <span>Para efetuar a prorrogação do plano, os critérios abaixo precisam estar aderentes. Verifique se todos eles foram atendidos e prossiga com a solicitação.</span>
</div>
<div class="itens">
  <div class="item">
    <mat-icon *ngIf="restricaoCredito === 1; else iconX" aria-hidden="false" aria-label="Ícone de sucesso" class="material-symbols success-icon" fontIcon="check"></mat-icon>
    <ng-template #iconX>
      <mat-icon aria-hidden="false" aria-label="Ícone de erro" class="material-symbols error-icon" fontIcon="close"></mat-icon>
    </ng-template>
    <span>Restrição de crédito</span>
  </div>
  <div class="item">
    <mat-icon *ngIf="quitacao === 1; else iconX" aria-hidden="false" aria-label="Ícone de sucesso" class="material-symbols success-icon" fontIcon="check"></mat-icon>
    <ng-template #iconX>
      <mat-icon aria-hidden="false" aria-label="Ícone de erro" class="material-symbols error-icon" fontIcon="close"></mat-icon>
    </ng-template>
    <span>Quitação de plano</span>
  </div>
  <div class="item">
    <span>Saldo projetado: {{saldoProjetado | currency}}</span>
  </div>
</div>
<div class="margin-table scrollable-table-container">
  <table mat-table [dataSource]="dataSource" #secondTable #secondTableSort="matSort" matSort
      (matSortChange)="announceSortChange($event)">

      <ng-container matColumnDef="recarga">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              Recarga
          </th>
          <td mat-cell *matCellDef="let element"> {{element.recarga}}</td>
      </ng-container>

      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by number">
            Valor
        </th>
        <td mat-cell *matCellDef="let element"> {{element.valor | currency}}</td>
    </ng-container>

      <ng-container matColumnDef="dataVencimento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            sortActionDescription="Sort by dataVencimento">
            Data de vencimento
        </th>
        <td mat-cell *matCellDef="let element">
        <div class="td-icon">
          <span> {{element.dataVencimento | date:'dd/MM/yyyy'}}</span>
          <div class="align-right">
          </div>
        </div>
      </td>
      </ng-container>

      <ng-container matColumnDef="statusSituacaoTitulo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by statusSituacaoTitulo">
              Status
          </th>
          <td mat-cell *matCellDef="let element"> {{getStatusName(element.statusSituacaoTitulo)}}
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div class="message-document-warning">
  <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols-outlined" fontIcon="warning"> </mat-icon>
  <span>Para efetuar a prorrogação, serão geradas 6 parcelas adicionais ao plano e pelo menos 4 delas deverão ser pagas de forma pontual para atingir a elegibilidade ao final da prorrogação. Este plano só poderá ser prorrogado 1 vez.</span>
</div>
</mat-card-content>
</mat-card>
<div class="container-buttons">
  <div *appHasAuthorization="cancelAllowedPermissions">
  <yfs-button
  type="secondary"
  [state]="'enabled'"
  label="QUERO RESGATAR O SALDO"
  color="red"
  [fullWidth]="isFullWidth"
  [press]="openReleaseValues"
>
</yfs-button>
  </div>
  <yfs-button [fullWidth]="isFullWidth" label="PRORROGAR PLANO" [state]="isButtonDisabled() ? 'disabled' : 'enabled'" type="primary" [press]="openModalExtension"></yfs-button>
</div>
</div>

<div class="container" *ngIf="showSendCard || termSent">
  <div class="divisor-content steps">
    <span class="points"><img src="assets/svg/step-1.svg" alt="icone do primeiro passo"> ..... </span>
    <span *ngIf="!fileSizeError && showSendCard"><img src="assets/svg/step-2.svg" alt="icone de segundo passo"></span>
    <span *ngIf="fileSizeError"><img src="assets/svg/step-2-error.svg" alt="icone de segundo passo com erro"></span>
    <span *ngIf="!showSendCard && !fileSizeError"><img src="assets/svg/step-2-check.svg" alt="icone de segundo passo com sucesso"></span>
  </div>
  <mat-card *ngIf="showSendCard && assinaturaEletronicaHabilitada" class="card">
    <mat-card-content class="card-content">
       <div class="recipient-content">
        <span>Escolha como quer receber o termo de prorrogação:</span>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="formaEnvio">
          <mat-radio-button class="margin-left" value="Email">E-mail</mat-radio-button>
          <mat-radio-button class="margin" value="Whatsapp">Whatsapp</mat-radio-button>
          <mat-radio-button class="margin" value="Sms">SMS</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field *ngIf="formaEnvio === 'Email'" class="input" appearance="fill">
        <mat-label>E-mail</mat-label>
        <input data-test="input-email" [formControl]="email" matInput maxlength="50">
      </mat-form-field>
      <mat-form-field *ngIf="formaEnvio === 'Whatsapp'" class="input" appearance="fill">
        <mat-label>Whatsapp</mat-label>
        <input data-test="input-phone-cell-ddd"  [formControl]="celular" mask="(00) 0 0000-0000" matInput>
      </mat-form-field>
      <mat-form-field *ngIf="formaEnvio === 'Sms'" class="input" appearance="fill">
        <mat-label>SMS</mat-label>
        <input data-test="input-phone-cell-ddd"  [formControl]="sms" mask="(00) 0 0000-0000" matInput>
      </mat-form-field>
      <div style="margin-bottom: 24px;">
        <div class="alert-message">
          <span class="icon-alert-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
      fontIcon="info"></mat-icon></span>
          <span class="title-alert-message">Certifique-se que os dados estejam corretos, pois eles somente poderão ser alterados após a assinatura do termo de prorrogação.</span>
        </div>
    </div>
    <div class="button-confirm">
      <yfs-button [fullWidth]="isFullWidth" data-test="button-regulamento" label="CONFIRMAR FORMA DE ENVIO" icon="check_circle" (click)="verifyShipping()" type="primary" state="enabled"></yfs-button>
    </div>
    </mat-card-content>
</mat-card>
<div *ngIf="termSent && assinaturaEletronicaHabilitada" class="container">
  <div class="verify-title-message-container">
    <span class="verify-title-message">Termo de prorrogação enviado e aguardando assinatura.</span>
  </div>
  <div class="verify-message">
    <span class="verify-subtitle-message">Verifique o e-mail, whatsapp ou sms escolhido para envio e <b>assine o termo de prorrogação</b> para efetivar a prorrogação do contrato.</span>
  </div>
  <div  style="margin-bottom: 40px;">
    <div class="card-signature-message">
      <span class="icon-signature-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
  fontIcon="info"></mat-icon></span>
      <span class="signature-message"><b>Assine o termo até {{ dataExpiracaoTermoProrrogacao | date : 'dd/MM/yyyy' }}</b> para efetivar a prorrogação do contrato. A não assinatura do mesmo resultará no retorno ao status anterior.</span>
    </div>
  </div>
  <div class="plan">
  <yfs-button [fullWidth]="isFullWidth" data-test="button-regulamento" label="IR PARA PLANOS CONTRATADOS" class="plan" (click)="goToPlan()" type="primary" state="enabled"></yfs-button>
  </div>
</div>
</div>
</section>
<yfs-release-paid-amounts *ngIf="releaseValuesIsOpen" (closeReleaseValues)="closeReleaseValues()"
    [idContrato]="idContrato"
    [statusContratoNumero]="statusContratoNumero"
    [cpfClient]="cpfClient"
    [userName]="userName"
    [concessionariaValue]="concessionariaValue"
    [dadosBancariosData]="dadosBancariosData"
    [emailCliente]="emailCliente"
    [telefoneCliente]="telefoneCliente"></yfs-release-paid-amounts>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>

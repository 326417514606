import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { UserService } from 'src/app/services/user.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { HeaderComponent } from '../../components/header/header.component';
import { NgIf } from '@angular/common';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { RegisterConcessionaireComponent } from './register-concessionaire/register-concessionaire.component';
import { RegisterDocumentsComponent } from './register-documents/register-documents.component';
import { RegisterPersonalDataComponent } from './register-personal-data/register-personal-data.component';
import { RegisterAddressComponent } from './register-address/register-address.component';
import { RegisterContactComponent } from './register-contact/register-contact.component';
import { VerifyDataComponent } from './verify-data/verify-data.component';
import { RegisterDeliveryConcessionaireComponent } from './register-delivery-concessionaire/register-delivery-concessionaire.component';
import { CompleteComponent } from './complete/complete.component';

interface IRelationBackFlow {
  [key: string]: string;
}

@Component({
    selector: 'yfs-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    imports: [
        HeaderComponent,
        NgIf,
        LinkBackComponent,
        RegisterConcessionaireComponent,
        RegisterDocumentsComponent,
        RegisterPersonalDataComponent,
        RegisterAddressComponent,
        RegisterContactComponent,
        VerifyDataComponent,
        RegisterDeliveryConcessionaireComponent,
        CompleteComponent,
    ],
})
export class RegisterComponent implements OnInit {
  public currentFlow = '';
  public relationBackFlow: IRelationBackFlow = {
    concessionaire: 'home',
    document: 'concessionaire',
    personalData: 'document',
    address: 'personalData',
    contact: 'address',
    delivery: 'contact',
    verifyData: 'contact',
  };

  private tokenSubscription: any;
  constructor(private userService: UserService, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    this.isLogged()

    this.tokenSubscription = this.userService.getLoggedUserObservable().subscribe(user => {
      const userLocal: any = user;
      if (userLocal && !userLocal.PermissaoAcesso.includes(EPermissaoAcesso.CONTRATAR_PLANO)) {
        this.dialog.closeAll()
        this.openModalUnauthorized();
        this.router.navigate(["home"]);
      }
    });
  }

  ngOnDestroy() {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
  }

  isLogged() {
    if (this.userService.userIsLogged()) {
      this.currentFlow = 'concessionaire';
    } else {
      this.currentFlow = 'document';
    }
  }

  changeCurrentFlow(flow: string) {
    this.currentFlow = flow;
    window.scrollTo(0, 0);
  }

  getBackHandle() {
    const navigationExtras: NavigationExtras = {
      state: {
        flow: 'productView',
      },
    };

    this.router.navigate(['/simulation'], navigationExtras);

    const flowToGo = this.relationBackFlow[this.currentFlow];
    this.currentFlow = flowToGo;
  }

  openModalUnauthorized() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não é possível visualizar essas informações pois você não tem permissões para acessar essa tela.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }
}

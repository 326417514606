import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { Router } from '@angular/router';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { ButtonComponent } from '../../components/buttons/button/button.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { HasAuthorizationDirective } from '../../shared/directives/has-authorization.directive';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, MatCard, MatCardContent, NgIf, ButtonComponent, MatMenuTrigger, MatMenu, HasAuthorizationDirective, MatMenuItem, MatIcon, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatSelect, MatCheckbox, NgFor, MatOption, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, NgClass, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, LoadingSpinnerComponent, DatePipe]
})
export class UserManagementComponent {

  public nomeUsuario = new FormControl('');
  public perfilAcesso = new FormControl();
  public tipoUsuario = new FormControl();
  public hasValue = true;
  public isLoading = false;
  public listaUsuarios = [];
  public length = 50;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public inactiveUserSelected = {}

  public selectedPerfilAcesso: number[] = [];
  public listaPerfilAcesso: any[] = [];
  public idsPerfilAcesso: number[] = [];
  public checkedAllPerfilAcesso = false;

  public selectedTipoUsuario: number[] = [];
  public listaTipoUsuario: any[] = [
    {
      idTipoUsuario: 1,
      nome: "Interno"
    },
    {
      idTipoUsuario: 0,
      nome: "Externo"
    }];
  public idsTipoUsuario: number[] = [];
  public checkedAllTipoUsuario = false;

  public displayedColumns: string[] = ["nome", "email", "perfilAcesso", "ultimoLogin", "concessionaria", "ativo", "acoes"];
  public dataSource: any;
  public addInternalUserPermission = [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO];
  public addExternalUserPermission = [EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO];
  public typePerfil: string = '';
  public permissions: string[] = [];

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
    public dialog: MatDialog,
    private customPaginator: MatPaginatorIntl,
    private router: Router,
    private userService: UserService
  ) {
    const user = this.userService.getCurrentUser();
    this.typePerfil = user?.PerfilAcesso;
    customPaginator.itemsPerPageLabel = "Itens por página";
    this.searchUsers()
    this.getPerfilAcesso()
  }

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  setPageStart() {
    this.pageIndex = 0;
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.searchUsers()
  }

  getBack() {
    this.router.navigate(['area']);
  }

  sendEditData(login: any, interno: boolean, perfilAcesso: any) {
    let userEdit = this.getPerfilAcessoNome(perfilAcesso);
    const user = this.userService.getCurrentUser()
    if (user) {
      this.permissions = user.PermissaoAcesso || [];
    }
    if (interno)
    this.router.navigate(['edit-internal-user', login]);
      else {
        if (userEdit === "Master" && user.PerfilAcesso === "Master" || userEdit === "Master Representante" && user.PerfilAcesso === "Master Representante" || userEdit === "Master" && user.PerfilAcesso === "Master Representante" || userEdit === "Master Representante" && user.PerfilAcesso === "Master" || this.hasPermission('PERMISSAO_EDICAO_USUARIOS_GERENCIAMENTO_USUARIOS')) {
          this.openDialogMasterError()
        } else
        this.router.navigate(['edit-external-user', login]);
    }
  }

  getPerfilAcessoNome(perfilAcesso: number): string {
    switch (perfilAcesso) {
      case 9:
        return "Backoffice Financeiro";
      case 8:
        return "Backoffice Formalização";
      case 11:
        return "Cadastro";
      case 6:
        return "Cliente";
      case 7:
        return "Contábil";
      case 4:
        return "Master";
      case 12:
        return "Master Representante";
      case 13:
        return "Vendedor Representante";
      case 2:
        return "Produtos";
      case 10:
        return "SAC / Ouvidoria";
      case 1:
        return "SI-Acessos";
      case 3:
        return "TI - Sistemas";
      case 5:
        return "Vendedor";
      default:
        return "";
    }
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  selectAllPerfilAcesso() {
    if (this.selectedPerfilAcesso.length === this.listaPerfilAcesso.length) {
      this.selectedPerfilAcesso = [];
      this.idsPerfilAcesso = [];
      this.checkedAllPerfilAcesso = false;
    } else {
      this.selectedPerfilAcesso = this.listaPerfilAcesso.map((perfilAcesso: { idPerfilAcesso: any; }) => perfilAcesso.idPerfilAcesso);
      this.idsPerfilAcesso = this.selectedPerfilAcesso.slice();
      this.checkedAllPerfilAcesso = true;
    }
    this.perfilAcesso.setValue(this.selectedPerfilAcesso);
    this.setPageStart();
    this.searchUsers();
  }

  checkHandlePerfilAcesso(IdPerfilAcesso: number) {
    if (this.selectedPerfilAcesso.includes(IdPerfilAcesso)) {
      this.selectedPerfilAcesso = this.selectedPerfilAcesso.filter(item => item !== IdPerfilAcesso);
    } else {
      this.selectedPerfilAcesso.push(IdPerfilAcesso);
    }

    this.idsPerfilAcesso = this.selectedPerfilAcesso.slice();

    if (this.selectedPerfilAcesso.length === this.listaPerfilAcesso.length) {
      this.checkedAllPerfilAcesso = true;
    } else {
      this.checkedAllPerfilAcesso = false;
    }
    this.perfilAcesso.setValue(this.selectedPerfilAcesso);
    this.setPageStart();
    this.searchUsers();
  }

  selectAllTipoUsuario() {
    if (this.selectedTipoUsuario.length === this.listaTipoUsuario.length) {
      this.selectedTipoUsuario = [];
      this.idsTipoUsuario = [];
      this.checkedAllTipoUsuario = false;
    } else {
      this.selectedTipoUsuario = this.listaTipoUsuario.map(tipoUsuario => tipoUsuario.idTipoUsuario);
      this.idsTipoUsuario = this.selectedTipoUsuario.slice();
      this.checkedAllTipoUsuario = true;
    }
    this.tipoUsuario.setValue(this.selectedTipoUsuario);
    this.setPageStart();
    this.searchUsers();
  }

  checkHandleTipoUsuario(idTipoUsuario: number) {
    if (this.selectedTipoUsuario.includes(idTipoUsuario)) {
      this.selectedTipoUsuario = this.selectedTipoUsuario.filter(item => item !== idTipoUsuario);
    } else {
      this.selectedTipoUsuario.push(idTipoUsuario);
    }

    this.idsTipoUsuario = this.selectedTipoUsuario.slice();

    if (this.selectedTipoUsuario.length === this.listaTipoUsuario.length) {
      this.checkedAllTipoUsuario = true;
    } else {
      this.checkedAllTipoUsuario = false;
    }
    this.tipoUsuario.setValue(this.selectedTipoUsuario);
    this.setPageStart();
    this.searchUsers();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  searchUsers() {
    const searchValue = this.nomeUsuario.value ?? ""

    this.isLoading = true;
    this.apiService.getUsers(searchValue, this.idsPerfilAcesso, this.idsTipoUsuario, this.pageIndex + 1, this.pageSize).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.listaUsuarios = result.items;
          this.dataSource = new MatTableDataSource<any>(this.listaUsuarios);
          this.dataSource.sort = this.firstTableSort;
          this.dataSource.sortingDataAccessor = (item: any, property: string) => {
            switch (property) {
              case 'nome':
                return item.nome?.toLowerCase() || '';
              case 'email':
                return item.email?.toLowerCase() || '';
              case 'perfilAcesso':
                return item.perfilAcesso?.nome?.toLowerCase() || '';
              case 'ultimoLogin':
                return item.dataUltimoLogin || '';
                case 'concessionaria':
                  return item.usuariosConcessionarias?.[0]?.concessionaria?.razaoSocial?.toLowerCase() || '';
              default:
                return item[property];
            }
          };
          if (this.listaUsuarios && this.listaUsuarios.length === 0) this.hasValue = false
          else this.hasValue = true;
          this.length = result.totalCount
          this.customPaginator.getRangeLabel = this.getRangeLabel
        },
        error: error => {
          this.isLoading = false;
          console.log(error)
        }
      })
  }

  activeUser() {
    this.isLoading = true;
    this.apiService.activeOrInactiveUser(this.inactiveUserSelected).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.openDialogSucess('active')
        },
        error: error => {
          this.isLoading = false;
          this.openDialogError('active')
          console.log(error)
        }
      })
  }

  inactiveUser() {
    this.isLoading = true;
    this.apiService.activeOrInactiveUser(this.inactiveUserSelected).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    )
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.openDialogSucess('inactive')
        },
        error: error => {
          this.isLoading = false;
          this.openDialogError('inactive')
          console.log(error)
        }
      })
  }

  handleSituationClick(situation: boolean, nomeUsuario: string, login: string) {
    this.inactiveUserSelected = {
      login: login,
      habilitado: !situation
    }
    if (situation == true) this.openDialogInactiveUser(nomeUsuario)
    else this.openDialogActiveUser(nomeUsuario)
  }

  openDialogInactiveUser(nomeUsuario: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '388px',
      data: {
        text: "Ao confirmar, o usuário perderá acesso ao sistema.",
        textBold: "Tem certeza que deseja inativar este usuário?",
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, INATIVAR',
        secundaryButtonAction: () => { this.inactiveUser() },
        secundaryButtonVariant: 'primary',
        secundaryButtonColor: 'red',
        highlightText: nomeUsuario
      }
    })
  }

  openDialogActiveUser(nomeUsuario: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '388px',
      data: {
        text: "Ao confirmar, o usuário terá acesso ao sistema.",
        textBold: "Tem certeza que deseja ativar este usuário?",
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ATIVAR',
        secundaryButtonAction: () => { this.activeUser() },
        secundaryButtonVariant: 'primary',
        highlightText: nomeUsuario
      }
    })
  }

  openDialogSucess(situation: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: `Usuário ${situation == 'active' ? 'ativado' : 'inativado'} com sucesso.`,
        primaryButtonText: 'IR PRA A LISTA DE USUÁRIOS',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'primary'
      }
    }).afterClosed().subscribe(() => this.searchUsers())
  }

  openDialogError(situation: string) {
    this.dialog.open(ModalGenericComponent, {
      data: {
        icon: 'error',
        text: `Houve um problema ao tentar ${situation == 'active' ? 'ativar' : 'inativar'} o usuário.`,
        primaryButtonText: 'VOLTAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {
          if (situation == 'active')
            this.activeUser()
          else
            this.inactiveUser()
        }
      }
    })
  }

  openDialogMasterError() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: `Não é possível editar os dados de um usuário Master. Para isso, entre em contato com o SI.`,
        primaryButtonText: 'FECHAR',
        primaryButtonAction: 'close',
        primaryButtonVariant: 'primary'
      }
    })
  }

  goToRegisterInternalUser() {
    this.router.navigate(["create-internal-user"])
  }

  goToRegisterExternalUser() {
    this.router.navigate(["create-external-user"])
  }

  getPerfilAcesso() {
    this.isLoading = true;
    this.apiService.getPerfilAcesso().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result && result.length > 0) {
            this.listaPerfilAcesso = result;
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

}

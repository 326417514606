import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ButtonComponent } from '../buttons/button/button.component';

@Component({
    selector: 'yfs-modal-delete-message',
    templateUrl: './modal-delete-message.component.html',
    styleUrls: ['./modal-delete-message.component.scss'],
    standalone: true,
    imports: [CdkScrollable, MatDialogContent, ButtonComponent]
})
export class ModalDeleteMessageComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalDeleteMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }

  deleteTemplate() {
    this.closeModal()
    this.data.handleDeleteTemplate()
  }
}

<section class="container-content">
    <div class="button-back">
        <yfs-link-back text="Voltar para produtos" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
        <h1>EDITAR PRODUTO</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="product-image">
                <img
                    [src]="imgUpload">
                    <input hidden data-test="input-file" (change)="onFileSelected($event)" #fileInput type="file" accept=".jpeg, .jpg, .png, .bmp"
            id="file">
                    <yfs-button *ngIf="!isDisabledButton" state="enabled" label="ALTERAR IMAGEM" type="primary" size="small" (click)="fileInput.click()"></yfs-button>
                    <yfs-button *ngIf="isDisabledButton" state="disabled" label="ALTERAR IMAGEM" type="primary" size="small"></yfs-button>

            </div>
            <div class="form-section">
                <div class="inputs">
                    <mat-form-field style="width: 33%;" appearance="fill">
                        <mat-label>Produto</mat-label>
                        <input [formControl]="nomeProduto" matInput>
                    </mat-form-field>
                    <mat-form-field *ngIf="situacao.value != '3'" style="width: 33%;" appearance="fill">
                        <mat-label>Situação</mat-label>
                        <mat-select [formControl]="situacao">
                            <mat-option *ngFor="let situacao of listaStatus"
                                [value]="situacao.idStatus">{{situacao.nome}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="situacao.invalid">
                            O campo é obrigatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="situacao.value == '3'" style="width: 33%" appearance="fill">
                        <mat-label>Situação</mat-label>
                        <mat-select [formControl]="situacao">
                            <mat-option [value]="3">Descontinuado</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 33%;" appearance="fill">
                      <mat-label>MTcode</mat-label>
                      <input [formControl]="mtCode" (input)="filtrarNumeros()" maxlength="4" matInput>
                      <mat-error *ngIf="mtCode.invalid">
                        O campo é obrigatorio
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="inputs" style="margin-top: 2px;">
                    <mat-form-field style="width: 50%;" appearance="fill">
                      <mat-label>Valor Base</mat-label>
                      <input [formControl]="valorBase" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" matInput>
                      <mat-error *ngIf="valorBase.invalid">
                            O campo é obrigatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 50%;" appearance="fill">
                      <mat-label>Valor Referência</mat-label>
                      <input [formControl]="valorReferencia" matInput>
                      <mat-error *ngIf="valorReferencia.invalid">
                            O campo é obrigatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="percentual-section">
                    <span class="title">Percentual de capitalização</span>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoCapitalizacao" (change)="handleRadioClick()">
                        <mat-radio-button style="margin-left: -10px;" value="standard" [disabled]="isDisabledTipoCapitalizacao"><span class="span-radio">Standard</span></mat-radio-button>
                        <mat-radio-button style="margin-left: 32px;" value="customizado" [disabled]="isDisabledTipoCapitalizacao"><span class="span-radio">Customizado</span></mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field *ngIf="tipoCapitalizacao == 'standard'" style="width: 100%;" appearance="fill">
                    <mat-label>Percentual de capitalização Standard</mat-label>
                    <input [formControl]="percentualCapitalizacaoStandard" matInput>
                </mat-form-field>
                <div class="inputs" *ngIf="tipoCapitalizacao == 'customizado'">
                    <mat-form-field style="width: 50%;" appearance="fill">
                        <mat-label>Percentual de capitalização Mínimo</mat-label>
                        <input [formControl]="percentualCapitalizacaoMinimo" maxlength="4" (input)="formatPercentualMinimo()" matInput>
                        <mat-error *ngIf="percentualCapitalizacaoMinimo.hasError('required')">
                            O campo é obrigatorio
                        </mat-error>
                        <mat-error *ngIf="percentualCapitalizacaoMinimo.hasError('rangeError')">
                            O percentual deve ser entre 10% e 100%
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 50%;" appearance="fill">
                        <mat-label>Percentual de capitalização Máximo</mat-label>
                        <input [formControl]="percentualCapitalizacaoMaximo" maxlength="4" (input)="formatPercentualMaximo()" matInput>
                        <mat-error *ngIf="percentualCapitalizacaoMaximo.hasError('required')">
                            O campo é obrigatorio
                        </mat-error>
                        <mat-error *ngIf="percentualCapitalizacaoMaximo.hasError('rangeError')">
                            O percentual deve ser entre 10% e 90%
                        </mat-error>
                        <mat-error *ngIf="percentualCapitalizacaoMaximo.hasError('minValueError')">
                            O percentual máximo deve ser maior que o mínimo
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="percentual-section">
                    <span class="title">Majoração</span>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoMajoracao" (change)="handleRadioMajoracaoClick()">
                        <mat-radio-button style="margin-left: -10px;" value="standard" [disabled]="isDisabledTipoMajoracao"><span class="span-radio">Standard</span></mat-radio-button>
                        <mat-radio-button style="margin-left: 32px;" value="customizado" [disabled]="isDisabledTipoMajoracao"><span class="span-radio">Customizado</span></mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="inputs">
                <mat-form-field style="width: 50%;" appearance="fill">
                  <mat-label>{{ tipoMajoracao === 'customizado' ? 'Majoração de PPS Customizado' : 'Majoração de PPS Standard' }}</mat-label>
                    <input [formControl]="majoracao" maxlength="4" (input)="formatPercentualMajoracao()" matInput>
                    <mat-error *ngIf="majoracao.hasError('required')">
                        O campo é obrigatorio
                    </mat-error>
                    <mat-error *ngIf="majoracao.hasError('rangeError')">
                        O percentual deve ser entre 0% e 100%
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="tipoMajoracao === 'customizado'" style="width: 50%;" appearance="fill">
                  <mat-label>Valor PPS Customizado</mat-label>
                  <input [formControl]="majoracaoFinal" matInput>
                </mat-form-field>
            </div>
                <mat-form-field style="width: 100%; margin-top: 2px;" appearance="fill">
                    <mat-label>Informações</mat-label>
                    <textarea [formControl]="informacoes" maxlength="2000" matInput></textarea>
                </mat-form-field>
                <div class="button-content">
                    <yfs-button state="enabled" label="CANCELAR" type="secondary" (click)="openDialogExist()"></yfs-button>
                    <yfs-button [state]="situacao.value == '3' || situacao.invalid || valorBase.invalid || majoracao.hasError('required') || mtCode.invalid || (tipoCapitalizacao === 'customizado' && (percentualCapitalizacaoMaximo.hasError('required') || percentualCapitalizacaoMinimo.hasError('required'))) ? 'disabled' : 'enabled'" label="SALVAR" type="primary" [press]="openDialogSaveChanges"></yfs-button>
                </div>
            </div>
        </mat-card-content>

  </mat-card>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
